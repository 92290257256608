/**
 * Gets list of saved projects by user.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function getUserProjects(userid, addressid, keywords, fromDate, toDate, dispatch) {

        const abortController = new AbortController();

        const startDate = fromDate.getFullYear()+'-'+ ('0'+(fromDate.getMonth()+ 1)).slice(-2) +'-'+('0'+fromDate.getDate()).slice(-2);
        const endDate = toDate.getFullYear()+'-'+ ('0'+(toDate.getMonth()+ 1)).slice(-2) +'-'+('0'+toDate.getDate()).slice(-2);

        //console.log("Get projects: u."+userid+" a."+addressid);
        const request = {   
            userid:userid,
            addressid:addressid,
            keywords:keywords.trim(),
            fromDate:startDate,
            toDate:endDate,
          };
    
        let mounted = true;
        (async () => {  
          const res = await fetch(process.env.REACT_APP_NMC_API+'/project/get', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request),
            signal: abortController.signal,
          }).catch(handleError);
          const data = await res.json();
          if (mounted) {
            if (data.status === "success"){
                dispatch({
                    type: "setprojects",
                    payload: { 
                        projects: data.projects,
                    }
                });
    
            } else {
            }
          }
          const cleanup = () => {
            mounted = false;
            abortController.abort();
          };
          return cleanup;
      
      })();

    }
