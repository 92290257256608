import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Topbar from './createComponents/Topbar';
import InfoPanel from './createComponents/LeftInfoPanel';
import StepToolPanel from './createComponents/RightToolPanel';
import LocationPanel from './createComponents/LocationPanel';
import MappingPanel from './createComponents/MappingPanel';
import SummaryPanel from './createComponents/OrderSummaryPanel';
import CompletePanel from './createComponents/OrderCompletePanel';

import { SessionContext } from "../context/session-context";
import { ActivityContext } from "../context/activity-context";

const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    }

  });
  
  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

export default function CreateInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [hasInfoPanel, setHasInfoPanel] = useState(true);//default: true
    const [infoPanelWidth, setInfoPanelWidth] = useState(0);//default: 0
    const [hasLocationPanel, setHasLocationPanel] = useState(true); //default: true
    const [hasMappingPanel, setHasMappingPanel] = useState(false);//default: false
    const [hasSummaryPanel, setHasSummaryPanel] = useState(false);//default: false
    const [hasPaymentPanel, setHasPaymentPanel] = useState(false);//default: false
    const [hasCompletePanel, setHasCompletePanel] = useState(false);//default: false
    const [hasStepPanel, setHasStepPanel] = useState(false);//default: false
    const [containerDiv, setContainerDiv] = useState({});
    const [mainPanelHeight, setMainPanelHeight] = useState(500);
    const [mainPanelWidth, setMainPanelWidth] = useState(500);

    /**
     * First load
     */
    useEffect(() => {
        getScreenSize();

        setContainerDiv({
          display:"flex",
          flexDirection:"row",
          width:"100%"
        })

    },[])

    /**
     * First load, set resize window listerner
    */
   useEffect(() => {
     const debouncedHandleResize = debounce(function handleResize() {
       getScreenSize();
     }, 1000)
 
     window.addEventListener('resize', debouncedHandleResize);
 
     return _ => {
       window.removeEventListener('resize', debouncedHandleResize);
     }
   })

   /**
    * Calculates the required screensize
    */
    const getScreenSize = () => {

      let panelW = parseInt(window.innerWidth);
      setMainPanelHeight(parseInt(window.innerHeight) - 86);

      if (hasInfoPanel) panelW -= 272;
      if (hasStepPanel) panelW -= 275;
      setMainPanelWidth(panelW);
    }

    /**
    * Determine what central panal to display
    * Central panel is based on cview
    */
   useEffect(() => {

    if (sState.isActive) { 

      //console.log("ViewStep: "+aState.viewStep);
      switch (aState.viewStep){
        default:
        case "address":
          setHasInfoPanel(true);
          setHasLocationPanel(true);
          setHasMappingPanel(false);
          setHasSummaryPanel(false);
          setHasPaymentPanel(false);
          setHasCompletePanel(false);
          setHasStepPanel(false);
          break;
        case "mapping":
          setHasInfoPanel(true);
          setHasStepPanel(true);
          setHasMappingPanel(true);
          setHasSummaryPanel(false);
          setHasLocationPanel(false);
          setHasPaymentPanel(false);
          setHasCompletePanel(false);
          break;
        case "orderSummary":
          setHasInfoPanel(true);
          setHasStepPanel(true);
          setHasSummaryPanel(true);
          setHasMappingPanel(false);
          setHasLocationPanel(false);
          setHasPaymentPanel(false);
          setHasCompletePanel(false);
          break;
        case "orderPayment":
          setHasInfoPanel(true);
          setHasStepPanel(true);
          setHasSummaryPanel(false);
          setHasPaymentPanel(true);
          setHasMappingPanel(false);
          setHasLocationPanel(false);
          setHasCompletePanel(false);
          break;
        case "orderComplete":
          setHasCompletePanel(true);
          setHasInfoPanel(true);
          setHasStepPanel(false);
          setHasSummaryPanel(false);
          setHasMappingPanel(false);
          setHasLocationPanel(false);
          setHasPaymentPanel(false);
          break;

      }
    }
  },[aState.viewStep])
  

  /**
   * Listern for changes to tool panel
   */
  useEffect(() => {
    getScreenSize();
  },[hasStepPanel])

    /**
   * Listern for changes to info panel
   */
  useEffect(() => {
    getScreenSize();
    if (hasInfoPanel){
      setInfoPanelWidth(272)
    } else {
      setInfoPanelWidth(0);
    }
  },[hasInfoPanel])

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Topbar />
                </Grid>
                <Grid item xs={12}>
                <div style={containerDiv} >
                    { hasInfoPanel ? <InfoPanel /> : '' }
                    { hasLocationPanel ? <LocationPanel height={mainPanelHeight} width={mainPanelWidth} /> : '' }
                    { hasMappingPanel ? <MappingPanel height={mainPanelHeight} width={mainPanelWidth} infoPanelWidth={infoPanelWidth}/> : '' }
                    { hasSummaryPanel ? <SummaryPanel height={mainPanelHeight} width={mainPanelWidth}/> : '' }
                    { hasPaymentPanel ? <SummaryPanel height={mainPanelHeight} width={mainPanelWidth}/> : '' }
                    { hasCompletePanel ? <CompletePanel height={mainPanelHeight}/> : '' }
                    { hasStepPanel ? <StepToolPanel height={mainPanelHeight}/> : '' }
                </div>
                </Grid>
            </Grid>
        </div>
    )
  };
