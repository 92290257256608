import React, { useState, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { ActivityContext } from "../../context/activity-context";

const useStyles = makeStyles({
    menuButton: {
      color: '#000',
        '&:hover': {
          color: '#F9AF44'
        }
    }
  });

export default function ProductLinkButton(props) {
  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);

  const handleClick = () => {
    aDispatch({
        type: "setorderstep",
        payload: { orderStep: "productSelect" }
    });
    aDispatch({
      type: "setprintframe",
      payload: { extent: [] }
    });
    aDispatch({
      type: "actionchange",
      payload: { action: "clear_orderframes" }
    });

  }

  return (
  <div className={classes.root}>
    {props.display ? <Button className={classes.menuButton} onClick={handleClick}>
              <Box fontSize={12}>Back to selection</Box>
          </Button> : ''
    }
  </div>
  );
}