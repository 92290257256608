import React, { Component } from 'react';

import UserInterface from './components/UserInterface';
import { ActivityContextProvider } from "./context/activity-context";
import { UserContextProvider } from "./context/user-context";
import { SessionContextProvider } from "./context/session-context";

import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
    }
    render() {
      return (
        <SessionContextProvider>
          <UserContextProvider>
            <ActivityContextProvider><UserInterface /> </ActivityContextProvider>
          </UserContextProvider> 
        </SessionContextProvider>
        );
    }
}

export default App;
