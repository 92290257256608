
import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ReactLoading from 'react-loading';

import { SessionContext } from "../../context/session-context";
import { UserContext } from "../../context/user-context";


const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '70vh',
        backgroundColor:'#3D3E4F',
        minWidth:350,
        minHeight: 500
    },
    benefitDiv: {
        paddingLeft: 'calc(100% / 10)',
        paddingRight: 'calc(100% / 10)',
        paddingTop: 'calc(100% / 5)',
    },
    benefits: {
        color:'#FFFFFF',
        paddingBottom: 10,
    },
    guestButton: {
        marginTop: 30,
        height: 47,
        color: '#000000',
          '&:hover': {
            color: '#F9AF44'
          }
    },
    registerButton: {
        marginTop: 20,
        height: 38,
        backgroundColor: '#F6A036',
        color: '#000000',
          '&:hover': {
            color: '#F9AF44'
          }
    },
    helplink:{
        color: '#F6A036',
        fontStyle: 'bold',
        textDecoration: 'none',
    },
    helpemail:{
        color: '#F6A036',
        textDecoration: 'none', 
    }
  });
  

export default function LoginForm(props) {
    const classes = useStyles();
  
    const [state, dispatch] = useContext(SessionContext);
    const [uState, uDispatch] = useContext(UserContext);

    const handleGuest = (event) => {

    };
    const handleRegister = (event) => {

    };

    const handleChange = (event) => {

    };

    return (
        <div className={classes.root}>
            <div className={classes.benefitDiv}>
                <Box component="span" display="block" className={classes.benefits} fontWeight="fontWeightBold" fontSize={16}>Welcome</Box>
                <Box component="span" display="block" className={classes.benefits} fontSize={16}>Our new map system is intutive and easy to use, but if you need help, please use our <a className={classes.helplink} href='https://www.mapsnmc.co.uk/resources/' target="_blank">quick guides.</a></Box>
                <Box component="span" display="block" className={classes.benefits} fontSize={16}>We will be adding more functionality during {new Date().getFullYear()}, those areas that are currently greyed out will be added soon.</Box>
                <Box component="span" display="block" className={classes.benefits} fontSize={16}>Any comments? Please email: <a href="mailto:help@mapsnmc.co.uk" className={classes.helpemail}>help@mapsnmc.co.uk</a></Box>
                <Box component="span" display="block">
                    <Button className={classes.guestButton} disabled={true} variant="contained" onClick={handleGuest}>
                        <Box fontWeight="fontWeightBold"  fontSize={22}>Continue as guest</Box>
                    </Button>
                </Box>
                <Box component="span" display="block">
                    <Button className={classes.registerButton} disabled={true} variant="contained" onClick={handleRegister}>
                        <Box fontWeight="fontWeightBold" fontSize={16}>Register new account</Box>
                    </Button>
                </Box>
            </div>
        </div>
    )
  };
