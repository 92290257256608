/**
 * Gets the user details from database including default settings.
 * Stores in User Context.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };


export default async function getUserDetails(userid,ident) {
    try{
    const request = {   
        ident:ident,
        userid:userid
      };
    const res = await fetch(process.env.REACT_APP_NMC_API+'/users/detail', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(request),
        }).catch(handleError);

    const data = await res.json();
    //console.log("data: "+JSON.stringify(data));
    return data;
    } catch (e){
        //console.log("order error", e);
    }
  
}