

function parseMeasureArea(annotationArea, measureUnit, units) {

    const output = {};
    const selectedArea = Math.round(annotationArea * 1000) / 1000;
    let value = ''
    //console.log("Measurement unit "+measureUnit);
    switch (measureUnit){
        case "m²":
            //output = Math.round(selectedArea * 100) / 100+'m&sup2';
            value = Math.round(selectedArea * 100) / 100;
            if (units) value += 'm²';
            output.value = value;
            
        break;
        case "km²":
            //output = Math.round((selectedArea / 1000000) * 100) / 100+'km&sup2';
            value = Math.round((selectedArea / 1000000) * 100) / 100;
            if (units) value += 'km²';
            output.value = value;
        break;
        case "acre":
            //output = Math.round((selectedArea / 4047) * 100) / 100+' acre';
            value = Math.round((selectedArea / 4047) * 100) / 100;
            if (units) value += ' acre';
            output.value = value;
        break;
        case "sqft":
            //output = Math.round((selectedArea * 10.764) * 100) / 100+'ft&sup2';
            value = Math.round((selectedArea * 10.764) * 100) / 100;
            if (units) value += 'ft²';
            output.value = value;
        break;
        default:
        case "ha":
            //output = Math.round((selectedArea / 10000) * 100) / 100+'ha';
            value = Math.round((selectedArea / 10000) * 100) / 100;
            if (units) value += 'ha';
            output.value = value;
        break;
    }
    output.raw = selectedArea;
    return output;
}

export default parseMeasureArea;