import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Topbar from './createComponents/Topbar';
import ListPanel from './projectComponents/projectListInfterface';
import DetailPanel from './projectComponents/detailInterface';

import { SessionContext } from "../context/session-context";
import { ActivityContext } from "../context/activity-context";

const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    }

  });
  
  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

export default function ProjectInterface(props) {
    const classes = useStyles();
  
    const [state] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [hasListPanel, setHasListPanel] = useState(true);//default: true
    const [hasDetailPanel, setHasDetailPanel] = useState(false);//default: false
    const [savedSelected, setSavedSelected] = useState(0);//default: 0
    
    /**
     * Listerns for changes to the display.
     */
    useEffect(() => {
      if (aState.viewStep === 'list'){
        setHasListPanel(true);
        setHasDetailPanel(false);
      } else {
        setHasListPanel(false);
        setHasDetailPanel(true);
      }

    },[aState.viewStep])


    const handleDisplayDetails = (value) => {
      setSavedSelected(value);
      aDispatch({
        type: "updateviewstep",
        payload: { viewStep: 'detail' }
      });

    };

    const handleCloseDisplayDetails = (value) => {

      aDispatch({
        type: "updateviewstep",
        payload: { viewStep: 'list' }
      });
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Topbar />
                </Grid>
                <Grid item xs={12}>
                <div >
                    { hasListPanel ? <ListPanel onDisplayDetails={handleDisplayDetails} /> : '' }
                    { hasDetailPanel ? <DetailPanel onCloseDisplayDetails={handleCloseDisplayDetails} selectedId={savedSelected} /> : '' }
                </div>
                </Grid>
            </Grid>
        </div>
    )
  };