/**
 * Requests and initiates the download of the selected order.
 */

const handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

function downloadOrder(orderid, sessionid, userid, filename) {


    const link = document.createElement('a');
    link.href = process.env.REACT_APP_NMC_API+'/order/download?orderid='+orderid+'&sessionid='+sessionid+'&userid='+userid;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

}
    
export default downloadOrder;