import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

//import createNewCompany from "../../functions/createNewCompany";
import getPricingModels from "../../functions/getPricingModels";

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

import {ReactComponent as DownloadIcon} from '../../assets/dark_icons/iconmonstr-download-19 1.svg';
import {ReactComponent as SearchIcon} from '../../assets/dark_icons/iconmonstr-magnifier-6 1.svg';


const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    },
    formLabel:{
        marginTop: 40,
        marginBottom: 20,
        color: '#447095',
    },
    formtable:{
        height: 600,
        width: '100%'
    },
    filterButton:{
        marginTop: 10,
        marginLeft: 20,
    },
    productcontainer:{
        margin: 20,
        padding: 20,
        border: '1px solid #D2D2D8',
    },
    userscontainer:{
        margin: 20,
        padding: 20,
        border: '1px solid #D2D2D8',
        height:150 
    },
    containerLabel:{
        marginBottom: 20,
    },
    textField:{
        minWidth: 200,
    },
    menuButton: {
      color: '#000',
        '&:hover': {
          color: '#F6A036',
          backgroundColor:'#FFF',
        }
    },
    actionButton: {
        width:160,
    },
    actionButtonSuccess: {
        width:160,
        color: '#000',
        backgroundColor:'#4BB543',
    },
    actionButtonError:{
        width:160,
        color: '#000',
        backgroundColor:'#ff0033',
    },
    userslist:{
        height:240,
        margin: 5,
        overflowY: 'auto',
        border: '1px solid #D2D2D8',
    },
    productItem:{
        color: '#000',
        backgroundColor: '#FFF',
        '&.MuiListItem-root.Mui-selected': {
          backgroundColor: '#C1DAE9',
        },
        border: '1px solid #EDEDEF',
    },
    listItemText:{
      fontSize:12,//Insert your required size
    },

  });


export default function ProjectListInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);

    const [hasError, setHasError] = useState(false);
    const [displayid, setDisplayid] = useState(0);
    const [successState, setSuccessState] = useState('new');

    const [companyid, setCompanyid] = useState('');
    const [businessid, setBusinessid] = useState('');
    const [companyname, setCompanyname] = useState('');
    const [creditlimit, setCreditlimit] = useState(500);

    const [businesstype, setBusinesstype] = useState('');
    const [parentbusiness, setParentbusiness] = useState('');
    const [dtmparent, setDtmparent] = useState('');
    const [pricingmodel, setPricingmodel] = useState('');
    const [models, setModels] = useState([]);
    
    const [status, setStatus] = useState('active');
    const [hasvat, setHasvat] = useState('printdata');
    const [defaultviewlayer, setDefaultviewlayer] = useState('os10k');

    const [canPrint, setCanPrint] = useState(true);
    const [printHasgrid, setPrintHasgrid] = useState(true);
    const [printDefaultLogo, setPrintDefaultLogo] = useState('');
    const [printDefaultLayer, setPrintDefaultLayer] = useState('os10k,10000');
    const [printDefaultOrientation, setPrintDefaultOrientation] = useState('portrait');
    const [printDefaultSize, setPrintDefaultSize] = useState('a4');
    const [printDefaultCopies, setPrintDefaultCopies] = useState('1');

    const [print_osmmbw_a4, setPrint_osmmbw_a4] = useState(true);
    const [print_osmmbw_a3, setPrint_osmmbw_a3] = useState(true);
    const [print_osmmbw_a2, setPrint_osmmbw_a2] = useState(true);
    const [print_osmmbw_a1, setPrint_osmmbw_a1] = useState(true);
    const [print_osmmbw_a0, setPrint_osmmbw_a0] = useState(true);

    const [print_osmm_a4, setPrint_osmm_a4] = useState(true);
    const [print_osmm_a3, setPrint_osmm_a3] = useState(true);
    const [print_osmm_a2, setPrint_osmm_a2] = useState(true);
    const [print_osmm_a1, setPrint_osmm_a1] = useState(true);
    const [print_osmm_a0, setPrint_osmm_a0] = useState(true);

    const [print_osvml_a4, setPrint_osvml_a4] = useState(true);
    const [print_osvml_a3, setPrint_osvml_a3] = useState(true);
    const [print_osvml_a2, setPrint_osvml_a2] = useState(true);
    const [print_osvml_a1, setPrint_osvml_a1] = useState(true);
    const [print_osvml_a0, setPrint_osvml_a0] = useState(true);

    const [print_os25_a4, setPrint_os25_a4] = useState(true);
    const [print_os25_a3, setPrint_os25_a3] = useState(true);
    const [print_os25_a2, setPrint_os25_a2] = useState(false);
    const [print_os25_a1, setPrint_os25_a1] = useState(false);
    const [print_os25_a0, setPrint_os25_a0] = useState(false);

    const [print_os50_a4, setPrint_os50_a4] = useState(true);
    const [print_os50_a3, setPrint_os50_a3] = useState(true);
    const [print_os50_a2, setPrint_os50_a2] = useState(false);
    const [print_os50_a1, setPrint_os50_a1] = useState(false);
    const [print_os50_a0, setPrint_os50_a0] = useState(false);

    const [print_os250_a4, setPrint_os250_a4] = useState(true);
    const [print_os250_a3, setPrint_os250_a3] = useState(true);
    const [print_os250_a2, setPrint_os250_a2] = useState(false);
    const [print_os250_a1, setPrint_os250_a1] = useState(false);
    const [print_os250_a0, setPrint_os250_a0] = useState(false);

    const [print_aerial_a4, setPrint_aerial_a4] = useState(true);
    const [print_aerial_a3, setPrint_aerial_a3] = useState(true);
    const [print_aerial_a2, setPrint_aerial_a2] = useState(false);
    const [print_aerial_a1, setPrint_aerial_a1] = useState(false);
    const [print_aerial_a0, setPrint_aerial_a0] = useState(false);

    const [canExport, setCanExport] = useState(true);
    const [hasFixedPrice, setHasFixedPrice] = useState(true);
    const [dataDefaultLayer, setDataDefaultLayer] = useState('osmm9');
    const [dataDefaultFormat, setDataDefaultFormat] = useState('dwg');
    const [dataDefaultSize, setDataDefaultSize] = useState('4ha');

    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [userStatus, setUserStatus] = useState('active');
    const [userType, setUserType] = useState('manager');
    const [userPassword, setUserPassword] = useState('');

    /**
     * First load
     */
    useEffect(() => {
        const request = {
            userid: sState.userid,
            ident: sState.ident,
        }
        getPricingModels(request, aDispatch);
    },[])

    /**
     * Listerns for change to pricing models.
     * Populates the pricing model drop down.
     */
    useEffect(() => {
        if (aState.pricingmodels.length > 0){
            const mod = [];
            aState.pricingmodels.forEach(function (item) {
                let m ={};
                m.value = item.modelid;
                m.label = item.name;
                mod.push(m)
            });
            setModels(mod);
        }
    },[aState.pricingmodels])

    const processUserDetails = (co) => {

        co.uname = username;
        co.password = userPassword;
        co.email = email;
        co.utype = userType;
        co.firstname = firstname;
        co.lastname = lastname;
        co.telephone = telephone;
        return co;
    }

    const processClientDetails = () => {
        
        const co = {};
        co.companyid = -1;
        co.businessid = businessid;
        co.companyname = companyname;
        co.creditlimit = creditlimit;
        co.business_type = businesstype;
        co.parent_business = parentbusiness;
        co.dtm_parent = dtmparent;
        co.pricing_id = pricingmodel;
        co.hasvat = hasvat;
        co.status = status;
        const defaultsettings = {}
        defaultsettings.defaultlayer = defaultviewlayer;
        defaultsettings.canprint = canPrint;
        defaultsettings.hasgrid = printHasgrid;
        const printdlayer = printDefaultLayer.split(',');
        defaultsettings.dprintproduct = printdlayer[0];
        defaultsettings.dprintscale = printdlayer[1];
        defaultsettings.dprintlogo = printDefaultLogo;
        defaultsettings.dprintorientation = printDefaultOrientation;
        defaultsettings.dprintsize = printDefaultSize;
        defaultsettings.dprintcopies = printDefaultCopies;

        const po = [];
        const p_osmmbw = {};
        p_osmmbw.product = "osmmbw";
        p_osmmbw.a4 = print_osmmbw_a4;
        p_osmmbw.a3 = print_osmmbw_a3;
        p_osmmbw.a2 = print_osmmbw_a2;
        p_osmmbw.a1 = print_osmmbw_a1;
        p_osmmbw.a0 = print_osmmbw_a0;
        po.push(p_osmmbw);

        const p_osmm = {};
        p_osmm.product = "osmm";
        p_osmm.a4 = print_osmm_a4;
        p_osmm.a3 = print_osmm_a3;
        p_osmm.a2 = print_osmm_a2;
        p_osmm.a1 = print_osmm_a1;
        p_osmm.a0 = print_osmm_a0;
        po.push(p_osmm);

        const p_os10k = {};
        p_os10k.product = "os10k";
        p_os10k.a4 = print_osvml_a4;
        p_os10k.a3 = print_osvml_a3;
        p_os10k.a2 = print_osvml_a2;
        p_os10k.a1 = print_osvml_a1;
        p_os10k.a0 = print_osvml_a0;
        po.push(p_os10k);

        const p_os25k = {};
        p_os25k.product = "os25k";
        p_os25k.a4 = print_os25_a4;
        p_os25k.a3 = print_os25_a3;
        p_os25k.a2 = print_os25_a2;
        p_os25k.a1 = print_os25_a1;
        p_os25k.a0 = print_os25_a0;
        po.push(p_os25k);

        const p_os50k = {};
        p_os50k.product = "os50k";
        p_os50k.a4 = print_os50_a4;
        p_os50k.a3 = print_os50_a3;
        p_os50k.a2 = print_os50_a2;
        p_os50k.a1 = print_os50_a1;
        p_os50k.a0 = print_os50_a0;
        po.push(p_os50k);

        const p_os250k = {};
        p_os250k.product = "os250k";
        p_os250k.a4 = print_os250_a4;
        p_os250k.a3 = print_os250_a3;
        p_os250k.a2 = print_os250_a2;
        p_os250k.a1 = print_os250_a1;
        p_os250k.a0 = print_os250_a0;
        po.push(p_os250k);

        const p_aerial = {};
        p_aerial.product = "aerial";
        p_aerial.a4 = print_aerial_a4;
        p_aerial.a3 = print_aerial_a3;
        p_aerial.a2 = print_aerial_a2;
        p_aerial.a1 = print_aerial_a1;
        p_aerial.a0 = print_aerial_a0;
        po.push(p_aerial);
        defaultsettings.dprintoptions = po;

        defaultsettings.canexport = canExport;
        defaultsettings.ddatafixedprice = hasFixedPrice;
        defaultsettings.ddataproduct = dataDefaultLayer;
        defaultsettings.ddataformat = dataDefaultFormat;
        defaultsettings.ddatascale = dataDefaultSize;

        co.defaultsettings = defaultsettings;
        return co;
    };


    const handleCancel = () => {
        setDisplayid(-1);
        setSuccessState('new');
    };


    const handleSave = () => {
        let client = processClientDetails();
        client = processUserDetails(client);
        createNewCompany(sState, client, aDispatch)
        setDisplayid(-1);
    };

    var handleError = function (err) {
        console.log(err);
        return new Response(JSON.stringify({
          code: 400,
          message: 'Stupid network Error'
        }));
      };
    
    const createNewCompany = (params, company, dispatch) => {
    
            const abortController = new AbortController();

            const request = {
                userid: params.userid,
                ident: params.ident,
                companyid:company.companyid,
                businessid:company.businessid,
                companyname:company.companyname,
                creditlimit:company.creditlimit,
                business_type:company.business_type,
                parent_business:company.parent_business,
                dtm_parent:company.dtm_parent,
                hasvat:company.hasvat,
                vaton:company.hasvat,
                status:company.status,
                pricingmodelid:company.pricing_id,
                defaultsettings:company.defaultsettings,
                uname:company.uname,
                password:company.password,
                email:company.email,
                utype:company.utype,
                firstname:company.firstname,
                lastname:company.lastname,
                telephone:company.telephone,
            }
            let mounted = true;
            (async () => {  
                const res = await fetch(process.env.REACT_APP_NMC_API+'/admin/company/add', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(request),
                    signal: abortController.signal,
                }).catch(handleError);
                const data = await res.json();
                if (mounted) {
                    setSuccessState(data.status);
                }
                const cleanup = () => {
                    mounted = false;
                    abortController.abort();
                };
                return cleanup;
    
            })();
    
        }


    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.formLabel} fontWeight="500" fontStyle="italic" fontSize={22} >Add new client</Box>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.formdetails}>
                        <Grid container>
                            <Grid item xs={3} md={3}>
                                <TextField className={classes.textField} error={hasError} name="companyname" label="Name" size="small" margin="dense" variant="outlined" value={companyname} onChange={e => setCompanyname(e.target.value)} required />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <TextField className={classes.textField} error={hasError} name="businessid" label="Business id" size="small" type="number" margin="dense" variant="outlined" value={businessid} onChange={e => setBusinessid(e.target.value)} required />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <TextField className={classes.textField} error={hasError} name="creditlimit" label="Credit Limit" size="small" type="number" margin="dense" variant="outlined" value={creditlimit} onChange={e => setCreditlimit(e.target.value)} required />
                            </Grid>
                            <Grid item xs={3} md={2}>
                            {successState === 'new' ? ( 
                                <Button className={classes.actionButton} variant="contained" onClick={handleSave} >
                                    <Box fontWeight="fontWeightRegular" fontSize={12} >Save</Box>
                                </Button>
                            ) : '' }
                            {successState === 'success' ? ( 
                                <Button className={classes.actionButtonSuccess} variant="contained"  >
                                    <Box fontWeight="fontWeightRegular" fontSize={12} >Success</Box>
                                </Button>
                            ) : '' }
                            {successState === 'error' ? ( 
                                <Button className={classes.actionButtonError} variant="contained"  >
                                    <Box fontWeight="fontWeightRegular" fontSize={12} >Error</Box>
                                </Button>
                            ) : '' }
                                <Button className={classes.actionButton} variant="contained" onClick={handleCancel} >
                                    <Box fontWeight="fontWeightRegular" fontSize={12} >Cancel / Clear</Box>
                                </Button>
                            </Grid>
                            <div className={classes.userscontainer}>
                                <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >First User</Box>
                                <Grid container>
                                    <Grid item xs={3} md={4}>
                                        <TextField className={classes.textField} value={username} onChange={e => setUsername(e.target.value)} name="username" label="Username" size="small" margin="dense" variant="outlined" required/>
                                        <TextField className={classes.textField} value={userPassword} onChange={e => setUserPassword(e.target.value)} name="userPassword" label="Password" size="small" margin="dense" variant="outlined" required/>
                                        <TextField className={classes.textField} value={email} onChange={e => setEmail(e.target.value)} name="email" label="Email" size="small" margin="dense" variant="outlined" required/>
                                    </Grid>
                                    <Grid item xs={3} md={4}>
                                        <TextField className={classes.textField} value={firstname} onChange={e => setFirstname(e.target.value)} name="firstname" label="Firstname" size="small" margin="dense" variant="outlined" required/>
                                        <TextField className={classes.textField} value={lastname} onChange={e => setLastname(e.target.value)} name="lastname" label="Lastname" size="small" margin="dense" variant="outlined" required/>
                                        <TextField className={classes.textField} value={telephone} onChange={e => setTelephone(e.target.value)} name="telephone" label="Telephone" size="small" margin="dense" variant="outlined"/>
                                    </Grid>
                                    <Grid item xs={3} md={4}>
                                        <TextField className={classes.textField} value={userStatus} onChange={e => setUserStatus(e.target.value)} name="userStatus" label="Status" size="small" margin="dense" variant="outlined" select>
                                            <MenuItem value={'active'}>Active</MenuItem>
                                            <MenuItem value={'disabled'}>Disabled</MenuItem>
                                        </TextField>
                                        
                                        <TextField className={classes.textField} value={userType} onChange={e => setUserType(e.target.value)} name="userType" label="Usertype" size="small" margin="dense" variant="outlined" select>
                                            <MenuItem value={'super'}>Super</MenuItem>
                                            <MenuItem value={'admin'}>Admin</MenuItem>
                                            <MenuItem value={'manager'}>Manager</MenuItem>
                                            <MenuItem value={'user'}>User</MenuItem>
                                        </TextField>
                                        
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid item xs={12}>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >Client Details</Box>
                                    <Grid container>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={businesstype} onChange={e => setBusinesstype(e.target.value)} name="Businesstype" label="Business Type" error={hasError} size="small" margin="dense" variant="outlined"/>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={parentbusiness} onChange={e => setParentbusiness(e.target.value)} name="price" label="Parent Business" error={hasError} size="small" margin="dense" variant="outlined"/>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={dtmparent} onChange={e => setDtmparent(e.target.value)} name="Dtmparent" label="DTM Parent" error={hasError} size="small" margin="dense" variant="outlined"/>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={pricingmodel} onChange={e => setPricingmodel(e.target.value)} name="Pricingmodel" label="Pricing Model" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                {models.map((item, i) =>
                                                    <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                                                )}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={status} onChange={e => setStatus(e.target.value)} name="Status" label="Status" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                    <MenuItem value={'active'}>Active</MenuItem>
                                                    <MenuItem value={'disabled'}>Disabled</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={hasvat} onChange={e => setHasvat(e.target.value)} name="VAT" label="VAT on" error={hasError} size="small" margin="dense" variant="outlined" select>                                              
                                                    <MenuItem value={'printdata'}>Prints and Data</MenuItem>
                                                    <MenuItem value={'data'}>Data only</MenuItem>
                                                    <MenuItem value={'print'}>Print only</MenuItem>
                                                    <MenuItem value={'none'}>None</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={defaultviewlayer} onChange={e => setDefaultviewlayer(e.target.value)} name="Defaultviewlayer" label="Default view layer" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                    <MenuItem value={'osmmbw'}>OS Plan Black / White</MenuItem>
                                                    <MenuItem value={'os10k'}>1:10,000</MenuItem>
                                                </TextField>
                                            </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >Client Print Settings</Box>
                                    <Grid container>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12}>Can Print</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Grid</Box>
                                        </Grid>
                                        <Grid item xs={6} md={8}></Grid>
                                        <Grid item xs={3} md={2}>
                                            <Checkbox checked={canPrint} onChange={e => setCanPrint(!canPrint)}/>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Checkbox checked={printHasgrid} onChange={e => setPrintHasgrid(!printHasgrid)}/>
                                        </Grid>
                                        <Grid item xs={4} md={3}>
                                            <TextField className={classes.textField} value={printDefaultLogo} onChange={e => setPrintDefaultLogo(e.target.value)} name="DefaultLogo" label="Default Logo" error={hasError} size="small" margin="dense" variant="outlined" />
                                        </Grid>
                                        <Grid item xs={2} md={5}></Grid>

                                        <Grid item xs={4} md={3}>
                                            <TextField className={classes.textField} value={printDefaultLayer} onChange={e => setPrintDefaultLayer(e.target.value)} name="DefaultLayer" label="Default Layer" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                    <MenuItem value={'osmmbw,1250'}>OS Mastermap BW @ 1250</MenuItem>
                                                    <MenuItem value={'osmm,1250'}>OS Mastermap Colour @ 1250</MenuItem>
                                                    <MenuItem value={'os10k,10000'}>OS VML @ 10,000</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={printDefaultOrientation} onChange={e => setPrintDefaultOrientation(e.target.value)} name="DefaultOrientation" label="Orientation" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                <MenuItem value={'landscape'}>Landscape</MenuItem>
                                                <MenuItem value={'portrait'}>Portrait</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <TextField className={classes.textField} value={printDefaultSize} onChange={e => setPrintDefaultSize(e.target.value)} name="DefaultSize" label="Paper Size" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                <MenuItem value={'a4'}>A4</MenuItem>
                                                <MenuItem value={'a3'}>A3</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <TextField className={classes.textField} value={printDefaultCopies} onChange={e => setPrintDefaultCopies(e.target.value)} name="DefaultCopies" label="Copies" type="number" error={hasError} size="small" margin="dense" variant="outlined"/>
                                        </Grid>
                                        <Grid item xs={1} md={3}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>Print layer</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a4</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a3</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a2</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a1</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a0</Box>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OSMM BW</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a4} onChange={e => setPrint_osmmbw_a4(!print_osmmbw_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a3} onChange={e => setPrint_osmmbw_a3(!print_osmmbw_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a2} onChange={e => setPrint_osmmbw_a2(!print_osmmbw_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a1} onChange={e => setPrint_osmmbw_a1(!print_osmmbw_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a0} onChange={e => setPrint_osmmbw_a0(!print_osmmbw_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OSMM</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a4} onChange={e => setPrint_osmm_a4(!print_osmm_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a3} onChange={e => setPrint_osmm_a3(!print_osmm_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a2} onChange={e => setPrint_osmm_a2(!print_osmm_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a1} onChange={e => setPrint_osmm_a1(!print_osmm_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a0} onChange={e => setPrint_osmm_a0(!print_osmm_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OS VML</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a4} onChange={e => setPrint_osvml_a4(!print_osvml_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a3} onChange={e => setPrint_osvml_a3(!print_osvml_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a2} onChange={e => setPrint_osvml_a2(!print_osvml_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a1} onChange={e => setPrint_osvml_a1(!print_osvml_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a0} onChange={e => setPrint_osvml_a0(!print_osvml_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OS 1:25,000</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a4} onChange={e => setPrint_os25_a4(!print_os25_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a3} onChange={e => setPrint_os25_a3(!print_os25_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a2} onChange={e => setPrint_os25_a2(!print_os25_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a1} onChange={e => setPrint_os25_a1(!print_os25_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a0} onChange={e => setPrint_os25_a0(!print_os25_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OS 1:50,000</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a4} onChange={e => setPrint_os50_a4(!print_os50_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a3} onChange={e => setPrint_os50_a3(!print_os50_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a2} onChange={e => setPrint_os50_a2(!print_os50_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a1} onChange={e => setPrint_os50_a1(!print_os50_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a0} onChange={e => setPrint_os50_a0(!print_os50_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OS 1:250,000</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a4} onChange={e => setPrint_os250_a4(!print_os250_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a3} onChange={e => setPrint_os250_a3(!print_os250_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a2} onChange={e => setPrint_os250_a2(!print_os250_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a1} onChange={e => setPrint_os250_a1(!print_os250_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a0} onChange={e => setPrint_os250_a0(!print_os250_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>Aerial Imagery</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a4} onChange={e => setPrint_aerial_a4(!print_aerial_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a3} onChange={e => setPrint_aerial_a3(!print_aerial_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a2} onChange={e => setPrint_aerial_a2(!print_aerial_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a1} onChange={e => setPrint_aerial_a1(!print_aerial_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a0} onChange={e => setPrint_aerial_a0(!print_aerial_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>
                                            
                                    </Grid>
                                </div>

                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >Client Data Settings</Box>
                                    <Grid container>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12}>Can Data</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Use Fixed Price</Box>
                                        </Grid>
                                        <Grid item xs={6} md={8}></Grid>
                                        <Grid item xs={3} md={2}>
                                            <Checkbox checked={canExport} onChange={e => setCanExport(!canExport)}/>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Checkbox checked={hasFixedPrice} onChange={e => setHasFixedPrice(!hasFixedPrice)}/>
                                        </Grid>
                                        <Grid item xs={6} md={8}></Grid>

                                        <Grid item xs={6} md={4}>
                                            <TextField className={classes.textField} value={dataDefaultLayer} onChange={e => setDataDefaultLayer(e.target.value)} name="DefaultLayer" label="Default Layer" error={hasError} size="small" margin="dense" variant="outlined" select>                                                 
                                                <MenuItem value={'osmm9'}>OS Plan (CAD) Standard 9 Layers</MenuItem>
                                                <MenuItem value={'osmm55'}>OS Plan (CAD) Enhanced 55 Layers</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={dataDefaultFormat} onChange={e => setDataDefaultFormat(e.target.value)} name="DefaultFormat" label="Default Format" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                <MenuItem value={'dwg'}>DWG</MenuItem>
                                                <MenuItem value={'dxf'}>DXF</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={dataDefaultSize} onChange={e => setDataDefaultSize(e.target.value)} name="DefaultSize" label="Default Area Size" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                <MenuItem value={'1ha'}>1 ha</MenuItem>
                                                <MenuItem value={'4ha'}>4 ha</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                    </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
  };
