import React, { useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MapZoomIn from './button/zoomIn_button';
import MapZoomOut from './button/zoomout_button';
import MapCentre from './button/centremap_button';
import PrintExtent from './button/printextent_button';

import { ActivityContext } from "../../context/activity-context";

export default function MapToolsPaper(props) {
  
    const useStyles = makeStyles({
        root: {
            position: 'absolute',
            top: 92,
            left: props.infoPanelWidth+15,
            padding: '2px 2px',
            zIndex:10,
          },
      });

      const classes = useStyles();
      const [aState, aDispatch] = useContext(ActivityContext);

  return (
    <div className={classes.root}>
        <MapZoomIn />
        <MapZoomOut />
        <MapCentre />
        { aState.orderStep === 'plot' ? <PrintExtent /> : '' }

    </div>
  );
}