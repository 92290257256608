function getZoomInt(scale){

    let scaleInt = 6;
    switch(parseInt(scale)){
        case 200:
            scaleInt = 1;
        break;
        case 500:
            scaleInt = 2;
        break;
        case 1250:
            scaleInt = 3;
        break;
        case 2500:
            scaleInt = 4;
        break;
        case 5000:
            scaleInt = 5;
        break;
        case 10000:
            scaleInt = 6;
        break;
        case 15000:
            scaleInt = 7;
        break;
        case 25000:
            scaleInt = 8;
        break;
        case 30000:
            scaleInt = 9;
        break;
        case 50000:
            scaleInt = 10;
        break;
        case 150000:
            scaleInt = 11;
        break;
        case 250000:
            scaleInt = 12;
        break;
        default: 
            scaleInt = 0;
        break;
    }
    return scaleInt;
  }

  export default getZoomInt;