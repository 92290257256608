import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {ReactComponent as FileIcon} from '../../assets/dark_icons/iconmonstr-file-2 1.svg';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Basemaps from './BaseMapSelect';
import getProductName from '../../functions/getProductName';

import ProductBackButton from '../../components/button/ProductLinkButton';

import { ActivityContext } from "../../context/activity-context";
import { SessionContext } from "../../context/session-context";

const useStyles = makeStyles(() => ({
  root: {
    width: 270,
    height: '80%',
    //borderRight: '1px solid #D2D2D8',
    '&.MuiExpansionPanelDetails-root':{
      display:'block',
    },
    display:'block',
  },
  expansionpanel:{
    width: 270,
    '&.MuiExpansionPanel-root.Mui-expanded':{
      margin:0
    },
    borderTop: '1px solid #D2D2D8',
  },
  expansionpanelsummary:{
    '&.MuiExpansionPanelSummary-root.Mui-expanded':{
      height: 32,
      minHeight: 32,
    },
    '&.MuiExpansionPanelSummary-root':{
      height: 32,
      minHeight: 32,
    },
  },
  heading: {
    fontSize: 13,
    fontWeight: 'normal',
  },
  displayBlock:{
    display:'block',
  },
  blcontainer:{
    position: 'absolute',
    bottom: 5,
    left: 5,
  },
  displayText: {
    padding: 0,
  },
  fileIcon:{
    width:20,
    height:20,
  },
  productTypeText:{
    paddingTop:2,
    paddingLeft:10,
  },
  productNameText:{
    paddingTop:2,
    paddingLeft:5,
  },
  productPriceVATLabel:{
    paddingTop:2,
    paddingLeft:5,
  },
  menuButton: {
    textDecoration: 'underline',
  }
  
}));

export default function LeftInfoPanel() {
  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [sState] = useContext(SessionContext);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [hasLocation, setHasLocation] = useState(false);
  const [hasMapping, setHasMapping] = useState(false);
  const [hasProduct, setHasProduct] = useState(false);
  const [hasProductSelected, setHasProductSelected] = useState(false);
  const [hasProductChange, setHasProductChange] = useState(false);
  const [productType, setProductType] = useState('');
  const [productName, setProductName] = useState('');
  const [hasOrder, setHasOrder] = useState(false);
  const [hasPayment, setHasPayment] = useState(false);
  const [locationAddress, setlocationAddress] = useState('');
  const [locationCoordinates, setlocationCoordinates] = useState('');
  const [disableProduct, setDisableProduct] = useState(true);
  const [disableOrder, setDisableOrder] = useState(true);
  const [disablePayment, setDisablePayment] = useState(true);
  const [orderPrice, setOrderPrice] = useState(0);
  const [orderGrossPrice, setOrderGrossPrice] = useState(0);
  const [goBackLabel, setGoBackLabel] = useState('');
  const [hasBackButton, setHasBackButton] = useState(false);

  /**
   * Listern for changes to info panel
   */
  useEffect(() => {
    setHasLocation(aState.hasAddress);
    setHasMapping(true);
  },[aState.hasAddress])

    /**
   * Listern for changes to second level view
   */
  useEffect(() => {
    //console.log("Current Order Step: "+aState.orderStep);
    let pn = '';
    switch(aState.orderStep){
      case "addressSearch":
        setHasLocation(false);
        setHasMapping(false);
        setDisableProduct(true);
        setHasProduct(false);
        setHasProductSelected(false);
        setHasOrder(false);
        setDisableOrder(true);
        setDisablePayment(true);
        setHasPayment(false);
        setHasProductChange(false);
        setHasBackButton(false);
        setGoBackLabel('');
      break;
      case "productSelect":
        setHasLocation(true);
        setHasMapping(true);
        setDisableProduct(false);
        setHasProduct(false);
        setHasProductSelected(false);
        setHasOrder(false);
        setDisableOrder(true);
        setDisablePayment(true);
        setHasPayment(false);
        setHasProductChange(false);
        setHasBackButton(true);
        setGoBackLabel('Back to address search');
      break;
      case "plot":
        setHasLocation(true);
        setHasMapping(true);
        setDisableProduct(false);
        setHasProduct(true);
        setHasProductSelected(true);
        setProductType('Plot Map');
        setHasOrder(true);
        setDisableOrder(true);
        setHasProductChange(true);
        setHasBackButton(true);
        setGoBackLabel('Back to product selection');

        //Gets and saves the formal product name
        pn = getProductName(aState.productLayerSelect,'plot');
        setProductName(pn);
        aDispatch({
          type: "setproductname",
          payload: { productName: 'Plot Map '+pn }
        });

      break;
      case "data":
        setHasLocation(true);
        setHasMapping(true);
        setDisableProduct(false);
        setHasProduct(true);
        setHasProductSelected(true);
        setProductType('Data Map');

        pn = getProductName(aState.productLayerSelect,'data');
        setProductName(pn);

        setHasOrder(true);
        setDisableOrder(true);
        setHasProductChange(true);
        setHasBackButton(true);
        setGoBackLabel('Back to product selection');
        aDispatch({
          type: "setproductname",
          payload: { productName: pn }
        });

      break;
      case "orderSummary":
        setHasLocation(true);
        setHasMapping(false);
        setDisableProduct(false);
        setHasProduct(true);
        setHasProductSelected(true);
        setHasOrder(true);
        setDisableOrder(false);
        setDisablePayment(true);
        setHasPayment(false);
        setHasProductChange(false);
        setHasBackButton(true);
        setGoBackLabel('Back to product selection');
      break;
      case "orderComplete":
        setHasLocation(true);
        setHasMapping(false);
        setDisableProduct(false);
        setHasProduct(true);
        setHasProductSelected(true);
        setHasOrder(true);
        setDisableOrder(false);
        setDisablePayment(true);
        setHasPayment(false);
        setHasProductChange(false);
        setHasBackButton(true);
        setGoBackLabel('Back to product selection');
      break;
    }
  },[aState.orderStep])
  

  //Listerns for search address details. 
  useEffect(() => {
    if (typeof aState.caddress.name !== 'undefined' && !isFirstLoad){
      let add = aState.caddress.name;
      add = add.replace(/, /g, '\n');
      setlocationAddress(add);
      if (typeof aState.caddress.value !== 'undefined' ){
        const dCoord = 'BNG X/Y: '+aState.caddress.value.x+' '+aState.caddress.value.y;
        setlocationCoordinates(dCoord);
      }
      
    } else {
      setIsFirstLoad(false);
    }
  },[hasLocation])


    //Listerns for order price and populates. 
    useEffect(() => {

      if (orderPrice !== aState.cprice){
        const money = parseFloat(aState.cprice);
        let gross = parseFloat(aState.cprice);
        setOrderPrice(money.toFixed(2));

        let vatRate = 0;
        let vat = 0;
        console.log("VAT status: "+sState.company.vaton+", productSelect:"+aState.productSelect);
        switch (aState.productSelect){
          case "plot":
            if (sState.company.vaton === 'printdata' || sState.company.vaton === 'print'){
              vatRate = parseFloat(process.env.REACT_APP_VAT_RATE)/100;
              vat = parseFloat(money * vatRate);
              gross = parseFloat(money + vat);
            }
          break;
          case "data":
            if (sState.company.vaton === 'printdata' || sState.company.vaton === 'data'){
              vatRate = parseFloat(process.env.REACT_APP_VAT_RATE)/100;
              vat = parseFloat(money * vatRate);
              gross = parseFloat(money + vat);
            }
          break;

        }
        setOrderGrossPrice(gross.toFixed(2));
      }
      //Acknowledge price waiting finished.
      aDispatch({
        type: "setpriceloading",
        payload: { priceloading: false}
      });
      
    },[aState.cprice])

  const addLineBreaks = (string) => {
    if (typeof string !== 'undefined'){
        return  string.split('\n').map((text, index) => (
            <React.Fragment key={`${text}-${index}`}>
            {text}
            <br />
            </React.Fragment>
        ));
    }
  }

  /**
   * Go back button to previous interface.
   */
  const goBack = () => {
    console.log("current orderStep: "+aState.orderStep);
    aDispatch({ type: "hasToolbar", payload: { hasToolbar: false} });

    switch(aState.orderStep){
      case "addressSearch":
      case "productSelect":
        aDispatch({ type: "resetmap", toplevel: 'mapping'});
        aDispatch({ type: "updatetoplevel", payload: { toplevel: 'mapping', viewStep: 'address', orderStep:'addressSearch'}});
        aDispatch({ type: "hasToolbar", payload: { hasToolbar: false} });
        aDispatch({ type: "setpriceloading", payload: { priceloading: true} });
      break;
      case "plot":
        aDispatch({ type: "setorderstep", payload: { orderStep: "productSelect" }});
        aDispatch({ type: "updateviewstep", payload: { viewStep: "mapping" }});
        aDispatch({ type: "setorderstep", payload: { orderStep: "productSelect" }});
        aDispatch({ type: "resetorderdetails", payload: {} });
        aDispatch({ type: "hasToolbar", payload: { hasToolbar: false} });
      break;
      case "data":
        aDispatch({ type: "setorderstep", payload: { orderStep: "productSelect" }});
        aDispatch({ type: "updateviewstep", payload: { viewStep: "mapping" }});
        aDispatch({ type: "setorderstep", payload: { orderStep: "productSelect" }});
        aDispatch({ type: "resetorderdetails", payload: {} });
        aDispatch({ type: "hasToolbar", payload: { hasToolbar: false} });
      break;
      case "orderSummary":
        aDispatch({ type: "setorderstep", payload: { orderStep: "productSelect" }});
        aDispatch({ type: "updateviewstep", payload: { viewStep: "mapping" }});
        aDispatch({ type: "setorderstep", payload: { orderStep: "productSelect" }});
        aDispatch({ type: "resetorderdetails", payload: {} });
        aDispatch({ type: "hasToolbar", payload: { hasToolbar: false} });
      break;
      case "orderComplete":
        aDispatch({ type: "setorderstep", payload: { orderStep: "productSelect" }});
        aDispatch({ type: "updateviewstep", payload: { viewStep: "mapping" }});
        aDispatch({ type: "setorderstep", payload: { orderStep: "productSelect" }});
        aDispatch({ type: "resetorderdetails", payload: {} });
        aDispatch({ type: "hasToolbar", payload: { hasToolbar: false} });
      break;
      default:
        break;
    }



  }
//<Box ><ProductBackButton display={hasProductChange}/></Box>
  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={hasLocation} className={classes.expansionpanel} onChange={e => setHasLocation(!hasLocation)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.expansionpanelsummary}
        >
          <Typography className={classes.heading}>LOCATION AND AREA</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box display="flex" flexWrap="wrap">
            <Box p={1} className={classes.displayText} fontSize={14}>{addLineBreaks(locationAddress)}</Box>
            <Box p={1} className={classes.displayText} fontSize={14}>{locationCoordinates}</Box>
          </Box>
          </ExpansionPanelDetails>
          <ExpansionPanelDetails>
          { hasMapping ?<Box>
            <Box p={1} className={classes.displayText} fontSize={14}>Displayed Background</Box>
             <Basemaps />
          </Box> : '' }
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={hasProduct} className={classes.expansionpanel} disabled={disableProduct} onChange={e => setHasProduct(!hasProduct)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.expansionpanelsummary}
        >
          <Typography className={classes.heading}>PRODUCT TYPE</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        { !hasProductSelected ? <Box display="flex" flexWrap="wrap">
            <Box p={1} className={classes.displayText} fontSize={14}>Please select a product from the menu on the right</Box>
        </Box> : ''}
        { hasProductSelected ?
          <Box display="flex" flexWrap="wrap">
              <Box ><FileIcon className={classes.fileIcon} /></Box>
              <Box p={1} className={classes.productTypeText} fontSize={14}>{productType}</Box>
              <Box p={1} className={classes.productNameText} fontSize={14}>{productName}</Box>
              
          </Box> : ''}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={hasOrder} className={classes.expansionpanel} disabled={disableOrder} onChange={e => setHasOrder(!hasOrder)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.expansionpanelsummary}
        >
          <Typography className={classes.heading}>ORDER SUMMARY</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.displayBlock}>

          <Box display="flex" flexWrap="nowrap">
              <Box fontSize={14} fontWeight="fontWeightBold">£{orderPrice}</Box>
              <Box className={classes.productPriceVATLabel} fontSize={12} > + VAT</Box><br />
          </Box>
          <Box fontSize={12} fontWeight="fontWeightLight">(£{orderGrossPrice})</Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={hasPayment} className={classes.expansionpanel} disabled={disablePayment} onChange={e => setHasPayment(!hasPayment)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.expansionpanelsummary}
        >
          <Typography className={classes.heading}>PAYMENT</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <div className={classes.blcontainer}>
        { hasBackButton ? <Button className={classes.menuButton} onClick={goBack}><Box fontSize={12} >{goBackLabel}</Box></Button> : ''}
        <Box className={classes.copyrightText} fontSize={12}>{process.env.REACT_APP_LOGIN_COPYRIGHT.replace("dddd", new Date().getFullYear())} | <a href={process.env.REACT_APP_SITE_TERMS} target="_blank" rel="noreferrer">Terms</a></Box>
      </div>
    </div>
  );
}