import React, { useState, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import {ReactComponent as PenIcon} from '../../assets/dark_icons/iconmonstr-pen-14 1.svg';

import { ActivityContext } from "../../context/activity-context";

const useStyles = makeStyles({
    processStepper: {
        //width: 270,
        display:"start",
        flexDirection:"row",
    },
    toolButton:{
        width:220,
        height:50,
        backgroundColor:"#878897",
        marginTop: 10,
        marginLeft: 15,
        marginRight: 5,
        marginBottom: 16,
        padding:0,
        color: '#FFF',
        '&:hover': {
            backgroundColor:"#C1DAE9",
            color: '#000', 
        },
    },
    menuButton: {
        backgroundColor:"#5C5D6B",
        width:50,
        height:50,
    },
    penIcon:{
        paddingTop:16,
        width:30,
        height:20,
        fill:'#fff',
    },
    menuTitleContainer:{
        marginTop:20,
        width: 190,
        height:50,
        textAlign: 'left',
        marginLeft:10,
    },
  });

export default function ToolsButton(props) {
  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);

  const showTools = (event) => {
    aDispatch({
        type: "hasAnnotationbar",
        payload: { hasAnnotationbar: true}
    });
  }
  //className={classes.toolButton} 
  return (
    <div className={classes.processStepper}>
      <Button className={classes.toolButton} disabled={props.disabled} onClick={showTools}>
          <Box className={classes.menuTitleContainer}><Box fontWeight="fontWeightBold" fontSize={16}>Tools</Box></Box>
          <Box className={classes.menuButton}><PenIcon className={classes.penIcon} /></Box>
      </Button>
    </div>
  );
}