/**
 * Creates or updates a full pricing model.
 * This is a super call.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function createPricingModel(params, model, dispatch) {

        const abortController = new AbortController();
        const request = {
            userid: params.userid,
            ident: params.ident,
            modelid: model.modelid,
            name: model.name,
            description: model.description,
            model: model.model
        }
        let mounted = true;
        (async () => {  
            const res = await fetch(process.env.REACT_APP_NMC_API+'/super/model/save', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(request),
                signal: abortController.signal,
            }).catch(handleError);
            const data = await res.json();
            if (mounted) {
              if (data.status === "success"){
                //console.log("get model response:"+JSON.stringify(data.models));
               
                dispatch({
                  type: "setpricingmodels",
                  payload: { pricingmodels: data.models}
                });
                
              }
            }
            const cleanup = () => {
                mounted = false;
                abortController.abort();
            };
            return cleanup;

        })();

    }
