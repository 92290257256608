import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// Start Openlayers imports
import { Map, View } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer, Image} from 'ol/layer';
import { Vector as VectorSource, OSM as OSMSource, ImageStatic} from 'ol/source';


import {Fill, Stroke, Style, Circle, Text} from 'ol/style';

import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import CirclePolygon from 'ol/geom/Circle';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import MultiPoint from 'ol/geom/MultiPoint';
import {fromExtent} from 'ol/geom/Polygon';
import {boundingExtent} from 'ol/extent';

import Icon from 'ol/style/Icon';
import { unByKey } from "ol/Observable";
//import LayerGroup from 'ol/layer/Group';

import { Projection } from 'ol/proj';
import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';

import WKT from 'ol/format/WKT';
//import Zoom from 'ol/control/Zoom';
import MousePosition from 'ol/control/MousePosition';
//import ZoomTools from 'ol/control/Zoom';
//import ZoomToExtent from 'ol/control/ZoomToExtent'

import {createStringXY} from 'ol/coordinate';
//import Select from 'ol/interaction/Select';
import UndoRedo from "ol-ext/interaction/UndoRedo";

import { ActivityContext } from "../../context/activity-context";
import { SessionContext } from "../../context/session-context";
import { UserContext } from "../../context/user-context";

import BaseLayers from '../../functions/baseLayers';
import setMapScale from "../../functions/setMapScale";
import setMapScaleCentre from "../../functions/setMapScaleCentre";
import getDisplayLayer from "../../functions/getDisplayLayer";
import recordView from "../../functions/recordView";
import getPrintWindowExt from "../../functions/getPrintWindowExtent";
import getMapExtentbyPoint from "../../functions/getMapExtentbyPoint";
import getLayerDefaultScale from "../../functions/getLayerDefaultScale";
import getZoomScaleInt from "../../functions/getZoomScaleInt";
import getViewScaleFromInt from "../../functions/getViewScaleFromInt";
import getLayerMinZoomInt from "../../functions/getLayerMinZoomInt";
import getScaleFromExtent from "../../functions/getScaleFromExtent";
import getNextScale from "../../functions/getFixedScaleFromScale";
//import getPolygonExtent from "../../functions/getExtentFromOLPolygon";
import getGeomFromCoords from "../../functions/getGeomFromCoords";
import getMeasureArea from "../../functions/parseAreaMeasurement";
import getMeasureLength from "../../functions/parseMeasureLength";

import {createBox} from 'ol/interaction/Draw';
import {defaults,  Draw, Modify, Snap, Select, Translate, DragPan, Extent} from 'ol/interaction';
//import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
//import {defaults as defaultInteractions, Select, Translate} from 'ol/interaction';
//import DragPan from 'ol/interaction/DragPan';

import "ol/ol.css";
//import "ol-ext/dist/ol-ext.css";

//import { ListItemText } from '@material-ui/core';

export default function Mapping(props) {

    const useStyles = makeStyles({
        mapstyle: {
            width: props.width,
            height: props.height,
            backgroundColor: '#fff',
            zindex: 100
        },
        mouseposition:{
            display:"inline",
        },
        copyrightLabel:{
            position:'fixed',
            height: 16,
            width: 300,
            bottom: 20,
            fontSize:12,
            left: props.left+(props.width/2)-90,
            //left: 'calc((100% / 2) - 90px)',
            color: '#000',
            zIndex:120,
        }
      });

    const classes = useStyles();
    const [aState, aDispatch] = useContext(ActivityContext);
    const [sState] = useContext(SessionContext);
    const [uState] = useContext(UserContext);
    const [mapobj, setMapobj] = useState(null);
    const [mapPinLayer, setMapPinLayer] = useState(null);
    const [mapaction, setMapaction] = useState(null);
    const [freezeaction, setFreezeaction] = useState(false);
    const [mapextent, setMapextent] = useState([]);
    const [featuresLayer, setFeaturesLayer] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [hasPrintWindow, setHasPrintWindow] = useState(false);
    const [printWindowLoaded, setPrintWindowLoaded] = useState(false);
    const [printWindowLayer, setPrintWindowLayer] = useState(null);
    const [blayer, setBlayer] = useState(null);
    const [blayername, setBlayername] = useState('os10k');
    const [scale, setScale] = useState(10000);
    const [scaleInt, setScaleInt] = useState(6);
    const [outsideScale, setOutsideScale] = useState(false); //Scale is changed outside of map window. i.e. select scale menu
    const [printFrame, setPrintFrame] = useState(null);

    const [hasGrid, setHasGrid] = useState(false);
    const [gridLayer, setGridLayer] = useState(null);
    const [hasDataWindow, setHasDataWindow] = useState(false);
    const [dataWindowLoaded, setDataWindowLoaded] = useState(false);
    const [userAction, setUserAction] = useState(false);
    const [dataWindowLayer, setDataWindowLayer] = useState(null);

    const [annotationLayer, setAnnotationLayer] = useState(null);
    const [hasAnnotationLayer, setHasAnnotationLayer] = useState(false);
    const [annotationWindowLayer, setAnnotationWindowLayer] = useState(null);
    const [annotationArray, setAnnotationArray] = useState([]);
    const [annotationUnits, setAnnotationUnits] = useState({});
    const [annotationArea, setAnnotationArea] = useState({});
    const [annotationLength, setAnnotationLength] = useState({});
 
    const [measurementLayer, setMeasurementLayer] = useState(null);
    const [hasMeasurementLayer, setHasMeasurementLayer] = useState(false);
    const [measurementAreaUnit, setMeasurementAreaUnit] = useState('ha');
    const [measurementAreaValue, setMeasurementAreaValue] = useState(0);
    const [measurementLineUnit, setMeasurementLineUnit] = useState('m');
    const [measurementLineValue, setMeasurementLineValue] = useState(0);

    const [hasWatermarkLayer, setHasWatermarkLayer] = useState(false);
    const [watermarkLayer, setWatermarkLayer] = useState(null);

    //map interactions
    const [mapSelect, setMapSelect] = useState(null);
    const [mapDraw, setMapDraw] = useState(null);
    const [mapTranslate, setMapTranslate] = useState(null);
    const [mapSnap, setMapSnap] = useState(null);
    const [mapModify, setMapModify] = useState(null);
    const [mapWheelZoom, setMapWheelZoom] = useState(null);
    const [mapPan, setMapPan] = useState(null);
    const [clickListerner, setClickListerner] = useState(null);
    const [undoRedo, setUndoRedo] = useState(null);

    //map controls
    const [toolExtent, setToolExtent] = useState(null);
    const [mapCentreKey, setMapCentreKey] = useState(null);

    //Capture from OSMM.
    const [fillGeom, setFillGeom] = useState('');
    const [doUnion, setDoUnion] = useState(false);
    const [clickCoords, setClickCoords] = useState([]);
    const [fillToolActive, setFillToolActive] = useState(false);


    //This is the mapping component.
    var map = new Map({interactions: defaults({dragPan: false, mouseWheelZoom: false, doubleClickZoom :false}),target: null,layers: null,view: null,controls: []});
    /**
     * First load to load the map component.
     * This should also be the entry point. needs aState.caddress
     */
    useEffect(() => {
        //console.log("first load ..."+firstLoad);
        if (firstLoad){

            setFirstLoad(false);
            proj4.defs('EPSG:27700', "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs"); 
            register(proj4);

            const osgb = new Projection({
                code: 'EPSG:27700',
                extent: [0, 0, 1400000, 1600000]
            });

            //Gets the address to display
            const posX = parseFloat(aState.caddress.value.x);
            const posY = parseFloat(aState.caddress.value.y);
            

            map.setTarget('map');
            //Populate the layers to display. Done first time only.


            if (aState.isProject){ 
                //console.log("bmap :"+aState.bmap+" , "+aState.rviewscale);
                const mapext = aState.mapextent;
                const cx = parseFloat(mapext.minX+((mapext.maxX - mapext.minX)/2));
                const cy = parseFloat(mapext.minY+((mapext.maxY- mapext.minY)/2));

                const savedview = new View({projection: osgb, center: [cx, cy], zoom: 11 });
                const savedlayer = BaseLayers(aState.bmap, false);
                setBlayername(aState.bmap);
                setBlayer(savedlayer);
                map.addLayer(savedlayer);
                map.setView(savedview);

                const newExtent = setMapScaleCentre(aState.rviewscale, map, cx, cy);
                map.getView().fit([newExtent.xmin, newExtent.ymin, newExtent.xmax, newExtent.ymax]);
                setScale(parseInt(aState.rviewscale));
                setScaleInt(getZoomScaleInt(parseInt(aState.rviewscale)));

                map.render();
            } else {

                const view = new View({projection: osgb, center: [posX, posY],zoom: 11 }); //Live code
                const l = BaseLayers('os10k', false);
                setBlayername('os10k');
                setBlayer(l);
                //map.setLayerGroup(l);
                map.addLayer(l);
                map.setView(view);

                if (!aState.isProject && (aState.addressSource === 'postcode' || aState.addressSource === 'address')) { 
                    const displayLayer = sState.default.defaultlayer;
                    //console.log("Default display layer:"+displayLayer)
                    //
                    const displayScale = getLayerDefaultScale(displayLayer);
                    const minZoomInt = getLayerMinZoomInt(displayLayer);
    
                    aDispatch({
                        type: "requestviewchange",
                        payload: { bmap: displayLayer, rviewscale: displayScale, minZoomInt:minZoomInt}
                    });  
    
                }
    
            }
            const dp = new DragPan();
            setMapPan(dp);
            map.addInteraction(dp);

            const mp = new MousePosition({className: 'custom-mouse-position', coordinateFormat: createStringXY(0),});
            mp.setTarget(document.getElementById('map_coordinates'));
            map.addControl(mp);

            map.render();
            setMapobj(map);

            //Adds the map pin to map.
            addMapPin(map, posX, posY);

            //Draw annotations if added.
            //mapAnnotations:{count:0, annotations:[]}
            if (aState.mapAnnotations.count > 0){
                //Need to draw annotations.
                drawSavedAnnotations(aState.mapAnnotations.annotations, map);
            }

            /**
             * Map events
             */
            map.on("movestart", () => {
                setUserAction(true);
                aDispatch({ type: "isloading", payload: { isloading: true} });  
            });

            map.on("moveend", () => {
                //This seems to be the best option to track.
                const extent = map.getView().calculateExtent()
                setMapextent(extent);
                aDispatch({ type: "setmapextent", payload: { mapextent: extent} });
                setUserAction(false);
            });

            
            map.on("postrender", () => {
                aDispatch({ type: "isloading", payload: { isloading: false} });
            });

        }
    },[]);


    /**
     * Function for drawing saved annotations.
     */
     const drawSavedAnnotations = (annotations, map) => {

        //console.log("Annotations to draw:"+annotations.length);
        //console.log("Annotations:"+JSON.stringify(annotations));
    
        let annoLayer = null;
        if (!hasAnnotationLayer){
            annoLayer = new VectorLayer({
                source: new VectorSource({wrapX: false}),
                style: null
            });
            setAnnotationLayer(annoLayer);
            setHasAnnotationLayer(true);
            map.addLayer(annoLayer);
        } else {
            annoLayer = annotationLayer;
        }

        annotations.forEach((a) => {
            
            let g = null;
            let f = null;
            let styles = null;

            switch(a.type){
                case "circle":
                    //console.log("Circle Annotations:"+JSON.stringify(a));
                    styles = [new Style({
                        stroke: new Stroke({color: a.stroke.color_, lineDash: a.stroke.lineDash_, width: a.stroke.width_}),
                        fill: new Fill({color: a.fill.color_}),
                    })];
                    
                    f = new Feature({
                        geometry: new CirclePolygon([a.x,a.y],a.radius),
                    });
                    
                break;
                case "linestring":
                case "polyline":
                    //console.log("Polyline Annotations:"+JSON.stringify(a));
                    styles = [new Style({
                        stroke: new Stroke({color: a.stroke.color_, lineDash: a.stroke.lineDash_, width: a.stroke.width_})
                    })];

                    var points = [];
                    var coords = a.coordinates.toString().split(',');
                    for(let p = 0 ; p < coords.length; p+=2) {
                        points.push([parseFloat(coords[p]), parseFloat(coords[p+1])]);
                    }
                    f = new Feature({
                        geometry: new LineString(points),
                    });

                break;
                default:
                    //console.log("Default Annotations:"+JSON.stringify(a));
                    var points = [];
                    var coords = a.coordinates.toString().split(',');
                    for(let p = 0 ; p < coords.length; p+=2) {
                        points.push([parseFloat(coords[p]), parseFloat(coords[p+1])]);
                    }

                    f = new Feature({
                        geometry: new Polygon([points]),
                    });
                    
                    styles = [new Style({
                        stroke: new Stroke({color: a.stroke.color_, lineDash: a.stroke.lineDash_, width: a.stroke.width_}),
                        fill: new Fill({color: a.fill.color_}),
                    })];
                break;
            }

            f.setStyle(styles);
            f.setId(a.id);
            f.set('shape',a.type);
            annoLayer.getSource().addFeature(f);
        
        });

        map.render();
     }



    /**
     * Stores the view request if the extent has changed.
     */
    useEffect(() => {
        if (mapextent.length === 4){

            if (blayername === 'osmm' || blayername === 'osmmbw'){
                displayGridlines(true);
            } else {
                displayGridlines(false);
            }
 
            addWatermark();
            recordView(mapobj, aState.bmap, aState, sState);
        }
    },[mapextent]);

    /**
     * Draws the gridlines
     */
    const displayGridlines = (display) => {

        if (hasGrid && !display){
            mapobj.removeLayer(gridLayer);
            setHasGrid(false);
            return;
        }

        if (!hasGrid && display){
            const g = BaseLayers('grid', false);
            setGridLayer(g);
            setHasGrid(true);
            mapobj.addLayer(g);
            return;
        }
    }

    /**
     * Draws the watermark
     */
    const addWatermark = () => {

        if (hasWatermarkLayer){
            watermarkLayer.setMap(null);
        }
        const extArray = mapobj.getView().calculateExtent();
        const mapSize = mapobj.getSize();
        const px = (extArray[2] - extArray[0]) / mapSize[0];
        const py = (extArray[3] - extArray[1]) / mapSize[1];
        
        //image width: 3000, Height: 2970
        const imgExtent = []
        imgExtent.push(extArray[0]);
        imgExtent.push(extArray[1]);
        imgExtent.push(extArray[0] + (3000*px));
        imgExtent.push(extArray[1] + (2970*py));

        const watermark = new Image({
            source: new ImageStatic({
                imageExtent: imgExtent,
                url: process.env.REACT_APP_WATERMARK_PATH,
            }),
            map: mapobj,
            opacity: 0.3
        });
        setWatermarkLayer(watermark);
        setHasWatermarkLayer(true);
    }

    /**
     * Draws the map pin to mark the coordinates
     */
    const addMapPin = (map, posX, posY) => {

        const iconFeature = new Feature({
            geometry: new Point([posX,posY])
        });
        iconFeature.setId('mapPin');

        const svg = '<svg width="32" height="48" viewBox="0 0 32 48" xmlns="http://www.w3.org/2000/svg">'
        + '<path d="M16 0C7.604 0 0 6.806 0 15.204C0 23.6 6.938 33.624 16 48C25.062 33.624 32 23.6 32 15.204C32 6.806 24.398 0 16 0ZM16 22C12.686 22 10 19.314 10 16C10 12.686 12.686 10 16 10C19.314 10 22 12.686 22 16C22 19.314 19.314 22 16 22Z" fill="#DC3131"/>'
        + '</svg>';
        
        const style = new Style({
        image: new Icon({
            anchor:[0.5,1],
            opacity: 0.8,
            //src: 'data:image/svg+xml;utf8,' + svg,
            src: 'data:image/svg+xml,' + encodeURIComponent(svg),
            scale: 0.6
        })
        });

        const pinLayer = new VectorLayer({
            source: new VectorSource({features: [iconFeature]}),
            style: style
        });
        setMapPinLayer(pinLayer);
        map.addLayer(pinLayer);
    }

    /**
     * Handles user actions the effect the map object
     */
    useEffect(() => {
        if ((typeof mapobj !== 'undefined' && mapobj !== null && aState.toplevel === 'mapping') || aState.backdoor === true){
            //Set up event listerns based on action.
            //console.log("Current action:"+aState.caction);
            
            switch(aState.caction){
                case "centremap": 
                    //Centres map on click.
                    const listernKey = mapobj.on('singleclick', mapCentreClickEvent );
                    setMapCentreKey(listernKey);
                    
                break;
                case "centremap_clear": 
                    //console.log("centremap_clear called.")
                    mapobj.un('singleclick', mapCentreClickEvent );
                    unByKey(mapCentreKey);
                    setMapCentreKey(null);
                break;
                case "zoomprint": 
                    clearListerns();
                    if (aState.orderStep == 'plot'){
                        mapZoomFrameExtentClickEvent();
                    }
                    setMapaction('select')
                break;
                case "map_zoomin": 
                    zoomMapWindow('in');
                    clearAction();
                break;
                case "map_zoomout": 
                    zoomMapWindow('out');
                    clearAction();
                break;
                case "gotoprint_extent":
                    mapZoomFrameExtentClickEvent();
                    clearAction();
                break;
                case "dataorder_draw_rect":
                    drawDataRectangle();
                break;
                case "dataorder_draw_poly": 
                    drawDataPolygon();
                break;
                case "dataorder_draw_clear": 
                    drawDataClear();
                break;
                case "clear_orderframes": 
                    endPrintWindow();
                    drawDataClear();
                break;
                case "createCoordPolygon":
                    drawDataCoords(aState.requestdatageom.minX, aState.requestdatageom.minY, aState.requestdatageom.maxX, aState.requestdatageom.maxY);
                    clearAction();
                break;
                case "createAnnotationOSpolygon": 
                    clearSelectedAnnotations();
                    const listernOSKey = mapobj.on('singleclick', mapClickEvent);
                    setClickListerner(listernOSKey);
                    setFillToolActive(true);
                    setDoUnion(false);
                break;
                case "createAnnotationOSmultipolygon": 
                    clearSelectedAnnotations();
                    const listernOSMultiKey = mapobj.on('singleclick', mapClickEvent);
                    setClickListerner(listernOSMultiKey);
                    setFillToolActive(true);
                    setDoUnion(true);
                break;
                case "createAnnotationRectangle":
                    //console.log("createAnnotationRectangle selected");
                    clearSelectedAnnotations();
                    clearAnnotationInteractions();
                    drawAnnotationRectangle();
                break;
                case "createAnnotationPolygon":
                    clearSelectedAnnotations();
                    clearAnnotationInteractions();
                    drawAnnotationPolygon();
                break;
                case "createAnnotationCircle":
                    clearSelectedAnnotations();
                    clearAnnotationInteractions();
                    drawAnnotationCircle();
                break;
                case "selectAnnotation":
                    clearSelectedAnnotations();
                    clearAnnotationInteractions();
                    clearMeasurementLayer();
                    selectAnnotationObject();
                break;
                case "createAnnotationLine": 
                    clearSelectedAnnotations();
                    clearAnnotationInteractions();
                    clearMeasurementLayer();
                    drawAnnotationLine();
                break;
                case "createMeasureArea": 
                    clearSelectedAnnotations();
                    clearAnnotationInteractions();
                    drawMeasureArea(measurementAreaUnit);
                break;
                case "createMeasureLine": 
                    clearSelectedAnnotations();
                    clearAnnotationInteractions();
                    drawMeasureLine(measurementLineUnit);
                break;
                case "endAnnotationTools":
                    clearSelectedAnnotations();
                    savesMapAnnotations();
                    clearAnnotationInteractions();
                    clearMeasurementLayer();
                    endAnnotationInteractions();
                break;
                case "doSavedProject":
                    clearSelectedAnnotations();
                    clearAnnotationInteractions();
                    clearMeasurementLayer();
                    savesMapAnnotations();
                break;
                case "ordersummary":
                    //Order summary page displayed. Mapping no longer displayed.
                    savesMapAnnotations();
                break;
            }
        }
    },[aState.caction]);


    /**
     * Handles annotation bar single actions. i.e. undo, redo.
     */
    useEffect(() => {
        switch(aState.annotationAction){

            case "undoLast":
                undoAnnotation();
            break;
            case "redoLast":
                redoAnnotation();
            break;
            case "deleteObject":
                deleteMapAnnotation();
            break;
            default:

            break;
        }
        aDispatch({ type: "annotationAction", payload: { annotationAction: 'none' } })
    },[aState.annotationAction]);
    
    
    /**
     * Centre map on click.
     */
    const mapCentreClickEvent = (evt) => {
        const coords = String(mapobj.getCoordinateFromPixel(evt.pixel));
        const coordArray = coords.split(",");
        const mapExtent = getMapExtentbyPoint(parseInt(coordArray[0]), parseInt(coordArray[1]),mapobj);
        mapobj.getView().fit([mapExtent.minX, mapExtent.minY, mapExtent.maxX, mapExtent.maxY]);
        mapobj.render();
    };

    const endMapInteraction = () => {
        mapobj.removeInteraction(mapSelect);
        mapobj.removeInteraction(mapTranslate);
        mapobj.removeInteraction(mapSnap);
    }

    const clearAction = () => {
        aDispatch({
            type: "actionchange",
            payload: { action: ''}
        });
    }

    /**
     * Function for os polygon fill.
     */
    const mapClickEvent = (evt) => {
        const coords = String(mapobj.getCoordinateFromPixel(evt.pixel));
        const coordArray = coords.split(",");
        setClickCoords(coordArray);
    };

    /**
     * Listern event for map clicks.
     */
     useEffect(() => {

        if (fillToolActive){
            //console.log("Capture from OS Mapping requested!");
            getGeomFromCoords(parseInt(clickCoords[0]), parseInt(clickCoords[1]), doUnion,fillGeom, aDispatch);
        }
    },[clickCoords]);
    
    const clearFillPolygon = () => {
        unByKey(clickListerner);
        setClickListerner(null);
        if (fillToolActive) setFillToolActive(false);
    }

    /**
     * Activated on page size change
     */
    useEffect(() => {
        if (!firstLoad){
            mapobj.updateSize();
        }
    },[props.width, props.height]);



    /**
     * Activated on view scale change request
     */
    useEffect(() => {
       //console.log("Map view scale change? firstload"+firstLoad+" "+aState.rviewscale+" "+scale);
        if (!firstLoad && (aState.rviewscale !== scale) ){

            if (parseInt(aState.rviewscale) !== parseInt(scale)){
                const mapExtent = setMapScale(aState.rviewscale, mapobj);
                mapobj.getView().fit([mapExtent.xmin, mapExtent.ymin, mapExtent.xmax, mapExtent.ymax]);
                setScale(parseInt(aState.rviewscale));
                setScaleInt(getZoomScaleInt(parseInt(aState.rviewscale)));

                //Calculate the map area.
                const xLen = mapExtent.xmax - mapExtent.xmin;
                const yLen = mapExtent.ymax - mapExtent.ymin;
                let mapArea = xLen * yLen;
                let mapUnit = "km";

                if (aState.rviewscale <=2500){ mapUnit = "ha"; mapArea = (mapArea * 0.0001)} else { mapArea = (mapArea * 0.001) }
                aDispatch({
                    type: "updatemaparea",
                    payload: { mapArea: mapArea.toFixed(2), mapUnit: mapUnit}
                });
            }

            const l = getDisplayLayer(parseInt(aState.rviewscale));
            if (blayername !== l){
                aDispatch({
                    type: "lchange",
                    payload: { bmap: l}
                });
            } else {
                mapobj.render();
            }
        }
    },[aState.rviewscale]);

    useEffect(() => {
        //console.log("Map background change? firstload"+firstLoad+" "+aState.bmap+" "+blayername);
        if (!firstLoad && (aState.bmap !== blayername) ){
            if (aState.bmap !== blayername){
                if (blayer !== null) mapobj.removeLayer(blayer);
                const l = BaseLayers(aState.bmap, false);
                setBlayer(l);
                setBlayername(aState.bmap);
                mapobj.getLayers().insertAt(0, l);
            }
            mapobj.render();
        }
    },[aState.bmap]);

    /**
     * Activated on action change.
     * Stores the current action.
     */
    useEffect(() => {
        if (!freezeaction){
            aDispatch({
                type: "actionchange",
                payload: { action: mapaction}
            });
        }
    },[mapaction]);

    /**
     * Activated on change of view scale
     * Stores the current view scale
     */
    useEffect(() => {
        if (aState.cviewscale != scale){
            aDispatch({
                type: "updateviewscale",
                payload: { cviewscale: scale}
              });
        }
    },[scale]);

    /**
     * Activates when user requested a new view scale.
     */
    const zoomMapWindow = (z) => {
        //console.log("Min Zoom Int: "+aState.minZoomInt);
        let i = scaleInt;
        if (z === 'in'){
            if (scaleInt > 1 && scaleInt >=aState.minZoomInt ){ i--; }
        } else {
            if (scaleInt < 12){ i++; }
        }
        setScaleInt(i);
        //Set new view scale.
        aDispatch({
            type: "requestviewscale",
            payload: { rviewscale: getViewScaleFromInt(i)}
        });
        //setScale(vs);
    } 

    /**
     * Min zoom int has changed.
     * Check to make sure valid.
     * Will zoom accordingly.
     */
    useEffect(() => {

        const s = getViewScaleFromInt(aState.minZoomInt);
        if (scale < s){
            aDispatch({
                type: "requestviewscale",
                payload: { rviewscale: s}
              });
        }
    },[aState.minZoomInt]);



    /**************  Map Object Printing Starts **************/

    /**
     * Display the print window.
     * Adjusts according to page variables
     */
    useEffect(() => {
        if (aState.toplevel == 'mapping' && aState.orderStep == 'plot'){
            let timer;
            clearTimeout(timer);
            timer = setTimeout(function() { //Implements a delay to draw print frame.
                drawPrintWindow();
            }.bind(this), 1000);

            return function cleanup() {
                clearTimeout(timer);
            };
        }
    },[aState.print]);

    /**
     * Stores the print frame extent for pricing.
     */
    useEffect(() => {

        if (printFrame != null){
            const extent = printFrame.getExtent();
            aDispatch({
                type: "setprintframe",
                payload: { extent: extent}
            });
        }

    },[printFrame]);


    const drawPrintWindow = () => {
        //console.log("print changed : "+aState.cview);
        if (aState.orderStep == 'plot' && aState.toplevel == 'mapping'){
            if(!firstLoad){
            //Draw the print window frame based on selected params.
            //Get the details to calculated the window size.
                //console.log("hasPrintWindow called:"+hasPrintWindow+" props width:"+props.width+" props height:"+props.height);

                let ext = []; 
                if (hasPrintWindow){
                    ext = printWindowLayer.getSource().getFeatureById('printGeom').getGeometry().getExtent();
                } else {
                    ext = mapobj.getView().calculateExtent();
                }
                const cx = parseInt(ext[0]) + ((parseInt(ext[2]) - parseInt(ext[0]))/2);
                const cy = parseInt(ext[1]) + ((parseInt(ext[3]) - parseInt(ext[1]))/2);
                const templates = sState.templates;

                let pwidth = 0; 
                let pheight = 0;
                templates.forEach(function (t) {
                    if (t.pagesize == aState.print.size && t.orientation == aState.print.orientation){
                        pwidth = t.mapwidth;
                        pheight = t.mapheight;
                    }
                });
                //const p = getPrintWindow(pwidth, pheight, props.width, props.height)
                
                const w = []
                const pw = getPrintWindowExt(aState.print.scale, pwidth, pheight, cx, cy, props.width, props.height);
                w.push([pw.px1,pw.py1],[pw.px1,pw.py2],[pw.px2,pw.py2],[pw.px2,pw.py1],[pw.px1,pw.py1]);

                const poly = new Polygon([w]);
                const feature = new Feature({
                    geometry: poly
                })
                feature.setId('printGeom');
                if (poly.getArea() > 10){
                    setPrintFrame(poly);
                    const styles = [new Style({
                        stroke: new Stroke({color: 'blue', width: 3}),
                        fill: new Fill({color: 'rgba(0, 0, 255, 0.1)'})
                    })];

                    if (hasPrintWindow){
                        mapobj.removeLayer(printWindowLayer);
                    }

                    //const vs = new VectorSource({features: [feature]});
                    const printLayer = new VectorLayer({
                        source: new VectorSource({features: [feature]}),
                        style: styles
                    });

                    setPrintWindowLayer(printLayer);
                    //mapobj.setLayerGroup(new LayerGroup);
                    mapobj.addLayer(printLayer);
                    mapobj.render();
                    setHasPrintWindow(true);
                    startPrintWindow(feature, printLayer);
                }
            }
        }
    };

    /**
     * Starts the print window
     */
    const startPrintWindow = (feature, printLayer) => {
            //console.log("startPrintWindow with features: "+printLayer.getSource().getFeatures().length);
            setPrintWindowLoaded(true);
            
            //const select = new Select();
            
            const select = new Select({
                layers:[printLayer]
            });
            
            const translate = new Translate({
                features: select.getFeatures()
            });
            select.getFeatures().push(feature);
            mapobj.addInteraction(select);
            mapobj.addInteraction(translate);
            setMapSelect(select);
            setMapTranslate(translate);
        //}
    };

    /**
     * Ends the print window
     */
    const endPrintWindow = () => {
        if (hasPrintWindow){

            endMapInteraction();
            mapobj.removeLayer(printWindowLayer);
            mapobj.removeControl(toolExtent);
            setHasPrintWindow(false);
            setPrintWindowLoaded(false);
        }
    };

    /**
     * Zoom to print map window.
     */
    const mapZoomFrameExtentClickEvent = () => {
        const extent = printFrame.getExtent();
        const pscale = getNextScale(getScaleFromExtent(mapobj, extent));

        aDispatch({
            type: "requestviewscale",
            payload: { rviewscale: pscale}
        });
    };

    /**************  Map Object Printing End **************/


    
    useEffect(() => {
        if (aState.orderStep === "productSelect"){
            if (hasDataWindow){
                endDataWindow();
            }
            if (hasPrintWindow){
                endPrintWindow();
            }
            
        }
    },[aState.orderStep]);

    /**************  Map Object Data Starts  **************/

    const dataObjectStyle = [new Style({
        stroke: new Stroke({color: 'blue', width: 3}),
        fill: new Fill({color: 'rgba(0, 0, 255, 0.1)'})
    })];

    const dataObjectSelectStyle = [new Style({
        stroke: new Stroke({color: 'rgba(20, 155, 232)', width: 3}),
        fill: new Fill({color: 'rgba(193, 218, 233, 0.3)'})
    })];

    /**
     * Display the data window.
     * Adjusts according to page variables and is entry point. i.e. aState.data changes
     */
    useEffect(() => {
        if (aState.toplevel == 'mapping' && aState.orderStep == 'data'){

            aDispatch({
                type: "setdataloading",
                payload: { dataloading: true }
            });

            let timer;
            clearTimeout(timer);
            timer = setTimeout(function() { //Implements a delay to draw data frame.
                drawDataWindow();
            }.bind(this), 500);

            return function cleanup() {
                clearTimeout(timer);
            };
        }
    },[aState.data]);

    const drawDataWindow = () => {
        //console.log("drawDataWindow called: has custom polygon"+aState.hasDataCustomPolygon+", "+aState.data.fixedsize);
        if (aState.orderStep == 'data' && aState.toplevel == 'mapping'){
            if(!firstLoad){
                switch (aState.data.fixedsize){
                    case '4ha':
                        drawDataFixed(200);
                        aDispatch({ type: "setdatageomcustom", payload: { hasDataCustomPolygon: false } });
                        break;
                    case '1ha':
                        drawDataFixed(100);
                        aDispatch({ type: "setdatageomcustom", payload: { hasDataCustomPolygon: false } });
                        break;
                    case 'custom':
                        //console.log("isProject."+aState.isProject);
                        if (!aState.hasDataCustomPolygon && !aState.isProject){
                            clearDataLayer();
                            //Displays the toolbar
                            //console.log("Displays the toolbar");
                            aDispatch({ type: "isDataCustom", payload: { isDataCustom: true, hasToolbar: true  } });
                        }
                        if (aState.isProject){ 
                            //console.log("Custom polygon from saved project.");
                            drawDataSavedPolygon();
                        }
                    default:

                    break;
                }
                if (aState.isProject) { aDispatch({ type: "savedProjectLoad", payload: { isProject: false }});  }
            }
        }
    };

    const drawDataFixed = (size) => {
        
        //console.log("draw DataFixed called"+size);
        clearDataInteractions();
        let ext = []; 
        if (hasDataWindow){
            //Area already exists to get cx and cy from geom.
            ext = dataWindowLayer.getSource().getFeatureById('dataGeom').getGeometry().getExtent();
            //ext = dataWindowLayer.getSource().getExtent();
        } else {
            ext = mapobj.getView().calculateExtent();
        }
        
        const cx = parseInt(ext[0]) + ((parseInt(ext[2]) - parseInt(ext[0]))/2);
        const cy = parseInt(ext[1]) + ((parseInt(ext[3]) - parseInt(ext[1]))/2);

        const px1 = parseInt(cx - (size/2));
        const px2 = parseInt(cx + (size/2));
        const py1 = parseInt(cy - (size/2));
        const py2 = parseInt(cy + (size/2));

        const w = []
        w.push([px1,py1],[px1,py2],[px2,py2],[px2,py1],[px1,py1]);
        const poly = new Polygon([w]);
        const feature = new Feature({
            geometry: poly
        })
        feature.setId('dataGeom');
        feature.set('shape','fixed');

        if (poly.getArea() > 10){
            //setPrintFrame(poly);

            if (hasDataWindow){
                mapobj.removeLayer(dataWindowLayer);
            }

            //const vs = new VectorSource({features: [feature]});
            const dataLayer = new VectorLayer({
                source: new VectorSource({features: [feature]}),
                style: dataObjectStyle
            });

            setDataWindowLayer(dataLayer);
            //mapobj.setLayerGroup(new LayerGroup);
            mapobj.addLayer(dataLayer);
            mapobj.render();
            storeDataPolygon(dataLayer);
            setHasDataWindow(true);
            startDataWindow(feature,dataLayer);
        }
    };

    const drawDataRectangle = () => {
        //console.log("draw rectangle called");
        setFreezeaction(true);
        
        if (hasDataWindow){
            mapobj.removeLayer(dataWindowLayer);
            setDataWindowLayer(null);
        }
        clearDataInteractions();

        const dataLayer = new VectorLayer({
            source: new VectorSource({wrapX: false}),
            style: dataObjectStyle
        });

        //mapobj.render();
        const draw = new Draw({
            source: dataLayer.getSource(),
            type: 'Circle',
            geometryFunction: createBox(),
        });

        draw.on('drawend', function(e) {
            e.feature.setProperties({
              'id': 'dataGeom',
              'shape': 'rectangle'
            });
        });

        setMapDraw(draw);
        mapobj.addInteraction(draw);
        setHasDataWindow(true);
        setDataWindowLayer(dataLayer);
        mapobj.addLayer(dataLayer);

        dataLayer.getSource().on('addfeature', setIdDataRectangle);
    }

    const drawDataPolygon = () => {
        setFreezeaction(true);
        clearDataInteractions();

        if (hasDataWindow){
            mapobj.removeLayer(dataWindowLayer);
            setDataWindowLayer(null);
        }

        const dataLayer = new VectorLayer({
            source: new VectorSource({wrapX: false}),
            style: dataObjectStyle
        });

        const draw = new Draw({
            source: dataLayer.getSource(),
            type: 'Polygon',
          });
    
        draw.on('drawend', function(e) {
            e.feature.setProperties({
              'id': 'dataGeom',
              'shape': 'polygon'
            });
        });
        setMapDraw(draw);
        mapobj.addInteraction(draw);

        dataLayer.getSource().on('addfeature', setIdDataPolygon);

        setDataWindowLayer(dataLayer);
        mapobj.addLayer(dataLayer);
        mapobj.render();
        setHasDataWindow(true);

    }

    const drawDataCoords = (x1, y1, x2, y2) => {

        //endDataWindow();
        //setDataWindowLoaded(false);
        clearDataInteractions();

        const px1 = parseInt(x1);
        const px2 = parseInt(x2);
        const py1 = parseInt(y1);
        const py2 = parseInt(y2);

        const w = []
        w.push([px1,py1],[px1,py2],[px2,py2],[px2,py1],[px1,py1]);
        const poly = new Polygon([w]);
        const feature = new Feature({
            id: 'dataGeom',
            geometry: poly
        })
        feature.setId('dataGeom');
        if (poly.getArea() > 1){
            //setPrintFrame(poly);
            if (hasDataWindow){ mapobj.removeLayer(dataWindowLayer); }

            const dataLayer = new VectorLayer({
                source: new VectorSource({features: [feature]}),
                style: dataObjectStyle
            });

            setDataWindowLayer(dataLayer);
            mapobj.addLayer(dataLayer);

            //Checks to see if created geom is outside current view extent, if so zoom to geom.
            const mapext = mapobj.getView().calculateExtent();
            if (px1 < mapext[0] || py1 < mapext[1] || px2 > mapext[2] || py2 > mapext[3]){
                mapobj.getView().fit([px1, py1,  px2, py2]);
                const pscale = getNextScale(getScaleFromExtent(mapobj, poly.getExtent()));
    
                aDispatch({
                    type: "requestviewscale",
                    payload: { rviewscale: pscale}
                });
            }

            setHasDataWindow(true);
            startDataWindow(feature, dataLayer);

            const geom = feature.getGeometry();
            storeCreatedArea(geom);
        }
    };

    const drawDataSavedPolygon = () => {

        //console.log("draw SavedPolygon called");
        clearDataInteractions();
        let ext = []; 
        if (hasDataWindow){
            //Area already exists to get cx and cy from geom.
            ext = dataWindowLayer.getSource().getFeatureById('dataGeom').getGeometry().getExtent();
            //ext = dataWindowLayer.getSource().getExtent();
        } else {
            ext = mapobj.getView().calculateExtent();
        }
        const poly = new Polygon(aState.datageom.geometry);
        const feature = new Feature({
            geometry: poly
        })
        feature.setId('dataGeom');
        feature.set('shape','polygon');

        if (poly.getArea() > 10){

            if (hasDataWindow){  
                mapobj.removeLayer(dataWindowLayer);
            }

            const dataLayer = new VectorLayer({
                source: new VectorSource({features: [feature]}),
                style: dataObjectStyle
            });

            setDataWindowLayer(dataLayer);
            //mapobj.setLayerGroup(new LayerGroup);
            mapobj.addLayer(dataLayer);
            mapobj.render();
            storeDataPolygon(dataLayer);
            setHasDataWindow(true);
            startEditDataWindow(feature);
        }

    }


    /**
     * Sets the id of the created polygon for data exports and stores.
     */
    const setIdDataPolygon = (event) => {
        //console.log("setIdDataPolygon called");
        setFreezeaction(false);
        mapobj.getInteractions().pop()
        event.feature.setId('dataGeom');
        //console.log("setIdDataPolygon called");
    
        const geom = event.feature.getGeometry();
        storeCreatedArea(geom);

        const select = new Select({
            wrapX: false,
        });
            
        const modify = new Modify({
            features: select.getFeatures(),
        });

        modify.on('modifyend', function(e) {
            const features = e.features.getArray();
            const editedgeom = features[0].getGeometry();
            storeCreatedArea(editedgeom);
        });
        
        const translate = new Translate({
            features: select.getFeatures()
        });

        
        translate.on('translateend', function(e) {
            const features = e.features.getArray();
            const editedgeom = features[0].getGeometry();
            storeCreatedArea(editedgeom);
        });

        const p = new  DragPan();
        setMapTranslate(translate);
        setMapSelect(select);
        setMapModify(modify);

        mapobj.addInteraction(select);
        mapobj.addInteraction(p);
        mapobj.addInteraction(translate);
        mapobj.addInteraction(modify);
        
        select.getFeatures().push(event.feature);
        
        //storeDataPolygon(dataWindowLayer);
        aDispatch({ type: "actionchange", payload: { action: 'dataorder_draw_complete' } });
    }

    const setIdDataRectangle = (event) => {
        //console.log("setIdDataRectangle called");
        setFreezeaction(false);
        mapobj.getInteractions().pop();
        event.feature.setId('dataGeom');

        //const features = event.features.getArray();
        const geom = event.feature.getGeometry();
        storeCreatedArea(geom);

        const select = new Select({ wrapX: false,});
        /*const select = new Select({
            layers:[dataWindowLayer],
            wrapX: false,
            style: dataObjectStyle
        });*/

        const modify = new Modify({
            features: select.getFeatures(),
        });

        //const snap = new Snap({source: dataWindowLayer.getSource()});
        //console.log("Get feature shape: "+event.feature.get('shape'));
        if (event.feature.get('shape') ==='rectangle'){

            //For handling rectangles
            var modifyingFeatures = [];
            var rectangleInteraction;
            modify.on('modifystart', function(e) {
                modifyingFeatures = e.features;
                var extent;
                const features = e.features.getArray();
                const editedgeom = features[0].getGeometry().getCoordinates();
                
                features[0].set('coordinates', editedgeom );

                extent = features[0].getGeometry().getExtent();
                document.addEventListener('pointermove', modifying);

                rectangleInteraction = new Extent({
                    boxStyle: dataObjectStyle
                });
                rectangleInteraction.setActive(false);
                if (extent) {
                    rectangleInteraction.setExtent(extent); 
                }
                mapobj.addInteraction(rectangleInteraction);
            });

            var modifying = function(c){

                const features = modifyingFeatures.getArray();
                var oldCoordinates = features[0].get('coordinates');
                
                if (!oldCoordinates) {
                    return;
                }
                var newCoordinates = features[0].getGeometry().getCoordinates();
                if (typeof newCoordinates !== 'undefined' || typeof oldCoordinates !== 'undefined'){
                    var newExtent = getRectangleExtent(oldCoordinates, newCoordinates)
                    rectangleInteraction.setExtent(newExtent);
                }
          };
        }

        modify.on('modifyend', function(e) {

            document.removeEventListener('pointermove', modifying);
            const features = e.features.getArray();
            const editedgeom = features[0].getGeometry();
            var polygon = new Feature(fromExtent(rectangleInteraction.getExtent()));
            editedgeom.setCoordinates(polygon.getGeometry().getCoordinates());
            editedgeom.unset('coordinates');

            modifyingFeatures = [];
            mapobj.removeInteraction(rectangleInteraction);
            rectangleInteraction = null;
            storeCreatedArea(editedgeom);
        });
        
        const translate = new Translate({
            features: select.getFeatures()
        });

        select.getFeatures().push(event.feature);
        //console.log("feature info: "+ select.length);
        translate.on('translateend', function(e) {
            const features = e.features.getArray();
            const editedgeom = features[0].getGeometry();
            storeCreatedArea(editedgeom);
        });

        const p = new  DragPan();
        setMapTranslate(translate);
        setMapSelect(select);
        setMapModify(modify);

        mapobj.addInteraction(select);
        mapobj.addInteraction(p);
        mapobj.addInteraction(translate);
        mapobj.addInteraction(modify);

        aDispatch({ type: "actionchange", payload: { action: 'dataorder_draw_complete' } });
    }

    function getRectangleExtent(oldCoordinates, newCoordinates) {
        var result;
        if (oldCoordinates[0].length == newCoordinates[0].length) {

          let diffVertex = -1;
          for (let i = 0; i < oldCoordinates[0].length; i++) {
            if (oldCoordinates[0][i][0] != newCoordinates[0][i][0] ||
                oldCoordinates[0][i][1] != newCoordinates[0][i][1]) {
              diffVertex = i;
            }
          }

          let oppositeVertex = diffVertex > 1 ? diffVertex - 2 : diffVertex + 2;
          if (typeof newCoordinates[0][diffVertex] !== 'undefined'){
            let minX = Math.min(newCoordinates[0][diffVertex][0], newCoordinates[0][oppositeVertex][0]);
            let minY = Math.min(newCoordinates[0][diffVertex][1], newCoordinates[0][oppositeVertex][1]);
            let maxX = Math.max(newCoordinates[0][diffVertex][0], newCoordinates[0][oppositeVertex][0]);
            let maxY = Math.max(newCoordinates[0][diffVertex][1], newCoordinates[0][oppositeVertex][1]);
            result = [minX, minY, maxX, maxY];
          }


        } else {

          let newVertex;
          let i;
          newCo:for (i = 0; i < newCoordinates[0].length; i++) {
            for (let j = 0; j < oldCoordinates[0].length; j++) {
              if (newCoordinates[0][i][0] == oldCoordinates[0][j][0]) {
                if (newCoordinates[0][i][1] == oldCoordinates[0][j][1]) {
                  continue newCo;
                }
              }
            }

            newVertex = i
            break;
          }
          if (!newVertex) {
            result = boundingExtent(oldCoordinates[0]);
            return result;
          }
          let oppositeVertex1 = i - 2 > 0 ? i - 2 : i + 3;
          let oppositeVertex2 = i - 3 > 0 ? i - 3 : i + 2;
          result = boundingExtent([
            newCoordinates[0][newVertex],
            newCoordinates[0][oppositeVertex1],
            newCoordinates[0][oppositeVertex2]
          ]);
 
        }
        return result;
      }

    const storeCreatedArea = (geom) => {

        if (typeof geom !== 'undefined'){
            //console.log("geom: "+JSON.stringify(geom));
            const g = {
                geometry: geom.getCoordinates(),
                area: geom.getArea(),
            }
            //console.log("Created geom area:"+geom.getArea());
            aDispatch({
                type: "setdatageom",
                payload: { datageom: g }
            });
    
            aDispatch({
                type: "setdataloading",
                payload: { dataloading: false }
            });
        }

    }

    /**
     * Stores the drawn data polygon for export.
     */
    const storeDataPolygon = (layer) => {

        const geom = layer.getSource().getFeatureById('dataGeom').getGeometry();
        const g = {
            geometry: geom.getCoordinates(),
            area: geom.getArea(),
        }

        aDispatch({
            type: "setdatageom",
            payload: { datageom: g }
        });

        aDispatch({
            type: "setdataloading",
            payload: { dataloading: false }
        });
    }

    const clearDataLayer = () => {

        if (hasDataWindow){
            mapobj.removeLayer(dataWindowLayer);
        }
    }


    const drawDataClear = () => {

        setFreezeaction(false);
        mapobj.removeLayer(dataWindowLayer);

        mapobj.getInteractions().forEach((interaction) => {
            if (interaction instanceof UndoRedo) {
                console.log("interaction UndoRedo found at drawDataClear");
            } else {
                mapobj.getInteractions().pop();
            }
            
        });

        const p = new  DragPan();
        mapobj.addInteraction(p);

        aDispatch({ type: "actionchange", payload: { action: 'dataorder_draw_complete' } });
    }

    const startDataWindow = (feature,dataLayer) => {
        clearDataInteractions();
    //if (!dataWindowLoaded){
        setDataWindowLoaded(true);

        //const select = new Select({ wrapX: false,});
        
        const select = new Select({
            layers:[dataLayer],
            wrapX: false,
            //style: dataObjectSelectStyle
        });

        const translate = new Translate({
            features: select.getFeatures()
        });
        select.getFeatures().push(feature);

        translate.on('translateend', function(e) {
            const features = e.features.getArray();
            const editedgeom = features[0].getGeometry();
            storeCreatedArea(editedgeom);
        });

        const p = new  DragPan();
        setMapSelect(select);
        setMapTranslate(translate);

        mapobj.addInteraction(select);
        mapobj.addInteraction(p);
        mapobj.addInteraction(translate);
        
        //}

    };

    const startEditDataWindow = (feature) => {
        clearDataInteractions();
        //console.log("startEditDataWindow called");
        setDataWindowLoaded(true);

        //const select = new Select({ wrapX: false,});
        const select = new Select({
            layers:[dataWindowLayer],
            wrapX: false,
            style: dataObjectSelectStyle
        });
            
        const modify = new Modify({
            features: select.getFeatures(),
        });

        modify.on('modifyend', function(e) {
            const features = e.features.getArray();
            const editedgeom = features[0].getGeometry();
            storeCreatedArea(editedgeom);
        });
        
        const translate = new Translate({
            features: select.getFeatures()
        });

        select.getFeatures().push(feature);

        translate.on('translateend', function(e) {
            const features = e.features.getArray();
            const editedgeom = features[0].getGeometry();
            storeCreatedArea(editedgeom);
        });

        const p = new  DragPan();
        setMapTranslate(translate);
        setMapSelect(select);
        setMapModify(modify);

        mapobj.addInteraction(select);
        mapobj.addInteraction(p);
        mapobj.addInteraction(translate);
        mapobj.addInteraction(modify);
        

    };

    const clearDataInteractions=()=>{
        setDataWindowLoaded(false);
        mapobj.getInteractions().forEach((interaction) => {
            if (interaction instanceof UndoRedo) {
                console.log("interaction UndoRedo found at clearDataInteractions");
            } else {
                mapobj.getInteractions().pop();
            }
            
        });
    }



    /**
     * Ends the Data window
     */
    const endDataWindow = () => {
        if (hasDataWindow){
            //console.log("endDataWindow called");
            endMapInteraction();
            mapobj.removeLayer(dataWindowLayer);
            setHasDataWindow(false);
            setDataWindowLoaded(false);

            mapobj.getInteractions().forEach((interaction) => {
                if (interaction instanceof UndoRedo) {
                    console.log("interaction UndoRedo found at endDataWindow");
                } else {
                    mapobj.getInteractions().pop();
                }
                
            });

            const p = new  DragPan();
            mapobj.addInteraction(p);
        }
    };


/**************  Map Object Data End **************/

/**************  Map Annotation Start ***************/

const loadAnnotationUndoRedo =() =>{
    //console.log("loadAnnotationBar called");
    let hasUndoRedo = false;
    mapobj.getInteractions().forEach((interaction) => {
        if (interaction instanceof UndoRedo) {
            //mapobj.getInteractions().pop();
            hasUndoRedo = true;
        }
    });

    if (!hasUndoRedo){
        const ur = new UndoRedo();
        mapobj.addInteraction(ur);
        setUndoRedo(ur);
        mapobj.render();
        ur.on('undo', function(e) {
            //aDispatch({ type: "annotationAction", payload: { annotationAction: 'none' } })
        });
        ur.on('redo', function(e) {
            
        });
        ur.on('stack:add', function (e) {
            // New action element
            console.log(ur.length()+' undo | '+ur.length('redo')+' redo')
        });
    }

}


const setLineStyle = (style, width) => {

    let lineDash = null;
    switch(style){
        case "solid":
            lineDash = null
            break;
        case "dashedtight":
            let dashtight = width;
            let dashtightspace = Math.ceil(width *1.3);
            if (dashtight < 4) dashtight = 4;
            if (dashtightspace < 6) dashtightspace = 6;
            lineDash = [dashtight,dashtightspace];
            break;
        case "dashedloose":
            let dashloose = Math.ceil(width*3);
            let dashloosespace = Math.ceil(width *3.3);
            if (dashloose < 10) dashloose = 10;
            if (dashloosespace < 14) dashloosespace = 14;
            lineDash = [dashloose,dashloosespace];

            break;
    }
    return lineDash;
}


const selectedPolygonVertexStyle = new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: 'rgb(229,83,0)'
      })
    }),
    geometry: function(feature) {
      // return the coordinates of the first ring of the polygon
      let coordinates = feature.getGeometry().getCoordinates()[0];
      return new MultiPoint(coordinates);
    }
})

const selectedLineVertexStyle = new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: 'rgb(229,83,0)'
      })
    }),
    geometry: function(feature) {
      // return the coordinates of the first ring of the polygon
      let coordinates = feature.getGeometry().flatCoordinates;
      return new MultiPoint(coordinates,'XY');
    }
})

const selectedCircleVertexStyle = new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: 'rgb(229,83,0)'
      })
    }),
    geometry: function(feature) {
      // return the coordinates of the first ring of the polygon
      let r = feature.getGeometry().getRadius();
      let x = feature.getGeometry().getCenter()[0];
      let y = feature.getGeometry().getCenter()[1];

      let coordinates = [];
      coordinates.push(parseFloat(x));
      coordinates.push(parseFloat(y));
      coordinates.push(parseFloat(x+r));
      coordinates.push(parseFloat(y));

      return new MultiPoint(coordinates,'XY');
    }
})

/**
 * function that handles the selection of map annotations.
 */
const selectAnnotationObject = () => {

        if (hasAnnotationLayer){
            let annoType = 'none';
            //clearMapInteractions();

            const p = new  DragPan();
            mapobj.addInteraction(p);

            const select = new Select({
                layers:[annotationLayer],
                style: null
            });

            select.on('select', function(e) {
                if (typeof e.selected[0] !== 'undefined'){ 
                    var selectStyle = [];
                    const cStyle = e.selected[0].getStyle();
                    const sStyle = aState.currentStyle;
                    let isStyle = false;
                    //console.log("Current Style: "+JSON.stringify(cStyle));
                    switch (e.selected[0].get('shape')){
                        case 'linestring':
                            annoType = 'linestring';
                            selectStyle = [
                                new Style({
                                    stroke: new Stroke({
                                    color: cStyle[0].stroke_.color_,
                                    width: cStyle[0].stroke_.width_,
                                    lineDash: cStyle[0].stroke_.lineDash_,
                                    }),
                                }),
                                selectedLineVertexStyle,
                            ];
                            e.selected[0].setStyle(selectStyle);
                            sStyle.outline.colour = cStyle[0].stroke_.color_;
                            sStyle.outline.width = cStyle[0].stroke_.width_;
                            sStyle.outline.dash = cStyle[0].stroke_.lineDash_;
                            sStyle.source = 'mapping';
                            sStyle.object = annoType;
                            isStyle = true;
                        break;
                        case 'circle':
                            annoType = 'circle';
                            //console.log("Current Style: "+JSON.stringify(cStyle));
                            selectStyle = [
                                new Style({
                                    stroke: new Stroke({
                                    color: cStyle[0].stroke_.color_,
                                    width: cStyle[0].stroke_.width_,
                                    lineDash: cStyle[0].stroke_.lineDash_,
                                    }),
                                    fill: new Fill({
                                    color: cStyle[0].fill_.color_,
                                    }),
                                }),
                                selectedCircleVertexStyle,
                            ];
                            e.selected[0].setStyle(selectStyle);
                            
                            sStyle.outline.colour = cStyle[0].stroke_.color_;
                            sStyle.outline.width = cStyle[0].stroke_.width_;
                            sStyle.outline.dash = cStyle[0].stroke_.lineDash_;
                            sStyle.fill.colour = cStyle[0].fill_.color_;
                            sStyle.source = 'mapping';
                            sStyle.object = annoType;
                            sStyle.geometry = e.selected[0].getGeometry();

                            isStyle = true;
                        break;
                        case 'polygon':
                            annoType = 'polygon';
                            //console.log("Current Style: "+JSON.stringify(cStyle));
                            selectStyle = [
                                new Style({
                                    stroke: new Stroke({
                                    color: cStyle[0].stroke_.color_,
                                    width: cStyle[0].stroke_.width_,
                                    lineDash: cStyle[0].stroke_.lineDash_,
                                    }),
                                    fill: new Fill({
                                    color: cStyle[0].fill_.color_,
                                    }),
                                }),
                                selectedPolygonVertexStyle,
                            ];
                            e.selected[0].setStyle(selectStyle);
                            
                            sStyle.outline.colour = cStyle[0].stroke_.color_;
                            sStyle.outline.width = cStyle[0].stroke_.width_;
                            sStyle.outline.dash = cStyle[0].stroke_.lineDash_;
                            sStyle.fill.colour = cStyle[0].fill_.color_;
                            sStyle.source = 'mapping';
                            sStyle.object = annoType;
                            sStyle.geometry = e.selected[0].getGeometry();
                            isStyle = true;
                        break;
                        case 'text':
                            annoType = 'text';
                            selectStyle = [
                                new Style({
                                    fill: new Fill({color: cStyle[0].fill_.color_}),
                                    stroke: new Stroke({color: cStyle[0].stroke_.color_, width: cStyle[0].stroke_.width_}),
                                    text: new Text({text:cStyle[0].text_.text_, font:cStyle[0].text_.font_})
                                }),
                                selectedPolygonVertexStyle,
                            ];
                            const currentAnnotation = sState.textAnnotation;
                            currentAnnotation.text = cStyle[0].text_.text_;
                            currentAnnotation.font = cStyle[0].text_.font_;
                            currentAnnotation.source = 'mapping';
                            //console.log("current annotation text: "+JSON.stringify(currentAnnotation));
                            //aDispatch({ type: "settextannotation", payload: { annotations: currentAnnotation } });
    
                            e.selected[0].setStyle(selectStyle);
                            isStyle = false;
                        break;

                    }
                    if (isStyle || isStyle === 'true'){
                        for (let i = 0; i < annotationArray.length; i++) {
                            if (annotationArray[i].id === e.selected[0].get('id')){
                                sStyle.style = annotationArray[i].originalStyle;
                                aDispatch({type: "setCurrentAnnotation", payload: { currentAnnotation: sStyle }});
                                switch (e.selected[0].get('shape')){
                                    case 'polygon':
                                        setAnnotationArea(getMeasureArea(e.selected[0].getGeometry().getArea() ,measurementAreaUnit, false));
                                    break;
                                    case 'circle':
                                        //Not working for circle.
                                        //setAnnotationArea(getMeasureArea(e.selected[0].getGeometry().getArea() ,measurementAreaUnit, false));
                                    break;
                                    case 'linestring':
                                        setAnnotationLength(getMeasureLength(e.selected[0].getGeometry().getLength(), measurementLineUnit, false))
                                    break;

                                }
                                break;
                            }
                        }
                    }

                }

                if (typeof e.deselected[0] !== 'undefined'){
                    const cStyle = e.deselected[0].getStyle();
                    switch (e.deselected[0].get('shape')){
                        case 'linestring':
                            selectStyle = [
                                new Style({
                                    stroke: new Stroke({color: cStyle[0].stroke_.color_, width: cStyle[0].stroke_.width_, lineDash: cStyle[0].stroke_.lineDash_}),
                                }),
                            ];
                        break;
                        case 'polygon':
                        case 'circle':
                            selectStyle = [
                                new Style({
                                    stroke: new Stroke({
                                    color: cStyle[0].stroke_.color_,
                                    width: cStyle[0].stroke_.width_,
                                    lineDash: cStyle[0].stroke_.lineDash_,
                                    }),
                                    fill: new Fill({
                                    color: cStyle[0].fill_.color_,
                                    }),
                                }),
                            ];
                        break;
                        case 'text':
                            selectStyle = [
                                new Style({
                                    fill: new Fill({color: cStyle[0].fill_.color_}),
                                    stroke: new Stroke({color: cStyle[0].stroke_.color_, width: cStyle[0].stroke_.width_}),
                                    text: new Text({text:cStyle[0].text_.text_, font:cStyle[0].text_.font_})
                                }),
                            ];
                        break;
                    }
                    e.deselected[0].setStyle(selectStyle);
                }
                
            });
              
            const translate = new Translate({
                features: select.getFeatures()
            });
    
            mapobj.addInteraction(translate);

            const modify = new Modify({
                features: select.getFeatures(),
            });
            setMapSelect(select);
            mapobj.addInteraction(select);

            //For handling rectangles
            var modifyingFeatures = [];
            var rectangleInteraction;
            modify.on('modifystart', function(e) {
                loadAnnotationUndoRedo();
                modifyingFeatures = e.features;
                var extent;
                const features = e.features.getArray();
                const editedgeom = features[0].getGeometry().getCoordinates();
                if (features[0].get('shape') ==='text'){

                    const cStyle = features[0].getStyle();
                    features[0].set('coordinates', editedgeom );
                    extent = features[0].getGeometry().getExtent();
                    document.addEventListener('pointermove', modifying);
    
                    const styles = [
                        new Style({
                            fill: new Fill({color: cStyle[0].fill_.color_}),
                            stroke: new Stroke({color: cStyle[0].stroke_.color_, width: cStyle[0].stroke_.width_}),
                            text: new Text({text:cStyle[0].text_.text_, font:cStyle[0].text_.font_})
                        }),
                    ];
                    rectangleInteraction = new Extent({
                        boxStyle: styles 
                    });
                    rectangleInteraction.setActive(false);

                    if (extent) {
                        rectangleInteraction.setExtent(extent); 
                    }
                    mapobj.addInteraction(rectangleInteraction);
                }
            });
    
            var modifying = function(c){

                const features = modifyingFeatures.getArray();
                var oldCoordinates = features[0].get('coordinates');

                if (!oldCoordinates) {
                    return;
                }
                var newCoordinates = features[0].getGeometry().getCoordinates();
                if (typeof newCoordinates !== 'undefined' || typeof oldCoordinates !== 'undefined'){
                    var newExtent = getRectangleExtent(oldCoordinates, newCoordinates)
                    rectangleInteraction.setExtent(newExtent);
                }
            };
            
            modify.on('modifyend', function(e) {

                const features = e.features.getArray();
                const editedgeom = features[0].getGeometry();

                if (features[0].get('shape') ==='text'){
                    document.removeEventListener('pointermove', modifying);
                    var polygon = new Feature(fromExtent(rectangleInteraction.getExtent()));
                    editedgeom.setCoordinates(polygon.getGeometry().getCoordinates());
                    editedgeom.unset('coordinates');
                    modifyingFeatures = [];
                    mapobj.removeInteraction(rectangleInteraction);
                    rectangleInteraction = null;
                }
                if (features[0].get('shape') === 'polygon'){
                    //setAnnotationArea(parseMeasureArea(editedgeom.getArea() ,measureUnit, false));
                }
                //storeAnnotationLayer();
            });
            
            //const modify = new Modify({source: dataWindowLayer.getSource()});
            mapobj.addInteraction(modify);
            setMapModify(modify);
    
            const snap = new Snap({features: select.getFeatures(), vertex: true});
            mapobj.addInteraction(snap);
            setMapSnap(snap);
        }
}

    /**
     * Listens for style change when an object has been selected.
     */
     useEffect(() => {
        //console.log("MapObject annotation style changed");
        if (typeof aState.currentStyle.outline !== 'undefined' && aState.currentStyle.source === 'toolbar'){
              //console.log("MapObject annotation style changed");
              if (mapSelect !== null){
                const selectedObject = mapSelect.getFeatures();
                
                if (selectedObject.getLength() > 0){

                    let lineColour = aState.currentStyle.outline.colour;
                    let lineWidth = aState.currentStyle.outline.width;
                    let fillColour = aState.currentStyle.fill.colour;
                    let lineDash = setLineStyle(aState.currentStyle.outline.linedash, lineWidth);
                    let selectStyle = [];
                    switch (selectedObject.item(0).get('shape')){
                        case 'linestring':
                            selectStyle = [
                                new Style({
                                    stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
                                }),
                                selectedLineVertexStyle,
                            ];
                            selectedObject.item(0).setStyle(selectStyle);

                        break;
                        case 'circle':
                            selectStyle = [
                                new Style({
                                    stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
                                    fill: new Fill({color: fillColour})
                                }),
                                selectedCircleVertexStyle,
                            ];
                            selectedObject.item(0).setStyle(selectStyle);
                            
                        break;
                        case 'polygon':
                            selectStyle = [
                                new Style({
                                    stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
                                    fill: new Fill({color: fillColour})
                                }),
                                selectedPolygonVertexStyle,
                            ];
                            selectedObject.item(0).setStyle(selectStyle);
                        break;
                        case 'text':
                            selectStyle = [
                                new Style({
                                    stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
                                    fill: new Fill({color: fillColour}),
                                    //text: new Text({text:cStyle[0].text_.text_, font:cStyle[0].text_.font_})
                                }),
                                selectedPolygonVertexStyle,
                            ];

                            selectedObject.item(0).setStyle(selectStyle);
                        break;

                    }
                    storeMapAnnotations(selectedObject.item(0), aState.currentStyle);
                }
            }

        }
    },[aState.currentStyle.outline.colour, aState.currentStyle.outline.width, aState.currentStyle.fill.colour, aState.currentStyle.outline.linedash]);

const clearSelectedAnnotations = () => {
    if (hasAnnotationLayer){
        //const features = annotationLayer.getSource().getFeatures();
        const features = annotationLayer.getSource().getFeatures();
        for (let i = 0; i < features.length; i++) {
            const cStyle = features[i].getStyle();
            let selectStyle = [];
            switch(features[i].get('shape')){
                case 'linestring':
                    selectStyle = [
                        new Style({
                            stroke: new Stroke({color: cStyle[0].stroke_.color_, width: cStyle[0].stroke_.width_, lineDash: cStyle[0].stroke_.lineDash_}),
                        }),
                    ];
                break;
                case 'polygon':
                case 'circle':
                    selectStyle = [
                        new Style({
                            fill: new Fill({color: cStyle[0].fill_.color_}),
                            stroke: new Stroke({color: cStyle[0].stroke_.color_, width: cStyle[0].stroke_.width_, lineDash: cStyle[0].stroke_.lineDash_}),
                        }),
                    ];
                break;
                case 'text':
                    selectStyle = [
                        new Style({
                            fill: new Fill({color: cStyle[0].fill_.color_}),
                            stroke: new Stroke({color: cStyle[0].stroke_.color_, width: cStyle[0].stroke_.width_}),
                            text: new Text({text:cStyle[0].text_.text_, font:cStyle[0].text_.font_})
                        }),
                    ];
                break;
                
            }
            features[i].setStyle(selectStyle);
        }
        mapobj.render();

    }
}

const annotationLayerExists = () => {
    let annoLayer = null;
    if (!hasAnnotationLayer){
        annoLayer = new VectorLayer({
            source: new VectorSource({wrapX: false}),
            style: null
        });
        setAnnotationLayer(annoLayer);
        setHasAnnotationLayer(true);
        mapobj.addLayer(annoLayer);
    } else {
        annoLayer = annotationLayer;
    }
    return annoLayer;
}

const drawAnnotationRectangle = () => {
    loadAnnotationUndoRedo();
    //console.log("drawAnnotationRectangle called.")
    setFreezeaction(true);
    
    let annoLayer = annotationLayerExists();

    //Define the item style.
    let lineColour = aState.currentStyle.outline.colour;
    let lineWidth = aState.currentStyle.outline.width;
    let fillColour = aState.currentStyle.fill.colour;
    let lineDash = setLineStyle(aState.currentStyle.outline.linedash, lineWidth);

    const styles = [new Style({
        stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
        fill: new Fill({color: fillColour})
    })];

    const draw = new Draw({
        source: annoLayer.getSource(),
        type: 'Circle',
        geometryFunction: createBox(),
    });

    draw.on('drawstart',function(e){

        let lineColour = aState.currentStyle.outline.colour;
        let lineWidth = aState.currentStyle.outline.width;
        let fillColour = aState.currentStyle.fill.colour;
        let lineDash = setLineStyle(aState.currentStyle.outline.linedash, lineWidth);
    
        const styles = [new Style({
            stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
            fill: new Fill({color: fillColour})
        })];

        e.feature.setStyle(styles);
    });

    draw.on('drawend', function(e) {
        let itemID = Math.random().toString(24).substring(2, 6) + Math.random().toString(24).substring(2, 6);
        e.feature.setProperties({
          'id':itemID,
          'shape': 'polygon'
        });
        storeMapAnnotations(e.feature, aState.currentStyle);
    });
    setMapDraw(draw);
    mapobj.addInteraction(draw);
    mapobj.render();

    annoLayer.getSource().on('addfeature', selectMapAnnoation);

}


const drawAnnotationPolygon = () => {

    //console.log("drawAnnotationPolygon called.")
    setFreezeaction(true);
    loadAnnotationUndoRedo();
    
    let annoLayer = annotationLayerExists();

    //Define the item style.
    let lineColour = aState.currentStyle.outline.colour;
    let lineWidth = aState.currentStyle.outline.width;
    let fillColour = aState.currentStyle.fill.colour;
    let lineDash = setLineStyle(aState.currentStyle.outline.linedash, lineWidth);

    const styles = [new Style({
        stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
        fill: new Fill({color: fillColour})
    })];

    const draw = new Draw({
        source: annoLayer.getSource(),
        type: 'Polygon',
    });

    draw.on('drawstart',function(e){
        //loadAnnotationUndoRedo();
        let lineColour = aState.currentStyle.outline.colour;
        let lineWidth = aState.currentStyle.outline.width;
        let fillColour = aState.currentStyle.fill.colour;
        let lineDash = setLineStyle(aState.currentStyle.outline.linedash, lineWidth);
    
        const styles = [new Style({
            stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
            fill: new Fill({color: fillColour})
        })];

        e.feature.setStyle(styles);
    });

    draw.on('drawend', function(e) {
        
        let itemID = Math.random().toString(24).substring(2, 6) + Math.random().toString(24).substring(2, 6);
        e.feature.setProperties({
          'id':itemID,
          'shape': 'polygon'
        });
        storeMapAnnotations(e.feature, aState.currentStyle);
        setAnnotationArea(getMeasureArea(e.feature.getGeometry().getArea(),measurementAreaUnit, false));
    });
    setMapDraw(draw);
    mapobj.addInteraction(draw);

    annoLayer.getSource().on('addfeature', selectMapAnnoation);
}

const drawAnnotationCircle = () => {

    //console.log("drawAnnotationCircle called.")
    setFreezeaction(true);
    loadAnnotationUndoRedo();
    
    let annoLayer = annotationLayerExists();

    //Define the item style.
    let lineColour = aState.currentStyle.outline.colour;
    let lineWidth = aState.currentStyle.outline.width;
    let fillColour = aState.currentStyle.fill.colour;
    let lineDash = setLineStyle(aState.currentStyle.outline.linedash, lineWidth);

    const styles = [new Style({
        stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
        fill: new Fill({color: fillColour})
    })];

    const draw = new Draw({
        source: annoLayer.getSource(),
        type: 'Circle',
    });

    draw.on('drawstart',function(e){
        //loadAnnotationUndoRedo();
        let lineColour = aState.currentStyle.outline.colour;
        let lineWidth = aState.currentStyle.outline.width;
        let fillColour = aState.currentStyle.fill.colour;
        let lineDash = setLineStyle(aState.currentStyle.outline.linedash, lineWidth);
    
        const styles = [new Style({
            stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
            fill: new Fill({color: fillColour})
        })];

        e.feature.setStyle(styles);
    });

    draw.on('drawend', function(e) {
        let itemID = Math.random().toString(24).substring(2, 6) + Math.random().toString(24).substring(2, 6);
        e.feature.setProperties({
          'id':itemID,
          'shape': 'circle'
        });

        storeMapAnnotations(e.feature, aState.currentStyle);
        //setAnnotationArea(getMeasureArea(e.feature.getGeometry().getArea(),measurementAreaUnit, false));
    });
    setMapDraw(draw);
    mapobj.addInteraction(draw);

    annoLayer.getSource().on('addfeature', selectMapAnnoation);
}


const drawAnnotationLine = () => {

    //console.log("drawAnnotationLine called. Set line dash to "+aState.currentStyle.outline.linedash);
    setFreezeaction(true);
    loadAnnotationUndoRedo();
    
    let annoLayer = annotationLayerExists();

    //Define the item style.
    let lineColour = aState.currentStyle.outline.colour;
    let lineWidth = aState.currentStyle.outline.width;
    let lineDash = setLineStyle(aState.currentStyle.outline.linedash);

    const styles = [new Style({
        stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
    })];

    const draw = new Draw({
        source: annoLayer.getSource(),
        type: 'LineString',
    });

    draw.on('drawstart',function(e){

        let lineColour = aState.currentStyle.outline.colour;
        let lineWidth = aState.currentStyle.outline.width;
        let lineDash = setLineStyle(aState.currentStyle.outline.linedash, lineWidth);

        const styles = [new Style({
            stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
        })];

        e.feature.setStyle(styles);
    });

    draw.on('drawend', function(e) {
        let itemID = Math.random().toString(24).substring(2, 6) + Math.random().toString(24).substring(2, 6);
        e.feature.setProperties({
          'id':itemID,
          'shape': 'linestring'
        });

        storeMapAnnotations(e.feature, aState.currentStyle);
    });
    setMapDraw(draw);
    mapobj.addInteraction(draw);

    annoLayer.getSource().on('addfeature', selectMapAnnoation);
}

    /**
     * Listerner for items to be drawn from fillPolygon click listerner
     */
     useEffect(() => {
        if (clickListerner !== null){
            setFreezeaction(true);
    
            let annoLayer = annotationLayerExists();
            
            //Define the item style.
            let lineColour = aState.currentStyle.outline.colour;
            let lineWidth = aState.currentStyle.outline.width;
            let lineDash = setLineStyle(aState.currentStyle.outline.linedash);
            let fillColour = aState.currentStyle.fill.colour;

            const styles = [new Style({
                stroke: new Stroke({color: lineColour, width: lineWidth, lineDash: lineDash}),
                fill: new Fill({color: fillColour})
            })];

            const wkt_geom = aState.dbAnnotation.geometry;
            setFillGeom(wkt_geom);
            if (doUnion && hasAnnotationLayer){
                //Removes last drawn feature
                var features = annoLayer.getSource().getFeatures();
                var lastFeature = features[features.length - 1];
                annoLayer.getSource().removeFeature(lastFeature);
            }

            const feature = new WKT().readFeature(aState.dbAnnotation.geometry);
            feature.setStyle(styles);
            
            let itemID = Math.random().toString(24).substring(2, 6) + Math.random().toString(24).substring(2, 6);
            feature.set('id', itemID);
            feature.set('shape', 'polygon');

            annoLayer.getSource().addFeature(feature);
            mapobj.render();
            storeMapAnnotations(feature, aState.currentStyle);
            setAnnotationArea(getMeasureArea(feature.getGeometry().getArea(),measurementAreaUnit, false));
            //Stores the geometry area for displaying
            //setAnnotationArea(feature.getGeometry().getArea());

        }
    },[aState.dbAnnotation.geometry]);


const selectMapAnnoation = (event) => {
    //clearDataInteractions();
    //setDataWindowLoaded(true);
    const geom = event.feature.getGeometry();
    //const select = new Select({ wrapX: false,});
    const select = new Select({
        layers:[annotationLayer],
        wrapX: false,
        style: null
    });
        
    const modify = new Modify({
        features: select.getFeatures(),
    });

    
    modify.on('modifystart', function(e) {
        loadAnnotationUndoRedo();
    });

    modify.on('modifyend', function(e) {
        const features = e.features.getArray();
        const editedgeom = features[0].getGeometry();
        storeCreatedArea(editedgeom);
    });
    
    const translate = new Translate({
        features: select.getFeatures()
    });

    select.getFeatures().push(event.feature);

    translate.on('translatestart', function(e) {
        loadAnnotationUndoRedo();
    });

    translate.on('translateend', function(e) {
        const features = e.features.getArray();
        const editedgeom = features[0].getGeometry();
        storeCreatedArea(editedgeom);
    });

    const p = new  DragPan();
    setMapTranslate(translate);
    setMapSelect(select);
    setMapModify(modify);

    mapobj.addInteraction(select);
    mapobj.addInteraction(p);
    mapobj.addInteraction(translate);
    mapobj.addInteraction(modify);

};

/**
 * Stores any map annotations into a local array storage.
 * Stores the results in local variable annotationArray.
 */
const storeMapAnnotations =(feature, oStyle)=>{
    const currentAnnotationArry = annotationArray;
    let newAnnotation = true;
    const itemStyle = feature.getStyle(); 
    const itemGeom = feature.getGeometry();

    for (let i = 0; i < currentAnnotationArry.length; i++) {
        if (currentAnnotationArry[i].id === feature.get('id')){

            currentAnnotationArry[i].type = feature.get('shape');
            currentAnnotationArry[i].mapScale = scale;
            currentAnnotationArry[i].fill = itemStyle[0].fill_;
            currentAnnotationArry[i].stroke = itemStyle[0].stroke_;
            currentAnnotationArry[i].text = itemStyle[0].text_;
            currentAnnotationArry[i].extent = itemGeom.extent_;
            currentAnnotationArry[i].originalStyle = {
                lineColour: oStyle.outline.colour,
                lineWidth: oStyle.outline.width,
                lineDash: oStyle.outline.linedash,
                fillColour: oStyle.fill.colour,
            };
            switch (currentAnnotationArry[i].type){
                case "circle":
                    currentAnnotationArry[i].radius = itemGeom.getRadius();
                    currentAnnotationArry[i].x = itemGeom.getCenter()[0];
                    currentAnnotationArry[i].y = itemGeom.getCenter()[1];
                break;
                case "polyline":
                case "linestring":
                    currentAnnotationArry[i].coordinates = itemGeom.flatCoordinates;
                    break;
                default:
                    currentAnnotationArry[i].coordinates = itemGeom.orientedFlatCoordinates_;
                break;
            }
            setAnnotationArray(currentAnnotationArry);
            //console.log("Existing item found!");
            newAnnotation = false;
        }
    }

    if (newAnnotation){ 

        let a = {};
        a.id = feature.get('id');
        a.type = feature.get('shape');
        a.mapScale = scale;
        a.fill = itemStyle[0].fill_;
        a.stroke = itemStyle[0].stroke_;
        a.text = itemStyle[0].text_;
        a.extent = itemGeom.extent_;
        a.originalStyle = {
            lineColour: oStyle.outline.colour,
            lineWidth: oStyle.outline.width,
            lineDash: oStyle.outline.linedash,
            fillColour: oStyle.fill.colour,
        };

        switch (a.type){
            case "circle":
                //const area = itemGeom.getArea();
                //a.radius = 0.565352 * Math.sqrt(area);
                a.radius = itemGeom.getRadius();
                a.x = itemGeom.getCenter()[0];
                a.y = itemGeom.getCenter()[1];
            break;
            case "polyline":
            case "linestring":
                //console.log("itemGeom polyline: "+JSON.stringify(itemGeom.flatCoordinates));
                a.coordinates = itemGeom.flatCoordinates;
                break;
            default:
                a.coordinates = itemGeom.orientedFlatCoordinates_;
            break;

        }
    
        currentAnnotationArry.push(a);
        setAnnotationArray(currentAnnotationArry);
    }
}

/**
 * Updates a map annotation.
 * Stores the results in local variable annotationArray.
 */
 const updateMapAnnotation =(feature)=>{
    const currentAnnotationArry = annotationArray;

    for (let i = 0; i < currentAnnotationArry.length; i++) {
        if (currentAnnotationArry[i].id === feature.get('id')){
            let itemStyle = feature.getStyle();
            let itemGeom = feature.getGeometry();
            currentAnnotationArry[i].mapScale = scale;
            currentAnnotationArry[i].fill = itemStyle[0].fill_;
            currentAnnotationArry[i].stroke = itemStyle[0].stroke_;
            currentAnnotationArry[i].text = itemStyle[0].text_;
            currentAnnotationArry[i].extent = itemGeom.extent_;
            currentAnnotationArry[i].coordinates = itemGeom.orientedFlatCoordinates_;
            setAnnotationArray(currentAnnotationArry);
            break;
        }
    }
}

/**
 * Deletes a map annotation.
 * Stores the results in local variable annotationArray.
 */
 const deleteMapAnnotation =()=>{

    if (mapSelect !== null){
        const selectedObject = mapSelect.getFeatures();
        
        if (selectedObject.getLength() > 0){
            const currentAnnotationArry = annotationArray;

            for (let i = 0; i < currentAnnotationArry.length; i++) {
                if (currentAnnotationArry[i].id === selectedObject.item(0).get('id')){
                    currentAnnotationArry.splice(i, 1);
                    setAnnotationArray(currentAnnotationArry);
                    annotationLayer.getSource().removeFeature(selectedObject.item(0));
                    break;
                }
            }
        }
    }
}

/**
 * Saves map annotations into session storage so that it can then be saved or printed.
 */
 const savesMapAnnotations =()=>{
    //console.log("savesMapAnnotations called. Annotations to save: "+annotationArray.length);

    const anno = {
        "count": parseInt(annotationArray.length),
        "annotations": annotationArray,
    }

    aDispatch({ type: "setmapannotation", payload: { annotations: anno } });

}

/**
 * Utilises ol-ext / undo redo.
 */
const undoAnnotation=()=>{
    console.log('undoRedo.undo() called');
    undoRedo.undo();
}
const redoAnnotation=()=>{
    console.log('undoRedo.redo() called');
    undoRedo.redo();
}

/**
 * Removes all map object interactions related to drawing annotations.
 */
const clearAnnotationInteractions=()=>{
    mapobj.getInteractions().forEach((interaction) => {

        if (interaction instanceof Select) {
            console.log("interaction Select found");
        }
        if (interaction instanceof UndoRedo) {
            console.log("interaction UndoRedo found");
        } else {
            mapobj.getInteractions().pop();
        }
        
    });

    if (doUnion) setDoUnion(false);
    if (fillToolActive) setFillToolActive(false);
}




const endAnnotationInteractions=()=>{

    //Reset the select to be either plot or data export window.
    if (hasPrintWindow){
        const features = printWindowLayer.getSource().getFeatures();
        const select = new Select({
            layers:[printWindowLayer]
        });
        
        const translate = new Translate({
            features: select.getFeatures()
        });
        select.getFeatures().push(features[0]);
        mapobj.addInteraction(select);
        mapobj.addInteraction(translate);
        setMapSelect(select);
        setMapTranslate(translate);
    } 
    if (hasDataWindow){

        const features = dataWindowLayer.getSource().getFeatures();
        const select = new Select({
            layers:[dataWindowLayer]
        });
        
        const translate = new Translate({
            features: select.getFeatures()
        });
        select.getFeatures().push(features[0]);
        mapobj.addInteraction(select);
        mapobj.addInteraction(translate);
        setMapSelect(select);
        setMapTranslate(translate);
    }
}


/**************  Map Annotation End **************/

    /**************  Map Object Measurement tools Start  **************/

    //const [measurementLayer, setMeasurementLayer] = useState(null);
    //const [hasMeasurementLayer, setHasMeasurementLayer] = useState(false);

    const drawMeasureLine = (measureUnit) => {
   
        mapobj.removeInteraction(mapTranslate);
        let measureLayer = null;
        if (!hasMeasurementLayer){
            measureLayer = new VectorLayer({
                source: new VectorSource({wrapX: false}),
                style: null
            });
            
            setMeasurementLayer(measureLayer);
            setHasMeasurementLayer(true);
            mapobj.addLayer(measureLayer);
        } else {
            measureLayer = measurementLayer;
        }

        const styles = [new Style({
            stroke: new Stroke({color: 'rgba(0, 0, 0, 0.5)', lineDash: [10, 10], width: 4}),
            fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),
            image: new Circle({adius: 5, stroke: new Stroke({color: 'rgba(0, 0, 0, 0.7)'}), fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),})
        })];

        const draw = new Draw({
            source: measureLayer.getSource(),
            type: 'LineString',
            style: styles
          });
        
        draw.on('drawstart',function(e){
            const sketch = e.feature;

            let listener = sketch.getGeometry().on('change', function(evt) {
              //setAnnotationLength(sketch.getGeometry().getLength())
              let lineMeasurement = getMeasureArea(e.feature.getGeometry().getArea(), measureUnit, true);
              if (parseFloat(lineMeasurement.value) > 0.00000001){
                const styleChange = [new Style({
                    stroke: new Stroke({color: 'rgba(0, 0, 0, 0.5)', lineDash: [10, 10], width: 4}),
                    fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),
                    image: new Circle({adius: 5, stroke: new Stroke({color: 'rgba(0, 0, 0, 0.7)'}), fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),}),
                    text: new Text({text: String(lineMeasurement.value), 
                    font: '20px sans-serif',
                    stroke: new Stroke({color: '#fff', width: 5}),})
                })];
                e.feature.setStyle(styleChange);
            }

            });

        }, this);

        draw.on('drawend', function(e) {
            e.feature.setProperties({
                'id': 'measureGeom',
                'shape': 'line'
            });
            setAnnotationLength(getMeasureLength(e.feature.getGeometry().getLength(), measureUnit, false))
        });

        setMapDraw(draw);
        mapobj.addInteraction(draw);
    }

    const drawMeasureArea = (measureUnit) => {
   
        mapobj.removeInteraction(mapTranslate);
        let measureLayer = null;
        if (!hasMeasurementLayer){
            measureLayer = new VectorLayer({
                source: new VectorSource({wrapX: false}),
                style: null
            });
            setMeasurementLayer(measureLayer);
            setHasMeasurementLayer(true);
            mapobj.addLayer(measureLayer);
        } else {
            measureLayer = measurementLayer;
        }

        const styles = [new Style({
            stroke: new Stroke({color: 'rgba(0, 0, 0, 0.5)', lineDash: [10, 10], width: 4}),
            fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),
            image: new Circle({radius: 5, stroke: new Stroke({color: 'rgba(0, 0, 0, 0.7)'}), fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),})
        })];

        const draw = new Draw({
            source: measureLayer.getSource(),
            type: 'Polygon',
            style: styles
          });
        
        draw.on('drawstart',function(e){

            //Delete any existing measure drawn first.
            measureLayer.getSource().clear();

            const sketch = e.feature;
            sketch.setStyle(styles);

            let listener = sketch.getGeometry().on('change', function(evt) {
                
                let areaMeasurement = getMeasureArea(e.feature.getGeometry().getArea(), measureUnit, true);
                if (parseFloat(areaMeasurement.value) > 0.00000001){
                    const styleChange = [new Style({
                        stroke: new Stroke({color: 'rgba(0, 0, 0, 0.5)', lineDash: [10, 10], width: 4}),
                        fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),
                        image: new Circle({radius: 5, stroke: new Stroke({color: 'rgba(0, 0, 0, 0.7)'}), fill: new Fill({color: 'rgba(255, 255, 255, 0.2)'}),}),
                        text: new Text({text: String(areaMeasurement.value), 
                        font: '20px sans-serif',
                        stroke: new Stroke({color: '#fff', width: 5}),})
                    })];
                    e.feature.setStyle(styleChange);
                }

            });
        }, this);

        draw.on('drawend', function(e) {
             e.feature.setProperties({
                'id': 'measureGeom',
                'shape': 'polygon'
              });
              setAnnotationArea(getMeasureArea(e.feature.getGeometry().getArea(),measureUnit, false));
        });

        mapobj.addInteraction(draw);

    }

    useEffect(() => {
        setMeasurementAreaUnit(aState.measurementUnits.area);
        //console.log("Measurement unit set to "+aState.measurementUnits.area);
        if (aState.caction === 'createMeasureArea'){
            clearAnnotationInteractions();
            measurementLayer.getSource().clear();
            drawMeasureArea(aState.measurementUnits.area);
            //console.log("drawMeasureArea reactivated");
        }

    },[aState.measurementUnits.area]);

    useEffect(() => {
        //console.log("annotationArea.raw changed to:"+annotationArea.raw);
        const m = {
            'raw':annotationArea.raw,
            'area':annotationArea.value,
            'length':'-',
        }
        aDispatch({ type: "setmeasurement", payload: { measurements: m } });

    },[annotationArea.raw]);


    useEffect(() => {
        setMeasurementLineUnit(aState.measurementUnits.length);
        if (aState.caction === 'createMeasureLine'){
            clearAnnotationInteractions();
            measurementLayer.getSource().clear();
            drawMeasureLine(aState.measurementUnits.length);
        }

    },[aState.measurementUnits.length]);

    useEffect(() => {

        const m = {
            'raw':annotationLength.raw,
            'area':'-',
            'length':annotationLength.value,
        }
        aDispatch({ type: "setmeasurement", payload: { measurements: m } });

    },[annotationLength.raw]);

    const clearMeasurementLayer = () =>{

        if (hasMeasurementLayer){
            measurementLayer.getSource().clear();
            setHasMeasurementLayer(false);
        }
        
    }

    
    /**************  Map Object Measurement tools End  **************/

    const mapExtentChanged = () => {
        //console.log("mapExtentChanged called:"+firstLoad);
        if (!firstLoad){
            const extent = mapobj.getView().calculateExtent()
            setMapextent(extent);
            setMapaction('Render');
        }
    };

    const clearListerns = () => {
        //mapSelect.getFeatures().clear();
        mapobj.un('singleclick', mapCentreClickEvent );
    };

    return (
        <div>
            <div id='map' className={classes.mapstyle} ></div>
            <div className={classes.copyrightLabel}>{process.env.REACT_APP_OS_SCREENCOPY.replace("dddd", new Date().getFullYear())}</div> 
        </div>
    )
}
