import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import getCompanyLists from "../../functions/getCompanies";
import createCompany from "../../functions/createCompany";
import getPricingModels from "../../functions/getPricingModels";
import getCompanyUsers from "../../functions/getCompanyUser";
import createUser from "../../functions/createUser";
import saveUserPassword from "../../functions/setUserPassword";

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

import {ReactComponent as DownloadIcon} from '../../assets/dark_icons/iconmonstr-download-19 1.svg';
import {ReactComponent as SearchIcon} from '../../assets/dark_icons/iconmonstr-magnifier-6 1.svg';


const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    },
    formLabel:{
        marginTop: 40,
        marginBottom: 20,
        color: '#447095',
    },
    formtable:{
        height: 600,
        width: '100%'
    },
    filterButton:{
        marginTop: 10,
        marginLeft: 20,
    },
    productcontainer:{
        margin: 20,
        padding: 20,
        border: '1px solid #D2D2D8',
    },
    userscontainer:{
        margin: 20,
        padding: 20,
        border: '1px solid #D2D2D8',
        height:300 
    },
    containerLabel:{
        marginBottom: 20,
    },
    textField:{
        minWidth: 200,
    },
    menuButton: {
      color: '#000',
        '&:hover': {
          color: '#F6A036',
          backgroundColor:'#FFF',
        }
    },
    actionButton: {
        width:160,
        marginBottom: 5,
    },
    userslist:{
        height:240,
        margin: 5,
        overflowY: 'auto',
        border: '1px solid #D2D2D8',
    },
    productItem:{
        color: '#000',
        backgroundColor: '#FFF',
        '&.MuiListItem-root.Mui-selected': {
          backgroundColor: '#C1DAE9',
        },
        border: '1px solid #EDEDEF',
    },
    listItemText:{
      fontSize:12,//Insert your required size
    },

  });


export default function ProjectListInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [companyRows, setCompanyRows] = useState([]);
    const [userRows, setUserRows] = useState([]);
    const [displayList, setDisplayList] = useState(true); //default. true

    const [selection, setSelection] = useState([]);
    const [user, setUser] = useState({});
    
    const [hasError, setHasError] = useState(false);
    const [displayid, setDisplayid] = useState(0);
    const [displayuid, setDisplayuid] = useState(0);

    const [company, setCompany] = useState({});
    const [titleLabel, setTitleLabel] = useState('');

    const [companyid, setCompanyid] = useState('');
    const [businessid, setBusinessid] = useState('');
    const [companyname, setCompanyname] = useState('');
    const [creditlimit, setCreditlimit] = useState(500);
    const [balance, setBalance] = useState(0);

    const [businesstype, setBusinesstype] = useState('');
    const [parentbusiness, setParentbusiness] = useState('');
    const [dtmparent, setDtmparent] = useState('');
    const [pricingmodel, setPricingmodel] = useState('');
    const [models, setModels] = useState([]);
    
    const [status, setStatus] = useState('active');
    const [statusLabel, setStatusLabel] = useState('disabled');
    const [hasvat, setHasvat] = useState('printdata');
    const [defaultviewlayer, setDefaultviewlayer] = useState('os10k');

    const [canPrint, setCanPrint] = useState(true);
    const [printHasgrid, setPrintHasgrid] = useState(true);
    const [printDefaultLogo, setPrintDefaultLogo] = useState('');
    const [printDefaultLayer, setPrintDefaultLayer] = useState('os10k');
    const [printDefaultScale, setPrintDefaultScale] = useState('10000');
    const [printDefaultOrientation, setPrintDefaultOrientation] = useState('portrait');
    const [printDefaultSize, setPrintDefaultSize] = useState('a4');
    const [printDefaultCopies, setPrintDefaultCopies] = useState('1');

    const [print_osmmbw_a4, setPrint_osmmbw_a4] = useState(true);
    const [print_osmmbw_a3, setPrint_osmmbw_a3] = useState(true);
    const [print_osmmbw_a2, setPrint_osmmbw_a2] = useState(true);
    const [print_osmmbw_a1, setPrint_osmmbw_a1] = useState(true);
    const [print_osmmbw_a0, setPrint_osmmbw_a0] = useState(true);

    const [print_osmm_a4, setPrint_osmm_a4] = useState(true);
    const [print_osmm_a3, setPrint_osmm_a3] = useState(true);
    const [print_osmm_a2, setPrint_osmm_a2] = useState(true);
    const [print_osmm_a1, setPrint_osmm_a1] = useState(true);
    const [print_osmm_a0, setPrint_osmm_a0] = useState(true);

    const [print_osvml_a4, setPrint_osvml_a4] = useState(true);
    const [print_osvml_a3, setPrint_osvml_a3] = useState(true);
    const [print_osvml_a2, setPrint_osvml_a2] = useState(true);
    const [print_osvml_a1, setPrint_osvml_a1] = useState(true);
    const [print_osvml_a0, setPrint_osvml_a0] = useState(true);

    const [print_os25_a4, setPrint_os25_a4] = useState(true);
    const [print_os25_a3, setPrint_os25_a3] = useState(true);
    const [print_os25_a2, setPrint_os25_a2] = useState(false);
    const [print_os25_a1, setPrint_os25_a1] = useState(false);
    const [print_os25_a0, setPrint_os25_a0] = useState(false);

    const [print_os50_a4, setPrint_os50_a4] = useState(true);
    const [print_os50_a3, setPrint_os50_a3] = useState(true);
    const [print_os50_a2, setPrint_os50_a2] = useState(false);
    const [print_os50_a1, setPrint_os50_a1] = useState(false);
    const [print_os50_a0, setPrint_os50_a0] = useState(false);

    const [print_os250_a4, setPrint_os250_a4] = useState(true);
    const [print_os250_a3, setPrint_os250_a3] = useState(true);
    const [print_os250_a2, setPrint_os250_a2] = useState(false);
    const [print_os250_a1, setPrint_os250_a1] = useState(false);
    const [print_os250_a0, setPrint_os250_a0] = useState(false);

    const [print_aerial_a4, setPrint_aerial_a4] = useState(true);
    const [print_aerial_a3, setPrint_aerial_a3] = useState(true);
    const [print_aerial_a2, setPrint_aerial_a2] = useState(false);
    const [print_aerial_a1, setPrint_aerial_a1] = useState(false);
    const [print_aerial_a0, setPrint_aerial_a0] = useState(false);

    const [canExport, setCanExport] = useState(true);
    const [hasFixedPrice, setHasFixedPrice] = useState(true);
    const [dataDefaultLayer, setDataDefaultLayer] = useState('osmm9');
    const [dataDefaultFormat, setDataDefaultFormat] = useState('dwg');
    const [dataDefaultSize, setDataDefaultSize] = useState('4ha');

    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [userType, setUserType] = useState('');
    const [userPassword, setUserPassword] = useState('');

    const [pollTimer, setPollTimer] = useState(null);
    const [filterStr, setFilterStr] = useState('');
    

    /**
     * First load
     */
    useEffect(() => {

        refreshCompanyList();
        const request = {
            userid: sState.userid,
            ident: sState.ident,
        }
        getPricingModels(request, aDispatch);
    },[])

    /**
     * Listerns for change to pricing models.
     * Populates the pricing model drop down.
     */
    useEffect(() => {
        if (aState.pricingmodels.length > 0){
            const mod = [];
            aState.pricingmodels.forEach(function (item) {
                let m ={};
                m.value = item.modelid;
                m.label = item.name;
                mod.push(m)
            });
            setModels(mod);
        }
    },[aState.pricingmodels])

    const refreshCompanyList = () => {
        const request = {
            userid: sState.userid,
            ident: sState.ident,
        }
        getCompanyLists(request, aDispatch);
    }

    /**
     * Listerns for change to companies list.
     */
    useEffect(() => {
        //console.log("models:"+JSON.stringify(aState.companies));
        if (aState.companies.length > 0){
            setCompanyRows(aState.companies);
        }
    },[aState.companies])

    useEffect(() => {
        if (filterStr.length > 1){

            var filterArray = JSON.parse(JSON.stringify(aState.companies));
    
            filterArray = filterArray.filter(function (c) {
                return c.companyname.charAt(0).toLowerCase() == filterStr.charAt(0).toLowerCase();
            });

            if (filterStr.length > 1)
                filterArray = filterArray.filter(function (c) {
                    return c.companyname.charAt(1).toLowerCase() == filterStr.charAt(1).toLowerCase();
            });

            if (filterStr.length > 2)
            filterArray = filterArray.filter(function (c) {
                return c.companyname.charAt(2).toLowerCase() == filterStr.charAt(2).toLowerCase();
            });

            if (filterStr.length > 3)
            filterArray = filterArray.filter(function (c) {
                return c.companyname.charAt(3).toLowerCase() == filterStr.charAt(3).toLowerCase();
            });
            setCompanyRows(filterArray);
        } 

    },[filterStr])

    const handleClearFilter = () => {
        setCompanyRows(aState.companies);
    };

    const columns = [
        { field: 'companyid', hide: true},
        { field: 'businessid', headerName: 'businessid', width: 150 },
        { field: 'companyname',
        headerName: 'Name',
        sortable: true,
        width: 300,
        disableClickEventBubbling: true,
        renderCell: (params) => (
            getCompanyDetails(params)
        ),},
        { field: 'creditlimit', headerName: 'credit limit', width: 150 },
        { field: 'business_type', headerName: 'business type', width: 150 },
        { field: 'parent_business', headerName: 'parent_business', width: 150 },
        { field: 'dtm_parent', headerName: 'dtm_parent', width: 150 },
        { field: 'pricing_model', headerName: 'pricing model', width: 200 },
        { field: 'status', headerName: 'status', width: 150 },
    ];

    /**
     * Displays the download options for user orders and allows to download.
    */
   function getCompanyDetails(params) {

    const handleViewRequest = () => {
        const api = params.api;
        const fields = api
        .getAllColumns()
        .map((c) => c.field)
        .filter((c) => c !== "__check__" && !!c);
        const thisRow = {};

        fields.forEach((f) => {
        thisRow[f] = params.getValue(f);
        });
        setDisplayid(thisRow.companyid);
    };

    const sname = params.getValue('companyname');
    return <Button className={classes.menuButton} onClick={handleViewRequest}>
        <Box fontSize={12}>{sname}</Box>
    </Button>;
    }

    /**
     *  Listens for display pricing model details request.
     */
    useEffect(() => {
        if (displayid === -1){
            //Resets the form
            setDisplayList(true);
        } else {
            //Loops through to get details.
            
            companyRows.forEach(function (p) {

                if (p.companyid === displayid){
                    setDisplayList(false);
                    setCompany(p);
                    loadCompanyDetails(p);

                    //Get company users.
                    const request = {
                        userid: sState.userid,
                        ident: sState.ident,
                        companyid: p.companyid,
                    }
                    getCompanyUsers(request, aDispatch)
                    return;
                }
            });
            
        }
    },[displayid])

    /**
     * Listerns for change to company users list.
     */
    useEffect(() => {
        //console.log("models:"+JSON.stringify(aState.companies));
        if (aState.companyUsers.length > 0){
            setUserRows(aState.companyUsers);
        }
    },[aState.companyUsers])


    /**
     *  Listens for display of user request.
     */
    useEffect(() => {
        if (displayuid === -1){
            //Resets the form
            setUsername('');
            setFirstname('');
            setLastname('');
            setEmail('');
            setTelephone('');
            setUserStatus('');
            setUserPassword('');
        } else {
            //Loops through to get details.
            
            userRows.forEach(function (p) {

                if (p.userid === displayuid){
                    setUser(p);
                    setUsername(p.username);
                    setFirstname(p.firstname);
                    setLastname(p.lastname);
                    setEmail(p.email);
                    setTelephone(p.telephone);
                    setUserType(p.utype);
                    setUserStatus(p.status);
                    setUserPassword('');
                    return;
                }
            });
            
        }
    },[displayuid])


    const loadCompanyDetails = (co) => {
        
        console.log("Company settings: "+JSON.stringify(co));

        setCompanyid(co.companyid);
        setBusinessid(co.businessid);
        setCompanyname(co.companyname);
        setCreditlimit(co.creditlimit);
        setBusinesstype(co.business_type);
        setParentbusiness(co.parent_business);
       
        setDtmparent(co.dtm_parent);
        setPricingmodel(co.pricing_id);
        setHasvat(co.vaton);
        setStatus(co.status);
        switch(co.status){
            default:
            case "active":
                setStatusLabel('Disable');
                setTitleLabel(' : '+co.companyname);
            break;
            case "disabled":
                setStatusLabel('Enable');
                setTitleLabel(' : '+co.companyname+" (Disabled)");

            break;
        }
        setDefaultviewlayer(co.defaultsettings.defaultlayer);

        setCanPrint(co.defaultsettings.canprint);
        setPrintHasgrid(co.defaultsettings.hasgrid);
        setPrintDefaultLogo(co.defaultsettings.dprintlogo);
        setPrintDefaultLayer(co.defaultsettings.dprintproduct+","+co.defaultsettings.dprintscale);

        setPrintDefaultOrientation(co.defaultsettings.dprintorientation);
        setPrintDefaultSize(co.defaultsettings.dprintsize);
        setPrintDefaultCopies(co.defaultsettings.dprintcopies);

        co.defaultsettings.dprintoptions.forEach(function (p) {
            switch(p.product){
                case "osmmbw":
                    setPrint_osmmbw_a4(p.a4);
                    setPrint_osmmbw_a3(p.a3);
                    setPrint_osmmbw_a2(p.a2);
                    setPrint_osmmbw_a1(p.a1);
                    setPrint_osmmbw_a0(p.a0);
                break;
                case "osmm":
                    setPrint_osmm_a4(p.a4);
                    setPrint_osmm_a3(p.a3);
                    setPrint_osmm_a2(p.a2);
                    setPrint_osmm_a1(p.a1);
                    setPrint_osmm_a0(p.a0);
                break;
                case "os10k":
                    setPrint_osvml_a4(p.a4);
                    setPrint_osvml_a3(p.a3);
                    setPrint_osvml_a2(p.a2);
                    setPrint_osvml_a1(p.a1);
                    setPrint_osvml_a0(p.a0);
                break;
                case "os25k":
                    setPrint_os25_a4(p.a4);
                    setPrint_os25_a3(p.a3);
                    setPrint_os25_a2(p.a2);
                    setPrint_os25_a1(p.a1);
                    setPrint_os25_a0(p.a0);
                break;
                case "os50k":
                    setPrint_os50_a4(p.a4);
                    setPrint_os50_a3(p.a3);
                    setPrint_os50_a2(p.a2);
                    setPrint_os50_a1(p.a1);
                    setPrint_os50_a0(p.a0);
                break;
                case "os250k":
                    setPrint_os250_a4(p.a4);
                    setPrint_os250_a3(p.a3);
                    setPrint_os250_a2(p.a2);
                    setPrint_os250_a1(p.a1);
                    setPrint_os250_a0(p.a0);
                break;
                case "aerial":
                    setPrint_aerial_a4(p.a4);
                    setPrint_aerial_a3(p.a3);
                    setPrint_aerial_a2(p.a2);
                    setPrint_aerial_a1(p.a1);
                    setPrint_aerial_a0(p.a0);
                break;
            }
        });

        setCanExport(co.defaultsettings.canexport);
        setHasFixedPrice(co.defaultsettings.ddatafixedprice);
        setDataDefaultLayer(co.defaultsettings.ddataproduct);
        if (typeof co.defaultsettings.ddataformat !== 'undefined' && co.defaultsettings.ddataformat !== '') setDataDefaultFormat(co.defaultsettings.ddataformat);
        setDataDefaultSize(co.defaultsettings.ddatascale);

        //Get client balance.

        pollCurrentBalance(co);
        
    };


    const pollCurrentBalance = (co) => {
        pollBalanceRequest(co);
        let timer = window.setInterval(function(){
            pollBalanceRequest(co);
        }, 60000);
        setPollTimer(timer);      
    };

    const pollBalanceRequest = (co) => {
        //console.log("Poll orderid:"+orderID);
        const abortController = new AbortController();
        let mounted = true;
        (async () => {  
            const res = await fetch(process.env.REACT_APP_NMC_API+'/admin/company/balance?accountid='+co.companyid, {
              method: 'GET',
              headers: {'Content-Type': 'application/json'},
              signal: abortController.signal,
            }).catch();
          const data = await res.json();
          if (mounted) {
            const b = parseFloat(data.balance).toFixed(2);
            setBalance(b);
          }
          const cleanup = () => {
            mounted = false;
            abortController.abort();
         };
         return cleanup;
        })();
    }; 

    const processClientDetails = () => {
        
        const co = {};
        co.companyid = companyid;
        co.businessid = businessid;
        co.companyname = companyname;
        co.creditlimit = creditlimit;
        co.business_type = businesstype;
        co.parent_business = parentbusiness;
        co.dtm_parent = dtmparent;
        co.pricing_id = pricingmodel;

        if (hasvat === 'printdata') { co.hasvat = true; } else { co.hasvat = false; }
        co.vaton = hasvat;
        co.status = status;
        const defaultsettings = {}
        defaultsettings.defaultlayer = defaultviewlayer;
        defaultsettings.canprint = canPrint;
        defaultsettings.hasgrid = printHasgrid;
        const printdlayer = printDefaultLayer.split(',');
        defaultsettings.dprintproduct = printdlayer[0];
        defaultsettings.dprintscale = printdlayer[1];
        defaultsettings.dprintlogo = printDefaultLogo;
        defaultsettings.dprintorientation = printDefaultOrientation;
        defaultsettings.dprintsize = printDefaultSize;
        defaultsettings.dprintcopies = printDefaultCopies;

        const po = [];
        const p_osmmbw = {};
        p_osmmbw.product = "osmmbw";
        p_osmmbw.a4 = print_osmmbw_a4;
        p_osmmbw.a3 = print_osmmbw_a3;
        p_osmmbw.a2 = print_osmmbw_a2;
        p_osmmbw.a1 = print_osmmbw_a1;
        p_osmmbw.a0 = print_osmmbw_a0;
        po.push(p_osmmbw);

        const p_osmm = {};
        p_osmm.product = "osmm";
        p_osmm.a4 = print_osmm_a4;
        p_osmm.a3 = print_osmm_a3;
        p_osmm.a2 = print_osmm_a2;
        p_osmm.a1 = print_osmm_a1;
        p_osmm.a0 = print_osmm_a0;
        po.push(p_osmm);

        const p_os10k = {};
        p_os10k.product = "os10k";
        p_os10k.a4 = print_osvml_a4;
        p_os10k.a3 = print_osvml_a3;
        p_os10k.a2 = print_osvml_a2;
        p_os10k.a1 = print_osvml_a1;
        p_os10k.a0 = print_osvml_a0;
        po.push(p_os10k);

        const p_os25k = {};
        p_os25k.product = "os25k";
        p_os25k.a4 = print_os25_a4;
        p_os25k.a3 = print_os25_a3;
        p_os25k.a2 = print_os25_a2;
        p_os25k.a1 = print_os25_a1;
        p_os25k.a0 = print_os25_a0;
        po.push(p_os25k);

        const p_os50k = {};
        p_os50k.product = "os50k";
        p_os50k.a4 = print_os50_a4;
        p_os50k.a3 = print_os50_a3;
        p_os50k.a2 = print_os50_a2;
        p_os50k.a1 = print_os50_a1;
        p_os50k.a0 = print_os50_a0;
        po.push(p_os50k);

        const p_os250k = {};
        p_os250k.product = "os250k";
        p_os250k.a4 = print_os250_a4;
        p_os250k.a3 = print_os250_a3;
        p_os250k.a2 = print_os250_a2;
        p_os250k.a1 = print_os250_a1;
        p_os250k.a0 = print_os250_a0;
        po.push(p_os250k);

        const p_aerial = {};
        p_aerial.product = "aerial";
        p_aerial.a4 = print_aerial_a4;
        p_aerial.a3 = print_aerial_a3;
        p_aerial.a2 = print_aerial_a2;
        p_aerial.a1 = print_aerial_a1;
        p_aerial.a0 = print_aerial_a0;
        po.push(p_aerial);
        defaultsettings.dprintoptions = po;

        defaultsettings.canexport = canExport;
        defaultsettings.ddatafixedprice = hasFixedPrice;
        defaultsettings.ddataproduct = dataDefaultLayer;
        defaultsettings.ddataformat = dataDefaultFormat;
        defaultsettings.ddatascale = dataDefaultSize;

        co.defaultsettings = defaultsettings;
        return co;
    };


    const handleCancel = () => {
        setTitleLabel("");
        setDisplayid(-1);
    };


    

    const handleSave = () => {
        const client = processClientDetails();
        createCompany(sState, client, aDispatch);
        setTitleLabel("");
        setDisplayid(-1);
    };

    const handleSaveUser = () => {

        const u = user;
        u.companyid = companyid;
        u.username = username;
        u.firstname = firstname;
        u.lastname = lastname;
        u.email = email;
        u.telephone = telephone;
        u.status = userStatus;
        u.utype = userType;

        createUser(sState, u, aDispatch);
    };

    /**
     * Sets a new user.
     */
    const handleUpdatePassword = () => {
        const u = {};
        u.userid = displayuid;
        u.password = userPassword;
        saveUserPassword(sState, u)
        setUserPassword('');
    };

    /**
     * Creates a new user.
     */
    const handleSaveNewUser = () => {

        const u = {};
        u.companyid = companyid;
        u.userid = -1;
        u.username = username;
        u.firstname = firstname;
        u.lastname = lastname;
        u.email = email;
        u.telephone = telephone;
        u.status = userStatus;
        u.utype = userType;
        u.password = userPassword;

        createUser(sState, u, aDispatch);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.formLabel} fontWeight="500" fontStyle="italic" fontSize={22} >Company details {titleLabel}</Box>
                </Grid>
                <Grid item xs={12}>
                    { displayList ? 
                    <div className={classes.formtable}>
                      <Box>
                      <TextField className={classes.textField} name="filter" label="Filter Clients List" size="small" margin="dense" variant="outlined" value={filterStr} onChange={e => setFilterStr(e.target.value)}/>
                        <Button className={classes.filterButton} variant="contained" onClick={handleClearFilter} >
                            <Box fontWeight="fontWeightRegular" fontSize={12} >Clear filter</Box>
                        </Button>
                      </Box>
                      <DataGrid rows={companyRows} columns={columns} pageSize={10} onSelectionChange={(newSelection) => {setSelection(newSelection.rows);}}/>
                    </div>
                    : <div className={classes.formdetails}>
                        <Grid container>
                            <Grid item xs={3} md={3}>
                                <TextField className={classes.textField} error={hasError} name="companyname" label="Name" size="small" margin="dense" variant="outlined" value={companyname} onChange={e => setCompanyname(e.target.value)} required />
                                <TextField className={classes.textField} name="balance" label="Current balance" InputProps={{readOnly: true,}} size="small" margin="dense" variant="outlined" value={balance} />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <TextField className={classes.textField} error={hasError} name="businessid" label="Business id" size="small" type="number" margin="dense" variant="outlined" value={businessid} onChange={e => setBusinessid(e.target.value)} required />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <TextField className={classes.textField} error={hasError} name="creditlimit" label="Credit Limit" size="small" type="number" margin="dense" variant="outlined" value={creditlimit} onChange={e => setCreditlimit(e.target.value)} required />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <Button className={classes.actionButton} variant="contained" onClick={handleSave} >
                                    <Box fontWeight="fontWeightRegular" fontSize={12} >Save</Box>
                                </Button>
                                <Button className={classes.actionButton} variant="contained" onClick={handleCancel} >
                                    <Box fontWeight="fontWeightRegular" fontSize={12} >Cancel / Go back</Box>
                                </Button>
                                <TextField className={classes.textField} value={status} onChange={e => setStatus(e.target.value)} name="Status" label="Status" size="small" margin="dense" variant="outlined" select>
                                    <MenuItem value={'active'}>Active</MenuItem>
                                    <MenuItem value={'disabled'}>Disabled</MenuItem>
                                </TextField>
                            </Grid>
                 
                            <Grid item xs={12}>
                            <div className={classes.userscontainer}>
                                <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >Users</Box>
                                <Grid container>
                                    <Grid item xs={3} md={2}>
                                    <div className={classes.userslist}>
                                        <List component="nav" aria-label="userList">
                                            {userRows.map((item, i) => (
                                                <React.Fragment key={i}>
                                                <ListItem 
                                                    key={i}
                                                    button
                                                    className={classes.productItem}
                                                    selected={displayuid === item.userid}
                                                    onClick={() => setDisplayuid(item.userid)}
                                                    >
                                                    <ListItemText primary={item.username} classes={{primary:classes.listItemText}}/>
                                                </ListItem>
                                                <Divider />
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    </div>
                                    </Grid>
                                    <Grid item xs={3} md={4}>
                                        <TextField className={classes.textField} value={username} onChange={e => setUsername(e.target.value)} name="username" label="Username" size="small" margin="dense" variant="outlined"/>
                                        <TextField className={classes.textField} value={firstname} onChange={e => setFirstname(e.target.value)} name="firstname" label="Firstname" size="small" margin="dense" variant="outlined"/>
                                        <TextField className={classes.textField} value={lastname} onChange={e => setLastname(e.target.value)} name="lastname" label="Lastname" size="small" margin="dense" variant="outlined"/>
                                        <TextField className={classes.textField} value={email} onChange={e => setEmail(e.target.value)} name="email" label="Email" size="small" margin="dense" variant="outlined"/>

                                    </Grid>
                                    <Grid item xs={3} md={4}>
                                        <TextField className={classes.textField} value={userStatus} onChange={e => setUserStatus(e.target.value)} name="userStatus" label="Status" size="small" margin="dense" variant="outlined" select>
                                            <MenuItem value={'active'}>Active</MenuItem>
                                            <MenuItem value={'disabled'}>Disabled</MenuItem>
                                        </TextField>
                                        <TextField className={classes.textField} value={telephone} onChange={e => setTelephone(e.target.value)} name="telephone" label="Telephone" size="small" margin="dense" variant="outlined"/>
                                        <TextField className={classes.textField} value={userType} onChange={e => setUserType(e.target.value)} name="userType" label="Usertype" size="small" margin="dense" variant="outlined" select>
                                            <MenuItem value={'super'}>Super</MenuItem>
                                            <MenuItem value={'admin'}>Admin</MenuItem>
                                            <MenuItem value={'manager'}>Manager</MenuItem>
                                            <MenuItem value={'user'}>User</MenuItem>
                                        </TextField>
                                        <TextField className={classes.textField} value={userPassword} onChange={e => setUserPassword(e.target.value)} name="userPassword" label="Password" size="small" margin="dense" variant="outlined"/>
                                    </Grid>
                                    <Grid item xs={3} md={2}>
                                        <Button className={classes.actionButton} variant="contained" onClick={handleSaveUser} >
                                            <Box fontWeight="fontWeightRegular" fontSize={12} >Save</Box>
                                        </Button>
                                        <Button className={classes.actionButton} variant="contained" onClick={handleSaveNewUser} >
                                            <Box fontWeight="fontWeightRegular" fontSize={12} >New User</Box>
                                        </Button>
                                        <Button className={classes.actionButton} variant="contained" onClick={handleUpdatePassword} >
                                            <Box fontWeight="fontWeightRegular" fontSize={12} >Update password</Box>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >Client Details</Box>
                                    <Grid container>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={businesstype} onChange={e => setBusinesstype(e.target.value)} name="Businesstype" label="Business Type" error={hasError} size="small" margin="dense" variant="outlined"/>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={parentbusiness} onChange={e => setParentbusiness(e.target.value)} name="price" label="Parent Business" error={hasError} size="small" margin="dense" variant="outlined"/>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={dtmparent} onChange={e => setDtmparent(e.target.value)} name="Dtmparent" label="DTM Parent" error={hasError} size="small" margin="dense" variant="outlined"/>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={pricingmodel} onChange={e => setPricingmodel(e.target.value)} name="Pricingmodel" label="Pricing Model" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                {models.map((item, i) =>
                                                    <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                                                )}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={status} onChange={e => setStatus(e.target.value)} name="Status" label="Status" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                    <MenuItem value={'active'}>Active</MenuItem>
                                                    <MenuItem value={'disabled'}>Disabled</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={hasvat} onChange={e => setHasvat(e.target.value)} name="VAT" label="VAT chargeable on" error={hasError} size="small" margin="dense" variant="outlined" select>                                              
                                                    <MenuItem value={'printdata'}>Prints and Data</MenuItem>
                                                    <MenuItem value={'data'}>Data only</MenuItem>
                                                    <MenuItem value={'print'}>Print only</MenuItem>
                                                    <MenuItem value={'none'}>None</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <TextField className={classes.textField} value={defaultviewlayer} onChange={e => setDefaultviewlayer(e.target.value)} name="Defaultviewlayer" label="Default view layer" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                    <MenuItem value={'osmmbw'}>OS Plan Black / White</MenuItem>
                                                    <MenuItem value={'os10k'}>1:10,000</MenuItem>
                                                </TextField>
                                            </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >Client Print Settings</Box>
                                    <Grid container>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12}>Can Print</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Grid</Box>
                                        </Grid>
                                        <Grid item xs={6} md={8}></Grid>
                                        <Grid item xs={3} md={2}>
                                            <Checkbox checked={canPrint} onChange={e => setCanPrint(!canPrint)}/>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Checkbox checked={printHasgrid} onChange={e => setPrintHasgrid(!printHasgrid)}/>
                                        </Grid>
                                        <Grid item xs={4} md={3}>
                                            <TextField className={classes.textField} value={printDefaultLogo} onChange={e => setPrintDefaultLogo(e.target.value)} name="DefaultLogo" label="Default Logo" error={hasError} size="small" margin="dense" variant="outlined" />
                                        </Grid>
                                        <Grid item xs={2} md={5}></Grid>

                                        <Grid item xs={4} md={3}>
                                            <TextField className={classes.textField} value={printDefaultLayer} onChange={e => setPrintDefaultLayer(e.target.value)} name="DefaultLayer" label="Default Layer" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                    <MenuItem value={'osmmbw,1250'}>OS Mastermap BW @ 1250</MenuItem>
                                                    <MenuItem value={'osmm,1250'}>OS Mastermap Colour @ 1250</MenuItem>
                                                    <MenuItem value={'os10k,10000'}>OS VML @ 10,000</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={printDefaultOrientation} onChange={e => setPrintDefaultOrientation(e.target.value)} name="DefaultOrientation" label="Orientation" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                <MenuItem value={'landscape'}>Landscape</MenuItem>
                                                <MenuItem value={'portrait'}>Portrait</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <TextField className={classes.textField} value={printDefaultSize} onChange={e => setPrintDefaultSize(e.target.value)} name="DefaultSize" label="Paper Size" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                <MenuItem value={'a4'}>A4</MenuItem>
                                                <MenuItem value={'a3'}>A3</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <TextField className={classes.textField} value={printDefaultCopies} onChange={e => setPrintDefaultCopies(e.target.value)} name="DefaultCopies" label="Copies" type="number" error={hasError} size="small" margin="dense" variant="outlined"/>
                                        </Grid>
                                        <Grid item xs={1} md={3}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>Print layer</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a4</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a3</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a2</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a1</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Box fontSize={12} >a0</Box>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OSMM BW</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a4} onChange={e => setPrint_osmmbw_a4(!print_osmmbw_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a3} onChange={e => setPrint_osmmbw_a3(!print_osmmbw_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a2} onChange={e => setPrint_osmmbw_a2(!print_osmmbw_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a1} onChange={e => setPrint_osmmbw_a1(!print_osmmbw_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmmbw_a0} onChange={e => setPrint_osmmbw_a0(!print_osmmbw_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OSMM</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a4} onChange={e => setPrint_osmm_a4(!print_osmm_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a3} onChange={e => setPrint_osmm_a3(!print_osmm_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a2} onChange={e => setPrint_osmm_a2(!print_osmm_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a1} onChange={e => setPrint_osmm_a1(!print_osmm_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osmm_a0} onChange={e => setPrint_osmm_a0(!print_osmm_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OS VML</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a4} onChange={e => setPrint_osvml_a4(!print_osvml_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a3} onChange={e => setPrint_osvml_a3(!print_osvml_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a2} onChange={e => setPrint_osvml_a2(!print_osvml_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a1} onChange={e => setPrint_osvml_a1(!print_osvml_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_osvml_a0} onChange={e => setPrint_osvml_a0(!print_osvml_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OS 1:25,000</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a4} onChange={e => setPrint_os25_a4(!print_os25_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a3} onChange={e => setPrint_os25_a3(!print_os25_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a2} onChange={e => setPrint_os25_a2(!print_os25_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a1} onChange={e => setPrint_os25_a1(!print_os25_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os25_a0} onChange={e => setPrint_os25_a0(!print_os25_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OS 1:50,000</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a4} onChange={e => setPrint_os50_a4(!print_os50_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a3} onChange={e => setPrint_os50_a3(!print_os50_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a2} onChange={e => setPrint_os50_a2(!print_os50_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a1} onChange={e => setPrint_os50_a1(!print_os50_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os50_a0} onChange={e => setPrint_os50_a0(!print_os50_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>OS 1:250,000</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a4} onChange={e => setPrint_os250_a4(!print_os250_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a3} onChange={e => setPrint_os250_a3(!print_os250_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a2} onChange={e => setPrint_os250_a2(!print_os250_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a1} onChange={e => setPrint_os250_a1(!print_os250_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_os250_a0} onChange={e => setPrint_os250_a0(!print_os250_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>

                                        <Grid item xs={2} md={2}>
                                            <Box fontSize={12}>Aerial Imagery</Box>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a4} onChange={e => setPrint_aerial_a4(!print_aerial_a4)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a3} onChange={e => setPrint_aerial_a3(!print_aerial_a3)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a2} onChange={e => setPrint_aerial_a2(!print_aerial_a2)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a1} onChange={e => setPrint_aerial_a1(!print_aerial_a1)}/>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <Checkbox checked={print_aerial_a0} onChange={e => setPrint_aerial_a0(!print_aerial_a0)}/>
                                        </Grid>
                                        <Grid item xs={false} md={5}></Grid>
                                            
                                    </Grid>
                                </div>

                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >Client Data Settings</Box>
                                    <Grid container>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12}>Can Data</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Use Fixed Price</Box>
                                        </Grid>
                                        <Grid item xs={6} md={8}></Grid>
                                        <Grid item xs={3} md={2}>
                                            <Checkbox checked={canExport} onChange={e => setCanExport(!canExport)}/>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Checkbox checked={hasFixedPrice} onChange={e => setHasFixedPrice(!hasFixedPrice)}/>
                                        </Grid>
                                        <Grid item xs={6} md={8}></Grid>

                                        <Grid item xs={6} md={4}>
                                            <TextField className={classes.textField} value={dataDefaultLayer} onChange={e => setDataDefaultLayer(e.target.value)} name="DefaultLayer" label="Default Layer" error={hasError} size="small" margin="dense" variant="outlined" select>                                                 
                                                <MenuItem value={'osmm9'}>OS Plan (CAD) Standard 9 Layers</MenuItem>
                                                <MenuItem value={'osmm55'}>OS Plan (CAD) Enhanced 55 Layers</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={dataDefaultFormat} onChange={e => setDataDefaultFormat(e.target.value)} name="DefaultFormat" label="Default Format" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                <MenuItem value={'dwg'}>DWG</MenuItem>
                                                <MenuItem value={'dxf'}>DXF</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={dataDefaultSize} onChange={e => setDataDefaultSize(e.target.value)} name="DefaultSize" label="Default Area Size" error={hasError} size="small" margin="dense" variant="outlined" select>
                                                <MenuItem value={'1ha'}>1 ha</MenuItem>
                                                <MenuItem value={'4ha'}>4 ha</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                    </div>
                            </Grid>
                        </Grid>
                    </div>
                    }
                    </Grid>
            </Grid>
        </div>
    )
  };
