export default function getPolygonDetails(polygon) {

    //console.log("Geometry:"+JSON.stringify(polygon[0]));
    const arrayLength = polygon[0].length;

    let minX = 0;
    let maxX = 0;
    let minY = 0;
    let maxY = 0;

    for(let i = 0 ; i < arrayLength; i++) {
        let points = polygon[0][i].toString().split(',');

        if (i === 0){
            minX = maxX = parseFloat(points[0]);
            minY = maxY = parseFloat(points[1]);
        } else {
            if (parseFloat(points[0]) < parseFloat(minX)) { minX = parseFloat(points[0]); }
            if (parseFloat(points[0]) > parseFloat(maxX)) { maxX = parseFloat(points[0]); }
            if (parseFloat(points[1]) < parseFloat(minY)) { minY = parseFloat(points[1]); }
            if (parseFloat(points[1]) > parseFloat(maxY)) { maxY = parseFloat(points[1]); }
        }
    }

    const extent = {
        width: maxX - minX,
        height: maxY - minY,
        minX: minX,
        maxX: maxX,
        minY: minY,
        maxY: maxY,
    };
    //console.log("End extent:"+JSON.stringify(extent));
    return extent;
    
  }
