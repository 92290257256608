import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

import {ReactComponent as ArrowIcon} from '../../assets/dark_icons/icon_arrow_right_thin.svg';
import {ReactComponent as BinIcon} from '../../assets/dark_icons/iconmonstr-trash-can-16 1.svg';



const useStyles = makeStyles({
    root: {
        width:'100%',
    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    },
    primaryLabel:{
        marginTop: 40,
        marginBottom: 40,
        color: '#447095',
    },
    secondaryLabel:{
      marginBottom: 20,
      color: '#447095',
    },
    secondaryDescription:{
      marginBottom: 20,
      color: '#3D3E4F',
    },
    
    formtable:{
      paddingTop: 20,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 20,
      backgroundColor:'#F9F9FA',
      border: '1px solid #EDEDEF',
    },
    openButton:{
      marginTop: 30,
      height:40,
      backgroundColor:'#2D2F88',
      width:200,
      color: '#FFF',
      '&:hover': {
        color: '#F6A036',
      },
    },
    deleteButton:{
        marginTop: 30,
        height:40,
        width:200,
        backgroundColor:'#ECF4F9',
        color: '#000',
        '&:hover': {
          color: '#F6A036',
        },
      },
    listPadding:{
        marginBottom: 10,
        color: '#000',
    },
    buttonIcon: {
      width: 16,
      height: 16
    },
    projectText: {
      width: '100%',
      maxWidth: 300,
      minWidth: 200,
    },
  });


export default function ProjectDetailInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [useremail, setUseremail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [companyName, setCompanyName] = useState('');
    


    const [isDisabled, setIsDisabled] = useState(true);
    
  
    /**
     * First load.
     * Populate fields.
     */
    useEffect(() => {
      setFirstname(sState.user.firstname);
      setLastname(sState.user.lastname);
      setUseremail(sState.user.useremail);
      setTelephone(sState.user.telephone);
      setIsDisabled(true);
    },[])

    const handleOpenProject = (value) => {

    };

    const handleDeleteProject = (value) => {

    };
    //data.user = {"userid":1,"username":"SuiWin","useremail":"suiwin.hui@findmaps.co.uk","userutype":"admin","userstatus":"active"};
    //data.company = {"companyid":1,"companyname":"FIND Mapping Ltd","companytype":"DTM","hasvat":true,"companycreditlimit":100000,"companystatus":"active","companypricing":"","dtmparent":"FIND Mapping ltd","parentbusiness":"FIND","businessid":1,"parentbusinessid":1};
    return (
        <div className={classes.root}>

            <Grid container>
            <Grid item xs={false} sm={2} md={4}></Grid>
            <Grid item xs={12} sm={8} md={4}>
                <Box className={classes.primaryLabel} fontWeight="500" fontStyle="italic" fontSize={22} >My Account</Box>
                <Grid container className={classes.formtable}>
                  <Grid item xs={12} md={12}><Box className={classes.secondaryLabel} fontWeight="500" fontSize={18} >User details</Box></Grid>
                  <Grid item xs={12} md={12}><Box className={classes.secondaryDescription} fontWeight="300" fontSize={14} >{process.env.REACT_APP_ACCOUNT_USERDETAIL}</Box></Grid>
                    <Grid item xs={4} md={3} className={classes.listPadding}>First Name</Grid><Grid item xs={8} md={9} className={classes.listPadding}><TextField className={classes.projectText} disabled={isDisabled} InputProps={{ style: { fontSize: 12, color: '#000' } }} onChange={e => setFirstname(e.target.value)} name="firstname" variant="outlined" size="small" value={firstname} /></Grid>
                    <Grid item xs={4} md={3} className={classes.listPadding}>Last Name</Grid><Grid item xs={8} md={9} className={classes.listPadding}><TextField className={classes.projectText} disabled={isDisabled} InputProps={{ style: { fontSize: 12, color: '#000' } }} onChange={e => setLastname(e.target.value)} name="lastname" variant="outlined" size="small" value={lastname} /></Grid>
                    <Grid item xs={4} md={3} className={classes.listPadding}>Email</Grid><Grid item xs={8} md={9} className={classes.listPadding}><TextField className={classes.projectText} disabled={isDisabled} InputProps={{ style: { fontSize: 12, color: '#000' } }} onChange={e => setUseremail(e.target.value)} name="useremail" variant="outlined" size="small" value={useremail} /></Grid>
                    <Grid item xs={4} md={3} className={classes.listPadding}>Telephone</Grid><Grid item xs={8} md={9} className={classes.listPadding}><TextField className={classes.projectText} disabled={isDisabled} InputProps={{ style: { fontSize: 12, color: '#000' } }} onChange={e => setTelephone(e.target.value)} name="telephone" variant="outlined" size="small" value={telephone} /></Grid>
                </Grid>
            </Grid>
            <Grid item xs={false} sm={2} md={4}></Grid>
            </Grid>
        </div>
    )
  };