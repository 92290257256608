import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import getZoomScaleInt from "../../../functions/getZoomScaleInt";

import {ReactComponent as PlusIcon} from '../../../assets/dark_icons/iconmonstr-plus-2 1.svg';

import Tooltip from '@material-ui/core/Tooltip';
import { ActivityContext } from "../../../context/activity-context";

export default function MenuButton(props) {
  //console.log("props.top:"+props.top);
  const useStyles = makeStyles({
    root: {
        paddingBottom: 5,
    },
    menuButton: {
      color: '#000',
      backgroundColor: '#F9F9FA',
      '&:hover': {
        color: 'white',
        textDecoration: 'none',
		    backgroundColor: '#2D2F88',
      },
      '&.Mui-selected': {
        color: 'white',
        textDecoration: 'none',
        backgroundColor: '#2D2F88',
        border: '1px solid #FFFFFF',
        borderRadius: '0 0 2px 2px',
      },

      display: 'block',
      margin: 1,
      fontSize: '1.14em',
      fontWeight: 'bold',
      textDecoration: 'none',
      textAlign: 'center',
      height: '1.375em',
      width: '1.375em',
      lineHeight: '.4em',
      border: '1px solid #B2B2BC',
      borderRadius: '0 0 2px 2px',
      minWidth: '1.375em',
      padding: 0,
    },
    plusIcon:{
      fill: '#000',
      '&:hover': {
        fill: '#FFF',
      },
      '&.Mui-selected': {
        fill: '#FFF',
      },
      width: 16,
      height: 16
    }

  });

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [isDisabled, setIsDisabled] = useState(false);

  //
  //Disabled zoom in button when limit reached. 
  useEffect(() => {
      const currentInt = getZoomScaleInt(aState.cviewscale);
      if (parseInt(currentInt) <= parseInt(aState.minZoomInt) ) { setIsDisabled(true); } else { setIsDisabled(false); }
  },[aState.cviewscale, aState.minZoomInt])


  const setZoom = () =>{
    aDispatch({
      type: "actionchange",
      payload: { action: 'map_zoomin'}
    });  
  };
  
  return <div className={classes.root}>
          <Tooltip title="Zoomin">
            <Button className={classes.menuButton} disabled={isDisabled} onClick={() => { setZoom(); }} variant="contained">
                <PlusIcon className={classes.plusIcon}/>
            </Button>
          </Tooltip>
        </div>;
}