import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import ReactLoading from 'react-loading';

import {ReactComponent as HelpIcon} from '../../assets/dark_icons/icon_help.svg';
import { TextareaAutosize } from '@material-ui/core';

import AddressPanel from './AddressPanel';

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";
/**
 * This script displays the address search options. This is the first screen the user sees after login.
 * The user can action the display of the mapping window through the selection of browse map options.
 * 
 * The different search options are controlled by rules.
 */

var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
};

export default function LocationPanel(props) {

    const useStyles = makeStyles({
        root: {
            borderLeft: '1px solid #D2D2D8',
            width: props.width,
            height: props.height,
        },
        searchLabel:{
            marginLeft: 90,
            marginTop: 40,
            marginBottom: 20,
            color: '#447095',
        },
        searchContainer:{
            marginRight: 10,
            marginLeft: 50,
            border: '1px solid #EDEDEF',
            minWidth: 350,
            height: 350
        },
        helpContainer:{
            marginRight: 50,
            marginLeft: 10,
            backgroundColor: '#3D3E4F',
            border: '1px solid #3D3E4F',
            maxWidth: 380,
            minHeight: 350
        },
        businessTypeRDO: {
            paddingTop: 20,
            paddingLeft: 40,
            '& .MuiRadio-colorSecondary.Mui-checked':{
                color: '#447095',
            },
            '& .MuiTypography-body1':{
                fontSize: 16
            },
            
        },
        searchDescLabel: {
            color: '#000',
            paddingLeft: 40,
            paddingBotton: 5
        },
        helpIconContainer:{
            paddingTop:30,
            paddingLeft: 30

        },
        helpTitleContainer:{
            display:"flex",
            flexDirection:"row",
        },
        helpIcon:{
            width: 48,
            height: 44
        },
        helpTitle:{
            color: '#FFF',
            paddingTop:30,
            paddingLeft: 30
        },
        helpDescription:{
            color: '#FFF',
            paddingTop:20,
            paddingLeft: 30,
            paddingRight: 30
        },
        actionContainer:{
            display:"flex",
            flexDirection:"row",
        },
        searchButton: {
            marginTop: 30,
            marginLeft: 90,
            backgroundColor:'#2D2F88',
            color: '#FFF',
            '&:hover': {
              color: '#F6A036',
            },
            width: 90
          },
          browseButton: {
            marginTop: 35,
            marginLeft: 50,
            color: '#000000',
            '&:hover': {
              color: '#F6A036',
            },
            textDecoration: 'underline',
          },
    });

  const classes = useStyles();
  const [sState] = useContext(SessionContext);
  const [aState, aDispatch] = useContext(ActivityContext);
  const [searchType, setSearchType] = useState('postcode');
  const [searchField1, setSearchField1] = useState('');
  const [searchField2, setSearchField2] = useState('');
  const [searchField1Div, setSearchField1Div] = useState({});
  const [searchField2Div, setSearchField2Div] = useState({});
  const [hasDoubleField, setHasDoubleField] = useState(false);
  const [searchDesc, setSearchDesc] = useState('Search for');
  const [helpTitle, setHelpTitle] = useState('');
  const [helpDescription, setHelpDescription] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [addressResponse, setAddressResponse] = useState(false);
  const [matchedAddress, setMatchedAddress] = useState({});

  const [isProcess, setIsProcess] = useState(false);
  
  
  /**
   * Changes the seach bar based on search type.
   */
  useEffect(() => {
    setSearchField1('');
    setSearchField2(''); 
    switch (searchType){
        case "bng":
            setSearchDesc('BNG X / Y');
            setHelpTitle(process.env.REACT_APP_SEARCH_BNG_HEAD);
            setHelpDescription(process.env.REACT_APP_SEARCH_BNG_BODY);
            setHasDoubleField(true);
            setSearchField1Div({
                paddingLeft:40,
                width:135,
                height: 40,
            });
            setSearchField2Div({
                paddingLeft:10,
                width:135,
                height: 40,
            });
        break;
        case "latlong":
            setSearchDesc('Lat / Long');
            setHelpTitle(process.env.REACT_APP_SEARCH_LATLONG_HEAD);
            setHelpDescription(process.env.REACT_APP_SEARCH_LATLONG_BODY);
            setHasDoubleField(true);
            setSearchField1Div({
                paddingLeft:40,
                width:135,
                height: 40,
            });
            setSearchField2Div({
                paddingLeft:10,
                width:135,
                height: 40,
            });
        break;
        case "place":
            setSearchDesc('Search for');
            setHelpTitle(process.env.REACT_APP_SEARCH_PLACENAME_HEAD);
            setHelpDescription(process.env.REACT_APP_SEARCH_PLACENAME_BODY);
            setHasDoubleField(false);
            setSearchField1Div({
                paddingLeft:40,
                width:'80%',
                minWidth:300,
                height: 40,
            });
            break;
        case "address":
            setSearchDesc('Search for');
            setHelpTitle(process.env.REACT_APP_SEARCH_ADDRESS_HEAD);
            setHelpDescription(process.env.REACT_APP_SEARCH_ADDRESS_BODY);
            setHasDoubleField(false);
            setSearchField1Div({
                paddingLeft:40,
                width:'80%',
                minWidth:300,
                height: 40,
            });
        break;
        case "postcode":
            setSearchDesc('Search for');
            setHelpTitle(process.env.REACT_APP_SEARCH_POSTCODE_HEAD);
            setHelpDescription(process.env.REACT_APP_SEARCH_POSTCODE_BODY);
            setHasDoubleField(false);
            setSearchField1Div({
                paddingLeft:40,
                width:200,
                height: 40,
            });
        break;
        case "ostile":
            setSearchDesc('Search for');
            setHelpTitle(process.env.REACT_APP_SEARCH_OSSHEET_HEAD);
            setHelpDescription(process.env.REACT_APP_SEARCH_OSSHEET_BODY);
            setHasDoubleField(false);
            setSearchField1Div({
                paddingLeft:40,
                width:200,
                height: 40,
            });
        break;
    }
  }, [searchType]);

    const searchField1Change = (event) =>{
        let passed = false;
        switch (searchType){
            case "bng":
                passed = fieldBNGValidation(event, 'field1');
            break;
            case "latlong":
                passed = fieldLatLongValidation(event, 'field1');
            break;
            case "place":
                passed = fieldPlaceValidation(event);
                break;
            case "address":
                passed = fieldAddressValidation(event);
            break;
            case "postcode":
                passed = fieldPostcodeValidation(event);
            break;
            case "ostile":
                passed = fieldOSheetValidation(event);
            break;
        }
        //Enable or disable the search button
        if (passed){ setDisabled(false); } else { setDisabled(true); }
        
    };

    const searchField2Change = (event) =>{
        let passed = false;
        switch (searchType){
            case "bng":
                passed = fieldBNGValidation(event, 'field2');
            break;
            case "latlong":
                passed = fieldLatLongValidation(event, 'field2');
            break;
        }
        //Enable or disable the search button
        if (passed){ setDisabled(false); } else { setDisabled(true); }
    }

    const fieldBNGValidation = (event, field) =>{
        let passed = true;
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          if (field == 'field1') { 
              setSearchField1(event.target.value); 
              if (!fixedFieldLength(event.target.value, 6)) { passed = false; }
              if (!fixedFieldLength(searchField2, 6)) { passed = false; }
          }
          if (field == 'field2') { 
              setSearchField2(event.target.value);
              if (!fixedFieldLength(event.target.value, 6)) { passed = false; }
              if (!fixedFieldLength(searchField1, 6)) { passed = false; }
          }
       }
       return passed;
    };
    
    const fieldLatLongValidation = (event, field) =>{
        let passed = true;
    };
    const fieldPlaceValidation = (event) =>{
        let passed = true;
        let value = event.target.value;
        setSearchField1(value);

        if (value.length < 1) { passed = false; }
        return passed;
    };
    const fieldAddressValidation = (event) =>{
        let passed = true;
        let value = event.target.value;
        setSearchField1(value);

        if (value.length < 1) { passed = false; }
        return passed;
    };

    const fieldPostcodeValidation = (event) =>{
        let passed = false;
        let value = event.target.value;
        setSearchField1(value);
        value = value.replace(/\s/g, '');
        return isPostcode(value);
    };

    const fieldOSheetValidation = (event) =>{
        let passed = true;
        let value = event.target.value.trim();
        setSearchField1(value);
        return passed;
    };

    const numberOnly = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          //setBusinessId(event.target.value);
       }
       //if (re.test(value)) { return true; } else { return false; }
      };



      const maxFieldLength = (field, length) => {
        let value = field.trim();
        if (value.length > parseInt(length)){
            value = value.slice(0, parseInt(length));
        }
        return value;
      };

      const fixedFieldLength = (field, length) => {
        let value = field.trim();
        if (value.length === parseInt(length)){ return true; } else { return false; }
      };

      const isPostcode = (value) => {
        const re = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/;
        if (re.test(value)) { return true; } else { return false; }
      };

    const handleEnterSubmit = (e) => {
    if (e.key === 'Enter') {
        if (!disabled){
            handleSearch();
        }
    }
    };

    /**
     * Handles the user location search.
     * Request made to backend.
     */
    const handleSearch = (event) => {
        setIsProcess(true);
        let searchParam = '';
        let postSearch = true; //Flag to determine if db request is required.
        switch (searchType){
            case "latlong":
            case "bng":
                //searchParam = searchField1 +","+searchField2;
                postSearch = false;
                setMatchedAddress([{
                    id: 0,
                    address: 'Coordinates: '+searchField1.trim()+','+searchField2.trim(),
                    type: 'bng',
                    x: parseInt(searchField1.trim()),
                    y: parseInt(searchField2.trim()),
                }])
                setAddressResponse(true);
                setIsProcess(false);
            break;
            case "place":
            case "address":
            case "postcode":
            case "ostile":
            default:
                searchParam = searchField1.trim();
                break
        }

        if (postSearch){
            const abortController = new AbortController();
            const request = {
                type:searchType,
                search:searchParam
            };

            let mounted = true;
            (async () => {

                const res = await fetch(process.env.REACT_APP_NMC_API+'/search/address', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(request),
                signal: abortController.signal,
                }).catch(handleError);
                const data = await res.json();
                if (mounted) {
                if (data.status === "success"){
                    if (data.count !== 0){
                        setMatchedAddress(data.data);
                    } else {
                        setMatchedAddress([{
                            id: -1,
                            address: 'No address found',
                            type: 'none',
                            x: 0,
                            y: 0,
                        }])
                    }
                    setAddressResponse(true);
                }
                }
                setIsProcess(false);
                const cleanup = () => {
                mounted = false;
                abortController.abort();
                };
                return cleanup;
        })();
    }
  };

      const handleBrowse = (event) => {
  
      };

  return (
    <div className={classes.root}>
    <Grid container>
        <Grid item xs={6}>
            <Box className={classes.searchLabel} fontWeight="500" fontStyle="italic" fontSize={22} >Location Search</Box>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
            <div className={classes.searchContainer}>
                <RadioGroup className={classes.businessTypeRDO} aria-label="searchType" name="searchType" value={searchType} onChange={e => setSearchType(e.target.value)}>
                    <FormControlLabel value="postcode" control={<Radio />} label="Postcode" />
                    <FormControlLabel value="address" control={<Radio />} label="Address" />
                    <FormControlLabel value="place" disabled={true} control={<Radio />} label="Place name" />
                    <FormControlLabel value="bng" control={<Radio />} label="BNG co-ordinates" />
                    <FormControlLabel value="latlong" disabled={true} control={<Radio />} label="Lat/Long" />
                    <FormControlLabel value="ostile" disabled={true} control={<Radio />} label="OS Sheet Ref" />
                </RadioGroup>
                <Box className={classes.searchDescLabel} fontWeight="500" fontSize={16} >{searchDesc}</Box>
                <TextField style={searchField1Div} id="searchField1" value={searchField1} label="" variant="outlined" size="small" onChange={searchField1Change} onKeyDown={handleEnterSubmit}/>
                { hasDoubleField ? <TextField style={searchField2Div} value={searchField2} id="searchField2" label="" variant="outlined" size="small" onChange={searchField2Change} onKeyDown={handleEnterSubmit}/> : '' }
            </div>
            <div className={classes.actionContainer}>
            {isProcess ? ( 
                <Button className={classes.searchButton} variant="contained" >
                    <Box fontWeight="fontWeightBold" fontSize={16} ><ReactLoading className={classes.loadingImg} type={'spin'} color={'#F6A036'} height={20} width={20}/></Box>
                </Button>
            ) : ( 
                <Button className={classes.searchButton} variant="contained" onClick={handleSearch} disabled={disabled}>
                    <Box fontWeight="fontWeightRegular" fontSize={16} >Search</Box>
                </Button>
            )}
                <Box component="span" display="block">
                    <Button className={classes.browseButton} onClick={handleBrowse} disabled={true} style={{ fontSize: 16 }}>Browse by Map</Button>
                </Box>
            </div>
        </Grid>
        <Grid item xs={6}>
        { addressResponse ?
            <AddressPanel address={matchedAddress} searchType={searchType}/> : <div className={classes.helpContainer}>
                <div className={classes.helpTitleContainer}>
                    <Box className={classes.helpIconContainer} ><HelpIcon className={classes.helpIcon} /></Box>
                    <Box className={classes.helpTitle} fontWeight="fontWeightBold" fontSize={16} >{helpTitle}</Box>
                </div>
                <Box className={classes.helpDescription} fontWeight="fontWeightRegular" fontSize={16} >{helpDescription}</Box>
            </div> 

        }
        </Grid>
    </Grid>
    </div>
  );
}