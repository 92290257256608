import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import getRoyaltyModel from "../../functions/getPricingModels";

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

import {ReactComponent as DownloadIcon} from '../../assets/dark_icons/iconmonstr-download-19 1.svg';
import {ReactComponent as SearchIcon} from '../../assets/dark_icons/iconmonstr-magnifier-6 1.svg';



const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    },
    formLabel:{
        marginTop: 40,
        marginBottom: 20,
        color: '#447095',
    },
    formtable:{
        height: 600,
        width: '100%'
    },
    priceRow:{
        width: '100%',
    },
    downloadIcon: {
      width: 16,
      height: 16
    },
    downloadButton: {
        width: 200,
        backgroundColor:'#CAD8BA',
        color: '#304517',
        '&:hover': {
          color: '#F6A036',
        },   
    },
    menuButton: {
      color: '#000',
        '&:hover': {
          color: '#F6A036',
          backgroundColor:'#FFF',
        }
    },
    actionButton: {
      width:300,
      marginRight: 10,
    },
    resetButton: {
        width:160
    }

  });

  var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };


export default function ProjectListInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);

    const [hasError, setHasError] = useState(false);
    const [displayid, setDisplayid] = useState(0);
    const [model, setModel] = useState({});

    //Pricing model holder
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [created, setCreated] = useState('');
    const [modified, setModified] = useState('');

    const [coverage_area, setCoverage_area] = useState(0);
    const [os250k_print, setOs250k_print] = useState(0);
    const [os250k_export, setOs250k_export] = useState(0);

    const [os50k_print, setOs50k_print] = useState(0);
    const [os50k_export, setOs50k_export] = useState(0);

    const [os25k_print, setOs25k_print] = useState(0);
    const [os25k_export, setOs25k_export] = useState(0);

    const [osvml_print_band1, setOsvml_print_band1] = useState(0);
    const [osvml_print_band2, setOsvml_print_band2] = useState(0);
    const [osvml_print_copy1, setOsvml_print_copy1] = useState(0);
    const [osvml_export_band1, setOsvml_export_band1] = useState(0);
    const [osvml_export_band2, setOsvml_export_band2] = useState(0);

    const [osmm_print_band1, setOsmm_print_band1] = useState(0);
    const [osmm_print_band2Settlement, setOsmm_print_band2Settlement] = useState(0);
    const [osmm_print_band2Rural, setOsmm_print_band2Rural] = useState(0);
    const [osmm_print_band2Moor, setOsmm_print_band2Moor] = useState(0);
    const [osmm_print_band3Settlement, setOsmm_print_band3Settlement] = useState(0);
    const [osmm_print_band3Rural, setOsmm_print_band3Rural] = useState(0);
    const [osmm_print_band3Moor, setOsmm_print_band3Moor] = useState(0);
    const [osmm_print_copiesSettlement, setOsmm_print_copiesSettlement] = useState(0);
    const [osmm_print_copiesRural, setOsmm_print_copiesRural] = useState(0);
    const [osmm_print_copiesMoor, setOsmm_print_copiesMoor] = useState(0);

    const [osmm_export_band1, setOsmm_export_band1] = useState(0);
    const [osmm_export_band2Settlement, setOsmm_export_band2Settlement] = useState(0);
    const [osmm_export_band2Rural, setOsmm_export_band2Rural] = useState(0);
    const [osmm_export_band2Moor, setOsmm_export_band2Moor] = useState(0);
    const [osmm_export_band3Settlement, setOsmm_export_band3Settlement] = useState(0);
    const [osmm_export_band3Rural, setOsmm_export_band3Rural] = useState(0);
    const [osmm_export_band3Moor, setOsmm_export_band3Moor] = useState(0);

    useEffect(() => {
      getRoyaltyModel();
    },[])


    const getRoyaltyModel = () => {
        const abortController = new AbortController();
          let mounted = true;
        (async () => {
            const res = await fetch(process.env.REACT_APP_NMC_API+'/super/model/os', {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                signal: abortController.signal,
              }).catch(handleError);
              const data = await res.json();
              if (mounted) {
                if (data.status === "success"){
                    setModel(data.model);
                } else {
                    setModel({});
                }
              }
              const cleanup = () => {
                mounted = false;
                abortController.abort();
              };
              return cleanup;
        })();
    };

    useEffect(() => {
      populateModel();
    },[model])

    const populateModel = () => {
      
      if (typeof model.model !== 'undefined'){

        setCoverage_area(model.model.nationalcoverage.area);
        setOs250k_print(model.model.os250k.print.royalty);
        setOs250k_export(model.model.os250k.export.royalty);
        setOs50k_print(model.model.os50k.print.royalty);
        setOs50k_export(model.model.os50k.export.royalty);
        setOs25k_print(model.model.os25k.print.royalty);
        setOs25k_export(model.model.os25k.export.royalty);
        setOsvml_print_band1(model.model.vml.print.royalty_band1);
        setOsvml_print_band2(model.model.vml.print.royalty_band2);
        setOsvml_print_copy1(model.model.vml.print.royalty_copy1);
        setOsvml_export_band1(model.model.vml.export.royalty_band1);
        setOsvml_export_band2(model.model.vml.export.royalty_band2);
        setOsmm_print_band1(model.model.osmm.print.band1Price);
        setOsmm_print_band2Settlement(model.model.osmm.print.band2Settlement);
        setOsmm_print_band2Rural(model.model.osmm.print.band2Rural);
        setOsmm_print_band2Moor(model.model.osmm.print.band2Moor);
        setOsmm_print_band3Settlement(model.model.osmm.print.band3Settlement);
        setOsmm_print_band3Rural(model.model.osmm.print.band3Rural);
        setOsmm_print_band3Moor(model.model.osmm.print.band3Moor);
        setOsmm_print_copiesSettlement(model.model.osmm.print.copiesSettlement);
        setOsmm_print_copiesRural(model.model.osmm.print.copiesRural);
        setOsmm_print_copiesMoor(model.model.osmm.print.copiesMoor);
        setOsmm_export_band1(model.model.osmm.export.band1Price);
        setOsmm_export_band2Settlement(model.model.osmm.export.band2Settlement);
        setOsmm_export_band2Rural(model.model.osmm.export.band2Rural);
        setOsmm_export_band2Moor(model.model.osmm.export.band2Moor);
        setOsmm_export_band3Settlement(model.model.osmm.export.band3Settlement);
        setOsmm_export_band3Rural(model.model.osmm.export.band3Rural);
        setOsmm_export_band3Moor(model.model.osmm.export.band3Moor);
      }
    };

  /**
   * Saves the changes to the os royalty model.
   */

  const handleSave = () => {
    console.log(JSON.stringify(model.model));
    const osmodel = generateModel(model.model);

    const abortController = new AbortController();
    const request = {
        userid: sState.userid,
        ident: sState.ident,
        modelid: model.modelid,
        name: model.name,
        description: model.description,
        model: osmodel
    }
    let mounted = true;
    (async () => {  
        const res = await fetch(process.env.REACT_APP_NMC_API+'/super/model/os/save/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request),
            signal: abortController.signal,
        }).catch(handleError);
        const data = await res.json();
        if (mounted) {
          if (data.status === "success"){
            setModel(data.model);
          } else {
              setModel({});
          }
        }
        const cleanup = () => {
            mounted = false;
            abortController.abort();
        };
        return cleanup;

    })();


  }

  const generateModel = (model) => {
    
    model.nationalcoverage.area = coverage_area
    model.os250k.print.royalty = os250k_print;
    model.os250k.export.royalty = os250k_export;
    model.os50k.print.royalty = os50k_print;
    model.os50k.export.royalty = os50k_export;
    model.os25k.print.royalty = os25k_print;
    model.os25k.export.royalty = os25k_export;

    model.vml.print.royalty_band1 = osvml_print_band1;
    model.vml.print.royalty_band2 = osvml_print_band2;
    model.vml.print.royalty_copy1 = osvml_print_copy1;
    model.vml.export.royalty_band1 = osvml_export_band1;
    model.vml.export.royalty_band2 = osvml_export_band2;

    model.osmm.print.band1Price = osmm_print_band1;
    model.osmm.print.band2Settlement =osmm_print_band2Settlement;
    model.osmm.print.band2Rural =osmm_print_band2Rural;
    model.osmm.print.band2Moor =osmm_print_band2Moor;
    model.osmm.print.band3Settlement =osmm_print_band3Settlement;
    model.osmm.print.band3Rural =osmm_print_band3Rural;
    model.osmm.print.band3Moor =osmm_print_band3Moor;
    model.osmm.print.copiesSettlement =osmm_print_copiesSettlement;
    model.osmm.print.copiesRural =osmm_print_copiesRural;
    model.osmm.print.copiesMoor =osmm_print_copiesMoor;

    model.osmm.export.band1Price = osmm_export_band1;
    model.osmm.export.band2Settlement = osmm_export_band2Settlement;
    model.osmm.export.band2Rural = osmm_export_band2Rural;
    model.osmm.export.band2Moor = osmm_export_band2Moor;
    model.osmm.export.band3Settlement = osmm_export_band3Settlement;
    model.osmm.export.band3Rural = osmm_export_band3Rural;
    model.osmm.export.band3Moor = osmm_export_band3Moor;

    return model;
  };

  const handleReset = () => {
    populateModel();
  };
  

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.formLabel} fontWeight="500" fontStyle="italic" fontSize={22} >OS Charges</Box>
                    <Box className={classes.formLabel} fontSize={14} >This page allows the editing of OS charges for the ordering of plots and data. The values entered here are used to calculate the OS Total (This column appears in your royalty report). This os total for the order is calculated at the point of ordering.</Box>
                </Grid>
                <Grid item xs={12}>
                    <Button className={classes.actionButton} variant="contained" onClick={handleSave} >
                        <Box fontWeight="fontWeightRegular" fontSize={12} >Save changes to OS charges</Box>
                    </Button>
                    <Button className={classes.resetButton} variant="contained" onClick={handleReset} >
                        <Box fontWeight="fontWeightRegular" fontSize={12} >Reset form</Box>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                <div className={classes.productcontainer}>
                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >National Coverage Area</Box>
                    <Grid container>
                        <Grid item xs={4} md={2}>
                            <Box fontSize={12} >National Coverage in km2</Box>
                        </Grid>
                        <Grid item xs={8} md={6}>
                            <TextField className={classes.textField} value={coverage_area} onChange={e => setCoverage_area(e.target.value)} name="area" label="Area" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>

                    </Grid>
                </div>
                <div className={classes.productcontainer}>
                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS 1:250,000 Raster</Box>
                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                    <Grid container>
                        <Grid item xs={4} md={2}>
                            <Box fontSize={12} >Price per km</Box>
                        </Grid>
                        <Grid item xs={8} md={6}>
                            <TextField className={classes.textField} value={os250k_print} onChange={e => setOs250k_print(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>

                    </Grid>
                    <Box className={classes.containerLabel} fontSize={12} >Data</Box>
                    <Grid container>
                        <Grid item xs={4} md={2}>
                            <Box fontSize={12} >Price per km</Box>
                        </Grid>
                        <Grid item xs={8} md={6}>
                            <TextField className={classes.textField} value={os250k_export} onChange={e => setOs250k_export(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>
                    </Grid>
                </div>
                <div className={classes.productcontainer}>
                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS 1:50,000 Raster</Box>
                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                    <Grid container>
                        <Grid item xs={4} md={2}>
                            <Box fontSize={12} >Price per km</Box>
                        </Grid>
                        <Grid item xs={8} md={6}>
                            <TextField className={classes.textField} value={os50k_print} onChange={e => setOs50k_print(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>

                    </Grid>
                    <Box className={classes.containerLabel} fontSize={12} >Data</Box>
                    <Grid container>
                        <Grid item xs={4} md={2}>
                            <Box fontSize={12} >National Coverage</Box>
                        </Grid>
                        <Grid item xs={8} md={6}>
                            <TextField className={classes.textField} value={os50k_export} onChange={e => setOs50k_export(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>
                    </Grid>
                </div>
                <div className={classes.productcontainer}>
                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS 1:25,000 Raster</Box>
                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                    <Grid container>
                        <Grid item xs={4} md={2}>
                            <Box fontSize={12} >Price per km</Box>
                        </Grid>
                        <Grid item xs={8} md={6}>
                            <TextField className={classes.textField} value={os25k_print} onChange={e => setOs25k_print(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>

                    </Grid>
                    <Box className={classes.containerLabel} fontSize={12} >Data</Box>
                    <Grid container>
                        <Grid item xs={4} md={2}>
                            <Box fontSize={12} >National coverage</Box>
                        </Grid>
                        <Grid item xs={8} md={6}>
                            <TextField className={classes.textField} value={os25k_export} onChange={e => setOs25k_export(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>
                    </Grid>
                </div>
                <div className={classes.productcontainer}>
                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS Vectormap Local (Vector)</Box>
                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                    <Grid container>
                        <Grid item xs={4} md={2}>
                            <Box fontSize={12} >Price per km</Box>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <TextField className={classes.textField} value={osvml_print_band1} onChange={e => setOsvml_print_band1(e.target.value)} name="price" label="First km" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <TextField className={classes.textField} value={osvml_print_band2} onChange={e => setOsvml_print_band2(e.target.value)} name="price" label="Subequent km" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>
                        <Grid item xs={4} md={2}>
                          <Box fontSize={12} >Run on Royalties</Box>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <TextField className={classes.textField} value={osvml_print_copy1} onChange={e => setOsvml_print_copy1(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>

                    </Grid>
                    <Box className={classes.containerLabel} fontSize={12} >Data</Box>
                    <Grid container>
                        <Grid item xs={4} md={2}>
                            <Box fontSize={12} >Price per km</Box>
                        </Grid>
                        <Grid item xs={8} md={3}>
                            <TextField className={classes.textField} value={osvml_export_band1} onChange={e => setOsvml_export_band1(e.target.value)} name="Charge" label="First Km" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={8} md={3}>
                            <TextField className={classes.textField} value={osvml_export_band2} onChange={e => setOsvml_export_band2(e.target.value)} name="Charge" label="National coverage charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                        </Grid>
                        <Grid item xs={false} md={4}></Grid>
                    </Grid>
                </div>
                <div className={classes.productcontainer}>
                  <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS Mastermap</Box>
                  <Box className={classes.containerLabel} fontSize={12} >Prints</Box>
                  <Grid container>
                      <Grid item xs={3} md={2} >
                          <Box fontSize={12} >Band</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <Box fontSize={12} >Settlement (per hectare)</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <Box fontSize={12} >Rural (per hectare)</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <Box fontSize={12} >Moorland (per hectare)</Box>
                      </Grid>
                      <Grid item xs={false} md={4}></Grid>

                      <Grid item xs={3} md={2} >
                          <Box fontSize={12} >A</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_band1} onChange={e => setOsmm_print_band1(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                      </Grid>
                      <Grid item xs={3} md={2}>

                      </Grid>
                      <Grid item xs={false} md={4}></Grid>

                      <Grid item xs={3} md={2} >
                          <Box fontSize={12} >B</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_band2Settlement} onChange={e => setOsmm_print_band2Settlement(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_band2Rural} onChange={e => setOsmm_print_band2Rural(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_band2Moor} onChange={e => setOsmm_print_band2Moor(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={false} md={4}></Grid>

                      <Grid item xs={3} md={2} >
                          <Box fontSize={12} >C</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_band3Settlement} onChange={e => setOsmm_print_band3Settlement(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_band3Rural} onChange={e => setOsmm_print_band3Rural(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_band3Moor} onChange={e => setOsmm_print_band3Moor(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={false} md={4}></Grid>

                      <Grid item xs={3} md={2} >
                          <Box fontSize={12} >Run on Royalties</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_copiesSettlement} onChange={e => setOsmm_print_copiesSettlement(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_copiesRural} onChange={e => setOsmm_print_copiesRural(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_print_copiesMoor} onChange={e => setOsmm_print_copiesMoor(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={false} md={4}></Grid>
                  </Grid>
                  <Box className={classes.containerLabel} fontSize={12} >Data exports (50% discount is applied on final charge)</Box>
                  <Grid container>
                      <Grid item xs={3} md={2} >
                          <Box fontSize={12} >Band</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <Box fontSize={12} >Settlement (per hectare)</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <Box fontSize={12} >Rural (per hectare)</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <Box fontSize={12} >Moorland (per hectare)</Box>
                      </Grid>
                      <Grid item xs={false} md={4}></Grid>

                      <Grid item xs={3} md={2} >
                          <Box fontSize={12} >A</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_export_band1} onChange={e => setOsmm_export_band1(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                      </Grid>
                      <Grid item xs={3} md={2}>

                      </Grid>
                      <Grid item xs={false} md={4}></Grid>

                      <Grid item xs={3} md={2} >
                          <Box fontSize={12} >B</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_export_band2Settlement} onChange={e => setOsmm_export_band2Settlement(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_export_band2Rural} onChange={e => setOsmm_export_band2Rural(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_export_band2Moor} onChange={e => setOsmm_export_band2Moor(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={false} md={4}></Grid>

                      <Grid item xs={3} md={2} >
                          <Box fontSize={12} >C</Box>
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_export_band3Settlement} onChange={e => setOsmm_export_band3Settlement(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_export_band3Rural} onChange={e => setOsmm_export_band3Rural(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={3} md={2}>
                          <TextField className={classes.textField} value={osmm_export_band3Moor} onChange={e => setOsmm_export_band3Moor(e.target.value)} name="price" label="OS charge" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                      </Grid>
                      <Grid item xs={false} md={4}></Grid>
                  </Grid>
                </div>
              </Grid>

            </Grid>
        </div>
    )
  };
