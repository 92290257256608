/**
 * Records the view.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function recordView(mapObj, blayer, aState, sState) {

  //console.log("JSON view aState:"+JSON.stringify(aState));

      const p = parseFloat(aState.cprice);
      const v = parseFloat(0);
      const r = parseFloat(0);
      //if (uState.hasvat){
          //v = parseFloat(p * 0.2);
      //}
      const e = mapObj.getView().calculateExtent()

      const abortController = new AbortController();
      const request = {   
        userid:sState.userid,
        accountid:sState.aid,
        product:blayer,
        userref:aState.projectreference,
        projectname:aState.projectreference,
        price:p,
        royalty:r,
        vat:v,
        ident:sState.ident,
        sessionid:sState.ident,
        extent:e,
      };
  
      let mounted = true;
      (async () => {  
        const res = await fetch(process.env.REACT_APP_NMC_API+'/order/view', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(request),
          signal: abortController.signal,
        }).catch(handleError);
        const data = await res.json();
        if (mounted) {
  
        }
        const cleanup = () => {
          mounted = false;
          abortController.abort();
        };
        return cleanup;
    
    })();

}
