import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import getPolygonExtent from "../../functions/getExtentFromPolygon";
import getProductName from "../../functions/getProductName";
import deleteSaveProject from "../../functions/deleteSavedProject";
import openSaveProject from "../../functions/openSavedProject";

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

import {ReactComponent as ArrowIcon} from '../../assets/dark_icons/icon_arrow_right_thin.svg';
import {ReactComponent as BinIcon} from '../../assets/dark_icons/iconmonstr-trash-can-16 1.svg';



const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    },
    formLabel:{
        marginLeft: 90,
        marginTop: 40,
        marginBottom: 20,
        height: 120,
        color: '#447095',
    },
    formtable:{
      marginLeft: 90,
      marginRight: 90,
      marginBottom: 20,
      color: '#447095',
    },
    openButton:{
      marginTop: 30,
      height:40,
      backgroundColor:'#2D2F88',
      width:200,
      color: '#FFF',
      '&:hover': {
        color: '#F6A036',
      },
    },
    deleteButton:{
        marginTop: 30,
        height:40,
        width:200,
        backgroundColor:'#ECF4F9',
        color: '#000',
        '&:hover': {
          color: '#F6A036',
        },
      },
    listPadding:{
        marginBottom: 10,
        color: '#000',
    },
    buttonIcon: {
      width: 16,
      height: 16
    },

  });


export default function ProjectDetailInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [selectedID, setSelectedID] = useState(props.selectedId);//default: 0
    const [detailRow, setDetailRow] = useState({});
    const [boundingBox, setBoundingBox] = useState('');
    const [viewScale, setViewScale] = useState(0);
    const [basemap, setBasemap] = useState(0);
  
    /**
     * First load
     */
    useEffect(() => {
        aState.projects.forEach(function (p) {
            
            if (p.saveid === props.selectedId){
                setDetailRow(p);
                //console.log("saved project details: "+JSON.stringify(p));
                const extent = getPolygonExtent(p.geom);
                setBoundingBox(parseInt(extent.minX)+' '+parseInt(extent.minY)+', '+parseInt(extent.maxX)+' '+parseInt(extent.maxY));
                setViewScale(p.details.viewscale);
                setBasemap(getProductName(p.details.viewlayer, 'view'));
                //console.log("Saved project row: "+JSON.stringify(p));

                return;
            }
        });
    },[props.selectedId])

    const handleOpenProject = (value) => {
        openSaveProject(aState,props.selectedId,aDispatch,sState);
    };

    const handleDeleteProject = (value) => {
        deleteSaveProject(sState,props.selectedId,aDispatch);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.formLabel} fontWeight="500" fontStyle="italic" fontSize={22} >{detailRow.savename}</Box>
                </Grid>
                <Grid item xs={8}>
                    <Grid container className={classes.formtable}>
                        <Grid item xs={3} className={classes.listPadding}>Project name</Grid><Grid item xs={7} className={classes.listPadding}>{detailRow.savename}</Grid>
                        <Grid item xs={3} className={classes.listPadding}>User</Grid><Grid item xs={7} className={classes.listPadding}>{detailRow.username}</Grid>
                        <Grid item xs={3} className={classes.listPadding}>View scale</Grid><Grid item xs={7} className={classes.listPadding}>1:{viewScale}</Grid>
                        <Grid item xs={3} className={classes.listPadding}>Basemapping</Grid><Grid item xs={7} className={classes.listPadding}>{basemap}</Grid>
                        <Grid item xs={3} className={classes.listPadding}>Bounding box</Grid><Grid item xs={7} className={classes.listPadding}>{boundingBox}</Grid>
                        <Grid item xs={3} className={classes.listPadding}>Last accessed</Grid><Grid item xs={7} className={classes.listPadding}>{detailRow.lastaccessed}</Grid>
                        <Grid item xs={3} className={classes.listPadding}>Created</Grid><Grid item xs={7} className={classes.listPadding}>{detailRow.saveddate}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.formtable}>
                        <Button className={classes.openButton} variant="contained" onClick={handleOpenProject} >
                            <Box className={classes.downloadIconContainer} ><ArrowIcon className={classes.buttonIcon} /></Box><Box fontWeight="fontWeightRegular" fontSize={12} >&nbsp;&nbsp;Open this project</Box>
                        </Button><br/>
                        <Button className={classes.deleteButton} variant="contained" onClick={handleDeleteProject} >
                            <Box className={classes.downloadIconContainer} ><BinIcon className={classes.buttonIcon} /></Box><Box fontWeight="fontWeightRegular" fontSize={12} >&nbsp;&nbsp;Delete</Box>
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
  };