import React, { useState, useEffect, useContext } from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TopBar from './createComponents/Topbar';

import LoginInterface from './LoginInterface';
import CreateInterface from './CreateInterface';
import DownloadInterface from './DownloadInterface';
import ProjectInterface from './ProjectInterface';
import AccountInterface from './AccountInterface';
import BackdoorInterface from './BackdoorInterface';

import getUserDetail from './../functions/getUserDetails';
import getPrintTemplates from './../functions/getPrintTemplates';

import ReactLoading from 'react-loading';

import { SessionContext } from "../context/session-context";
import { UserContext } from "../context/user-context";
import { ActivityContext } from "../context/activity-context";


var handleError = function (err) {
  console.log(err);
  return new Response(JSON.stringify({
    code: 400,
    message: 'Stupid network Error'
  }));
};

const useStyles = makeStyles({

  loadingImg: {
  
  },

});

export default function UserInterface() {

    const classes = useStyles();
    //const [state, dispatch] = useContext(SessionContext);

    const [sState, sDispatch] = useContext(SessionContext);
    const [uState, udispatch] = useContext(UserContext);
    const [aState, adispatch] = useContext(ActivityContext);
    const [view, setView] = useState('login'); //default: login 
    const [loadingDiv, setLoadingDiv] = useState({});
    const [loading, setLoading] = useState(false);
    const [isDev, setIsDev] = useState(false); //Controls if in dev mode. default: false/
    const [backdoor, setBackdoor] = useState(false);
    
   /**
    * Store global settings.
    */
   useEffect(() => {

    const w = parseInt(window.innerWidth / 2);
    const h = parseInt(window.innerHeight / 2);
  
    setLoadingDiv({
      position:'absolute',
      top: h,
      right: w,
      zIndex:250
    });

    let search = window.location.search;
    let params = new URLSearchParams(search);

    //Backdoor for mapping toolbar work.
    /*
    let bdoor = params.get('backdoorid');
    if (typeof bdoor !== 'undefined' && bdoor === 'findonly'){
      setBackdoor(true);
      setView('backdoor');
      adispatch({
        type: "backdoor",
        payload: { backdoor: true }
      });
    }
    */
    }, []);

    /**
     * Displays the loading symbol if required.
     */
    useEffect(() => {
        setLoading(aState.isloading);
    }, [aState.isloading]);

   /**
   * Listerns for isActive. this is first login
   * Users could be registered or guest.
   * Changes from Login to Mapping Screen or back if logout
   */
  useEffect(() => {
    let toplevel;
    let cview;
    if (sState.isActive) { 
      toplevel = 'mapping'; cview = 'address'; 
      adispatch({
        type: "updatetoplevel",
        payload: { toplevel: toplevel, viewstep: cview }
      });
  
      //Get and store print templates for later use.
      getPrintTemplates(sState.aid, sDispatch);
    } 

  }, [sState.isActive]);

  /**
   * If registered user has logged in, get user defaults.
   */
  useEffect(() => {
    //console.log("isRegistered: "+sState.isRegistered);
    if (sState.isRegistered){

      async function getDetails() {
        const data = await getUserDetail(sState.userid, sState.ident);
        //console.log("isRegistered data: "+JSON.stringify(data));

        if (isDev){
          data.user = {"userid":1,"username":"SuiWin","useremail":"suiwin.hui@findmaps.co.uk","userutype":"admin","userstatus":"active"};
          data.company = {"companyid":1,"companyname":"FIND Mapping Ltd","companytype":"DTM","hasvat":"printdata","companycreditlimit":100000,"companystatus":"active","companypricing":"","dtmparent":"FIND Mapping ltd","parentbusiness":"FIND","businessid":1,"parentbusinessid":1};
          data.defaults = {"hasgrid":false,"showorders":false,"hasinitialpricing":true,"defaultlayer":"osmm","defaultmm":"osmm","dprintlogo":"find_logo.png","dprintproduct":"osmm","dprintscale":"1250","dprintorientation":"portrait","dprintsize":"a4","dprintcopies":1,"dprintoptions":[{"product":"osmmbw","a4":true,"a3":true,"a2":true,"a1":true,"a0":true,"scales":"200,500,1250,2500"},{"product":"osmm","a4":true,"a3":true,"a2":true,"a1":true,"a0":true,"scales":"200,500,1250,2500"},{"product":"os10k","a4":true,"a3":true,"a2":true,"a1":true,"a0":true,"scales":"15000,25000"},{"product":"os50k","a4":true,"a3":true,"a2":false,"a1":false,"a0":false,"scales":"30000,50000"},{"product":"os250k","a4":true,"a3":true,"a2":false,"a1":false,"a0":false,"scales":"150000,250000"},{"product":"aerial","a4":true,"a3":false,"a2":false,"a1":false,"a0":false,"scales":"200,500,1250,2500"}],"ddataproduct":"osmm9","ddataformat":"dwg","ddatascale":"","ddatafixedprice":true,"annotation_color":null};
        }

        if (data.status === "success"){
          sDispatch({
            type: "setuserdetails",
            payload: { userDetail: data.user, companyDetail: data.company, defaultDetail: data.defaults }
          });
        } else {
          sDispatch({
            type: "setRegistered",
            payload: { isRegistered: false }
          });
        }
      }
      getDetails();
    }
  }, [sState.isRegistered]);

   /**
   * Changes interface based on required view.
   */
    useEffect(() => {
        
        if (view !== aState.toplevel) { setView(aState.toplevel); }
      
    }, [aState.toplevel]);
  
    return (
      <div>
        <Grid container>
            <Grid item xs={12}>

                { view === 'login' ? <LoginInterface /> : '' }
                { view === 'mapping' ? <CreateInterface /> : '' }
                { view === 'download' ? <DownloadInterface /> : '' }
                { view === 'project' ? <ProjectInterface /> : '' }
                { view === 'account' ? <AccountInterface /> : '' }
                { view === 'backdoor' ? <BackdoorInterface /> : '' }
                {loading ? (
                      <Paper elevation={1} style={loadingDiv} ><ReactLoading className={classes.loadingImg} type={"bars"} color={"black"} /></Paper>
                ):''}
                
            </Grid>
        </Grid>
      </div>
  
    );
  }