
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MapCoordinates from './MapCoordinates';

import {ReactComponent as ArrowIcon} from '../../assets/dark_icons/icon_arrow_up_thin.svg';
import { ActivityContext } from "../../context/activity-context";


const useStyles = makeStyles({

  paper: {
    position: 'relative',
    bottom: 92,
    left: 15,
    border: '1px solid #D2D2D8',
    padding: '2px 2px',
    display: 'flex',
    width: 250,
    height: 75,
    zIndex:10,
    backgroundColor:'#FFF',
  },
  northLabel:{
    fontWeight:"bold",
    fontSize: 14,
    paddingLeft:19,
  },
  arrowIconContainer:{

  },
  arrowIcon:{
    paddingTop:15,
    paddingLeft:10,
    width:30,
    height:30,
  },
  infoContainer:{

  },
  scalelabel:{
    flexGrow: 0,
    fontSize:16,
    display:"inline",
    padding: 0,
    paddingTop:10,
    paddingLeft:10,
    paddingRight:5
  },
});


export default function MapInfoPaper(props) {

  const classes = useStyles();


  return (
    <div className={classes.paper}>
      
      <Box className={classes.northarrow}>
          <Box className={classes.arrowIconContainer} ><ArrowIcon className={classes.arrowIcon} /></Box>
          <Box className={classes.northLabel}>N</Box>
      </Box>
      <Box className={classes.infoContainer}><MapCoordinates /></Box>
    </div>
  );
}