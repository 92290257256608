
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { ActivityContext } from "../../context/activity-context";
import { SessionContext } from "../../context/session-context";

export default function OrderSummaryData(props) {

  const useStyles = makeStyles({
    paper: {
      border: '1px solid #D2D2D8',
      padding: '2px 2px',
      display: 'flex',
      width: 520,
      height: 400,
      backgroundColor:'#FFF',
    },
    projectLabel:{
        paddingTop: 15,
        paddingLeft: 10
    },
    projectBorderLabel:{
        borderTop: '2px solid #3D3E4F',
        paddingTop: 15,
        paddingLeft: 10
    },
    moneyField:{
        paddingTop: 15,
        width: 100,
    },
    moneyBorderField:{
        borderTop: '2px solid #3D3E4F',
        paddingTop: 15,
    }
  });


  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [sState] = useContext(SessionContext);
  const [product, setProduct] = useState('');
  const [format, setFormat] = useState('DWG');
  const [license, setLicense] = useState('Standard, 1 year');
  const [expiry, setExpiry] = useState('');
  const [price, setPrice] = useState('');
  const [vat, setVAT] = useState('');
  const [total, setTotal] = useState('');


  useEffect(() => {
    setProduct(aState.productName);
    setFormat(aState.data.format.toUpperCase());

    const money = parseFloat(aState.cprice);
    var vat = 0;
    let gross = parseFloat(money);
    setPrice(money.toFixed(2))
    //console.log("VAT status = "+sState.company.vaton);
    if (sState.company.vaton === 'printdata' || sState.company.vaton === 'data'){
        const vatRate = parseFloat(process.env.REACT_APP_VAT_RATE)/100;
        vat = parseFloat(money * vatRate).toFixed(2);
        gross = parseFloat(money) + parseFloat(vat);
    }
    setVAT(vat);
    setTotal(gross.toFixed(2));

    let date = new Date();
    date.setMonth(date.getMonth() + 12);
    const day= ('0'+date.getDate()).slice(-2);
    const month = ('0'+(date.getMonth()+ 1)).slice(-2);
    setExpiry(day+'/'+ month +'/'+date.getFullYear());

  },[]);

  return (
    <div className={classes.paper}>
        <Grid container spacing={0}>
            <Grid item xs={5}>
                <Box fontWeight={500} className={classes.projectLabel} fontSize={16}>Product</Box>
            </Grid>
            <Grid item xs={7} >
                <Box fontWeight={400} className={classes.projectLabel} fontSize={16}>{product}</Box>
            </Grid>

            <Grid item xs={5}>
                <Box fontWeight={500} className={classes.projectLabel} fontSize={16}>Format</Box>
            </Grid>
            <Grid item xs={7} >
                <Box fontWeight={400} className={classes.projectLabel} fontSize={16}>{format}</Box>
            </Grid>
            <Grid item xs={5}>
                <Box fontWeight={500} className={classes.projectLabel} fontSize={16}>License type</Box>
            </Grid>
            <Grid item xs={7} >
                <Box fontWeight={400} className={classes.projectLabel} fontSize={16}>{license}</Box>
            </Grid>
            <Grid item xs={5}>
                <Box fontWeight={500} className={classes.projectLabel} fontSize={16}>License expires</Box>
            </Grid>
            <Grid item xs={7} >
                <Box fontWeight={400} className={classes.projectLabel} fontSize={16}>{expiry}</Box>
            </Grid>
            <Grid item xs={5}>
                <Box ></Box>
            </Grid>
            <Grid item xs={4} >
                <Box fontWeight={500} className={classes.projectBorderLabel} fontSize={16}>Price</Box>
            </Grid>
            <Grid item xs={3} >
                <Box className={classes.moneyBorderField} justifyContent="flex-end" fontWeight={400} fontSize={16}>£{price}</Box>
            </Grid>
            <Grid item xs={5}>
                <Box ></Box>
            </Grid>
            <Grid item xs={4} >
                <Box fontWeight={500} className={classes.projectLabel} fontSize={16}>VAT</Box>
            </Grid>
            <Grid item xs={3} >
                <Box className={classes.moneyField} justifyContent="flex-end" fontWeight={400} fontSize={16}>£{vat}</Box>
            </Grid>
            <Grid item xs={5}>
                <Box ></Box>
            </Grid>
            <Grid item xs={4} >
                <Box fontWeight={500} className={classes.projectBorderLabel} fontSize={16}>Total:</Box>
            </Grid>
            <Grid item xs={3} >
                <Box className={classes.moneyBorderField} justifyContent="flex-end" fontWeight={500} fontSize={16}>£{total}</Box>
            </Grid>
        </Grid>
    </div>
  );
}