/** 
 * Storage for current activity.
 */
import React, { useReducer, createContext } from "react";

export const ActivityContext = createContext();

const initialState = {
  backdoor: false,
  toplevel: 'login', //default:login //Current toplevel interface to use. one of: login, mapping, order, downloads, projects, management
  viewStep: 'login', //default:login //Second level view. i.e. search, address, mapping, orderSummary, payment
  orderStep:'addressSearch', //The order position for plots or data. //default 'addressSearch'
  cviewscale: 10000, //Current user view scale
  rviewscale: 10000, //requested user view scale
  minZoomInt: 1, //sets the minimum zoom int user can zoom into.
  caction: '', //Current user action active
  annotationAction: '', //Current annotation tool action
  productSelect:'', //Either view, plot or data. default view
  productLayerSelect:'', //The data layer selected
  productName:'', //The formal name of the product being ordered.
  cprice: 0, //Current price of active item. i.e. print / data etc..
  orderid: 0, //Current orderid of processing item. i.e. print / data etc..
  cpriceapproved: false, //Price is approved.
  bmap: 'os10k', //Current basemap to display
  dbAnnotation:'', //Annotation requested from database. i.e. OSMM building outline
  addressSource: '',
  caddress: { name: 'Maps from NMC', value: {type: 'coordinates',x: 525651,y: 168117 }}, //Current address being displayed.
  mapArea: '', //Displays the map area value in ha or km.
  mapAreaUnits: 'ha', //Displays the map area value in ha or km.
  mapextent:[], //Stores the current mapextent.
  mapAnnotations:{count:0, annotations:[]}, //Stores the map annotations
  currentAnnotation: {}, //Stores the current active annotation.
  currentStyle: {outline:{colour: 'rgba(220,49,49,1)', width: 3, linedash:'solid'}, fill:{colour: 'rgba(220,49,49,0.5)'}, object: 'none', source: 'none'}, //Stores the current annotation styling information.
  projectreference:'',
  print:{},
  printframeextent:[],
  data:{}, //Stores data export information.
  requestdatageom: {}, //Stores information on the requested data geom.
  datageom: { geometry: 0, area: 0}, //Stores data geometry.
  measurementUnits:{area:'km', length:'km'},
  measurements:{raw:0, area:'0', length:'0'},
  firstmapload:true, //Flag for if this is the first time a map has been displayed..
  dataloading:true,
  priceloading:false, //Flag for if pricing is loading. Prevents site continue if true.
  isloading:false, //Displays the loading gif.
  hasAddress:false, //Flag for if address has been selected..
  hasProduct:false, //Flag for if a product has been selected.
  isProject:false,
  hasToolbar:false, //Flag for the display of the toolbar
  hasAnnotationbar:false, //Flag for the display of the annotationbar
  hasDataCustomPolygon:false, //Flag for if custom polygon has been created.
  isDataCustom:false, //Flag for if data custom has been selected.
  projects:{}, //Stores projects list.
  orders:{}, //Stores orderlist information.
  adminreport:{}, //Stores admin report list information.
  pricingmodels:{}, //Stores a list of all pricing models (Super).
  companies:{}, //Stores companies list.
  company:{}, //Store selected company details.
  companyUsers:{}, //Store selected company users.
  dtmParents:{}, //Store selected company details.
};



const reducer = (state, action) => {
  switch (action.type) {
    case "resetmap": //Sets values back to default as if just logged in.
      return { ...state, 
        bmap: 'os10k',
        cviewscale: 10000,
        rviewscale: 10000,
        projectreference:'',
        minZoomInt: 1,
        caddress: { name: 'Maps from NMC', value: {type: 'coordinates',x: 525651,y: 168117 }},
        cprice: 0,
        orderid: 0,
        caction: '',
        annotationAction: '',
        productSelect:'',
        productLayerSelect:'',
        productName:'',
        print:{},
        printframeextent:[],
        addressSource: '',
        print:{},
        printframeextent:[],
        data:{}, 
        datageom: {}, 
        firstmapload:true,
        pricingmodel:{},
        companies:{},
        company:{},
        backdoor:false
      };
    case "resetorderdetails": 
    return { ...state, 
      cprice: 0,
      orderid: 0,
      print:{},
      printframeextent:[],
      print:{},
      printframeextent:[],
      data:{}, 
      datageom: {},
      priceloading:false
    };
    
    case "updatetoplevel":
      return { ...state, 
        toplevel: action.payload.toplevel,
        viewStep: action.payload.viewStep,
        orderStep: action.payload.orderStep,
    };
        
    case "backdoor":
      return { ...state, 
        backdoor: action.payload.backdoor,
    };
    case "updateviewstep":
      //console.log("Current view is:"+action.payload.viewStep);
      return { ...state, 
        viewStep: action.payload.viewStep,
    };
    case "changemapview":
        //console.log("changemapview changed to: "+action.payload.bmap);
        return { ...state,
          bmap: action.payload.basemap,
          rviewscale: action.payload.viewscale,
    };
    case "updateviewscale":
        return { ...state, 
          cviewscale: action.payload.cviewscale,
    };
    case "setminzoom":
      return { ...state, 
        minZoomInt: action.payload.minZoomInt,
    };
    case "updatemaparea":
          return { ...state, 
            mapArea: action.payload.mapArea,
            mapAreaUnits: action.payload.mapUnit,
    };
    case "requestviewchange":
      //console.log("requestviewchange: "+action.payload.bmap+" "+action.payload.rviewscale+" "+action.payload.minZoomInt);
      return { ...state, 
        bmap: action.payload.bmap,
        rviewscale: action.payload.rviewscale,
        minZoomInt: action.payload.minZoomInt,
    };
    case "requestviewscale":
        return { ...state, 
          rviewscale: action.payload.rviewscale,
    };
    case "lchange":
      //console.log("Layer changed to: "+action.payload.bmap);
      return { ...state, 
        bmap: action.payload.bmap
    };
    case "actionchange":
      //console.log("actionchange changed to: "+action.payload.action);
        return { ...state,  
          caction:action.payload.action,
    };
    case "setreference":
      return { ...state, 
        projectreference: action.payload.projectreference,
    };
    case "setlocation":
      return { ...state, 
        caddress: action.payload.caddress,
    };
    case "setorderstep":
      //console.log("orderStep changed to: "+action.payload.orderStep);
      return { ...state, 
        orderStep: action.payload.orderStep,
    };
    case "setproduct":
      return { ...state, 
        productSelect: action.payload.productSelect,
    };
    case "setproductlayer": 
      //console.log("setproductlayer to: "+action.payload.productLayerSelect);
      return { ...state, 
        productLayerSelect: action.payload.productLayerSelect,
    };
    case "setproductandlayer":
      //console.log("setproductandlayer to: "+action.payload.productLayerSelect);
      return { ...state, 
        productSelect: action.payload.productSelect,
        productLayerSelect: action.payload.productLayerSelect,
    };
    case "setproductname":
      return { ...state, 
        productName: action.payload.productName,
    };    
    case "setprint":
      //console.log("setprint to: "+action.payload.print);
      return { ...state, 
        print: action.payload.print
      };
    case "setprintframe":
      return { ...state, 
        printframeextent: action.payload.extent
      };
    case "setdataloading":
      return { ...state, 
        dataloading: action.payload.dataloading
      };
    case "setpriceloading":
        return { ...state, 
          priceloading: action.payload.priceloading
    };  
    case "setdbannotation":
      return { ...state, 
        dbAnnotation: action.payload.annotations,
    };
    case "setdata":
      return { ...state, 
        data: action.payload.data
      };
    case "setdatageom":
      return { ...state, 
        datageom: action.payload.datageom
      };
    case "setdatageomcustom":
        return { ...state, 
          hasDataCustomPolygon: action.payload.hasDataCustomPolygon
    };  
    case "requestdatageom":
      return {
        ...state,
        caction: action.payload.action,
        requestdatageom: action.payload.requestdatageom,
    };
    case "setprice":
      return { ...state, 
        cprice: action.payload.price
    };
    case "setpriceapproved":
      return { ...state, 
        cpriceapproved: action.payload.priceapproved
    };
    case "setorderid":
      //console.log("Placed order id: "+action.payload.orderid);
      return { ...state, 
        orderid: action.payload.orderid
    };
    case "isloading":
      return { ...state, 
        isloading: action.payload.isloading
    };
    case "setCurrentAnnotation":
      return { ...state,
        currentAnnotation: action.payload.currentAnnotation
    };
    case "setCurrentStyle":
      //console.log("Current annotation style changed: "+action.payload.currentStyle);
      return { ...state,
        currentStyle: action.payload.currentStyle
    };
    case "firstLoad":
      return { ...state, 
        isloading: action.payload.isloading,
        caddress: action.payload.caddress,
        caction:'addresssearch',
        viewStep: action.payload.viewstep,
        orderStep: action.payload.orderstep,
        hasAddress: true,
        hasToolbar: false,
        addressSource: action.payload.addressSource
    };
    case "savedProjectLoad":
      return { ...state, 
        isProject: action.payload.isProject,
      };
    case "loadSavedProject":
      return { ...state, 
        isloading: true,
        toplevel: action.payload.toplevel,
        viewStep: action.payload.viewStep,
        orderStep: action.payload.orderStep,
        hasAddress: true,
        caddress: action.payload.caddress,
        addressSource: action.payload.addressSource,
        projectreference:action.payload.projectreference,
        mapAnnotations: action.payload.annotations,
        bmap: action.payload.basemap,
        rviewscale: action.payload.viewscale,
        mapextent: action.payload.mapextent,
        productSelect:action.payload.productSelect,
        productLayerSelect: action.payload.productLayer,
    };
    case "loadSavedPrint":
      return { ...state, 
        print: action.payload.print,
        printframeextent: action.payload.extent
    };
    case "loadSavedData":
      return { ...state, 
        data: action.payload.data,
        datageom: action.payload.datageom
    };
    case "hasAddress": //For manual change
      return { ...state, 
        hasAddress: action.payload.hasAddress,
    };
    case "hasToolbar": //For manual change and display of toolbar
    return { ...state, 
      hasToolbar: action.payload.hasToolbar,
    };
    case "hasAnnotationbar": //For manual change and display of toolbar
    return { ...state, 
      hasAnnotationbar: action.payload.hasAnnotationbar,
    };
    case "annotationAction": //Handler for specific annotation bar actions. redo, undo, delete.
    return { ...state, 
      annotationAction: action.payload.annotationAction,
    };
    case "isDataCustom": //For displaying data custom toolbar
    return { ...state, 
      hasToolbar: action.payload.hasToolbar,
      isDataCustom: action.payload.isDataCustom,
    };
    case "setorders":
      return { ...state, 
        orders: action.payload.orders,
    };
    case "setadminreport":
      return { ...state, 
        adminreport: action.payload.reports,
    };
    
    case "setprojects":
      return { ...state, 
        projects: action.payload.projects,
    };
    case "setmapextent":
      return { ...state, 
        mapextent: action.payload.mapextent,
        firstmapload:false,
    };
    case "setmapannotation":
      //console.log("Map annotations saved: "+action.payload.annotations);
      return { ...state, 
        mapAnnotations: action.payload.annotations,
    };
    case "clearmapannotation":
      return { ...state, 
        mapAnnotations: {count:0, annotations:[]},
    };
    case "setpricingmodels":
      return { ...state, 
        pricingmodels: action.payload.pricingmodels,
    };
    case "setcompanies":
      return { ...state, 
        companies: action.payload.companies,
    };
    case "setcompany":
      return { ...state, 
        company: action.payload.company,
    };
    case "setcompanyusers":
      return { ...state, 
        companyUsers: action.payload.users,
    };
    case "setdtmparents":
      return { ...state, 
        dtmParents: action.payload.companyname,
    };
    case "setmeasurementunits":
      return { ...state, 
        measurementUnits: action.payload.units,
    };
    case "setmeasurement":
      return { ...state, 
        measurements: action.payload.measurements,
    };
    case "end":  //Ends the user session and resets everything.
      return { ...state, 
        toplevel: 'login',
        viewStep: 'login', 
        orderStep:'addressSearch',
        cviewscale: 10000,
        rviewscale: 10000, 
        minZoomInt: 1, 
        caction: '',
        annotationAction: '',
        productSelect:'', 
        productLayerSelect:'', 
        productName:'', 
        cprice: 0, 
        orderid: 0, 
        cpriceapproved: false, 
        bmap: 'os10k', 
        addressSource: '',
        caddress: { name: 'Maps from NMC', value: {type: 'coordinates',x: 525651,y: 168117 }}, 
        mapArea: '', 
        mapAreaUnits: 'ha', 
        projectreference:'',
        print:{},
        printframeextent:[],
        data:{}, 
        datageom: { geometry: 0, area: 0}, 
        firstmapload:true,
        dataloading:true,
        priceloading:false,
        isloading:false, 
        hasAddress:false, 
        hasProduct:false, 
        hasToolbar: false,
        orders:{},
        adminreport:{},
        pricingmodel:{},
        companies:{},
        company:{},
        companyUsers:{},
        backdoor:false
    };
    default:
      console.log("Error action type: "+ action.type);
      throw new Error();
  }
};

export const ActivityContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ActivityContext.Provider value={[state, dispatch]}>
      {props.children}
    </ActivityContext.Provider>
  );
};