
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import RecordSaveProject from "../../functions/recordSaveProject";

import { ActivityContext } from "../../context/activity-context";
import { SessionContext } from "../../context/session-context";


const useStyles = makeStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    dialogTitle:{
      color: '#447095',
    },
    textField: {
      width: 400
    },
    mainButton: {
      backgroundColor:'#2D2F88',
      color: '#FFF',
      '&:hover': {
        color: '#F6A036',
      },
      width: 150
    },

  });
  

export default function SaveForm(props) {
    const classes = useStyles();
    const [aState, aDispatch] = useContext(ActivityContext);
    const [sState] = useContext(SessionContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [process, setProcess] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [notification, setNotification] = useState('');
    const [saveName, setSaveName] = useState('');
    const [isdisabled, setIsdisabled] = useState(true);

    /**
     * Handles the click of login.
     */
    const handleSubmit = (event) => {
      setLoading(true);
      setProcess(true);
    };
  
    useEffect(() => {
        if (props.open) { 
          aDispatch({ type: "actionchange", payload: { action: 'doSavedProject' } });
          setOpen(true); 
          setSaveName(aState.projectreference);
        }
    }, [props.open]);
    
  
    /**
     * Closes the form.
     */
    const handleClose = () => {
      props.onClose(true);
      setOpen(false);
    };

    useEffect(() => {

      if (saveName.length > 0 ) { setIsdisabled(false); } else { setIsdisabled(true); }

    }, [saveName]);

    const handleSaveView= () => {
      RecordSaveProject(saveName, aState, sState);
      props.onClose(true);
      setOpen(false);
      aDispatch({
        type: "updatetoplevel",
        payload: { toplevel: 'project', viewStep: 'list', orderStep:''}
      });
    };

    const handleSaveContinue= () => {
      RecordSaveProject(saveName, aState, sState);
      props.onClose(true);
      setOpen(false);
    };
  
    return (
      <Dialog open={open} onClose={handleClose}>
          <DialogContent>
                  <Box className={classes.dialogTitle} fontWeight="500" fontSize={16} >Save this map as a project</Box>
            </DialogContent>
            <DialogContent>
              <TextField className={classes.textField} name="saveName" label="Name" variant="outlined" size="small" margin="dense" value={saveName} onChange={e => setSaveName(e.target.value)} required />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveView} disabled={isdisabled} className={classes.mainButton} variant="contained" style={{ fontSize: 10 }}>Save and view all</Button>
                <Button onClick={handleSaveContinue} disabled={isdisabled} className={classes.mainButton} variant="contained" style={{ fontSize: 10 }}>Save and continue</Button>
                <Button onClick={handleClose} className={classes.mainButton} variant="contained" style={{ fontSize: 10 }}>Cancel</Button>
            </DialogActions>
      </Dialog>
    )
  };

  SaveForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };