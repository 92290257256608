/**
 * Gets and returns the next fixed zoom scale  
 */

export default function getNextScale(scale){

    const currentScale = parseInt(scale);
    if (currentScale <= 200) { return 200; }
    if (currentScale <= 500) { return 500; }
    if (currentScale <= 1250) { return 1250; }
    if (currentScale <= 2500) { return 2500; }
    if (currentScale <= 5000) { return 5000; }
    if (currentScale <= 10000) { return 10000; }
    if (currentScale <= 15000) { return 15000; }
    if (currentScale <= 25000) { return 25000; }
    if (currentScale <= 30000) { return 30000; }
    if (currentScale <= 50000) { return 50000; }
    if (currentScale <= 150000) { return 150000; }
    return 250000;
  }

