import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import {ReactComponent as MinusIcon} from '../../../assets/dark_icons/iconmonstr-minus-2 1.svg';

import Tooltip from '@material-ui/core/Tooltip';

import { ActivityContext } from "../../../context/activity-context";

export default function MenuButton(props) {
  //console.log("props.top:"+props.top);
  const useStyles = makeStyles({
    root: {
      paddingBottom: 5,
    },
    menuButton: {
      color: '#000',
      backgroundColor: '#F9F9FA',
      '&:hover': {
        color: 'white',
        textDecoration: 'none',
		    backgroundColor: '#2D2F88',
      },
      '&.Mui-selected': {
        color: 'white',
        textDecoration: 'none',
        backgroundColor: '#2D2F88',
        border: '1px solid #FFFFFF',
        borderRadius: '0 0 2px 2px',
      },

      display: 'block',
      margin: 1,
      fontSize: '1.14em',
      fontWeight: 'bold',
      textDecoration: 'none',
      textAlign: 'center',
      height: '1.375em',
      width: '1.375em',
      lineHeight: '.4em',
      border: '1px solid #B2B2BC',
      borderRadius: '0 0 2px 2px',
      minWidth: '1.375em',
      padding: 0,
    },
    minusIcon:{
      fill: '#000',
      '&:hover': {
        fill: '#FFF',
      },
      '&.Mui-selected': {
        fill: '#FFF',
      },
      width: 16,
      height: 16
    },

  });

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [action, setAction] = useState(null);
  const [zoomTool, setZoomTool] = useState(false);
  
  const setZoom = () =>{
    aDispatch({
      type: "actionchange",
      payload: { action: 'map_zoomout'}
    });  
  };

  return <div className={classes.root}>
          <Tooltip title="Zoomout">
            <Button className={classes.menuButton} onClick={() => { setZoom(); }} variant="contained">
                <MinusIcon className={classes.minusIcon}/>
            </Button>
          </Tooltip>
        </div>;
}