/**
 * Records the Order.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function logoutuser(userid, ident, sdispatch, adispatch) {

    const abortController = new AbortController();
  
    const request = {   
        userid:userid,
        ident:ident
    };

    let mounted = true;
    (async () => {
        const res = await fetch(process.env.REACT_APP_NMC_API+'/users/logout', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(request),
        signal: abortController.signal,
        });
        const data = await res.json();
        if (mounted) {
            if (data.status === "success"){
                console.log("Logout called");
                sdispatch({ type: "end", payload: { } });
                adispatch({ type: "end", payload: { } });
            }
        }
    })();
    const cleanup = () => {
        mounted = false;
        abortController.abort();
    };
    return cleanup;
    }
