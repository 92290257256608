
import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ReactLoading from 'react-loading';

import { SessionContext } from "../../context/session-context";
import { UserContext } from "../../context/user-context";


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    textField: {
      width: '80%',
      maxWidth:400,
      paddingBottom:20
    },
    loginButton: {
        marginTop: 30,
        height: 47,
        width:320,
        color: '#FFFFFF',
        backgroundColor: '#2D2F88',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: '#F6A036',
          },
    },
    processingButton: {
        marginTop: 30,
        height: 47,
        width:320,
        color: '#FFFFFF',
        backgroundColor: '#2D2F88',
      },
    resetButton: {
      marginTop: 30,
      color: '#000000',
      '&:hover': {
        color: '#F6A036',
      },
    },
    loadingImg: {
        zIndex:10
      }
  });
  

export default function UserLoginForm(props) {
    const classes = useStyles();
  
    const [state, dispatch] = useContext(SessionContext);
    const [uState, uDispatch] = useContext(UserContext);
    const [userId, setUserId] = useState(''); //suiwin.hui@findmaps.co.uk
    const [password, setPassword] = useState(''); //CH33se$2
    const [loading, setLoading] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [disabled, setDisabled] = useState(true);


    const loginButtonStatus = () => {
      if (userId.length > 1 && password.length > 1) { setDisabled(false); } else { setDisabled(true); }
    }

    useEffect(() => {
      loginButtonStatus();
    }, [userId, password]);

    const handleLogin = (event) => {

    };

    const handleReset = (event) => {

    };

    return (
        <div className={classes.root}>
            <Box component="span" display="block">
                <TextField className={classes.textField} error={hasError} type="email" name="username" label="Username" variant="outlined" size="small" margin="dense" value={userId} onChange={e => setUserId(e.target.value)} required />
            </Box>
            <Box component="span" display="block">
                <TextField className={classes.textField} error={hasError} type="password" name="password" label="Password" size="small" margin="dense" variant="outlined" value={password} onChange={e => setPassword(e.target.value)} required />
            </Box>
            {hasError ? ( <Box component="span" display="block"><Typography className={classes.errorTxt}>username or password.</Typography></Box>) : ( '' )}
            <Box component="span" display="block">
            {isProcess? ( 
                <Button className={classes.processingButton} variant="contained" >
                    <Box fontWeight="fontWeightBold" fontSize={16} ><ReactLoading className={classes.loadingImg} type={'spin'} color={'#FFF'} height={30} width={30}/></Box>
                </Button>
              ) : ( 
                <Button className={classes.loginButton} variant="contained" onClick={handleLogin} disabled={disabled}>
                  <Box fontWeight="fontWeightBold" fontSize={16} >Login with existing account</Box>
                </Button>
                 )}
            </Box>
            <Box component="span" display="block">
                <Button className={classes.resetButton} onClick={handleReset} disabled={true} style={{ fontSize: 16 }}>Forgotten Password</Button>
            </Box>
        </div>
    )
  };
