/**
 * Storage for user details and company preference.
 */

import React, { useReducer, createContext } from "react";

export const UserContext = createContext();

const initialState = {
  loaded: false,
  userid: 0,


};


const reducer = (state, action) => {
  switch (action.type) {
    case "create":
      console.log('Create payload:'+JSON.stringify(action.payload));
      return { ...state, 
        loaded: true,
        userid: action.payload.userid,
      };
      case "end":
        console.log('end payload:'+JSON.stringify(action.payload));
        return { ...state, 
          loaded: false,
          userid: 0,
          aid: 0,
        };
      
    default:
      throw new Error();
  }
};

export const UserContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {props.children}
    </UserContext.Provider>
  );
};