import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PricingModelPanel from "./pricingmodelPanel";
import NewClientPanel from "./newCompanyPanel";
import CompanyDetailsPanel from "./companyDetailsPanel";
import AdminReportsPanel from "./adminReportsPanel";
import RoyaltyDetailsPanel from "./royaltyDetailPanel";
import SystemNotificationPanel from "./systemStatusPanel";

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    //height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel:{
    width:'100%',
  }
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Dashboard" {...a11yProps(0)} />
        <Tab label="Add Client" {...a11yProps(1)} />
        <Tab label="Companies" {...a11yProps(2)} />
        <Tab label="Pricing Model" {...a11yProps(3)} />
        <Tab label="Admin Reports" {...a11yProps(4)} />
        <Tab label="Royalty Charges" {...a11yProps(5)} />
        <Tab label="System Notification" {...a11yProps(6)} />
      </Tabs>
      <TabPanel className={classes.tabPanel} value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <NewClientPanel></NewClientPanel>
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={2}>
        <CompanyDetailsPanel></CompanyDetailsPanel>
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={3}>
        <PricingModelPanel></PricingModelPanel>
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={4}>
        <AdminReportsPanel></AdminReportsPanel>
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={5}>
        <RoyaltyDetailsPanel></RoyaltyDetailsPanel>
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={6}>
        <SystemNotificationPanel></SystemNotificationPanel>
      </TabPanel>
      
    </div>
  )};