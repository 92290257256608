/**
 * Records the Order.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function recordReportOrder(sState, report, businessid, fromDate, toDate, dispatch) {

    console.log("Report with id. "+report.reportid+" ordered.");
    const abortController = new AbortController();

    const startDate = fromDate.getFullYear()+'-'+ ('0'+(fromDate.getMonth()+ 1)).slice(-2) +'-'+('0'+fromDate.getDate()).slice(-2);
    const endDate = toDate.getFullYear()+'-'+ ('0'+(toDate.getMonth()+ 1)).slice(-2) +'-'+('0'+toDate.getDate()).slice(-2);

    const request = {   
        userid:sState.userid,
        accountid:sState.aid,
        reportid: report.reportid,
        reportname: report.name,
        sessionid: sState.ident,
        dateFrom: startDate,
        dateTo: endDate,
        businessid: businessid
    };

    let mounted = true;
    (async () => {  
        const res = await fetch(process.env.REACT_APP_NMC_API+'/reports/report', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(request),
        signal: abortController.signal,
        }).catch(handleError);
        const data = await res.json();
        if (mounted) {
          let oid = 0;
          if (data.status === "success"){ oid = data.orderid; } else { oid = -1; }
          dispatch({
            type: "setorderid",
            payload: { orderid: oid}
          });
        }
        const cleanup = () => {
            mounted = false;
            abortController.abort();
        };
        return cleanup;
    
    })();

}
