import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Topbar from './createComponents/Topbar';
import UserPanel from './accountComponents/userPanel';
import ManagerPanel from './accountComponents/managerPanel';
import NmcPanel from './accountComponents/nmcPanel';
import SuperPanel from './accountComponents/superPanel';


import { SessionContext } from "../context/session-context";
import { ActivityContext } from "../context/activity-context";

const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    }

});
  
export default function AccountsInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [isUser, setIsUser] = useState(true);//default: true
    const [isManager, setIsManager] = useState(false);//default: true
    const [isNMC, setIsNMC] = useState(false);//default: true
    const [isSuper, setIsSuper] = useState(false);//default: true

    useEffect(() => {
        switch(sState.user.userutype){
            case "admin":
                setIsUser(false);
                setIsManager(false);
                setIsNMC(false);
                setIsSuper(true);
                
            break;
            default:
                setIsSuper(false);
                setIsManager(false);
                setIsNMC(false);
                setIsUser(true);
            break;


        }
      },[]);

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Topbar />
                </Grid>
                <Grid item xs={12}>
                <div >
                    { isUser ? <UserPanel /> : '' }
                    { isSuper ? <SuperPanel /> : '' }
                </div>
                </Grid>
            </Grid>
        </div>
    )
  };