/**
 * Returns the minX, minY, maxX, maxY from polygon.
 * 
 */
function getPolygonExtent(polygon) {

    let coords = polygon.trim().replace("POLYGON((", "");
    coords = coords.replace("))", "");
    //console.log("polygon coords: "+coords);
    let coordsArray = coords.split(",");
    const arrayLength = coordsArray.length;

    let minX = 0;
    let maxX = 0;
    let minY = 0;
    let maxY = 0;

    for(let i = 0 ; i < arrayLength; i++) {
        let points = coordsArray[i].split(" ");
        if (i === 0){
            minX = maxX = parseFloat(points[0]);
            minY = maxY = parseFloat(points[1]);
        } else {
            if (parseFloat(points[0]) < parseFloat(minX)) { minX = parseFloat(points[0]); }
            if (parseFloat(points[0]) > parseFloat(maxX)) { maxX = parseFloat(points[0]); }
            if (parseFloat(points[1]) < parseFloat(minY)) { minY = parseFloat(points[1]); }
            if (parseFloat(points[1]) > parseFloat(maxY)) { maxY = parseFloat(points[1]); }
        }
    }

    const extent = {
        minX: minX,
        maxX: maxX,
        minY: minY,
        maxY: maxY,
    };

    //console.log("Calculated extent:"+minX+" "+minY+","+maxX+" "+maxY);

    return extent;
  }
  export default getPolygonExtent;

