import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ToggleButton from '@material-ui/lab/ToggleButton';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { ActivityContext } from "../../context/activity-context";
import { SessionContext } from "../../context/session-context";

import {ReactComponent as PrintIcon} from '../../assets/dark_icons/icon_print.svg';
import {ReactComponent as DataIcon} from '../../assets/dark_icons/icon_data.svg';
//import { toUserExtent } from 'ol/proj';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 270,
    height: '80%',
    //borderLeft: '1px solid #D2D2D8',
  },
  productTitle: {
    paddingLeft:16,
    color: '#5C5D6B',
  },
  productContainer: {
    //width: 270,
    display:"flex",
    flexDirection:"row",
    height:65,
    paddingLeft:16
  },
  productButton: {
    width: 118,
    height:65,
    backgroundColor:"#FFF",
    '&:hover': {
        color: '#C1DAE9'
    },
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: '#C1DAE9',
        color: '#3D3E4F',
    },
    '&.MuiToggleButton-root':{
      color: '#3D3E4F',
    }
  },
  LabelContainer:{
    alignItems: 'center',
  },
  iconContainer:{
    width:'100%',
  },
  buttonLabel:{
    width:'100%',
  },
  printIcon:{
    width:20,
    height:24
  },
  dataIcon:{
    width:16,
    height:24
  },
  productListContainer:{
    padding: 16,
    maxHeight: 350,
    overflowY: 'auto',
  },
  productItem:{
      color: '#000',
      backgroundColor: '#FFF',
      '&.MuiListItem-root.Mui-selected': {
        backgroundColor: '#C1DAE9',
      },
      border: '1px solid #EDEDEF',
  },
  listItemText:{
    fontSize:12,//Insert your required size
  },

}));

const plotProducts = [
  { layer: 'OS Plan Black / White', value: 'osmmbw'},
  { layer: 'OS Plan Colour', value: 'osmm'},
  { layer: '1:10,000', value: 'os10k'},
  { layer: '1:25,000', value: 'os25k'},
  { layer: '1:50,000', value: 'os50k'},
  { layer: '1:250,000', value: 'os250k'},
];
//{ layer: 'Imagery', value: 'aerial'},

const dataProducts = [
  { layer: 'OS Plan (CAD) Standard 9 Layers', value: 'osmm9'},
  { layer: 'OS Plan (CAD) Enhanced 55 Layers', value: 'osmm55'},
  { layer: '1:10,000 Scale Raster', value: 'os10k'},
  { layer: '1:25,000 Scale Raster', value: 'os25k'},
  { layer: '1:50,000 Scale Raster', value: 'os50k'},
  { layer: '1:250,000 Scale Raster', value: 'os250k'},
];

export default function ProductPanel(props) {
  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [sState] = useContext(SessionContext);
  const [orderType, setOrderType] = useState('plot'); //'data'
  const [product, setProduct] = useState('plot');
  const [isPlot, setIsPlot] = useState(true);
  const [isData, setIsData] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [productList, setProductList] = useState([]);


  useEffect(() => {
    
    if (!aState.isProject){
      //console.log("product changed");
      let selProduct = '';
      if (product === 'plot'){ 
        selProduct = sState.default.dprintproduct;
        setProductList(plotProducts);
        setSelectedProduct(selProduct);
        setIsPlot(true);
        setIsData(false);
      } else {
        selProduct = sState.default.ddataproduct;
        setProductList(dataProducts);
        setSelectedProduct(selProduct);
        setIsPlot(false);
        setIsData(true);
      }
      //Store the change.
      if (aState.productSelect !== product){
        aDispatch({
          type: "setproductandlayer",
          payload: { productSelect: product, productLayerSelect: selProduct}
        });
      }
    }

  },[product])

  //Handles the selection of new product layer. stores the label and value.
  const handleListItemClick = (event, v) => {
    setSelectedProduct(v);
    if (aState.productLayerSelect !== v){
      //console.log("product changed to "+v);
      aDispatch({
        type: "setproductlayer",
        payload: { productLayerSelect: v}
      });
    }
  };

  return (
    <div className={classes.root}>
      <Box p={1} className={classes.productTitle} fontWeight={500} fontSize={12}>PRODUCT TYPE</Box>
      <div className={classes.productContainer}>
        <ToggleButton value={'plot'} className={classes.productButton} selected={isPlot} onChange={e => setProduct('plot')}>
            <div className={classes.LabelContainer}>
                <Box className={classes.iconContainer} flexGrow={1}><PrintIcon className={classes.printIcon} /></Box>
                <Box className={classes.buttonLabel} flexGrow={1} fontSize={12}>Plot map</Box>
            </div>
        </ToggleButton>
        <ToggleButton value={'data'} className={classes.productButton} selected={isData} onChange={e => setProduct('data')}>
          <div className={classes.LabelContainer}>
            <Box className={classes.iconContainer} flexGrow={1}><DataIcon className={classes.dataIcon} /></Box>
            <Box className={classes.buttonLabel} flexGrow={1} fontSize={12}>Data map</Box>
            </div>
        </ToggleButton>
      </div>
      <div className={classes.productListContainer}>
        <List component="nav" aria-label="Product List">
              {productList.map((item, i) => (
                    <React.Fragment key={i}>
                  <ListItem 
                      key={i}
                      button
                      className={classes.productItem}
                      selected={selectedProduct === item.value}
                      onClick={(event) => handleListItemClick(event, item.value)}
                      >
                      <ListItemText primary={item.layer} classes={{primary:classes.listItemText}}/>
                  </ListItem>
                  <Divider />
                  </React.Fragment>
              ))}
        </List>
      </div>
    </div>
  );
}