
import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ReactLoading from 'react-loading';

import { SessionContext } from "../../context/session-context";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    textField: {
      width: '80%',
      maxWidth:400,
      paddingBottom:20
    },
    loginButton: {
        marginTop: 30,
        height: 47,
        width:320,
        color: '#FFFFFF',
        backgroundColor: '#2D2F88',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: '#F6A036',
          },
    },
    processingButton: {
      marginTop: 30,
      height: 47,
      width:320,
      color: '#FFFFFF',
      backgroundColor: '#2D2F88',
    },
    resetButton: {
      marginTop: 30,
      color: '#000000',
      '&:hover': {
        color: '#F6A036',
      },
    },
    forgottenContainer:{
      marginTop: 30,
    },
    loadingImg: {
      zIndex:10
    }, 
    errorTxt: {
      color: '#FF0000',
    }
  });
  

export default function BusinessLoginForm(props) {
    const classes = useStyles();
  
    const [state, dispatch] = useContext(SessionContext);
    const [businessId, setBusinessId] = useState(0);
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState(''); 
    const [loading, setLoading] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [errMessage, setErrMessage] = useState('');


    const loginButtonStatus = () => {
      if (parseInt(businessId) > 0 && userId.length > 1 && password.length > 1) { setDisabled(false); } else { setDisabled(true); }
    }

    useEffect(() => {
      loginButtonStatus();
    }, [businessId, userId, password]);

    /**
     * Handles the user login.
     * Request made to backend.
     */
    const handleLogin = (event) => {
      setIsProcess(true);
      const abortController = new AbortController();
      const request = {   
        businessid:businessId,
        name:userId,
        password:password
      };

      let mounted = true;
      (async () => {
        const res = await fetch(process.env.REACT_APP_NMC_API+'/users/blogin', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(request),
          signal: abortController.signal,
        });
        const data = await res.json();
        if (mounted) {
          if (data.status === "success"){
          dispatch({
            type: "create",
            payload: { userid: data.uid, aid: data.aid, isActive: true, isRegistered: true, ident: data.ident }
          });
          setErrMessage('');
          setHasError(false);
          } else {
            //Login error.
            switch (data.status){
              case "error_company":
                setErrMessage('This account has been disabled.');
              break;
              case "error_details":
                setErrMessage('Invalid business id, username or password.');
              break;
              case "error_status":
                setErrMessage('This account has been disabled.');
              break;
              case "error":
                setErrMessage('An error has occured during login.');
              break;
            }
            setHasError(true);
          }
          setIsProcess(false);
        }
        const cleanup = () => {
          mounted = false;
          abortController.abort();
       };
       return cleanup;
      })();

    };

    const handleEnterSubmit = (e) => {
      if (e.key === 'Enter') {
          if (!disabled){
            handleLogin();
          }
      }
    };

    const handleReset = (event) => {

    };

    const numberOnly = (event) => {
      const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
        setBusinessId(event.target.value);
     }
    };
    /*
    <Box component="span" display="block">
    <Button className={classes.resetButton} onClick={handleReset} disabled={true} style={{ fontSize: 16 }}>Forgotten Password</Button>
    </Box>
    */

    return (
        <div className={classes.root}>
            <Box component="span" display="block">
                <TextField className={classes.textField} error={hasError} name="businessid" label="Business ID" variant="outlined" size="small" margin="dense" value={businessId} onChange={numberOnly} required />
            </Box>
            <Box component="span" display="block">
                <TextField className={classes.textField} error={hasError} name="username" label="Username" variant="outlined" size="small" margin="dense" value={userId} onChange={e => setUserId(e.target.value)} onKeyDown={handleEnterSubmit} required />
            </Box>
            <Box component="span" display="block">
                <TextField className={classes.textField} error={hasError} type="password" name="password" label="Password" size="small" margin="dense" variant="outlined" value={password} onChange={e => setPassword(e.target.value)} onKeyDown={handleEnterSubmit} required />
            </Box>
            {hasError ? ( <Box component="span" display="block"><Typography className={classes.errorTxt}>{errMessage}</Typography></Box>) : ( '' )}
            
            <Box component="span" display="block">
            {isProcess? ( 
                <Button className={classes.processingButton} variant="contained" >
                    <Box fontWeight="fontWeightBold" fontSize={16} ><ReactLoading className={classes.loadingImg} type={'spin'} color={'#FFF'} height={30} width={30}/></Box>
                </Button>
              ) : ( 
                <Button className={classes.loginButton} variant="contained" onClick={handleLogin} disabled={disabled}>
                  <Box fontWeight="fontWeightBold" fontSize={16} >Login with existing account</Box>
                </Button>
            )}
            </Box>
            <Box component="span" display="block" className={classes.forgottenContainer}>
              <Box fontSize={16} >Forgotten Password?</Box>
              <Box fontSize={16} >Please email helpdesk@mapsnmc.co.uk or call 01707 263747 (during office hours)</Box>
            </Box>
        </div>
    )
  };
