import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import {ReactComponent as CloseIcon} from '../../assets/dark_icons/iconmonstr-x-mark-2 1.svg';
import {ReactComponent as RectangleIcon} from '../../assets/dark_icons/icon-rectangle-square.svg';
import {ReactComponent as PolygonIcon} from '../../assets/dark_icons/icon-polygon-triangle.svg';

import {ReactComponent as TrashIcon} from '../../assets/dark_icons/iconmonstr-trash-can-16 1.svg';
import {ReactComponent as CustomAreaIcon} from '../../assets/dark_icons/custom-area.svg';

import getGeomExtent from "../../functions/getExtentFromOLPolygon";

import { ActivityContext } from "../../context/activity-context";
import { SessionContext } from "../../context/session-context";



export default function ToolsPanel(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
            width: 270,
            height: props.height - 50,
            //borderLeft: '1px solid #D2D2D8',
            overflowY: 'auto',
            backgroundColor: '#3D3E4F',
            display:props.display,
            '& .MuiTypography-colorTextSecondary':{
                color: '#FFF',
                fontSize:12,
            },
            '& .MuiInputAdornment-positionEnd':{
                marginLeft:3
            }
        },
        toolButton: {
            width: 120,
            height:65,
            backgroundColor: '#5C5D6B',
            color:'#FFF',
            border: '1px solid #3D3E4F',
            fill:'#FFF',
            '&:hover': {
                color: '#3D3E4F',
                backgroundColor: '#F9F9FA',
                fill: '#3D3E4F',
            },
        },
        smToolButton: {
            width: 80,
            height:65,
            backgroundColor: '#5C5D6B',
            color:'#FFF',
            border: '1px solid #3D3E4F',
            fill:'#FFF',
            '&:hover': {
                color: '#3D3E4F',
                backgroundColor: '#F9F9FA',
                fill: '#3D3E4F',
            },
            '&.MuiToggleButton-root.Mui-selected': {
                backgroundColor: '#F9F9FA',
                color: '#3D3E4F',
                fill: '#3D3E4F',
            },
        },
        smToolButtonHold: {
            width: 78,
            height:63,
            backgroundColor: '#5C5D6B',
            color:'#FFF',
            border: '1px solid #3D3E4F',
            fill:'#FFF',
        },
        LabelContainer:{
            alignItems: 'center',

        },
        iconContainer:{
            width:'100%',
        },
        buttonLabel:{
            color:'#FFF',
            width:'100%',
            '&:hover': {
                color: '#3D3E4F',
            },
            '&.MuiToggleButton-root.Mui-selected': {
                color: '#3D3E4F',
            },
        },
        closeLabel:{
            fill:'#FFF',
            paddingRight: '0px',
            minWidth:'0px',    
            color:'#FFF',   
            '&:hover': {
                color: '#C1DAE9',
            }     
        },
        iconClose:{
            height:16,
            width:16,
            fill:'inherit',
            '&:hover': {
                fill: '#C1DAE9',
            }
        },
        icon:{
            fill:'inherit',
            '&:hover': {
                fill: '#3D3E4F',
            },
            '&.MuiToggleButton-root.Mui-selected': {
                fill: '#3D3E4F',
            },
        },
        miniIcon:{
            width:16,
            height:16
        },
        lgButtonContainer:{
            marginLeft:8,
            '& .MuiToggleButton-root.Mui-selected': {
                color: '#3D3E4F',
                fill: '#3D3E4F',
                backgroundColor: '#C1DAE9'
            }
        },
        parameterInputLabel:{
            color: '#FFF',
            marginBottom:2,
        },
        parameterInputField:{
            marginTop:0,
            height:35,
            color: '#FFF',
            border: '1px solid #5C5D6B',
            '& .MuiOutlinedInput-adornedEnd' : {
				paddingRight: 5,
			},
        },
        parameterInputFont:{
            fontSize:12,
            color: '#FFF',
            marginTop:2,
            textAlign: 'end'
        },
        divider:{
            color: '#FFF',
            marginTop:20,
            marginBottom:20,
            backgroundColor: '#5C5D6B',
        }, 
        sectionContainer:{
            paddingTop:10,
        },
        sectionLabel:{
            fontSize:12,
            fontWeight:500,
            textTransform: 'uppercase',
            marginBottom:10,
            marginLeft: 17,
            color:'#B2B2BC',
        },
        sectionSubLabel:{
            fontSize:12,
            fontWeight:500,
            textTransform: 'uppercase',
            marginTop:20,
            marginLeft: 17,
            color:'#B2B2BC',
        },
        sectionItemLabel:{
            color:'#FFF',
            paddingTop:3,
        }, 
        areaIconContainer:{
            marginLeft: 17,
            width:30,
            marginBottom:10,
        },
        widthHeightLabel:{
            color:'#FFF',
        }, 
        textItemFont:{
            fontSize:12,
            color: '#FFF',
        },
        textAreaInput:{
            '& .MuiOutlinedInput-root':{
                border: '1px solid #5C5D6B',
                color: '#FFF',
            },
        },
        areaInputContainer:{
            marginLeft: 17,
            width:90,
        },
        createButton:{
            height:22,
            marginTop: 10,
            backgroundColor:'#878897',
            color: '#FFF',
            '&:hover': {
              color: '#5C5D6B',
            },
          },
          areacreateBtnContainer:{
            paddingRight:48,
            paddingBottom:10,
          }
        

          
    }));

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [sState] = useContext(SessionContext);
  const [isExport, setIsExport] = useState(aState.isDataCustom);
  const [drawnType, setDrawnType] = useState('');

  const [isDoRectangle, setIsDoRectangle] = useState(false);
  const [isDoPolygon, setIsDoPolygon] = useState(false);
  const [isDoDelete, setIsDoDelete] = useState(false);
  const [customRectDisabled, setCustomRectDisabled] = useState(false);
  const [isManual, setIsManual] = useState(false);

  const [areaWidth, setAreaWidth] = useState(0);
  const [areaHeight, setAreaHeight] = useState(0);

  const [areaMinX, setAreaMinX] = useState(0);
  const [areaMinY, setAreaMinY] = useState(0);
  const [areaMaxX, setAreaMaxX] = useState(0);
  const [areaMaxY, setAreaMaxY] = useState(0);


  useEffect(() => {
    setIsExport(aState.isDataCustom);
  },[aState.isDataCustom])

  //Closes the toolbar.
  const doClose = (event) => {
    aDispatch({ type: "isDataCustom", payload: { hasToolbar: false, isDataCustom: false } });
  }

  useEffect(() => {
    //Data area draw complete.
    if (aState.caction == 'dataorder_draw_complete'){
        aDispatch({ type: "setdatageomcustom", payload: { hasDataCustomPolygon: true } });
        setIsDoRectangle(false);
        setIsDoPolygon(false);
    }
  },[aState.caction])

  //Listerns for draw rectangle
  useEffect(() => {
    if (isExport && isDoRectangle){
        setDrawnType('rectangle');
        setIsDoPolygon(false);
        aDispatch({ type: "actionchange", payload: { action: 'dataorder_draw_rect' } });
        setCustomRectDisabled(false);
    } else {
        setCustomRectDisabled(true);
    }
  },[isDoRectangle])

    //Listerns for draw polygon
    useEffect(() => {
        if (isExport && isDoPolygon){
            setDrawnType('polygon');
            setIsDoRectangle(false);
            aDispatch({ type: "actionchange", payload: { action: 'dataorder_draw_poly' } });
            setCustomRectDisabled(true);
        }
      },[isDoPolygon])

    //Listerns for delete
    useEffect(() => {
        if (isExport && aState.hasDataCustomPolygon){
            setDrawnType('');
            aDispatch({ type: "setdatageomcustom", payload: { hasDataCustomPolygon: false } });
            aDispatch({ type: "actionchange", payload: { action: 'dataorder_draw_clear' } });
            setCustomRectDisabled(true);

            setAreaWidth(0);
            setAreaHeight(0);
            setAreaMinX(0);
            setAreaMinY(0);
            setAreaMaxX(0);
            setAreaMaxY(0);
        }
    },[isDoDelete])

  /**
   * Listens for change of data geometry for rectangle objects only.
  */
    useEffect(() => {
        setIsManual(false);
        if (isExport && aState.isDataCustom && drawnType === 'rectangle'){
            if (aState.datageom.area > 0){
                const poly = getGeomExtent(aState.datageom.geometry);
                setCustomRectDisabled(false);
                setAreaWidth(poly.width.toFixed(2));
                setAreaHeight(poly.height.toFixed(2));
                setAreaMinX(poly.minX.toFixed(2));
                setAreaMinY(poly.minY.toFixed(2));
                setAreaMaxX(poly.maxX.toFixed(2));
                setAreaMaxY(poly.maxY.toFixed(2));
            }
        } else {
            setCustomRectDisabled(true);
            setAreaWidth(0);
            setAreaHeight(0);
            setAreaMinX(0);
            setAreaMinY(0);
            setAreaMaxX(0);
            setAreaMaxY(0);
        }
        if (aState.isProject){ 
            if (typeof aState.datageom.geometry !== 'undefined'){
                const poly = getGeomExtent(aState.datageom.geometry);
                setAreaWidth(poly.width.toFixed(2));
                setAreaHeight(poly.height.toFixed(2));
                setAreaMinX(poly.minX.toFixed(2));
                setAreaMinY(poly.minY.toFixed(2));
                setAreaMaxX(poly.maxX.toFixed(2));
                setAreaMaxY(poly.maxY.toFixed(2));
            }

        }
    },[aState.datageom])


    const handleAreaWidth = (event) => {
        const re = /^[0-9.\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          if (event.target.value === '') { event.target.value = 0; }
            const v = parseFloat(event.target.value);
            setAreaWidth(v);
            setIsManual(true);
        }
    }

    const handleAreaHeight = (event) => {
        const re = /^[0-9.\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          if (event.target.value === '') { event.target.value = 0; }
            const v = parseFloat(event.target.value);
            setAreaHeight(v);
            setIsManual(true);
        }
    }

    const handleAreaMinX = (event) => {
        const re = /^[0-9.\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          if (event.target.value === '') { event.target.value = 0; }
            const v = parseFloat(event.target.value);
            setAreaMinX(v);
            setIsManual(true);
        }
    }

    const handleAreaMaxX = (event) => {
        const re = /^[0-9.\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          if (event.target.value === '') { event.target.value = 0; }
            const v = parseFloat(event.target.value);
            setAreaMaxX(v);
            setIsManual(true);
        }
    }

    const handleAreaMinY = (event) => {
        const re = /^[0-9.\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          if (event.target.value === '') { event.target.value = 0; }
            const v = parseFloat(event.target.value);
            setAreaMinY(v);
            setIsManual(true);
        }
    }

    const handleAreaMaxY = (event) => {
        const re = /^[0-9.\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          if (event.target.value === '') { event.target.value = 0; }
            const v = parseFloat(event.target.value);
            setAreaMaxY(v);
            setIsManual(true);
        }
    }

    /**
     * Event actions when the height and width is manually adjusted.
     */
    useEffect(() => {
        
        if (isManual){
            //console.log("mapextent: "+aState.mapextent);
            const mapext = aState.mapextent;
            const cx = parseFloat(mapext[0]+((mapext[2] - mapext[0])/2));
            const cy = parseFloat(mapext[1]+((mapext[3]- mapext[1])/2));
            //const cx = parseFloat(areaMinX) + ((parseFloat(areaMaxX) - parseFloat(areaMinX))/2);
            //const cy = parseFloat(areaMinY) + ((parseFloat(areaMaxY) - parseFloat(areaMinY))/2);
            const halfWidth = (parseFloat(areaWidth)/2);
            const halfHeight = (parseFloat(areaHeight)/2);

            setAreaMinX((cx - halfWidth).toFixed(2));
            setAreaMinY((cy - halfHeight).toFixed(2));
            setAreaMaxX((cx + halfWidth).toFixed(2));
            setAreaMaxY((cy + halfHeight).toFixed(2));
            setIsManual(false);
        }

    },[areaWidth, areaHeight])
        
    /**
     * Event actions when the polygon coordinates is manually adjusted.
     */
    useEffect(() => {
        if (isManual){
            if (parseFloat(areaMaxX) !== 0 && parseFloat(areaMinX)){
                const width = parseFloat(areaMaxX) - parseFloat(areaMinX);
                setAreaWidth(width.toFixed(2));
            }
            if (parseFloat(areaMaxY) !== 0 && parseFloat(areaMinY)){
                const height = parseFloat(areaMaxY) - parseFloat(areaMinY);
                setAreaHeight(height.toFixed(2));
            }
            setIsManual(false);
        }

    },[areaMinX, areaMinY, areaMaxX, areaMaxY])



    const handleCreate = (event) => {
        const params = {
            minX: areaMinX,
            minY: areaMinY,
            maxX: areaMaxX,
            maxY: areaMaxY,
          }
          aDispatch({ type: "setdatageomcustom", payload: { hasDataCustomPolygon: true } });
          aDispatch({ type: "requestdatageom", payload: { action: "createCoordPolygon", requestdatageom: params} });
          setIsDoRectangle(false);
    }
    
    //<Box fontSize={12} ><CloseIcon className={classes.iconClose}/></Box>
  return (
    <div className={classes.root}>
        <Box display="flex" justifyContent="flex-end" m={1}>
            <Button onClick={doClose}>
                <Box className={classes.closeLabel} fontSize={12}>CLOSE</Box>
            </Button>
        </Box>
        <div className={classes.lgButtonContainer}>
        <Box display="flex" flexDirection="row">
            <ToggleButton value={'rectangle'} className={classes.toolButton} selected={isDoRectangle} onChange={e => setIsDoRectangle(!isDoRectangle)}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><RectangleIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Rectangle</Box>
                </div>
            </ToggleButton>
            <ToggleButton value={'polygon'} className={classes.toolButton} selected={isDoPolygon} onChange={e => setIsDoPolygon(!isDoPolygon)}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><PolygonIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Polygon</Box>
                </div>
            </ToggleButton>
        </Box>
        <Divider className={classes.divider} />
        <Box display="flex" flexDirection="row">
            <Box className={classes.sectionLabel} flexGrow={1} fontSize={12}>Area Measurement</Box>
        </Box>
        <Box display="flex" flexDirection="row">
            <Box className={classes.areaIconContainer}><CustomAreaIcon className={classes.miniIcon} /></Box>
            <Box className={classes.sectionItemLabel} flexGrow={1} fontSize={11}>Area size (metres)</Box>
        </Box>
        <Box display="flex" flexDirection="row">
            <Box className={classes.areaInputContainer}><Box fontSize={12} className={classes.widthHeightLabel}>Width:</Box><TextField className={classes.textField} disabled={customRectDisabled} name="area_width" label="" variant="outlined" size="small" margin="dense" value={areaWidth} InputProps={{classes: {input: classes.parameterInputFont}, endAdornment:<InputAdornment position="end">m</InputAdornment>}} onChange={e => handleAreaWidth(e)} /></Box>
            <Box className={classes.areaInputContainer}><Box fontSize={12} className={classes.widthHeightLabel}>Height:</Box><TextField className={classes.textField} disabled={customRectDisabled} name="area_height" label="" variant="outlined" size="small" margin="dense" value={areaHeight} InputProps={{classes: {input: classes.parameterInputFont}, endAdornment:<InputAdornment position="end">m</InputAdornment>}} onChange={e => handleAreaHeight(e)} /></Box>
        </Box>
        <Box className={classes.sectionSubLabel} flexGrow={1} fontSize={12}>Enter your corner coordinates</Box>
        <Box display="flex" flexDirection="row" className={classes.sectionContainer}>
            <Box className={classes.areaInputContainer}>
                <Box fontSize={12} className={classes.widthHeightLabel}>Bottom-left:</Box>
                <Box fontSize={11} className={classes.widthHeightLabel}>X:</Box><TextField className={classes.textField} name="area_width" disabled={customRectDisabled} label="" variant="outlined" size="small" margin="dense" value={areaMinX} InputProps={{classes: {input: classes.parameterInputFont} }} onChange={e => handleAreaMinX(e)} />
                <Box fontSize={11} className={classes.widthHeightLabel}>Y:</Box><TextField className={classes.textField} name="area_width" disabled={customRectDisabled} label="" variant="outlined" size="small" margin="dense" value={areaMinY} InputProps={{classes: {input: classes.parameterInputFont} }} onChange={e => handleAreaMinY(e)} />
            </Box>
            <Box className={classes.areaInputContainer}>
                <Box fontSize={12} className={classes.widthHeightLabel}>Top-right:</Box>
                <Box fontSize={11} className={classes.widthHeightLabel}>X:</Box><TextField className={classes.textField} name="area_height" disabled={customRectDisabled} label="" variant="outlined" size="small" margin="dense" value={areaMaxX} InputProps={{classes: {input: classes.parameterInputFont} }} onChange={e => handleAreaMaxX(e)} />
                <Box fontSize={11} className={classes.widthHeightLabel}>Y:</Box><TextField className={classes.textField} name="area_height" disabled={customRectDisabled} label="" variant="outlined" size="small" margin="dense" value={areaMaxY} InputProps={{classes: {input: classes.parameterInputFont} }} onChange={e => handleAreaMaxY(e)} />
            </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Box className={classes.areacreateBtnContainer}>
                <Button className={classes.createButton} variant="contained" onClick={handleCreate} disabled={customRectDisabled}>
                    <Box fontWeight="fontWeightRegular" fontSize={12} >Create</Box>
                </Button>
            </Box>
        </Box>
        <Box display="flex" flexDirection="row" className={classes.sectionContainer}>
            <Box value={'undo'} className={classes.smToolButtonHold}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}></Box>
                    <Box flexGrow={1} fontSize={8}></Box>
                </div>
            </Box>
            <Box value={'redo'} className={classes.smToolButtonHold}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}></Box>
                    <Box flexGrow={1} fontSize={8}></Box>
                </div>
            </Box>
            <Button value={'trash'} className={classes.smToolButton} onClick={e => setIsDoDelete(!isDoDelete)}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><TrashIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Delete</Box>
                </div>
            </Button>
        </Box>
        </div>
    </div>
  );
}