/** 
 * Storage for session information.
 */

import React, { useReducer, createContext } from "react";

export const SessionContext = createContext();

const initialState = {
  userid: 0, //1
  aid: 0,//1
  ident: '',//'123456789'
  isActive: false, //Sets if user is active and has logged in. //default: false. dev set true to hide login screen
  isRegistered: false, //Used to identify if guest or account user. //default: false.
  user: {}, //User details
  company: {}, //Company details
  default: {}, //Default setting details
  templates:[], //Stores available print templates
};


const reducer = (state, action) => {
  switch (action.type) {
    case "create":
      //console.log('Create payload:'+JSON.stringify(action.payload));
      return { ...state, 
        userid: action.payload.userid,
        aid: action.payload.aid,
        isActive: action.payload.isActive,
        isRegistered: action.payload.isRegistered,
        ident: action.payload.ident
      };
      case "end":
        return { ...state, 
          userid: 0,
          aid: 0,
          isActive: false,
          isRegistered: false,  
          ident: '',
          user: {}, 
          company: {}, 
          default: {},
          templates:[]
        };
        case "setuserdetails":
          //console.log('Create payload:'+JSON.stringify(action.payload));
          return { ...state, 
            user: action.payload.userDetail, 
            company: action.payload.companyDetail,
            default: action.payload.defaultDetail, 
        };
        case "setRegistered":
          return { ...state, 
            isRegistered: action.payload.isRegistered
        };
        case "settemplates":
        //console.log('Templates loaded:'+JSON.stringify(action.payload.templates));
        return { ...state, 
          templates: action.payload.templates
        };
      
    default:
      throw new Error();
  }
};

export const SessionContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SessionContext.Provider value={[state, dispatch]}>
      {props.children}
    </SessionContext.Provider>
  );
};