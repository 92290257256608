export default function getPrintWindowExtent(newscale, winWidth, winHeight, cx, cy) {
    

    const sw = (winWidth * (newscale/1000)) / 2;
    const sh = (winHeight * (newscale/1000)) / 2;

    const objExtent = {
        "scale": newscale,
        "px1": cx - parseFloat(sw),
        "py1": cy - parseFloat(sh),
        "px2": cx + parseFloat(sw),
        "py2": cy + parseFloat(sh)
    }
    return objExtent;
  }