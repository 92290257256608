import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Topbar from './createComponents/Topbar';
import ListPanel from './downloadComponents/orderListInterface';
import DetailPanel from './downloadComponents/detailInterface';



import { SessionContext } from "../context/session-context";
import { ActivityContext } from "../context/activity-context";

const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    }

  });
  
  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

export default function DownloadInterface(props) {
    const classes = useStyles();
  
    const [state] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [hasListPanel, setHasListPanel] = useState(true);//default: true
    const [hasDetailPanel, setHasDetailPanel] = useState(false);//default: false

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Topbar />
                </Grid>
                <Grid item xs={12}>
                <div >
                    { hasListPanel ? <ListPanel /> : '' }
                    { hasDetailPanel ? <DetailPanel/> : '' }
                </div>
                </Grid>
            </Grid>
        </div>
    )
  };
