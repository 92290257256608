import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import { ActivityContext } from "../../context/activity-context";
import SaveButton from "../button/SaveButton";
import ToolButton from "../button/ToolsButton";


export default function ProjectPanel(props) {

  const useStyles = makeStyles((theme) => ({
    root: {

    },
    productTitle: {
      paddingLeft:16,
      color: '#5C5D6B',
    },
    productContainer: {
      display:"flex",
      flexDirection:"row",
      paddingLeft:16
    },
    projectReference: {
      width: 220,
    },
    
  }));

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [projectReference, setProjectReference] = useState(aState.projectreference);
  const [hasError, setHasError] = useState(false);
  

  //Listerner that stores the project reference.
  var projectReferenceTimeout = 0;
  useEffect(() => {
    clearTimeout(projectReferenceTimeout);
    projectReferenceTimeout = setTimeout(function() { 
      storeChange();
      if (projectReference.length <= 1){ setHasError(true); } else { setHasError(false); }
    }.bind(this), 1000);
    return function cleanup() {
      clearTimeout(projectReferenceTimeout);
    };
  },[projectReference]);


  /**
   * Stores the project reference.
   */
  const storeChange = () => {
    aDispatch({
      type: "setreference",
      payload: { projectreference: projectReference}
    });
  };

  
  return (
    <div className={classes.root}>
        <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>PROJECT NAME</Box>
        <Box component="span" display="block" className={classes.productContainer} >
            <TextField className={classes.projectReference} InputProps={{ style: { fontSize: 12 } }} onChange={e => setProjectReference(e.target.value)} name="projectReference" error={hasError} helperText="Project name is required to order." variant="outlined" size="small" value={projectReference} required />
        </Box>
        <SaveButton />
        { aState.orderStep === 'plot' || aState.orderStep === 'data' ? <ToolButton disabled={true}/> : '' }
    </div>
  );
}