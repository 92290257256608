/**
 * Returns the correct mapping to use base on view scale.
 */

function getLayerDefaultScale(baseLayer) {
let defaultScale = 10000 
switch(baseLayer){
 
    case "osmm":
    case "osmmbw":
        defaultScale = 1250 
    break;
    case "os10k":
        defaultScale = 10000 
    break;
    case "osvml":
        defaultScale = 10000 
    break;
    case "os25k":
        defaultScale = 25000 
    break;
    case "os50k":
        defaultScale = 50000 
    break;
    case "os250k":
        defaultScale = 250000 
    break;
    case "aerial":
        defaultScale = 1250
    break;

}
return defaultScale;
}

export default getLayerDefaultScale;