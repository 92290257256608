/**
 * Returns the boundary box for new view scale based on a provided centre point
 */

//export default function setMapScale(newscale,xmin,ymin,xmax,ymax,mapwidth,mapheight) {
    export default function setMapScaleCentre(newscale,mapObj, centreX, centreY) {
    
        const ppi = 72;
        const inches = 39.37007874015748031496062992126;
    
        const mapSize = mapObj.getSize();
        const mapwidth = mapSize[0];
        const mapheight = mapSize[1];
    
        const sw = (((mapwidth/ppi)/inches)*newscale)/2;  
        const sh = (((mapheight/ppi)/inches)*newscale)/2;
    
        const objExtent = {
            "scale": newscale,
            "xmin": centreX - sw,
            "ymin": centreY - sh,
            "xmax": centreX + sw,
            "ymax": centreY + sh
        }
    
        return objExtent;
      }