import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ToggleButton from '@material-ui/lab/ToggleButton';
import TextField from '@material-ui/core/TextField';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import getLayerMinZoomInt from "../../functions/getLayerMinZoomInt";
import getLayerDefaultScale from "../../functions/getLayerDefaultScale";
import getPrintPrice from "../../functions/getPrintPrice";
import PRPanel from "./ProjectReferencePanel";

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

import {ReactComponent as PrintIcon} from '../../assets/dark_icons/icon_print.svg';
import {ReactComponent as DataIcon} from '../../assets/dark_icons/icon_data.svg';
import { toUserExtent } from 'ol/proj';




const orientationProducts = [
  { label: 'Portrait', value: 'portrait'},
  { label: 'Landscape', value: 'landscape'},
];

const formatProducts = [
  { label: 'PDF', value: 'pdf'},
  { label: 'JPEG', value: 'jpg'}
];

export default function PlotPanel(props) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: 270,
      height: props.height - 51,
      //borderLeft: '1px solid #D2D2D8',
      overflowY: 'scroll',
    },
    productTitle: {
      paddingLeft:16,
      color: '#5C5D6B',
    },
    productContainer: {
      //width: 270,
      display:"flex",
      flexDirection:"row",
      height:65,
      paddingLeft:16
    },
    productButton: {
      width: 118,
      height:65,
      backgroundColor:"#FFF",
      '&:hover': {
          color: '#C1DAE9'
      },
      '&.MuiToggleButton-root.Mui-selected': {
          backgroundColor: '#C1DAE9',
          color: '#FFF',
      },
    },
    LabelContainer:{
      alignItems: 'center',
    },
    iconContainer:{
      width:'100%',
    },
    buttonLabel:{
      width:'100%',
    },
    printIcon:{
      width:20,
      height:24
    },
    dataIcon:{
      width:16,
      height:24
    },
    productListContainer:{
      padding: 16,
      maxHeight: 350,
      overflowY: 'auto',
    },
    productItem:{
        color: '#000',
        backgroundColor: '#FFF',
        '&.MuiListItem-root.Mui-selected': {
          backgroundColor: '#C1DAE9',
        },
        '&.MuiListItem-root':{
          paddingTop:4,
          paddingBottom:4,
        }
        //border: '1px solid #EDEDEF',
    },
    listItemText:{
      fontSize:12,//Insert your required size
    },
    paperCopiesContainer:{
      height:50
    },
    printcopies:{
      paddingLeft:10,
      width:100
    },
    paperGridContainer:{
      height:32
    },
    gridlineLabel:{
      paddingTop:12
    }
  
  }));


  const classes = useStyles();
  const [sState, sDispatch] = useContext(SessionContext);
  const [aState, aDispatch] = useContext(ActivityContext);
  const [product, setProduct] = useState('');
  const [size, setSize] = useState('');
  const [scale, setScale] = useState();
  const [scaleitems, setScaleitems] = useState([]);
  const [papersizeitems, setPapersizeitems] = useState([]);
  const [orientation, setOrientation] = useState('');
  const [format, setFormat] = useState('');
  const [copies, setCopies] = useState(1);
  const [gridline, setGridline] = useState(true);
  const [productList, setProductList] = useState([]);
  const [getprice, setGetprice] = useState(false);
  const [projectReference, setProjectReference] = useState('');
  

  /**
   * This is the standard entry point.
   */
  useEffect(() => {
    if (product !== aState.productLayerSelect && aState.productSelect === 'plot') { 
      console.log("Print layer selected: "+aState.productLayerSelect); 
      setProduct(aState.productLayerSelect); 
      configurePrintOptions(aState.productLayerSelect);
      if (!aState.isProject) {
        setOrientation(sState.default.dprintorientation);
        setCopies(parseInt(sState.default.dprintcopies));
        setFormat('pdf');
        //console.log("dprintscale: "+sState.default.dprintproduct+" "+parseInt(sState.default.dprintscale));
        //setScale(parseInt(sState.default.dprintscale));
        setSize(sState.default.dprintsize);
        setGridline(Boolean(sState.default.hasgrid));
      } else {
        setOrientation(aState.print.orientation);
        setCopies(parseInt(aState.print.copies));
        setFormat(aState.print.format);
        setScale(parseInt(aState.print.scale));
        setSize(aState.print.size);
        setGridline(Boolean(aState.print.gridlines));

        //If its from a saved project load, turn off flag.
        if (aState.isProject) { aDispatch({ type: "savedProjectLoad", payload: { isProject: false }});  }
      }
      
    }
  },[aState.productLayerSelect]);

  const configurePrintOptions = (value) => {
    const printSetup = sState.default.dprintoptions;
    const sizeAvailable = [];

    for (let p = 0; p < printSetup.length; p++) {

      if(printSetup[p].product === value){
        if (printSetup[p].a4 === true ){ sizeAvailable.push({ value: 'a4', label: 'A4' }); }
        if (printSetup[p].a3 === true ){ sizeAvailable.push({ value: 'a3', label: 'A3' }); }
        if (printSetup[p].a2 === true ){ sizeAvailable.push({ value: 'a2', label: 'A2' }); }
        if (printSetup[p].a1 === true ){ sizeAvailable.push({ value: 'a1', label: 'A1' }); }
        if (printSetup[p].a0 === true ){ sizeAvailable.push({ value: 'a0', label: 'A0' }); }
        break;
      }
    }
    setPapersizeitems(sizeAvailable);
    getLayerScales();
  };

  const getLayerScales = () => {
    const scaleAvailable = [];
    switch(aState.productLayerSelect){
      case "osmmbw":
      case "osmm":
        scaleAvailable.push({value: 200, label: '1:200'},{value: 500, label: '1:500'},{value: 1250, label: '1:1250'},{value: 2500, label: '1:2500'});
        break;      
      case "os10k":
        scaleAvailable.push({value: 5000, label: '1:5000'},{value: 10000, label: '1:10,000'});
        break;
      case "os25k":
        scaleAvailable.push({value: 15000, label: '1:15,000'},{value: 25000, label: '1:25,000'});
        break;
      case "os50k":
        scaleAvailable.push({value: 30000, label: '1:30,000'},{value: 50000, label: '1:50,000'});
        break;
      case "os250k":
        scaleAvailable.push({value: 150000, label: '1:150,000'},{value: 250000, label: '1:250,000'});
        break;
      case "aerial":
        scaleAvailable.push({value: 200, label: '1:200'},{value: 500, label: '1:500'},{value: 1250, label: '1:1250'},{value: 2500, label: '1:2500'});
        break;
    }
    setScaleitems(scaleAvailable);
    setScale(getLayerDefaultScale(aState.productLayerSelect));
    //Set the minimum zoom int to prevent users zoom in to much
    aDispatch({
        type: "setminzoom",
        payload: { minZoomInt: getLayerMinZoomInt(aState.productLayerSelect)}
    });
  };


  const handlePaperSizeClick = (event, v) => {
    setSize(v)
  };
  const handleScaleClick = (event, v) => {
    setScale(v)
  };
  const handleOrientationClick = (event, v) => {
    setOrientation(v);
  };
  const handleFormatClick = (event, v) => {
    setFormat(v);
  };

  let timer;
  useEffect(() => {
    if (aState.toplevel == 'mapping' && aState.orderStep == 'plot'){
      aDispatch({ type: "isloading", payload: { isloading: true} }); 
      aDispatch({ type: "setpriceloading", payload: { priceloading: true} });

      clearTimeout(timer);
      timer = setTimeout(function() { //Implements a delay in setting new values.
        handleTemplateChange(true);
      }.bind(this), 1500);
      return function cleanup() {
        clearTimeout(timer);
      };
      
    }
  },[size,scale,copies])

  /**
   * On gridline, orientation or format change when no changes to price.
   */
  useEffect(() => {
    if (aState.toplevel == 'mapping' && aState.orderStep == 'plot'){
      handleTemplateChange(false);
    }
  },[gridline,orientation,format])

  /**
   * Handle the changing of print template.
   */
  //let timer;
  const handleTemplateChange = (priceUpdate) => {
    if (product !== ""){
        const p = {
        layer: product,
        size: size,
        scale: scale,
        orientation: orientation,
        copies: copies,
        gridlines: gridline,
        format: format,
      }
      if (aState.print !== p){
        //console.log("setprint obj."+JSON.stringify(p));
        aDispatch({
          type: "setprint",
          payload: { print: p}
        });
        if (priceUpdate) { setGetprice(true); }
      }

    }
  };

  /**
   * Gets and returns the price for the template.
   * Requires getprice is true and aState.printframeextent
   */
  useEffect(() => {
    //console.log("getPrice:"+getprice+" extent length:"+aState.printframeextent.length);
    if (getprice && aState.printframeextent.length === 4){
      
      const request = {
          pricingid: sState.company.pricingid,
          aid: sState.aid,
          layer: product,
          size: size,
          scale: scale,
          copies: copies,
          gridlines: gridline,
          orientation :orientation,
          printframeextent : aState.printframeextent
      }
      getPrintPrice(request, aState, aDispatch);
      setGetprice(false);

    }
  },[aState.printframeextent])

  const numberOnly = (event) => {
    //const re = /^[0-9\b]+$/;
    //if (event.target.value === '0') { event.target.value = 1; }
    if (event.target.value < 1) { event.target.value = 1; }
    //if (re.test(event.target.value)) {
      //setCopies(event.target.value.substr(0, 2));
      setCopies(parseInt(event.target.value));
   //}
  };
  
  return (
    <div className={classes.root}>
      <PRPanel />
      <Divider />
      <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>PAPER SIZE</Box>
      <div className={classes.paperSizeContainer}>
        <List component="nav" aria-label="Paper Size">
          {papersizeitems.map((item, i) => (
            <React.Fragment key={i}>
                  <ListItem 
                      key={i}
                      button
                      className={classes.productItem}
                      selected={size === item.value}
                      onClick={(event) => handlePaperSizeClick(event, item.value)}
                      >
                      <ListItemText primary={item.label} classes={{primary:classes.listItemText}}/>
                  </ListItem>
            </React.Fragment>
          ))}
        </List>
      </div>
      <Divider />
      <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>PRINT SCALE</Box>
      <div className={classes.paperSizeContainer}>
        <List component="nav" aria-label="Paper Size">
          {scaleitems.map((item, i) => (
            <React.Fragment key={i}>
                  <ListItem 
                      key={i}
                      button
                      className={classes.productItem}
                      selected={scale === item.value}
                      onClick={(event) => handleScaleClick(event, item.value)}
                      >
                      <ListItemText primary={item.label} classes={{primary:classes.listItemText}}/>
                  </ListItem>
            </React.Fragment>
          ))}
        </List>
      </div>
      <Divider />
      <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>ORIENTATION</Box>
      <div className={classes.paperOrientationContainer}>
        <List component="nav" aria-label="Orientation">
              {orientationProducts.map((item, i) => (
                <React.Fragment key={i}>
                  <ListItem 
                      key={i}
                      button
                      className={classes.productItem}
                      selected={orientation === item.value}
                      onClick={(event) => handleOrientationClick(event, item.value)}
                      >
                      <ListItemText primary={item.label} classes={{primary:classes.listItemText}}/>
                  </ListItem>
                </React.Fragment>
              ))}
        </List>
      </div>
      <Divider />
      <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>PRODUCT FORMAT</Box>
      <div className={classes.paperFormatContainer}>
        <List component="nav" aria-label="Product Format">
              {formatProducts.map((item, i) => (
                <React.Fragment key={i}>
                  <ListItem 
                      key={i}
                      button
                      className={classes.productItem}
                      selected={format === item.value}
                      onClick={(event) => handleFormatClick(event, item.value)}
                      >
                      <ListItemText primary={item.label} classes={{primary:classes.listItemText}}/>
                  </ListItem>
                </React.Fragment>
              ))}
        </List>
      </div>
      <Divider />
      <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>COPIES (1 - 99)</Box>
      <div className={classes.paperCopiesContainer}>
        <TextField className={classes.printcopies} InputProps={{ style: { fontSize: 12 } }} name="copies" variant="outlined" size="small" type="number" value={copies} onChange={numberOnly}/>
      </div>
      <Divider />
      <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>TEMPLATE</Box>
      <div className={classes.paperGridContainer}>
        <Box display="flex" flexWrap="wrap">
          <Checkbox checked={gridline} onChange={e => setGridline(!gridline)} color="primary"  /><Box className={classes.gridlineLabel} fontSize={12}>Show Gridlines</Box>
        </Box>
      </div>
    </div>
  );
}