function getViewScale(scaleInt){

    let scale = 10000;
    switch(parseInt(scaleInt)){
        case 1:
            scale = 200;
        break;
        case 2:
            scale = 500;
        break;
        case 3:
            scale = 1250;
        break;
        case 4:
            scale = 2500;
        break;
        case 5:
            scale = 5000;
        break;
        case 6:
            scale = 10000;
        break;
        case 7:
            scale = 15000;
        break;
        case 8:
            scale = 25000;
        break;
        case 9:
            scale = 30000;
        break;
        case 10:
            scale = 50000;
        break;
        case 11:
            scale = 150000;
        break;
        case 12:
            scale = 250000;
        break;
        default: 
            scale = 10000;
        break;
    }
    return scale;
  }

  export default getViewScale;