import React, { useContext } from 'react';
import { Image } from 'ol/layer'
import {ImageWMS } from 'ol/source'

import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

function baseLayer(BaseLayer, grid) {

    //console.log("WMS address: "+process.env.REACT_APP_WMS_1);
    const source1 = process.env.REACT_APP_WMS_1
    //const source1 = 'http://192.168.82.59:8080/geoserver/OS/wms';
    let blayer = "";
    let wmsSource;
    //console.log("BaseLayer request: "+BaseLayer);
    switch(BaseLayer){
        case "osmm":
        wmsSource = new ImageWMS({
            url: source1,
            params: {'LAYERS': process.env.REACT_APP_LAYER_OSMM},
            serverType: 'geoserver',
        });
        blayer = new Image({source: wmsSource});

        break;
        case "osmmbw":
            wmsSource = new ImageWMS({
                url: source1,
                params: {'LAYERS': process.env.REACT_APP_LAYER_OSMMBW},
                serverType: 'geoserver',
            });
            blayer = new Image({source: wmsSource});
        break;

        case "os25k":
            wmsSource = new TileWMS({
                url: source1,
                params: {'LAYERS': process.env.REACT_APP_LAYER_25k},
                serverType: 'geoserver',
            });
        blayer = new TileLayer({source: wmsSource});
        break;
        case "os50k":
            wmsSource = new TileWMS({
                url: source1,
                params: {'LAYERS': process.env.REACT_APP_LAYER_50k},
                serverType: 'geoserver',
            });
            blayer = new TileLayer({source: wmsSource});

        break;
        case "os250k":
            wmsSource = new TileWMS({
                url: source1,
                params: {'LAYERS': process.env.REACT_APP_LAYER_250k},
                serverType: 'geoserver',
            });
            blayer = new TileLayer({source: wmsSource});

        break;
        case "aerial":
            wmsSource = new TileWMS({
                url: source1,
                params: {'LAYERS': process.env.REACT_APP_LAYER_AERIAL},
                serverType: 'geoserver',
            });
            blayer = new TileLayer({source: wmsSource});

        break;

        default:
        case "os10k":

            wmsSource = new TileWMS({
                url: source1,
                params: {'LAYERS': process.env.REACT_APP_LAYER_10k},
                serverType: 'geoserver',
            });
            blayer = new TileLayer({source: wmsSource});

        break;
        case "grid":
            const gridSource = new ImageWMS({
                url: source1,
                params: {'LAYERS': process.env.REACT_APP_LAYER_GRID},
                serverType: 'geoserver',
            });
    
            const grid = new Image({source: gridSource});
            blayer = grid;
        break;
        case "watermark":

        break;
    }

    return blayer;
}

export default baseLayer;