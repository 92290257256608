/**
 * Deleted the selected save project
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };


export default async function deleteSavedProject(sState,saveid,dispatch) {

    const abortController = new AbortController();
        const request = {   
            ident:sState.ident,
            userid:sState.userid,
            aid:sState.aid,
            saveid:saveid
          };
    
        let mounted = true;
        (async () => {  
          const res = await fetch(process.env.REACT_APP_NMC_API+'/project/delete', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request),
            signal: abortController.signal,
          }).catch(handleError);
          const data = await res.json();
          if (mounted) {
            if (data.status === "success"){
                dispatch({
                    type: "updatetoplevel",
                    payload: { toplevel: 'project', viewStep: 'list', orderStep:''}
                  });
            } else {

            }
          }
          const cleanup = () => {
            mounted = false;
            abortController.abort();
          };
          return cleanup;
      
      })();


  
}