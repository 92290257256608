/**
 * Opens the selected save project
 */

import getPolygonExtent from "./getExtentFromOLPolygon";

export default async function openSavedProject(state,saveid,dispatch,sState) {

    dispatch({ type: "savedProjectLoad", payload: { isProject: true }});
    
    state.projects.forEach(function (p) {
        if (p.saveid === saveid){
            //console.log("saved item: "+JSON.stringify(p));
            const topMenu = 'mapping';
            const mappingStep = 'mapping';
            const orderStep = p.details.savetype;
            let productlayer = '';
            //For plots, create a new extent based on the centre point of the plot window.
            let itemExtent = {};
            if (orderStep === 'plot'){
                const f = p.details.printframeextent;
                itemExtent.minX = f[0];
                itemExtent.minY = f[1];
                itemExtent.maxX = f[2];
                itemExtent.maxY = f[3];
                productlayer = p.details.print.layer;
                if (productlayer === ''){ productlayer = p.details.viewlayer; }
            } else {
                if (typeof p.details.datageom.geometry !== 'undefined'){
                    itemExtent = getPolygonExtent(p.details.datageom.geometry);
                    productlayer = p.details.data.layer;
                }
            }

            if (orderStep === 'plot'){
                dispatch({ type: "loadSavedPrint", payload: { 
                    print: p.details.print,
                    printframeextent: p.details.printframeextent
                }});
            } else {
                dispatch({ type: "loadSavedData", payload: { 
                    data: p.details.data,
                    datageom: p.details.datageom,
                }});
            }

            dispatch({ type: "loadSavedProject", payload: {
                toplevel: topMenu, 
                viewStep: mappingStep, 
                orderStep:orderStep,
                productSelect:p.details.savetype,
                caddress: p.details.caddress,
                addressSource: p.details.addressSource,
                annotations: p.details.annotations,
                basemap: p.details.viewlayer,
                viewscale: p.details.viewscale,
                projectreference:p.projectname,
                mapextent:itemExtent,
                productLayer: productlayer
            }});

            return;
        }
    });



  
}