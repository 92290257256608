import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import getUserProjects from "../../functions/getUserProjects";

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

import {ReactComponent as DownloadIcon} from '../../assets/dark_icons/iconmonstr-download-19 1.svg';
import {ReactComponent as SearchIcon} from '../../assets/dark_icons/iconmonstr-magnifier-6 1.svg';



const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    },
    formLabel:{
        marginLeft: 90,
        marginTop: 40,
        marginBottom: 20,
        color: '#447095',
    },
    formsearch:{
      display:"flex",
      flexDirection:"row",
      marginLeft: 90,
      marginRight: 90,
      marginBottom: 10,
      height: 120,
      backgroundColor: '#ECF4F9',
      flexWrap:"nowrap",
      alignItems: 'flex-start',
    },
    formtable:{
      marginLeft: 90,
      marginRight: 90,
      marginBottom: 20,
      minHeight: 400,
      height: 'calc(100vh - 330px)',
      color: '#447095',
    },
    searchContainer:{
      paddingTop: 20,
    },
    searchButton:{
      marginTop: 30,
      height:40,
      backgroundColor:'#2D2F88',
      color: '#FFF',
      '&:hover': {
        color: '#F6A036',
      },
    },
    searchIcon: {
      width: 16,
      height: 15
    },
    keywordsField:{
      marginTop: 20,
    },
    downloadIcon: {
      width: 16,
      height: 16
    },
    downloadButton: {
        width: 200,
        backgroundColor:'#CAD8BA',
        color: '#304517',
        '&:hover': {
          color: '#F6A036',
        },   
    },
    menuButton: {
      color: '#000',
        '&:hover': {
          color: '#F6A036',
          backgroundColor:'#FFF',
        }
    }
  });


export default function ProjectListInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [orderRows, setOrderRows] = useState([]);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [keyword, setkeyword] = useState(' ');
    const [selection, setSelection] = useState([]);

  
    /**
     * First load
     */
    useEffect(() => {

      let dtFromDate = new Date();
      let dtToDate = new Date();
      dtFromDate.setDate(dtFromDate.getDate() - 7);

      setFromDate(dtFromDate);
      setToDate(dtToDate);
      
      getUserProjects(sState.userid, sState.aid, keyword, dtFromDate, dtToDate, aDispatch);
  },[])



    /**
     * Listerns for change to orders list.
     */
    useEffect(() => {

        if (aState.projects.length > 0){
          
          //console.log("order rows: "+JSON.stringify(aState.orders));
          setOrderRows(aState.projects);
        }
    },[aState.projects])


    const columns = [
        { field: 'saveid', hide: true},
        { field: 'savename',
        headerName: 'Saved name',
        sortable: true,
        width: 400,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          getProjectDetailsOptions(params)
        ),},
        { field: 'username', headerName: 'Created by', width: 200 },
        { field: 'saveddate', headerName: 'Created date', type: 'date', width: 200},
      ];

      /**
       * Displays the download options for user orders and allows to download.
       */
      function getProjectDetailsOptions(params) {

        const handleViewRequest = () => {
          const api = params.api;
          const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);
          const thisRow = {};

          fields.forEach((f) => {
            thisRow[f] = params.getValue(f);
          });
          //downloadOrder(thisRow.orderid, sState.ident, sState.userid);
          console.log("Saved Project selected ="+JSON.stringify(thisRow));
          props.onDisplayDetails(thisRow.saveid);
        };

        const sname = params.getValue('savename');
        return <Button className={classes.menuButton} onClick={handleViewRequest}>
          <Box fontSize={12}>{sname}</Box>
        </Button>;

      }

      const handleKeywordChange = (value) => {
        setkeyword(value);
      };
      const handleFromDateChange = (date) => {
        setFromDate(date);
      };
      const handleToDateChange = (date) => {
        setToDate(date);
      };

      const handleOrderSearch = () => {
        getUserProjects(sState.userid, sState.aid, keyword, fromDate, toDate, aDispatch);
      };




    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.formLabel} fontWeight="500" fontStyle="italic" fontSize={22} >Saved Projects</Box>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.formsearch}>
                  <Grid container justify="space-around" className={classes.searchContainer}>
                  <Box component="span" display="block" className={classes.keywordsField}>
                    <TextField  name="searchField" label="Keywords" variant="outlined" size="small" margin="dense" value={keyword} onChange={handleKeywordChange} />
                  </Box>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
     
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="dateFrom"
                        label="From"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        className={classes.keywordsField}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="dateTo"
                        label="To"
                        value={toDate}
                        onChange={handleToDateChange}
                        className={classes.keywordsField}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                  </MuiPickersUtilsProvider>
                    <Button className={classes.searchButton} variant="contained" onClick={handleOrderSearch} >
                        <Box className={classes.downloadIconContainer} ><SearchIcon className={classes.searchIcon} /></Box><Box fontWeight="fontWeightRegular" fontSize={12} >&nbsp;&nbsp;Search</Box>
                    </Button>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.formtable}>
                      <DataGrid rows={orderRows} columns={columns} pageSize={10} onSelectionChange={(newSelection) => {setSelection(newSelection.rows);}}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
  };
