/**
 * Gets list of dtm parents.

 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function getDTMParents(dispatch) {

        const abortController = new AbortController();

        let mounted = true;
        (async () => {  
        const res = await fetch(process.env.REACT_APP_NMC_API+'/super/company/dtmparent/list/', {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          signal: abortController.signal,
        }).catch(handleError);
          const data = await res.json();
          if (mounted) {
            if (data.status === "success"){
                dispatch({
                    type: "setdtmparents",
                    payload: { 
                        companyname: data.companies,
                    }
                });
    
            } else {
            }
          }
          const cleanup = () => {
            mounted = false;
            abortController.abort();
          };
          return cleanup;
      
      })();

    }
