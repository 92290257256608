/**
 * Returns the geoserver name based on product name..
 */

function getGeoserverName(value) {
    let productName = '';
    switch (value){
        case "osmmbw":
            productName = process.env.REACT_APP_LAYER_OSMMBW;
            break;
        case "osmm":
            productName = process.env.REACT_APP_LAYER_OSMM;
            break;
        case "os10k":
            productName = process.env.REACT_APP_LAYER_10k;
            break;
        case "os25k":
            productName = process.env.REACT_APP_LAYER_25k;
            break;
        case "os50k":                
            productName = process.env.REACT_APP_LAYER_50k;
            break;
        case "os250k":
            productName = process.env.REACT_APP_LAYER_250k
            break;
        case "aerial":
            productName = process.env.REACT_APP_LAYER_AERIAL;
            break;
    }
    return productName;
  }
  export default getGeoserverName;