/**
 * Gets the print price.

 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function getPrintPrice(params, state, dispatch) {
         
        const abortController = new AbortController();
        const request = {
            pricingid: params.pricingid,
            layer: params.layer,
            pagesize: params.size,
            scale: params.scale,
            copies: params.copies,
            orientation :params.orientation,
            extent : params.printframeextent
        }
        let mounted = true;
        (async () => {  
            const res = await fetch(process.env.REACT_APP_NMC_API+'/print/price', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(request),
                signal: abortController.signal,
            }).catch(handleError);
            const data = await res.json();
            if (mounted) {
              if (data.status === "success"){
                //console.log("returned price: "+data.price+", current price: "+state.cprice);
                if (parseFloat(data.price) !== parseFloat(state.cprice)){
                  dispatch({ type: "setprice", payload: { price: data.price} });
                } else {
                  dispatch({ type: "setpriceloading", payload: { priceloading: false} });
                }
              }
              dispatch({ type: "isloading", payload: { isloading: false} }); 
            }
            const cleanup = () => {
                mounted = false;
                abortController.abort();
            };
            return cleanup;

        })();

    }
