
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    menuButton: {
        backgroundColor: '#FFFFFF',
        color: '#3D3E4F',
        '&:hover': {
          backgroundColor: '#3D3E4F',
          color: '#FFF',
        },
        '&.MuiToggleButton-root.Mui-selected': {
          backgroundColor: '#3D3E4F',
          color: '#FFF',
        },
        border: 0,
        marginRight: 4,
        height: 28,
    }
  });

export default function MenuButton(props) {
  const classes = useStyles();
  //const [selected, setSelected] = useState(props.selected);

  const handleChange = () => {
    props.handleChange();
  };

  return <ToggleButton value={props.label} className={classes.menuButton} selected={props.selected} onChange={handleChange}>
            <Box p={1} fontWeight="fontWeightBold" fontSize={14}>{props.label}</Box>
        </ToggleButton>;
}