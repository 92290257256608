/**
 * Gets list of orders ordered by user.
 * Called from UserInterface.js
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function getUserOrders(userid, addressid, keywords, fromDate, toDate, dispatch) {

        const abortController = new AbortController();

        const startDate = fromDate.getFullYear()+'-'+ ('0'+(fromDate.getMonth()+ 1)).slice(-2) +'-'+('0'+fromDate.getDate()).slice(-2);
        const endDate = toDate.getFullYear()+'-'+ ('0'+(toDate.getMonth()+ 1)).slice(-2) +'-'+('0'+toDate.getDate()).slice(-2);

        const request = {   
            userid:userid,
            addressid:addressid,
            keywords:keywords.trim(),
            fromDate:startDate,
            toDate:endDate,
          };
    
        let mounted = true;
        (async () => {  
          const res = await fetch(process.env.REACT_APP_NMC_API+'/order/list/user', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request),
            signal: abortController.signal,
          }).catch(handleError);
          const data = await res.json();
          if (mounted) {
            //console.log("get orders function: "+JSON.stringify(data));
            if (data.status === "success"){
                dispatch({
                    type: "setorders",
                    payload: { 
                    orders: data.orders,
                    }
                });
    
            } else {
            }
          }
          const cleanup = () => {
            mounted = false;
            abortController.abort();
          };
          return cleanup;
      
      })();

    }
