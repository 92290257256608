import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import {ReactComponent as ExtentIcon} from '../../../assets/dark_icons/iconmonstr-cursor-1 1.svg';

import Tooltip from '@material-ui/core/Tooltip';

import { ActivityContext } from "../../../context/activity-context";

export default function MenuButton(props) {

  const useStyles = makeStyles({
    root: {
        paddingBottom: 5,
    },
    
    menuButton: {
      color: '#000',
      backgroundColor: '#F9F9FA',
      '&:hover': {
        color: 'white',
        textDecoration: 'none',
		    backgroundColor: '#2D2F88',
      },
      '&.Mui-selected': {
        color: 'white',
        textDecoration: 'none',
        backgroundColor: '#2D2F88',
        border: '1px solid #FFFFFF',
        borderRadius: '0 0 2px 2px',
      },

      display: 'block',
      margin: 1,
      fontSize: '1.14em',
      fontWeight: 'bold',
      textDecoration: 'none',
      textAlign: 'center',
      height: '1.375em',
      width: '1.375em',
      lineHeight: '.4em',
      border: '1px solid #B2B2BC',
      borderRadius: '0 0 2px 2px',
      minWidth: '1.375em',
      padding: 0,
    },
    extentIcon:{
      fill: '#000',
      '&:hover': {
        fill: '#FFF',
      },
      '&.Mui-selected': {
        fill: '#FFF',
      },
      width: 16,
      height: 16
    },

  });

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [centreTool, setCentreTool] = useState(false);

  const setExtent = () =>{
    aDispatch({
      type: "actionchange",
      payload: { action: 'gotoprint_extent'}
    });  
  };
  
  return <div className={classes.root}>
        <Tooltip title="Zooms to print window extent">
            <Button className={classes.menuButton} onClick={() => { setExtent(); }} variant="contained">
                <ExtentIcon className={classes.extentIcon}/>
            </Button>
          </Tooltip>
        </div>;
}