/**
 * Gets and returns the company list.
 * This is a admin call.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function getCompanies(params, dispatch) {

        const abortController = new AbortController();
        const request = {
            userid: params.userid,
            ident: params.ident,
        }
        let mounted = true;
        (async () => {  
            const res = await fetch(process.env.REACT_APP_NMC_API+'/admin/company/list', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(request),
                signal: abortController.signal,
            }).catch(handleError);
            const data = await res.json();
            if (mounted) {
              if (data.status === "success"){
                //console.log("get model response:"+JSON.stringify(data.models));
                dispatch({
                  type: "setcompanies",
                  payload: { companies: data.companies}
                });
              }
            }
            const cleanup = () => {
                mounted = false;
                abortController.abort();
            };
            return cleanup;

        })();

    }