import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import { ActivityContext } from "../../context/activity-context";

export default function BasemappingSelector(props) {

  const useStyles = makeStyles({
    paper: {

    },
    mapLayers:{
      width:200,
      top: 5,
    },
    formControl: {
      '&.MuiFormControl-marginDense':{
        marginTop:0
      },
    }
  });

  const productList = [
    { key: 0, label: 'OS Plan - 1:200', value: '200'},
    { key: 1, label: 'OS Plan - 1:500', value: '500'},
    { key: 2, label: 'OS Plan - 1:1250', value: '1250'},
    { key: 3, label: 'OS Plan - 1:2500', value: '2500'},
    { key: 4, label: 'VML - 1:5000', value: '5000'},
    { key: 5, label: 'VML - 1:10,000', value: '10000'},
    { key: 6, label: '1:15,000 (OS 1:25,000)', value: '15000'},
    { key: 7, label: 'OS 1:25,000', value: '25000'},
    { key: 8, label: '1:30,000 (OS 1:50,000)', value: '30000'},
    { key: 9, label: 'OS 1:50,000', value: '50000'},
    { key: 10, label: '1:150,000 (OS 1:250,000)', value: '150000'},
    { key: 11, label: 'OS 1:250,000', value: '250000'},
    { key: 12, label: 'Imagery - 1:500', value: 'Imagery500'},
    { key: 13, label: 'Imagery - 1:1250', value: 'Imagery1250'},
    { key: 14, label: 'Imagery - 1:2500', value: 'Imagery2500'},
  ];

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [baseValue, setBaseValue] = useState('10000');

  /**
   * Sets the view scale based on the current map scale.
   */
  useEffect(() => {
    let storedLayer = '';
    if (aState.bmap === 'aerial') {
      storedLayer = 'Imagery'+aState.rviewscale.toString();
    } else {
      storedLayer = aState.rviewscale.toString();
    }
    if (storedLayer !== baseValue){
      setBaseValue(storedLayer);
    }

  },[aState.bmap, aState.rviewscale]);

  const handleChange = (event) => {
    setBaseValue(event.target.value);
    const resp = parseProduct(event.target.value);
    aDispatch({
        type: "changemapview",
        payload: { basemap: resp.layer, viewscale:resp.scale}
    });  
  };

  /**
   * Parse the selected product.
   * Returns object.
   */
  const parseProduct = ($product) => {
    const obj = {};
    switch ($product){
        case '200':
            obj.scale = 200;
            obj.layer = 'osmmbw';
            break;
        case '500':
            obj.scale = 500;
            obj.layer = 'osmmbw';
            break;
        case '1250':
            obj.scale = 1250;
            obj.layer = 'osmmbw';
            break;
        case '2500':
            obj.scale = 2500;
            obj.layer = 'osmmbw';
            break;
        case '5000':
            obj.scale = 5000;
            obj.layer = 'osvml';
            break;
        case '10000':
            obj.scale = 10000;
            obj.layer = 'osvml';
            break;
        case '15000':
            obj.scale = 15000;
            obj.layer = 'os25k';
            break;
        case '25000':
            obj.scale = 25000;
            obj.layer = 'os25k';
            break;
        case '30000':
            obj.scale = 30000;
            obj.layer = 'os50k';
            break;
        case '50000':
            obj.scale = 50000;
            obj.layer = 'os50k';
            break;
        case '150000':
            obj.scale = 150000;
            obj.layer = 'os250k';
            break;
        case '250000':
            obj.scale = 250000;
            obj.layer = 'os250k';
            break;
        case 'Imagery500':
            obj.scale = 500;
            obj.layer = 'aerial';
            break;
        case 'Imagery1250':
            obj.scale = 1250;
            obj.layer = 'aerial';
            break;
        case 'Imagery2500':
            obj.scale = 2500;
            obj.layer = 'aerial';
            break;
    }
    return obj;
  }

  return (
    <div className={classes.paper}>
      <FormControl variant="outlined" size="small" margin="dense" className={classes.formControl}>
      <Select
        //disableUnderline={true}
        className={classes.mapLayers}
        labelId="mapLayer"
        id="mapLayer"
        value={baseValue}
        onChange={handleChange}
        border={1}
        >
        {productList.map((option) => 
          <MenuItem key={option.key} value={option.value}><Box fontSize={12}>{option.label}</Box></MenuItem>
        )}
      </Select>
      </FormControl>
    </div>
  );
}