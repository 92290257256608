import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { SessionContext } from "../../context/session-context";

const useStyles = makeStyles({
    root:{
        backgroundColor: '#F6A036',
        width: '100%',
        height: 70
    },
    notification: {
        paddingTop: 20,
        color: '#000000',
    }

});

export default function useNotificationPanel() {

  const classes = useStyles();
  const [state, dispatch] = useContext(SessionContext);
  const [notification, setNotification] = useState('Notification and System Banner');

    /**
     * Gets any system notifications on load.
     */
    useEffect(() => {

        if (!state.isActive){
            const abortController = new AbortController();
            let mounted = true;
            (async () => {
              
              const res = await fetch(process.env.REACT_APP_NMC_API+'/session/notification', {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                signal: abortController.signal,
              });
              const data = await res.json();
              if (mounted) {
        
                //const d = new Date(data.created);
                const d = new Date();
                const dDate = ('0'+d.getDate()).slice(-2);
                const dMonth = ('0'+(parseInt(d.getMonth()) + 1)).slice(-2);
                const dHours = ('0'+d.getHours()).slice(-2);
                const dMins = ('0'+d.getMinutes()).slice(-2);
                const dSeconds = ('0'+d.getSeconds()).slice(-2);
        
                const n = dDate+'-'+ dMonth +'-'+d.getFullYear()+' '+dHours+':'+dMins+':'+dSeconds+' - '+data.notification;
                setNotification(n);
              }
    
              const cleanup = () => {
                mounted = false;
                abortController.abort();
             };
             return cleanup;
            })();
          }
      }, [state.isActive]);

  return (
    <div className={classes.root}>
        <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={9}>
              <Box p={1} fontWeight="fontWeightBold" className={classes.notification} fontSize={16}>{notification}</Box>
            </Grid>
        </Grid>
    </div>
  );
}
