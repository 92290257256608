/**
 * Returns the correct mapping to use base on view scale.
 */

    function getDisplayLayer(scale) {
        let layer;
        if (scale <= 4000){
            layer = "osmmbw";
        } else if (scale <= 12500) {
            layer = "os10k";
        } else if (scale <= 27500) {
            layer = "os25k";
        } else if (scale <= 100000) {
            layer = "os50k";
        } else {
            layer = "os250k";
        }
        return layer;
      }
      export default getDisplayLayer;