/**
 * Returns the correct mapping to use base on view scale.
 */

function getLayerMinZoomInt(baseLayer) {
    let defaultInt = 6
    switch(baseLayer){
     
        case "osmm9":
        case "osmm55":
        case "osmm":
        case "osmmbw":
        case "aerial":
            defaultInt = 1;
        break;
        case "10k":
        case "os10k":
        case "osvml":
        case "vml":
            defaultInt = 5;
        break;
        break;
        case "25k":
        case "os25k":
            defaultInt = 7; 
        break;
        case "50k":
        case "os50k":
            defaultInt = 9; 
        break;
        case "250k":
        case "os250k":
            defaultInt = 11; 
        break;    
    }
    return defaultInt;
    }
    
    export default getLayerMinZoomInt;