/**
 * Records the requested saved project.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function recordSaveProject(saveName, aState, sState) {

    const abortController = new AbortController();
    const details = {};
    details.savetype = aState.productSelect;
    details.annotations = aState.mapAnnotations;
    details.viewscale = aState.cviewscale;
    details.viewlayer = aState.bmap;
    details.addressSource = aState.addressSource;
    details.caddress = aState.caddress;

    if (aState.productSelect === 'plot'){
      details.print = aState.print;
      details.printframeextent = aState.printframeextent;
    } else {
      details.data = aState.data;
      details.datageom = aState.datageom;
    }

    const request = {   

        userid:sState.userid,
        accountid:sState.aid,
        ident:sState.ident,
        orderid:aState.orderid,
        savename:saveName,
        userref:aState.projectreference,
        projectname:aState.projectreference,
        details: details,
        mapextent:aState.mapextent
    };

    let mounted = true;
    (async () => {  
        const res = await fetch(process.env.REACT_APP_NMC_API+'/project/save', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(request),
        signal: abortController.signal,
        }).catch(handleError);
        const data = await res.json();
        if (mounted) {
          let oid = 0;
          if (data.status === "success"){

          }
  
        }
        const cleanup = () => {
            mounted = false;
            abortController.abort();
        };
        return cleanup;
    
    })();

}
