import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ActivityContext } from "../../context/activity-context";
import { SessionContext } from "../../context/session-context";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReactLoading from 'react-loading';

import ProjectPaper from "../orderComponents/projectPaper";
import recordPrintOrder from "../../functions/recordPrintOrder";
import recordDataOrder from "../../functions/recordDataOrder";
import getGeoserverName from "../../functions/getGeoserverName";
import getProductName from "../../functions/getProductName";
import downloadOrder from "../../functions/downloadOrder";

import {ReactComponent as DownloadIcon} from '../../assets/dark_icons/iconmonstr-download-thin 1.svg';

/**
 * Order complete page. This interface displays the order complete interface.
 *
 * Print and data export requests are performed on this page. 
 */

export default function OrderCompletePanel(props) {

    const useStyles = makeStyles({
        root: {
            backgroundColor: '#EDEDEF',
            width:'100%',
            height: props.height,
        },
        completeContainer: {
            marginLeft: 90,
        },
        completeTitle: {
            marginTop: 40,
            color: '#447095',
            paddingBottom: 20,
        },
        completeDescription: {
            color:'#3D3E4F',
            width:props.width -200,
            maxWidth:640,
            paddingBottom: 20,
        },
        downloadIcon: {
            width: 32,
            height: 32
        },
        downloadButton: {
            width: 220,
            color:'#FFF',
            backgroundColor: '#2D2F88',
            '&:hover': {
              backgroundColor: '#F6A036',
            },
        },
        orderError:{
            color:'#FF0000',
        },
        options:{
            marginTop: 30,
        },
        menuButton: {
            textDecoration: 'underline',
            marginTop: 15,
            color: '#000',
              '&:hover': {
                color: '#F9AF44'
              }
        }
    });

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [sState] = useContext(SessionContext);
  const [isProcess, setIsProcess] = useState(true);
  const [orderID, setOrderID] = useState(0);
  const [orderStatus, setOrderStatus] = useState('');
  const [doPoll, setDoPoll] = useState(false);
  const [pollTimer, setPollTimer] = useState(null);

  const [errorMsg, setErrorMsg] = useState('');
  const [hasError, setHasError] = useState(false);

    //Prepare and order the requested item.
    useEffect(() => {
        console.log("Product ordered: "+aState.productSelect);
        switch(aState.productSelect){
            case "plot":
                processPlotOrder();
                break;
            
            case "data":
                processDataOrder();
                break;
        }
    },[])

    const processPlotOrder = () => {

        try{
            //prepare the print template.
            const template = preparePlotRequest();

            const p = {};
            const money = parseFloat(aState.cprice).toFixed(2);
            p.price = money;
            let vat = 0;
            let gross = money;

            if (sState.company.vaton === 'printdata' || sState.company.vaton === 'print'){
                const vatRate = parseFloat(process.env.REACT_APP_VAT_RATE)/100;
                vat = parseFloat(money * vatRate).toFixed(2);
                gross = parseFloat(money) + parseFloat(vat);
            }

            p.vat = parseFloat(vat).toFixed(2);
            p.total = parseFloat(gross).toFixed(2);
            //Stores the order transaction.       
            
            let date = new Date();
            date.setMonth(date.getMonth() + 12);
            const day= ('0'+date.getDate()).slice(-2);
            const month = ('0'+(date.getMonth()+ 1)).slice(-2);
            const expiryDate = date.getFullYear()+'-'+ month +'-'+day;
            
            recordPrintOrder(aState, p, sState, aState.printframeextent, 'ordered','12months', expiryDate, template, aDispatch);
            
    } catch(err) {
        console.log("recordPrintOrder error:"+err);
        setHasError(true);
    }
    };

    const processDataOrder = () => {

        try{
            const p = {};
            const money = parseFloat(aState.cprice).toFixed(2);
            p.price = money;
            let vat = 0;
            let gross = money;

            if (sState.company.vaton === 'printdata' || sState.company.vaton === 'data'){
                const vatRate = parseFloat(process.env.REACT_APP_VAT_RATE)/100;
                vat = parseFloat(money * vatRate).toFixed(2);
                gross = parseFloat(money) + parseFloat(vat);
            }

            p.vat = parseFloat(vat).toFixed(2);
            p.total = parseFloat(gross).toFixed(2);
            //Stores the order transaction.       
            
            let date = new Date();
            date.setMonth(date.getMonth() + 12);
            const day= ('0'+date.getDate()).slice(-2);
            const month = ('0'+(date.getMonth()+ 1)).slice(-2);
            const expiryDate = date.getFullYear()+'-'+ month +'-'+day;
    
            recordDataOrder(aState, p, sState, aState.datageom.geometry, 'ordered','12months', expiryDate, aDispatch)
        } catch(err) {
            console.log("recordDataOrder error:"+err);
            setHasError(true);
        }


    };
 
    /** Monitoring section */
    
	/**
     * Tracks the response from the order request.
     * Gets the current orderid.
     */
    useEffect(() => {

        switch(parseInt(aState.orderid)){
            case  0:
            case -1:
                //Do nothing
            break;
            case -100:
                //Session validation error.
                setErrorMsg("Failed to order. Your session has expired.")
                setOrderStatus("error");
            break;
            case -200:
                //Insufficent credit.
                setErrorMsg("Failed to order. Insufficent credit available.")
                setOrderStatus("error");
            break;
            case -300:
                //Order error.
                setErrorMsg("Failed to order. An error has occured.")
                setOrderStatus("error");
                
            break;
            default:
                setOrderID(aState.orderid);
                setOrderStatus("ordered");
            break;

        }

    },[aState.orderid])

    /**
     * Manages the monitoring
     */
    useEffect(() => {
        switch(orderStatus){
            case "ordered":
            case "processing":
                if (!doPoll){ setDoPoll(true); }
                if (!isProcess){ setIsProcess(true); }
                break;
            case "completed":
                if (doPoll){ setDoPoll(false); window.clearInterval(pollTimer);}
                if (isProcess){ setIsProcess(false); }
                break;
            default:
                break;
        }
    },[orderStatus])

    /**
     * Activates the order poll after order has been placed/
     */
    useEffect(() => {
        if (doPoll){
            pollOrderStatus();
        }
    },[doPoll])

    /**
     * Polls for order status.
    **/
    const pollOrderStatus = () => {

        let timer = window.setInterval(function(){
            pollRequest();
        }, 10000);
        setPollTimer(timer);

        const pollRequest = () => {
            //console.log("Poll orderid:"+orderID);
            const abortController = new AbortController();
            const request = {   
                userid:sState.userid,
                orderid:orderID,
                sessionid:sState.ident,
            };

            let mounted = true;
            (async () => {
              const res = await fetch(process.env.REACT_APP_NMC_API+'/order/status', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(request),
                signal: abortController.signal,
              });
              const data = await res.json();
              if (mounted) {
                if (data.status === "success"){
                    if (data.orderStatus !== orderStatus){
                        setOrderStatus(data.orderStatus);
                    }
                }
              }
              const cleanup = () => {
                mounted = false;
                abortController.abort();
             };
             return cleanup;
            })();
        };        
    };


    /** Print template editing section **/

    /**
     * Modifies and adjust the template to match requirement.
     */
    const preparePlotRequest= () => {
        const templates = sState.templates;
        let bFound = false;
        let template ={};

        //console.log("Print:"+aState.print.size+":"+aState.print.orientation);
        templates.forEach(function (t) {
            if (t.pagesize == aState.print.size && t.orientation == aState.print.orientation){
                template = JSON.parse(JSON.stringify(t.ptemplate.items[0]));
                bFound = true;
            }
        });
        if (bFound){
            template.templatePage.type = aState.print.format.toLowerCase();
            let mapFormat;
            //console.log("Print layer: "+aState.productLayerSelect)
            switch(aState.productLayerSelect){
                case "osmm":
                case "osmmbw":
                    mapFormat = "image/svg";
                break;
                case "os10k":
                case "osvml":
                case "os25k":
                case "os50k":
                case "os250k":
                case "aerial":
                    mapFormat = "image/jpeg";
                break;
            }

            //console.log("Template items:"+JSON.stringify(template.items));
            const ext = aState.printframeextent;
            for(let i in template.templatePage.items){
                switch(Object.keys(template.templatePage.items[i]).toString()){
                  case "map":
                    template.templatePage.items[i].map.basePath = process.env.REACT_APP_WMS_PRINT;
                    template.templatePage.items[i].map.showGrid = Boolean(aState.print.gridlines);
                    template.templatePage.items[i].map.mapScale = parseInt(aState.print.scale);
                    template.templatePage.items[i].map.service = "WMS";
                    template.templatePage.items[i].map.version = "1.1.1";
                    template.templatePage.items[i].map.request = "GetMap";
                    template.templatePage.items[i].map.format = mapFormat;
                    template.templatePage.items[i].map.layers = getGeoserverName(aState.productLayerSelect);
                    template.templatePage.items[i].map.srs = "EPSG:27700";
                    template.templatePage.items[i].map.bbox = ext[0]+","+ext[1]+","+ext[2]+","+ext[3];
                    template.templatePage.items[i].map.annotations = [];
                    template.templatePage.items[i].map.transparent = true;

                    //Get grid size.
                    let size = 10;
                    if (parseInt(aState.print.scale) < 499) { 
                        size = 10;
                    } else if (parseInt(aState.print.scale) >= 500 && parseInt(aState.print.scale) <= 999){
                        size = 50;
                    } else if (parseInt(aState.print.scale) >= 1000 && parseInt(aState.print.scale) <= 3999){
                        size = 100;
                    } else if (parseInt(aState.print.scale) >= 4000 && parseInt(aState.print.scale) <= 9999){
                        size = 500;
                    } else if (parseInt(aState.print.scale) >= 10000 && parseInt(aState.print.scale) <= 79999){
                        size = 1000;
                    } else if (parseInt(aState.print.scale) >= 80000 && parseInt(aState.print.scale) <= 300000){
                        size = 10000;
                    }

                    const grid = {};
                    grid.size = size;
                    grid.lineWidth = 0.5;
                    grid.red = 0;
                    grid.green = 0;
                    grid.blue = 0;
                    grid.fontsize = 6;
                    template.templatePage.items[i].map.grid = grid;

                                    //Add Map annotations
                    //console.log("No. of annotations to draw on print: "+JSON.stringify(aState.mapAnnotations))
                    if (aState.mapAnnotations.count > 0){
                        const mapMarkups = [];
                        let annoLength = parseInt(aState.mapAnnotations.annotations.length);
                        for(let i = 0 ; i < annoLength; i++) {
                        let a = {};
                        let m = aState.mapAnnotations.annotations[i];
                        //console.log("Map annotation m."+JSON.stringify(m));
                        if (m.type === 'polygon'){
    
                            let pDetails = {};
                            pDetails.showLine = true;
                            let lineStyle = rgbStringConvert(m.stroke.color_);
                            pDetails.fillOpacity = 0
                            pDetails.lineWidth = parseFloat(m.stroke.width_);
                            pDetails.lineRed = lineStyle.r;
                            pDetails.lineGreen = lineStyle.g;
                            pDetails.lineBlue = lineStyle.b;
                            let fillStyle = rgbaStringConvert(m.fill.color_);
                            if (fillStyle.a === 0) { pDetails.showFill = false; } else { pDetails.showFill = true; }
                            pDetails.fillOpacity = fillStyle.a;
                            pDetails.fillRed = fillStyle.r;
                            pDetails.fillGreen = fillStyle.g;
                            pDetails.fillBlue = fillStyle.b;
                            let points = [];
                            let coords = m.coordinates.toString().split(',');
                            for(let p = 0 ; p < coords.length; p+=2) {
                                let coodv = {};
                                coodv.x = coords[p];
                                coodv.y = coords[p+1];
                                points.push(coodv);
                            }
                            pDetails.points = points;
                            a.polygon = pDetails;
                            mapMarkups.push(a);
                        }

                        if (m.type === 'circle'){
                            //console.log("Map annotation m."+JSON.stringify(m));
                            //{"circle":{"showLine":true,"fillOpacity":0.9,"lineWidth":0.1,"lineRed":0,"lineGreen":255,"lineBlue":0,"showFill":false,"fillRed":255,"fillGreen":255,"fillBlue":0,"x":322663.9583, "y":612284.4166,"radius":100}},
                            
                            let pDetails = {};
                            pDetails.showLine = true;
                            let lineStyle = rgbStringConvert(m.stroke.color_);
                            pDetails.fillOpacity = 0
                            pDetails.lineWidth = parseFloat(m.stroke.width_);
                            pDetails.lineRed = lineStyle.r;
                            pDetails.lineGreen = lineStyle.g;
                            pDetails.lineBlue = lineStyle.b;
                            let fillStyle = rgbaStringConvert(m.fill.color_);
                            if (fillStyle.a === 0) { pDetails.showFill = false; } else { pDetails.showFill = true; }
                            pDetails.fillOpacity = fillStyle.a;
                            pDetails.fillRed = fillStyle.r;
                            pDetails.fillGreen = fillStyle.g;
                            pDetails.fillBlue = fillStyle.b;

                            pDetails.x = m.x;
                            pDetails.y = m.y;
                            pDetails.radius = m.radius;
                            a.circle = pDetails;
                            mapMarkups.push(a);
                            
                        }

                        if (m.type === 'polyline' || m.type === 'linestring'){
                            //console.log("Map polyline annotation m."+JSON.stringify(m));
                            //{"polyline":{"showLine":true,"fillOpacity":0.9,"lineWidth":0.1,"lineRed":0,"lineGreen":0,"lineBlue":255,"showFill":false,"fillRed":0,"fillGreen":255,"fillBlue":0,"points":[{"x":320722,"y":611654},{"x":320900,"y":611550},{"x":321121,"y":611500},{"x":321321,"y":611490},{"x":321151,"y":611660}]}}
                            let pDetails = {};
                            pDetails.showLine = true;
                            let lineStyle = rgbaStringConvert(m.stroke.color_);
                            pDetails.lineWidth = parseFloat(m.stroke.width_);
                            pDetails.lineRed = lineStyle.r;
                            pDetails.lineGreen = lineStyle.g;
                            pDetails.lineBlue = lineStyle.b;
                            pDetails.lineStyle = m.stroke.lineDash_;

                            let points = [];
                            let coords = m.coordinates.toString().split(',');
                            for(let p = 0 ; p < coords.length; p+=2) {
                                let coodv = {};
                                coodv.x = coords[p];
                                coodv.y = coords[p+1];
                                points.push(coodv);
                            }
                            pDetails.points = points;
                            a.polyline = pDetails;
                            mapMarkups.push(a);
                            
                        }

                        if (m.type === 'text'){
                            //{"type":"text","fill":{"color_":"rgba(244,67,54,0)"},"stroke":{"color_":"rgb(244,67,54)","lineDash_":null,"width_":3},"text":{"font_":"12px Arial","scaleArray_":[1,1],"text_":"My text to add","fill_":{"color_":"#333"},"maxAngle_":0.7853981633974483,"placement_":"point","overflow_":false,"stroke_":null,"offsetX_":0,"offsetY_":0,"backgroundFill_":null,"backgroundStroke_":null,"padding_":null},"extent":[525584.6242675781,168224.19299316406,525584.6242675781,168224.19299316406]}
                            let pDetails = {};
                            pDetails.text = m.text.text_;
                            let coords = m.extent.toString().split(',');
                            pDetails.x = coords[0];
                            pDetails.y = coords[1];
                            pDetails.width = coords[2] - coords[0];
                            pDetails.height = coords[3] - coords[1];
                            
                            let fontfill = m.fill.color_;
                            fontfill = fontfill.replace('rgba(', '');
                            fontfill = fontfill.replace(')', '');
                            let afontfill = fontfill.split(',');
                            pDetails.fillRed = afontfill[0];
                            pDetails.fillGreen = afontfill[1];
                            pDetails.fillBlue = afontfill[2];
                            pDetails.fillOpacity = afontfill[3];
                            pDetails.showFill = true;
    
                            pDetails.lineWidth = parseFloat(m.stroke.width_);
    
                            let font = m.text.font_.split(' ');
                            let fontSize = font[0].replace('px', '');
    
                            let fontStyle = {};
                            fontStyle.size = parseInt(fontSize);
                            fontStyle.family =font[1];
                            fontStyle.red = 0;
                            fontStyle.green = 0;
                            fontStyle.blue = 0;
                            pDetails.font = fontStyle;
                            //"font": {"family": "Arial","style": "","size": 6,"red": 0,"green": 0,"blue": 0}
    
                            a.text = pDetails;
                            mapMarkups.push(a);
                        }
    
    
                        }
                        template.templatePage.items[i].map.annotations = mapMarkups;
                    }
                    /*
                    "annotations":[
                        
                        {"polygon":{"showLine":true,"fillOpacity":0.9,"lineWidth":0.1,"lineRed":255,"lineGreen":0,"lineBlue":0,"showFill":false,"fillRed":0,"fillGreen":0,"fillBlue":255,"points":[{"x":320650,"y":612000},{"x":321500,"y":611400},{"x":321200,"y":611200},{"x":320700,"y":611900},{"x":320750,"y":611600}]}},
                        {"circle":{"showLine":true,"fillOpacity":0.9,"lineWidth":0.1,"lineRed":0,"lineGreen":255,"lineBlue":0,"showFill":false,"fillRed":255,"fillGreen":255,"fillBlue":0,"x":322663.9583, "y":612284.4166,"radius":100}},
                        {"polyline":{"showLine":true,"fillOpacity":0.9,"lineWidth":0.1,"lineRed":0,"lineGreen":0,"lineBlue":255,"showFill":false,"fillRed":0,"fillGreen":255,"fillBlue":0,"points":[{"x":320722,"y":611654},{"x":320900,"y":611550},{"x":321121,"y":611500},{"x":321321,"y":611490},{"x":321151,"y":611660}]}},
                        {"text":{"text":"Map position", "x":321822,"y":612254, "width": 30, "height":20 ,"showLine":true,"fillOpacity":0.9,"lineWidth":0.1,"lineRed":0,"lineGreen":0,"lineBlue":255,"showFill":false,"fillRed":0,"fillGreen":255,"fillBlue":0,"font": {"family": "Arial","style": "","size": 6,"red": 0,"green": 0,"blue": 0}}}
                    ]*/

                    break;
                  case "image":
                    switch (template.templatePage.items[i].image.id){
                        case "nmc_logo": 
                           template.templatePage.items[i].image.name = process.env.REACT_APP_PRINT_NMCLOGO;
                        break;
                        case "client_logo":
                            template.templatePage.items[i].image.name = sState.default.dprintlogo;
                        break;
                        case "north_arrow":
                            template.templatePage.items[i].image.name = process.env.REACT_APP_PRINT_NORTHARROW;
                        break;
                        case "os_logo":
                            template.templatePage.items[i].image.name = process.env.REACT_APP_PRINT_OSLOGO
                    }

                  break;

                  case "cell":
                    //console.log("Template cell items:"+template.items[0].templatePage.items[i].cell.id);
                    
                    switch (template.templatePage.items[i].cell.id){
                        case "ProductName":
                            template.templatePage.items[i].cell.text = getProductName(aState.productLayerSelect, 'plot');
                            break;                        
                        case "scale":
                            template.templatePage.items[i].cell.text = "1: "+aState.print.scale;
                        break;
                        case "Title of Map":
                            template.templatePage.items[i].cell.text = "";
                            //template.templatePage.items[i].cell.text = aState.projectreference;
                        break;
                        case "Additional Information":
                            let add = aState.caddress.name;
                            add = add.replace(/,/g, '\n');
                            template.templatePage.items[i].cell.text = add;
                        break;
                        case "SuppliedBy":
                            template.templatePage.items[i].cell.text = process.env.REACT_APP_SUPPLIEDBY;
                        break;
                        case "Date":
                            let date = new Date();
                            const day= ('0'+date.getDate()).slice(-2);
                            const month = ('0'+(date.getMonth()+ 1)).slice(-2);
                            template.templatePage.items[i].cell.text = day+'/'+ month +'/'+date.getFullYear();
                        break;
                        case "OrderId":
                            template.templatePage.items[i].cell.text = "$(orderid)";
                        break;
                        case "CentreCoords":
                            const cx = parseFloat(ext[0]) + parseFloat((parseFloat(ext[2]) - parseFloat(ext[0]))/2);
                            const cy = parseFloat(ext[1]) + parseFloat((parseFloat(ext[3]) - parseFloat(ext[1]))/2);
                            template.templatePage.items[i].cell.text = cx.toFixed(0)+","+cy.toFixed(0);
                        break;
                        case "DownloadName":
                            template.templatePage.items[i].cell.text = "";
                        break;
                        case "ProjectRef":
                            template.templatePage.items[i].cell.text = aState.projectreference;
                        break;
                        case "LicenseNumber":
                            template.templatePage.items[i].cell.text = process.env.REACT_APP_PRINT_LICENSENUMBER;
                        break;
                        case "copyright":
                            template.templatePage.items[i].cell.text = process.env.REACT_APP_COPYRIGHT.replace("dddd", new Date().getFullYear());
                        break;
                    }
                  break;
                }
            }
        } else {
            console.log("Template not found!");
        }
        return template;
    };

    /**
     * Requests the download.
     */
    const handleFileDownload = (event) => {
        downloadOrder(orderID, sState.ident, sState.userid);
    }


    const gotoDownload = () => {
        aDispatch({
            type: "updatetoplevel",
            payload: { toplevel: 'download', viewStep: 'list', orderStep:''}
        });
    }

    const rgbStringConvert = (rgb) => {
  
        rgb = rgb.replace('rgb(', '');
        rgb = rgb.replace(')', '');
        const rgb_parts = rgb.split(',');
        const v = {};
        v.r = parseInt(rgb_parts[0]);
        v.g = parseInt(rgb_parts[1]);
        v.b = parseInt(rgb_parts[2]);
        return v;
      }
      
      const rgbaStringConvert = (rgba) => {
        
        rgba = rgba.replace('rgba(', '');
        rgba = rgba.replace(')', '');
        const rgba_parts = rgba.split(',');
        const v = {};
        v.r = parseInt(rgba_parts[0]);
        v.g = parseInt(rgba_parts[1]);
        v.b = parseInt(rgba_parts[2]);
        v.a = parseFloat(rgba_parts[3]);
        return v;
      }

  return (
    <div className={classes.root}>
        <Box display="block" className={classes.completeContainer} m={1} p={1} >
            <Box className={classes.completeTitle} fontWeight="500" fontStyle="italic" fontSize={22} >Thank you for your order</Box>
            <Box className={classes.completeDescription} fontSize={16}>{process.env.REACT_APP_ORDER_DOWNLOAD_BODY}</Box>
            <ProjectPaper disabled={true}/>
            <div className={classes.options}>
                {isProcess ? ( 
                    <Button className={classes.downloadButton} variant="contained" disabled>
                        <Box display="flex"><Box><ReactLoading className={classes.loadingImg} type={'spin'} color={'#F6A036'} height={20} width={20}/></Box><Box fontWeight="fontWeightBold" fontSize={16}>&nbsp;&nbsp;Processing</Box></Box>
                    </Button>
                ) : ( 
                    <Button className={classes.downloadButton} variant="contained" onClick={handleFileDownload} >
                        <Box className={classes.downloadIconContainer} ><DownloadIcon className={classes.downloadIcon} /></Box><Box fontWeight="fontWeightRegular" fontSize={16} >&nbsp;&nbsp;Download now</Box>
                    </Button>
                )}
                {orderStatus === 'error' ? (<Box className={classes.orderError} fontWeight="500" fontSize={18} >{errorMsg}</Box>) : ('')}
                <Button className={classes.menuButton} onClick={gotoDownload}>
                    <Box fontSize={12}>All files available for download</Box>
                </Button>
            </div>
        </Box>      
    </div>
  );
}