/**
 * Records the Order.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function recordPrintOrder(aState, p, sState, geometry, status, license, licenseexpiry, template, dispatch) {

    const abortController = new AbortController();
    const request = {   
        userid:sState.userid,
        accountid:sState.aid,
        product:aState.productLayerSelect,
        userref:aState.projectreference,
        projectname:aState.projectreference,
        price:p.price,
        royalty:0,
        vat:p.vat,
        ident:sState.ident,
        ordertype: aState.productSelect,
        copies: aState.print.copies,
        status: status,
        license: license,
        licenseexpiry: licenseexpiry,
        geometry:geometry,
        template:template,
    };

    let mounted = true;
    (async () => {  
        const res = await fetch(process.env.REACT_APP_NMC_API+'/order/print', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(request),
        signal: abortController.signal,
        }).catch(handleError);
        const data = await res.json();
        if (mounted) {
          let oid = data.orderid;
          //if (data.status === "success"){ oid = data.orderid; } else { oid = -1; }
          dispatch({
            type: "setorderid",
            payload: { orderid: oid}
          });
        }
        const cleanup = () => {
            mounted = false;
            abortController.abort();
        };
        return cleanup;
    
    })();

}
