function parseMeasureLength (annotationLength, measureUnit, units) {

    var output = {};
    let selectedLength = Math.round(annotationLength * 100) / 100;
    let value = ''
    switch (measureUnit){
        default:
        case "m":
            output.value = Math.round(selectedLength * 100) / 100;
            if (units) output.value += 'm';
        break;
        case "ft":
            output.value = Math.round((selectedLength * 3.281) * 100) / 100;
            if (units) output.value += 'ft';
        break;
        case "km":
            output.value = Math.round((selectedLength / 1000 ) * 100) / 100;
            if (units) output.value += 'km';
        break;
        case "mile":
            output.value = Math.round((selectedLength / 4047) * 100) / 100;
            if (units) output.value += ' miles';
        break;
    }
    output.raw = selectedLength;
    return output;
}
export default parseMeasureLength;