/**
 * Creates or updates a full pricing model.
 * This is a super call.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function createCompany(params, company, dispatch) {

        const abortController = new AbortController();

        const request = {
            userid: params.userid,
            ident: params.ident,
            companyid:company.companyid,
            businessid:company.businessid,
            companyname:company.companyname,
            creditlimit:company.creditlimit,
            business_type:company.business_type,
            parent_business:company.parent_business,
            dtm_parent:company.dtm_parent,
            hasvat:company.hasvat,
            vaton:company.vaton,
            status:company.status,
            pricingmodelid:company.pricing_id,
            defaultsettings:company.defaultsettings
        }
        let mounted = true;
        (async () => {  
            const res = await fetch(process.env.REACT_APP_NMC_API+'/admin/company/save', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(request),
                signal: abortController.signal,
            }).catch(handleError);
            const data = await res.json();
            if (mounted) {
              if (data.status === "success"){
                dispatch({
                    type: "setcompanies",
                    payload: { companies: data.companies}
                  });
              }
            }
            const cleanup = () => {
                mounted = false;
                abortController.abort();
            };
            return cleanup;

        })();

    }
