import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { ActivityContext } from "../../context/activity-context";

const useStyles = makeStyles({

  root: {
    flexGrow: 1,
    color: 'black',
    paddingLeft:10,
    paddingTop:20
  },
  coordsLabel: {
    fontSize:11,
    paddingLeft:10,
  },
  mapCoords: {
    fontSize:11,
    minWidth:90,
    paddingRight:0,
    paddingLeft:10,
  },
  mapScale: {
    fontSize:11,
    minWidth:40,
    paddingRight:0,
    paddingLeft:27,
  },
  mapArea: {
    fontSize:11,
    minWidth:40,
    paddingRight:0,
    paddingLeft:10,
  },
  coordsMetres: {
    fontSize:11,
    paddingLeft:0,
  }
});

export default function MappingCoordinates() {

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [mapArea, setMapArea] = useState('');
  const [mapUnits, setMapUnits] = useState('');
  const [viewScale, setViewScale] = useState(0);

  useEffect(() => {
    setMapArea(aState.mapArea);
  },[aState.mapArea])

  useEffect(() => {
    setMapUnits(aState.mapAreaUnits);
  },[aState.mapAreaUnits])

    /**
   * Sets the view scale based on the current map scale.
   */
  useEffect(() => {
    if (aState.cviewscale !== viewScale){
      setViewScale(aState.cviewscale);
    }
  },[aState.cviewscale]);

    return (
      <div className={classes.root}>
          <Box display="flex" justifyContent="flex-start" >
            <Box className={classes.coordsLabel}> BNG Ref.</Box>
            <Box className={classes.mapCoords}> <div id='map_coordinates'/></Box>
            <Box className={classes.coordsMetres}> Metres</Box>
          </Box>
          <Box display="flex" justifyContent="flex-start" >
            <Box className={classes.coordsLabel}> Scale.</Box>
            <Box className={classes.mapScale}>1: {viewScale}</Box>
          </Box>  
          <Box display="flex" justifyContent="flex-start" >
            <Box className={classes.coordsLabel}> Viewing Area.</Box>
            <Box className={classes.mapArea}>{mapArea} {mapUnits}</Box>
          </Box>  
      </div>
    );
  }