import React, {useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Accounts from '../AccountInterface';
import Downloads from '../DownloadInterface';
import MenuButton from '../button/MenuButton';
import MainButton from '../button/MainMenuButton';

import Logoutuser from '../../functions/logoutUser';

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

const imgLogo = require('../../assets/nmc_north_arrow.png');

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    background: 'white',
    color: 'white',
    
  },
  appbar:{
    boxShadow: 'none',
    borderBottom: '1px solid #D2D2D8',
  },
  buttonContainer: {
    alignSelf: 'flex-end',
  },
  menuButton: {
    color: '#273090',
      '&:hover': {
        color: '#F9AF44'
      }
  },
  logo: {
    width: 50,
    height: 60,
    paddingRight: 30,
  },
  toolbar: {
    display:"flex",
    flexDirection:"row",
    flexWrap:"nowrap",
    background: 'white',
    height: 80,
    alignItems: 'flex-start',
    
  },
  containerDiv: {
    paddingTop: 10,
    display:"flex",
    flexDirection:"row",
    height: 60,
},

});

export default function Topbar(props) {
  const classes = useStyles();
  const [sState, sDispatch] = useContext(SessionContext);
  const [aState, aDispatch] = useContext(ActivityContext);
  const [isActive, setIsActive] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isProject, setIsProject] = useState(false);
  const [isAccount, setIsAccount] = useState(false);
  const [seletedMenu, setSeletedMenu] = useState('login');

   /**
   * Changes interface based on required view.
   */
  useEffect(() => {
    if (seletedMenu !== aState.toplevel && sState.isActive) { handleMenuChange(aState.toplevel); }
  }, [aState.toplevel]);

  /**
   * Handles the display and selection of main menu options.
   */
  const handleMenuChange = (menu) => {
    let topMenu = '';
    let mappingStep = '';
    let orderStep = '';
    let isLogout = false;
    setSeletedMenu(menu);
    switch(menu){
      case 'mapping':
        setIsCreate(true);
        setIsDownload(false);
        setIsProject(false);
        
        topMenu = 'mapping';
        mappingStep = 'address';
        orderStep = 'addressSearch';
      
      if (!aState.isProject){  
        //Reset params.
        aDispatch({ type: "resetmap", toplevel: topMenu });
      }
      break;
      case 'download':
        setIsCreate(false);
        setIsDownload(true);
        setIsProject(false);
        setIsAccount(false);
        topMenu = 'download';
        mappingStep = 'list';
        orderStep = '';
        break;
      case 'project':
        setIsCreate(false);
        setIsDownload(false);
        setIsProject(true);
        setIsAccount(false);
        topMenu = 'project';
        mappingStep = 'list';
        orderStep = '';
        break;
      case 'account':
        setIsCreate(false);
        setIsDownload(false);
        setIsProject(false);
        setIsAccount(true);
        topMenu = 'account';
        mappingStep = '';
        orderStep = '';
      break;
      case 'logout':
        setIsCreate(false);
        setIsDownload(false);
        setIsProject(false);
        setIsAccount(false);
        isLogout = true;
        Logoutuser(sState.userid, sState.ident, sDispatch, aDispatch);
      break;
      default:
        setIsCreate(false);
        setIsDownload(false);
        setIsProject(false);
        setIsAccount(false);
        topMenu = 'mapping';
        mappingStep = '';
        orderStep = '';
      break;
    }

    if (!aState.isProject && !isLogout){
      //console.log('updatetoplevel has happened.');
      aDispatch({
        type: "updatetoplevel",
        payload: { toplevel: topMenu, viewStep: mappingStep, orderStep:orderStep}
      });
    }
  };

  /**
   * Displays the help pages.
   */
  function handleHelpSubmit() {
    window.open(process.env.REACT_APP_HELPSITE, "_blank");
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Box component="div" className={classes.containerDiv} justifyContent="flex-start" alignItems="center" flexGrow={1}>
            <Box p={1}> <img src={imgLogo} alt="" className={classes.logo} /></Box>
            <MainButton label="Create New" selected={isCreate} handleChange={() => {handleMenuChange('mapping')} }/>
            <MainButton label="Download Orders" selected={isDownload} handleChange={() => {handleMenuChange('download')} }/>
            <MainButton label="Saved Projects" selected={isProject} handleChange={() => {handleMenuChange('project')} }/>
          </Box>
          <Box component="div" className={classes.containerDiv} justifyContent="flex-end" alignItems="center">
            <MainButton label="My Account" selected={isAccount} handleChange={() => {handleMenuChange('account')} }/>
            <MenuButton label="Help" selected={false} handleClick={() => {handleHelpSubmit()} }/>
            <MainButton label="Logout" selected={false} handleChange={() => {handleMenuChange('logout')} } />
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}