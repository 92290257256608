/**
 * Gets list of orders ordered by user.
 * Called from UserInterface.js
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function getAdminReports(userid, addressid, dispatch) {

        const abortController = new AbortController();

        let fromDate = new Date();
        let toDate = new Date();
        fromDate.setDate(fromDate.getDate() - 60);

        const startDate = fromDate.getFullYear()+'-'+ ('0'+(fromDate.getMonth()+ 1)).slice(-2) +'-'+('0'+fromDate.getDate()).slice(-2);
        const endDate = toDate.getFullYear()+'-'+ ('0'+(toDate.getMonth()+ 1)).slice(-2) +'-'+('0'+toDate.getDate()).slice(-2);

        let mounted = true;
        (async () => {  
        const res = await fetch(process.env.REACT_APP_NMC_API+'/super/reports/list?userid='+userid+'&addressid='+addressid+'&fromDate='+startDate+'&toDate='+endDate, {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          signal: abortController.signal,
        }).catch(handleError);
          const data = await res.json();
          if (mounted) {
            if (data.status === "success"){
                dispatch({
                    type: "setadminreport",
                    payload: { 
                        reports: data.orders,
                    }
                });
    
            } else {
            }
          }
          const cleanup = () => {
            mounted = false;
            abortController.abort();
          };
          return cleanup;
      
      })();

    }
