import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import getDataPrice from "../../functions/getDataPrice";
import PRPanel from "./ProjectReferencePanel";

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

import {ReactComponent as PrintIcon} from '../../assets/dark_icons/icon_print.svg';
import {ReactComponent as DataIcon} from '../../assets/dark_icons/icon_data.svg';
import { toUserExtent } from 'ol/proj';

const formatProducts = [
  { label: 'DWG', value: 'dwg'},
  { label: 'DXF', value: 'dxf'}
];

const sizeProducts = [
  { label: '1 ha. (100m x 100m)', value: '1ha'},
  { label: '4 ha. (200m x 200m)', value: '4ha'},
  { label: 'Custom', value: 'custom'},
];

const imgSizeProducts = [
  { label: 'Custom', value: 'custom'},
];

export default function DataPanel(props) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: 270,
      height: props.height - 51,
      //borderLeft: '1px solid #D2D2D8',
      overflowY: 'scroll',
    },
    productTitle: {
      paddingLeft:16,
      color: '#5C5D6B',
    },
    productContainer: {
      //width: 270,
      display:"flex",
      flexDirection:"row",
      height:65,
      paddingLeft:16
    },
    productButton: {
      width: 118,
      height:65,
      backgroundColor:"#FFF",
      '&:hover': {
          color: '#C1DAE9'
      },
      '&.MuiToggleButton-root.Mui-selected': {
          backgroundColor: '#C1DAE9',
          color: '#FFF',
      },
    },
    LabelContainer:{
      alignItems: 'center',
    },
    iconContainer:{
      width:'100%',
    },
    buttonLabel:{
      width:'100%',
    },
    printIcon:{
      width:20,
      height:24
    },
    dataIcon:{
      width:16,
      height:24
    },
    productListContainer:{
      padding: 16,
      maxHeight: 350,
      overflowY: 'auto',
    },
    productItem:{
        color: '#000',
        backgroundColor: '#FFF',
        '&.MuiListItem-root.Mui-selected': {
          backgroundColor: '#C1DAE9',
        },
        '&.MuiListItem-root':{
          paddingTop:4,
          paddingBottom:4,
        }
        //border: '1px solid #EDEDEF',
    },
    listItemText:{
      fontSize:12,//Insert your required size
    },
    paperCopiesContainer:{
      height:50
    },
    printcopies:{
      paddingLeft:10,
      width:100
    },
    paperGridContainer:{
      height:32
    },
    gridlineLabel:{
      paddingTop:12
    },
    editCustomButton: {
      textDecoration: 'underline',
      color: '#000',
        '&:hover': {
          color: '#F9AF44'
        },
      marginLeft: 10,
      marginTop: -20,
  }
  
  }));


  const classes = useStyles();
  const [sState, sDispatch] = useContext(SessionContext);
  const [aState, aDispatch] = useContext(ActivityContext);
  const [product, setProduct] = useState('');
  const [format, setFormat] = useState('');
  const [size, setSize] = useState(0);
  const [getprice, setGetprice] = useState(false);
  const [projectReference, setProjectReference] = useState('');
  const [isMastermap, setIsMastermap] = useState(false);
  

  useEffect(() => {
    setProduct(aState.productLayerSelect);
    if (product !== aState.productLayerSelect && aState.productSelect === 'data' && (aState.productLayerSelect === "osmm9" || aState.productLayerSelect === "osmm55")) { 
      //console.log("Is saved."+aState.data.format+":"+aState.data.fixedsize);
      displayLoading();
      
      if (!aState.isProject) {
        configureDataOptions(); 
      } else {
        console.log("This is saved project. loading format and fixedsize."+aState.data.format+":"+aState.data.fixedsize);
        setFormat(aState.data.format);
        setSize(aState.data.fixedsize);
        //If its from a saved project load, turn off flag.
        //if (aState.isProject) { aDispatch({ type: "savedProjectLoad", payload: { isProject: false }});  }
      }
      setIsMastermap(true);
    } else {
      setFormat('tif');
      setIsMastermap(false);
    }
  },[aState.productLayerSelect]);

  const configureDataOptions = () => {
    setFormat(sState.default.ddataformat.toLowerCase());
    if (sState.default.ddatascale !== ''){ setSize(sState.default.ddatascale.toLowerCase()); } else { setSize('4ha'); }

  };

  const handleDataTypeClick = (event, v) => {
    setFormat(v);
  };

  const handleDataSizeClick = (event, v) => {
    setSize(v)
  };

  var dataTimer = 0;
  useEffect(() => {
    if (aState.toplevel == 'mapping' && aState.orderStep == 'data'){
      console.log("Processing data change request, with timer.")
      displayLoading();

      clearTimeout(dataTimer);
      dataTimer = setTimeout(function() {
        handleDataChange(true);
      }.bind(this), 1500);
      return function cleanup() {
        clearTimeout(dataTimer);
      };
    }
  },[product, size, format])


  const displayLoading = () => {
    if (!aState.priceloading){
      aDispatch({
        type: "setpriceloading",
        payload: { priceloading: true}
      });
    }
  }


  /**
   * Handle the changing of data.
  */
  const handleDataChange = (updatePrice) => {
    //if (!aState.isProject){  //Temp removed to test issue with saved project.  Why was isProject test used.
      const d = {
        layer: product,
        fixedsize: size,
        format: format,
        geom: aState.datageom
      }
      aDispatch({
        type: "setdata",
        payload: { data: d}
      });
      setGetprice(updatePrice);
    //}
  };

  /**
   * Gets and returns the price for the data.
   * Requires getprice is true and aState.datageom.area
   */
  var dataPriceTimer = 0;
  useEffect(() => {

    if (aState.datageom.area > 0){

      clearTimeout(dataPriceTimer);
      dataPriceTimer = setTimeout(function() { 

        const request = {
          pricingid: sState.company.pricingid,
          aid: sState.aid,
          layer: product,
          fixedsize: size,
          fixedprice: Boolean(sState.default.ddatafixedprice),
          format: format,
          geom: aState.datageom
      }
      getDataPrice(request, aState, aDispatch);
      setGetprice(false);

      }.bind(this), 1500);
      return function cleanup() {
        clearTimeout(dataPriceTimer);
      };

    }

  },[aState.datageom])


  const displayToolbar = () => {
    aDispatch({ type: "isDataCustom", payload: { isDataCustom: true, hasToolbar: true  } });
  }
  
  return (
    <div className={classes.root}>
      <PRPanel />
      <Divider />
      {isMastermap ? 
      <div>
        <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>PRODUCT FORMAT</Box>
        <div className={classes.dataFormatContainer}>
          <List component="nav" aria-label="Product Format">
            {formatProducts.map((item, i) => (
              <React.Fragment key={i}>
                    <ListItem 
                        key={i}
                        button
                        className={classes.productItem}
                        selected={format === item.value}
                        onClick={(event) => handleDataTypeClick(event, item.value)}
                        >
                        <ListItemText primary={item.label} classes={{primary:classes.listItemText}}/>
                    </ListItem>
              </React.Fragment>
            ))}
          </List>
        </div>
        <Divider />
        <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>FIXED SIZES</Box>
        <div className={classes.paperSizeContainer}>
          <List component="nav" aria-label="Fixed Size">
            {sizeProducts.map((item, i) => (
              <React.Fragment key={i}>
                    <ListItem 
                        key={i}
                        button
                        className={classes.productItem}
                        selected={size === item.value}
                        onClick={(event) => handleDataSizeClick(event, item.value)}
                        >
                        <ListItemText primary={item.label} classes={{primary:classes.listItemText}}/>
                    </ListItem>
              </React.Fragment>
            ))}
          </List>
          {size === 'custom' ? <Button className={classes.editCustomButton} onClick={displayToolbar}> <Box fontSize={10}>Edit custom</Box></Button> :''}
        </div>
      </div> : <div>
        <Box p={1} className={classes.productTitle} fontWeight="fontWeightBold" fontSize={12}>SIZE</Box>
          <div className={classes.paperSizeContainer}>
            <List component="nav" aria-label="Fixed Size">
              {imgSizeProducts.map((item, i) => (
                <React.Fragment key={i}>
                      <ListItem 
                          key={i}
                          button
                          className={classes.productItem}
                          selected={size === item.value}
                          onClick={(event) => handleDataSizeClick(event, item.value)}
                          >
                          <ListItemText primary={item.label} classes={{primary:classes.listItemText}}/>
                      </ListItem>
                </React.Fragment>
              ))}
            </List>
            {size === 'custom' ? <Button className={classes.editCustomButton} onClick={displayToolbar}> <Box fontSize={10}>Edit custom</Box></Button> :''}
          </div>
        </div>}
    </div>
  );
}