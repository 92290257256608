import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import getReports from "../../functions/getAdminReports";
import getDTMParents from "../../functions/getDTMParents";
import recordReportRequest from "../../functions/recordReportOrder";
import downloadOrder from "../../functions/downloadOrder";

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";
import ReactLoading from 'react-loading';

import {ReactComponent as DownloadIcon} from '../../assets/dark_icons/iconmonstr-download-19 1.svg';
import {ReactComponent as SearchIcon} from '../../assets/dark_icons/iconmonstr-magnifier-6 1.svg';

const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    },
    formLabel:{
        marginLeft: 90,
        marginTop: 40,
        marginBottom: 20,
        color: '#447095',
    },
    formsearch:{
      display:"flex",
      flexDirection:"row",
      marginLeft: 90,
      marginRight: 90,
      marginBottom: 10,
      height: 300,
      backgroundColor: '#ECF4F9',
      flexWrap:"nowrap",
      alignItems: 'flex-start',
    },
    formtable:{
      marginLeft: 90,
      marginRight: 90,
      marginBottom: 20,
      minHeight: 430,
      color: '#447095',
    },
    textField:{
      minWidth: 200,
    },
    searchContainer:{
      paddingTop: 20,
    },
    searchButton:{
      marginTop: 30,
      height:40,
      backgroundColor:'#2D2F88',
      color: '#FFF',
      '&:hover': {
        color: '#F6A036',
      },
    },
    searchIcon: {
      width: 16,
      height: 15
    },
    keywordsField:{
      marginTop: 20,
    },
    downloadIcon: {
      width: 16,
      height: 16
    },
    downloadButton: {
        width: 200,
        backgroundColor:'#CAD8BA',
        color: '#304517',
        '&:hover': {
          color: '#F6A036',
        },
    },
  });


export default function AdminReportInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [dtmParents, setDTMParents] = useState([]);
    const [dtmParent, setDTMParent] = useState('');
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [selection, setSelection] = useState('');
    const [selectionLabel, setSelectionLabel] = useState('');
    const [isProcess, setIsProcess] = useState(true);
    const [orderID, setOrderID] = useState(0);
    const [orderStatus, setOrderStatus] = useState('');
    const [doPoll, setDoPoll] = useState(false);
    const [pollTimer, setPollTimer] = useState(null);
    const [tableRows, setTableRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState('');
    
    


    /**
     * First load
     */
    useEffect(() => {

      let dtFromDate = new Date();
      let dtToDate = new Date();
      dtFromDate.setDate(dtFromDate.getDate() - 7);

      setFromDate(dtFromDate);
      setToDate(dtToDate);

      getReports(sState.userid, sState.aid, aDispatch);
      getDTMParents(aDispatch);
      pollOrderStatus();
  },[])


  useEffect(() => {
      if (aState.dtmParents.length > 0){
        setDTMParents(aState.dtmParents);
      }
  },[aState.dtmParents])

  

    const handleReportChange = (event) => {
        console.log("Select report changed");
        //setSelection(event.target.value);
        setSelectedReport(event.target.value);
        setSelectionLabel(getReportName(event.target.value));
    };

    const getReportName = (reportid) => {
      let name = "";
      switch(reportid){
        default:
        case "invoicereport":
          name = "Invoice Report";
          break;
        case "royaltyreport":
          name = "Royalty Report";
          break;
        case "dtmreport":
          name = "DTM Usage Report";
          break;
        case "clientreport":
          name = "Client Report";
          break;
        case "osreturns":
          name = "OS Royalty Return Report";
          break;
      }
      return name;
    }

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const handleReportRequest = () => {
      //console.log("Report "+selectedReport)
      const request = {
        reportid: selectedReport,
        name: selectionLabel,
      }
      //console.log("Report "+selectionLabel+" ordered.");
      if (selectedReport !== ''){
        recordReportRequest(sState, request, dtmParent, fromDate, toDate, aDispatch);

        setTimeout(function (){
          getReports(sState.userid, sState.aid, aDispatch);
        }, 1000);
      }
    };

        /**
     * Listerns for change to admin report list.
     */
    useEffect(() => {

      if (aState.adminreport.length > 0){
        
        setTableRows(aState.adminreport);
      }
  },[aState.adminreport])
    

    const columns = [
      { field: 'orderid', headerName: 'Order ID', width: 130 },
      { field: 'projectname', headerName: 'Project name', width: 400 },
      { field: 'username', headerName: 'Created by', width: 200 },
      { field: 'orderdate', headerName: 'Order date', type: 'date', width: 160},
      { field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        getDownloadOptions(params)
      ),},
    ];
    
      /**
       * Displays the download options for user orders and allows to download.
       */
      function getDownloadOptions(params) {

        const handleDownloadRequest = () => {
          const api = params.api;
          const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);
          const thisRow = {};

          fields.forEach((f) => {
            thisRow[f] = params.getValue(f);
          });
          downloadOrder(thisRow.orderid, sState.ident, sState.userid);
          console.log("Orderid selected ="+JSON.stringify(thisRow));
        };

        const orderid = parseInt(params.getValue('orderid'));
        if (params.value === 'completed'){
            return <Button className={classes.downloadButton} variant="contained" onClick={handleDownloadRequest}>
              <Box className={classes.downloadIconContainer} ><DownloadIcon className={classes.downloadIcon} /></Box><Box fontWeight="fontWeightRegular" fontSize={12} >&nbsp;&nbsp;Download now</Box>
            </Button>;
        } else {
          return '';
        }

      }

    /**
     * Polls for order status.
    **/
    const pollOrderStatus = () => {

      let timer = window.setInterval(function(){
          pollRequest();
      }, 10000);
      setPollTimer(timer);

      const pollRequest = () => {
        getReports(sState.userid, sState.aid, aDispatch);
      };        
  };


    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.formLabel} fontWeight="500" fontStyle="italic" fontSize={22} >Admin Reports</Box>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.formsearch}>
                  <Grid container justify="space-around" className={classes.searchContainer}>
                    <Grid item xs={12}>
                      <RadioGroup aria-label="Reports" name="reports" value={selectedReport} onChange={handleReportChange}>
                          <FormControlLabel value="invoicereport" control={<Radio />} label="Invoice Report" />
                          <FormControlLabel value="royaltyreport" control={<Radio />} label="Royalty Report" />
                          <FormControlLabel value="dtmreport" control={<Radio />} label="DTM Usage Report" />
                          <FormControlLabel value="clientreport" control={<Radio />} label="Client Report" />
                          <FormControlLabel value="osreturns" control={<Radio />} label="OS Royalty Return Report" />
                      </RadioGroup>
                    </Grid>
                    {selectedReport === "dtmreport" ? ( 
                      <Grid item xs={12}>
                            <TextField className={classes.textField} value={dtmParent} onChange={e => setDTMParent(e.target.value)} name="dtmparent" label="DTM Parent" size="small" margin="dense" variant="outlined" select>
                              {dtmParents.map((item, i) =>
                                  <MenuItem key={i} value={item.dtm_parent}>{item.dtm_parent}</MenuItem>
                              )}
                            </TextField>
                      </Grid>
                      ) : '' }
                    <Grid item xs={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="dateFrom"
                          label="From"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          className={classes.keywordsField}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="dateTo"
                          label="To"
                          value={toDate}
                          onChange={handleToDateChange}
                          className={classes.keywordsField}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />          
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <Button className={classes.searchButton} variant="contained" onClick={handleReportRequest} >
                        <Box fontWeight="fontWeightRegular" fontSize={12} >&nbsp;&nbsp;Request</Box>
                      </Button>
                    </Grid>
                  </Grid>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Box className={classes.formLabel} fontWeight="500" fontSize={12} >Once complete, the report will be available to download from below.</Box>
                </Grid>
                  
 
                <Grid item xs={12}>
                  <div className={classes.formtable}>
                    <DataGrid rows={tableRows} columns={columns} pageSize={6} onSelectionChange={(newSelection) => {setSelection(newSelection.rows);}}/>
                  </div>
                </Grid>
            </Grid>
        </div>
    )
  };
