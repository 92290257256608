import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import getPricingLists from "../../functions/getPricingModels";
import createPricingModel from "../../functions/createPricingModel";



import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

import {ReactComponent as DownloadIcon} from '../../assets/dark_icons/iconmonstr-download-19 1.svg';
import {ReactComponent as SearchIcon} from '../../assets/dark_icons/iconmonstr-magnifier-6 1.svg';



const useStyles = makeStyles({
    root: {

    },
    containerDiv: {
      border: '1px solid #D2D2D8',
    },
    formLabel:{
        marginTop: 40,
        marginBottom: 20,
        color: '#447095',
    },
    formtable:{
        height: 600,
        width: '100%'
    },
    priceRow:{
        width: '100%',
    },
    downloadIcon: {
      width: 16,
      height: 16
    },
    downloadButton: {
        width: 200,
        backgroundColor:'#CAD8BA',
        color: '#304517',
        '&:hover': {
          color: '#F6A036',
        },   
    },
    menuButton: {
      color: '#000',
        '&:hover': {
          color: '#F6A036',
          backgroundColor:'#FFF',
        }
    },
    actionButton: {
        width:160
    }

  });


export default function ProjectListInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [aState, aDispatch] = useContext(ActivityContext);
    const [pricemodelRows, setPricemodelRows] = useState([]);
    const [displayList, setDisplayList] = useState(true); //default. true
    const [selection, setSelection] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [displayid, setDisplayid] = useState(0);
    const [model, setModel] = useState({});

    //Pricing model holder
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [created, setCreated] = useState('');
    const [modified, setModified] = useState('');

    //OS250k
    const [os250_view_price, setOs250_view_price] = useState(0);
    const [os250_print_minprice, setOs250_print_minprice] = useState(0);
    const [os250_print_band1_price, setOs250_print_band1_price] = useState(0);
    const [os250_export_minprice, setOs250_export_minprice] = useState(0);
    const [os250_export_band1_price, setOs250_export_band1_price] = useState(0);

    //OS50k
    const [os50_view_price, setOs50_view_price] = useState(0);
    const [os50_print_minprice, setOs50_print_minprice] = useState(0);
    const [os50_print_a4, setOs50_print_a4] = useState(0);
    const [os50_print_a3, setOs50_print_a3] = useState(0);
    const [os50_print_a2, setOs50_print_a2] = useState(0);
    const [os50_print_a1, setOs50_print_a1] = useState(0);
    const [os50_print_a0, setOs50_print_a0] = useState(0);
    const [os50_print_band1_price, setOs50_print_band1_price] = useState(0);
    const [os50_export_minprice, setOs50_export_minprice] = useState(0);
    const [os50_export_band1_price, setOs50_export_band1_price] = useState(0);

    //OS25k
    const [os25_view_price, setOs25_view_price] = useState(0);
    const [os25_print_minprice, setOs25_print_minprice] = useState(0);
    const [os25_print_a4, setOs25_print_a4] = useState(0);
    const [os25_print_a3, setOs25_print_a3] = useState(0);
    const [os25_print_a2, setOs25_print_a2] = useState(0);
    const [os25_print_a1, setOs25_print_a1] = useState(0);
    const [os25_print_a0, setOs25_print_a0] = useState(0);
    const [os25_print_band1_price, setOs25_print_band1_price] = useState(0);
    const [os25_export_minprice, setOs25_export_minprice] = useState(0);
    const [os25_export_band1_price, setOs25_export_band1_price] = useState(0);

    //OSVMl
    const [osvml_view_threshold_price, setOsvml_view_threshold_price] = useState(0);
    const [osvml_view_band1_price, setOsvml_view_band1_price] = useState(0);
    const [osvml_print_minprice, setOsvml_print_minprice] = useState(0);
    const [osvml_print_a4_5k, setOsvml_print_a4_5k] = useState(0);
    const [osvml_print_a3_5k, setOsvml_print_a3_5k] = useState(0);
    const [osvml_print_a2_5k, setOsvml_print_a2_5k] = useState(0);
    const [osvml_print_a1_5k, setOsvml_print_a1_5k] = useState(0);
    const [osvml_print_a0_5k, setOsvml_print_a0_5k] = useState(0);
    const [osvml_print_a4_10k, setOsvml_print_a4_10k] = useState(0);
    const [osvml_print_a3_10k, setOsvml_print_a3_10k] = useState(0);
    const [osvml_print_a2_10k, setOsvml_print_a2_10k] = useState(0);
    const [osvml_print_a1_10k, setOsvml_print_a1_10k] = useState(0);
    const [osvml_print_a0_10k, setOsvml_print_a0_10k] = useState(0);
    const [osvml_print_threshold_price, setOsvml_print_threshold_price] = useState(0);
    const [osvml_print_band1_price, setOsvml_print_band1_price] = useState(0);
    const [osvml_export_minprice, setOsvml_export_minprice] = useState(0);
    const [osvml_export_band1_price, setOsvml_export_band1_price] = useState(0);

    //OSMM
    const [osmm_view_threshold_price, setOsmm_view_threshold_price] = useState(0);
    const [osmm_view_band1_high_price, setOsmm_view_band1_high_price] = useState(0);
    const [osmm_view_band1_med_price, setOsmm_view_band1_med_price] = useState(0);
    const [osmm_view_band1_low_price, setOsmm_view_band1_low_price] = useState(0);
    const [osmm_view_band2_high_price, setOsmm_view_band2_high_price] = useState(0);
    const [osmm_view_band2_med_price, setOsmm_view_band2_med_price] = useState(0);
    const [osmm_view_band2_low_price, setOsmm_view_band2_low_price] = useState(0);

    const [osmm_print_a4_200_high_price, setOsmm_print_a4_200_high_price] = useState(0);
    const [osmm_print_a3_200_high_price, setOsmm_print_a3_200_high_price] = useState(0);
    const [osmm_print_a2_200_high_price, setOsmm_print_a2_200_high_price] = useState(0);
    const [osmm_print_a1_200_high_price, setOsmm_print_a1_200_high_price] = useState(0);
    const [osmm_print_a0_200_high_price, setOsmm_print_a0_200_high_price] = useState(0);
    const [osmm_print_a4_200_med_price, setOsmm_print_a4_200_med_price] = useState(0);
    const [osmm_print_a3_200_med_price, setOsmm_print_a3_200_med_price] = useState(0);
    const [osmm_print_a2_200_med_price, setOsmm_print_a2_200_med_price] = useState(0);
    const [osmm_print_a1_200_med_price, setOsmm_print_a1_200_med_price] = useState(0);
    const [osmm_print_a0_200_med_price, setOsmm_print_a0_200_med_price] = useState(0);
    const [osmm_print_a4_200_low_price, setOsmm_print_a4_200_low_price] = useState(0);
    const [osmm_print_a3_200_low_price, setOsmm_print_a3_200_low_price] = useState(0);
    const [osmm_print_a2_200_low_price, setOsmm_print_a2_200_low_price] = useState(0);
    const [osmm_print_a1_200_low_price, setOsmm_print_a1_200_low_price] = useState(0);
    const [osmm_print_a0_200_low_price, setOsmm_print_a0_200_low_price] = useState(0);

    const [osmm_print_a4_500_high_price, setOsmm_print_a4_500_high_price] = useState(0);
    const [osmm_print_a3_500_high_price, setOsmm_print_a3_500_high_price] = useState(0);
    const [osmm_print_a2_500_high_price, setOsmm_print_a2_500_high_price] = useState(0);
    const [osmm_print_a1_500_high_price, setOsmm_print_a1_500_high_price] = useState(0);
    const [osmm_print_a0_500_high_price, setOsmm_print_a0_500_high_price] = useState(0);
    const [osmm_print_a4_500_med_price, setOsmm_print_a4_500_med_price] = useState(0);
    const [osmm_print_a3_500_med_price, setOsmm_print_a3_500_med_price] = useState(0);
    const [osmm_print_a2_500_med_price, setOsmm_print_a2_500_med_price] = useState(0);
    const [osmm_print_a1_500_med_price, setOsmm_print_a1_500_med_price] = useState(0);
    const [osmm_print_a0_500_med_price, setOsmm_print_a0_500_med_price] = useState(0);
    const [osmm_print_a4_500_low_price, setOsmm_print_a4_500_low_price] = useState(0);
    const [osmm_print_a3_500_low_price, setOsmm_print_a3_500_low_price] = useState(0);
    const [osmm_print_a2_500_low_price, setOsmm_print_a2_500_low_price] = useState(0);
    const [osmm_print_a1_500_low_price, setOsmm_print_a1_500_low_price] = useState(0);
    const [osmm_print_a0_500_low_price, setOsmm_print_a0_500_low_price] = useState(0);

    const [osmm_print_a4_1250_high_price, setOsmm_print_a4_1250_high_price] = useState(0);
    const [osmm_print_a3_1250_high_price, setOsmm_print_a3_1250_high_price] = useState(0);
    const [osmm_print_a2_1250_high_price, setOsmm_print_a2_1250_high_price] = useState(0);
    const [osmm_print_a1_1250_high_price, setOsmm_print_a1_1250_high_price] = useState(0);
    const [osmm_print_a0_1250_high_price, setOsmm_print_a0_1250_high_price] = useState(0);
    const [osmm_print_a4_1250_med_price, setOsmm_print_a4_1250_med_price] = useState(0);
    const [osmm_print_a3_1250_med_price, setOsmm_print_a3_1250_med_price] = useState(0);
    const [osmm_print_a2_1250_med_price, setOsmm_print_a2_1250_med_price] = useState(0);
    const [osmm_print_a1_1250_med_price, setOsmm_print_a1_1250_med_price] = useState(0);
    const [osmm_print_a0_1250_med_price, setOsmm_print_a0_1250_med_price] = useState(0);
    const [osmm_print_a4_1250_low_price, setOsmm_print_a4_1250_low_price] = useState(0);
    const [osmm_print_a3_1250_low_price, setOsmm_print_a3_1250_low_price] = useState(0);
    const [osmm_print_a2_1250_low_price, setOsmm_print_a2_1250_low_price] = useState(0);
    const [osmm_print_a1_1250_low_price, setOsmm_print_a1_1250_low_price] = useState(0);
    const [osmm_print_a0_1250_low_price, setOsmm_print_a0_1250_low_price] = useState(0);

    const [osmm_print_a4_2500_high_price, setOsmm_print_a4_2500_high_price] = useState(0);
    const [osmm_print_a3_2500_high_price, setOsmm_print_a3_2500_high_price] = useState(0);
    const [osmm_print_a2_2500_high_price, setOsmm_print_a2_2500_high_price] = useState(0);
    const [osmm_print_a1_2500_high_price, setOsmm_print_a1_2500_high_price] = useState(0);
    const [osmm_print_a0_2500_high_price, setOsmm_print_a0_2500_high_price] = useState(0);
    const [osmm_print_a4_2500_med_price, setOsmm_print_a4_2500_med_price] = useState(0);
    const [osmm_print_a3_2500_med_price, setOsmm_print_a3_2500_med_price] = useState(0);
    const [osmm_print_a2_2500_med_price, setOsmm_print_a2_2500_med_price] = useState(0);
    const [osmm_print_a1_2500_med_price, setOsmm_print_a1_2500_med_price] = useState(0);
    const [osmm_print_a0_2500_med_price, setOsmm_print_a0_2500_med_price] = useState(0);
    const [osmm_print_a4_2500_low_price, setOsmm_print_a4_2500_low_price] = useState(0);
    const [osmm_print_a3_2500_low_price, setOsmm_print_a3_2500_low_price] = useState(0);
    const [osmm_print_a2_2500_low_price, setOsmm_print_a2_2500_low_price] = useState(0);
    const [osmm_print_a1_2500_low_price, setOsmm_print_a1_2500_low_price] = useState(0);
    const [osmm_print_a0_2500_low_price, setOsmm_print_a0_2500_low_price] = useState(0);

    const [osmm_export_minprice, setOsmm_export_minprice] = useState(0);
    const [osmm_export_area1ha_price, setOsmm_export_area1ha_price] = useState(0);
    const [osmm_export_area4ha_price, setOsmm_export_area4ha_price] = useState(0);

    const [osmm_export_threshold_price, setOsmm_export_threshold_price] = useState(0);
    const [osmm_export_band1_high_price, setOsmm_export_band1_high_price] = useState(0);
    const [osmm_export_band1_med_price, setOsmm_export_band1_med_price] = useState(0);
    const [osmm_export_band1_low_price, setOsmm_export_band1_low_price] = useState(0);
    const [osmm_export_band2_high_price, setOsmm_export_band2_high_price] = useState(0);
    const [osmm_export_band2_med_price, setOsmm_export_band2_med_price] = useState(0);
    const [osmm_export_band2_low_price, setOsmm_export_band2_low_price] = useState(0);

    //Aerial
    const [aerial_view_threshold_price, setAerial_view_threshold_price] = useState(0);
    const [aerial_print_minprice, setAerial_print_minprice] = useState(0);
    const [aerial_print_threshold_price, setAerial_print_threshold_price] = useState(0);
    const [aerial_print_band1_price, setAerial_print_band1_price] = useState(0);
    const [aerial_print_band2_price, setAerial_print_band2_price] = useState(0);
    const [aerial_print_band3_price, setAerial_print_band3_price] = useState(0);

  
    /**
     * First load
     */
    useEffect(() => {

        refreshPricingList();

    },[])

    const refreshPricingList = () => {
        const request = {
            userid: sState.userid,
            ident: sState.ident,
        }
        getPricingLists(request, aDispatch);
    }
    /**
     * Listerns for change to orders list.
     */
    useEffect(() => {
        console.log("models:"+JSON.stringify(aState.pricingmodels));
        if (aState.pricingmodels.length > 0){
            setPricemodelRows(aState.pricingmodels);
        }
    },[aState.pricingmodels])


    const columns = [
        { field: 'modelid', hide: true},
        { field: 'name',
        headerName: 'Name',
        sortable: true,
        width: 300,
        disableClickEventBubbling: true,
        renderCell: (params) => (
            getModelDetails(params)
        ),},
        { field: 'description', headerName: 'Description', width: 500 },
    ];

    /**
     * Displays the download options for user orders and allows to download.
    */
    function getModelDetails(params) {

    const handleViewRequest = () => {
        const api = params.api;
        const fields = api
        .getAllColumns()
        .map((c) => c.field)
        .filter((c) => c !== "__check__" && !!c);
        const thisRow = {};

        fields.forEach((f) => {
        thisRow[f] = params.getValue(f);
        });
        console.log("Model selected :"+thisRow.modelid);
        setDisplayid(thisRow.modelid);
    };

    const sname = params.getValue('name');
    return <Button className={classes.menuButton} onClick={handleViewRequest}>
        <Box fontSize={12}>{sname}</Box>
    </Button>;
    }

    /**
     *  Listens for display pricing model details request.
     */
    useEffect(() => {
        if (displayid === -1){
            //Resets the form
            setDisplayList(true);
        } else {
            //Loops through to get details.
            pricemodelRows.forEach(function (p) {

                if (p.modelid === displayid){
                    setModel(p);
                    loadModelDetails(p);
                    return;
                }
            });
        }
    },[displayid])

    /**
     * display the model.
     */
    const loadModelDetails = (model) => {
        setDisplayList(false);
        setName(model.name);
        setDescription(model.description);
        setCreated('')
        setModified('')

        setOs250_view_price(model.model.os250k.view.price);
        setOs250_print_minprice(model.model.os250k.print.fixed.minFixed);
        setOs250_print_band1_price(model.model.os250k.print.band1.price);
        setOs250_export_minprice(model.model.os250k.export.fixed.minFixed);
        setOs250_export_band1_price(model.model.os250k.export.band1.price);

        setOs50_view_price(model.model.os50k.view.price);
        setOs50_print_a4(model.model.os50k.print.fixed.a4);
        setOs50_print_a3(model.model.os50k.print.fixed.a3);
        setOs50_print_a2(model.model.os50k.print.fixed.a2);
        setOs50_print_a1(model.model.os50k.print.fixed.a1);
        setOs50_print_a0(model.model.os50k.print.fixed.a0);
        setOs50_print_minprice(model.model.os50k.print.fixed.minFixed);
        setOs50_print_band1_price(model.model.os50k.print.band1.price);
        setOs50_export_minprice(model.model.os50k.export.fixed.minFixed);
        setOs50_export_band1_price(model.model.os50k.export.band1.price);

        setOs25_view_price(model.model.os25k.view.price);
        setOs25_print_a4(model.model.os25k.print.fixed.a4);
        setOs25_print_a3(model.model.os25k.print.fixed.a3);
        setOs25_print_a2(model.model.os25k.print.fixed.a2);
        setOs25_print_a1(model.model.os25k.print.fixed.a1);
        setOs25_print_a0(model.model.os25k.print.fixed.a0);
        setOs25_print_minprice(model.model.os25k.print.fixed.minFixed);
        setOs25_print_band1_price(model.model.os25k.print.band1.price);
        setOs25_export_minprice(model.model.os25k.export.fixed.minFixed);
        setOs25_export_band1_price(model.model.os25k.export.band1.price);

        setOsvml_view_threshold_price(model.model.vml.view.threshold.price);
        setOsvml_view_band1_price(model.model.vml.view.band1.price);
        setOsvml_print_a4_5k(model.model.vml.print.fixed.a4_5000);
        setOsvml_print_a3_5k(model.model.vml.print.fixed.a3_5000);
        setOsvml_print_a2_5k(model.model.vml.print.fixed.a2_5000);
        setOsvml_print_a1_5k(model.model.vml.print.fixed.a1_5000);
        setOsvml_print_a0_5k(model.model.vml.print.fixed.a0_5000);
        setOsvml_print_a4_10k(model.model.vml.print.fixed.a4_10000);
        setOsvml_print_a3_10k(model.model.vml.print.fixed.a3_10000);
        setOsvml_print_a2_10k(model.model.vml.print.fixed.a2_10000);
        setOsvml_print_a1_10k(model.model.vml.print.fixed.a1_10000);
        setOsvml_print_a0_10k(model.model.vml.print.fixed.a0_10000);

        setOsvml_print_minprice(model.model.vml.print.fixed.minFixed);
        setOsvml_print_threshold_price(model.model.vml.print.threshold.price);
        setOsvml_print_band1_price(model.model.vml.print.band1.price);
        setOsvml_export_minprice(model.model.vml.export.fixed.minFixed);
        setOsvml_export_band1_price(model.model.vml.export.band1.price);

        setOsmm_view_threshold_price(model.model.osmm.view.threshold.price);
        setOsmm_view_band1_high_price(model.model.osmm.view.band1.high.price);
        setOsmm_view_band1_med_price(model.model.osmm.view.band1.med.price);
        setOsmm_view_band1_low_price(model.model.osmm.view.band1.low.price);
        setOsmm_view_band2_high_price(model.model.osmm.view.band2.high.price);
        setOsmm_view_band2_med_price(model.model.osmm.view.band2.med.price);
        setOsmm_view_band2_low_price(model.model.osmm.view.band2.low.price);

        setOsmm_print_a4_200_high_price(model.model.osmm.print.scale200.a4.high);
        setOsmm_print_a3_200_high_price(model.model.osmm.print.scale200.a3.high);
        setOsmm_print_a2_200_high_price(model.model.osmm.print.scale200.a2.high);
        setOsmm_print_a1_200_high_price(model.model.osmm.print.scale200.a1.high);
        setOsmm_print_a0_200_high_price(model.model.osmm.print.scale200.a0.high);
        setOsmm_print_a4_200_med_price(model.model.osmm.print.scale200.a4.med);
        setOsmm_print_a3_200_med_price(model.model.osmm.print.scale200.a3.med);
        setOsmm_print_a2_200_med_price(model.model.osmm.print.scale200.a2.med);
        setOsmm_print_a1_200_med_price(model.model.osmm.print.scale200.a1.med);
        setOsmm_print_a0_200_med_price(model.model.osmm.print.scale200.a0.med);
        setOsmm_print_a4_200_low_price(model.model.osmm.print.scale200.a4.low);
        setOsmm_print_a3_200_low_price(model.model.osmm.print.scale200.a3.low);
        setOsmm_print_a2_200_low_price(model.model.osmm.print.scale200.a2.low);
        setOsmm_print_a1_200_low_price(model.model.osmm.print.scale200.a1.low);
        setOsmm_print_a0_200_low_price(model.model.osmm.print.scale200.a0.low);

        setOsmm_print_a4_500_high_price(model.model.osmm.print.scale500.a4.high);
        setOsmm_print_a3_500_high_price(model.model.osmm.print.scale500.a3.high);
        setOsmm_print_a2_500_high_price(model.model.osmm.print.scale500.a2.high);
        setOsmm_print_a1_500_high_price(model.model.osmm.print.scale500.a1.high);
        setOsmm_print_a0_500_high_price(model.model.osmm.print.scale500.a0.high);
        setOsmm_print_a4_500_med_price(model.model.osmm.print.scale500.a4.med);
        setOsmm_print_a3_500_med_price(model.model.osmm.print.scale500.a3.med);
        setOsmm_print_a2_500_med_price(model.model.osmm.print.scale500.a2.med);
        setOsmm_print_a1_500_med_price(model.model.osmm.print.scale500.a1.med);
        setOsmm_print_a0_500_med_price(model.model.osmm.print.scale500.a0.med);
        setOsmm_print_a4_500_low_price(model.model.osmm.print.scale500.a4.low);
        setOsmm_print_a3_500_low_price(model.model.osmm.print.scale500.a3.low);
        setOsmm_print_a2_500_low_price(model.model.osmm.print.scale500.a2.low);
        setOsmm_print_a1_500_low_price(model.model.osmm.print.scale500.a1.low);
        setOsmm_print_a0_500_low_price(model.model.osmm.print.scale500.a0.low);

        setOsmm_print_a4_1250_high_price(model.model.osmm.print.scale1250.a4.high);
        setOsmm_print_a3_1250_high_price(model.model.osmm.print.scale1250.a3.high);
        setOsmm_print_a2_1250_high_price(model.model.osmm.print.scale1250.a2.high);
        setOsmm_print_a1_1250_high_price(model.model.osmm.print.scale1250.a1.high);
        setOsmm_print_a0_1250_high_price(model.model.osmm.print.scale1250.a0.high);
        setOsmm_print_a4_1250_med_price(model.model.osmm.print.scale1250.a4.med);
        setOsmm_print_a3_1250_med_price(model.model.osmm.print.scale1250.a3.med);
        setOsmm_print_a2_1250_med_price(model.model.osmm.print.scale1250.a2.med);
        setOsmm_print_a1_1250_med_price(model.model.osmm.print.scale1250.a1.med);
        setOsmm_print_a0_1250_med_price(model.model.osmm.print.scale1250.a0.med);
        setOsmm_print_a4_1250_low_price(model.model.osmm.print.scale1250.a4.low);
        setOsmm_print_a3_1250_low_price(model.model.osmm.print.scale1250.a3.low);
        setOsmm_print_a2_1250_low_price(model.model.osmm.print.scale1250.a2.low);
        setOsmm_print_a1_1250_low_price(model.model.osmm.print.scale1250.a1.low);
        setOsmm_print_a0_1250_low_price(model.model.osmm.print.scale1250.a0.low);

        setOsmm_print_a4_2500_high_price(model.model.osmm.print.scale2500.a4.high);
        setOsmm_print_a3_2500_high_price(model.model.osmm.print.scale2500.a3.high);
        setOsmm_print_a2_2500_high_price(model.model.osmm.print.scale2500.a2.high);
        setOsmm_print_a1_2500_high_price(model.model.osmm.print.scale2500.a1.high);
        setOsmm_print_a0_2500_high_price(model.model.osmm.print.scale2500.a0.high);
        setOsmm_print_a4_2500_med_price(model.model.osmm.print.scale2500.a4.med);
        setOsmm_print_a3_2500_med_price(model.model.osmm.print.scale2500.a3.med);
        setOsmm_print_a2_2500_med_price(model.model.osmm.print.scale2500.a2.med);
        setOsmm_print_a1_2500_med_price(model.model.osmm.print.scale2500.a1.med);
        setOsmm_print_a0_2500_med_price(model.model.osmm.print.scale2500.a0.med);
        setOsmm_print_a4_2500_low_price(model.model.osmm.print.scale2500.a4.low);
        setOsmm_print_a3_2500_low_price(model.model.osmm.print.scale2500.a3.low);
        setOsmm_print_a2_2500_low_price(model.model.osmm.print.scale2500.a2.low);
        setOsmm_print_a1_2500_low_price(model.model.osmm.print.scale2500.a1.low);
        setOsmm_print_a0_2500_low_price(model.model.osmm.print.scale2500.a0.low);

        setOsmm_export_minprice(model.model.osmm.export.fixed.minFixed);
        setOsmm_export_area1ha_price(model.model.osmm.export.fixed.area1ha);
        setOsmm_export_area4ha_price(model.model.osmm.export.fixed.area4ha);
        setOsmm_export_threshold_price(model.model.osmm.export.threshold.price);
        setOsmm_export_band1_high_price(model.model.osmm.export.band1.high.price);
        setOsmm_export_band1_med_price(model.model.osmm.export.band1.med.price);
        setOsmm_export_band1_low_price(model.model.osmm.export.band1.low.price);
        setOsmm_export_band2_high_price(model.model.osmm.export.band2.high.price);
        setOsmm_export_band2_med_price(model.model.osmm.export.band2.med.price);
        setOsmm_export_band2_low_price(model.model.osmm.export.band2.low.price);

        setAerial_view_threshold_price(model.model.aerial.view.threshold.price);
        setAerial_print_minprice(model.model.aerial.print.fixed.minFixed);
        setAerial_print_threshold_price(model.model.aerial.print.threshold.price);
        setAerial_print_band1_price(model.model.aerial.print.band1.price);
        setAerial_print_band2_price(model.model.aerial.print.band2.price);
        setAerial_print_band3_price(model.model.aerial.print.band3.price);
    }

    const generateModelDetails = (model) => {

        model.os250k.view.price = os250_view_price;
        model.os250k.print.fixed.minFixed = os250_print_minprice;
        model.os250k.print.band1.price = os250_print_band1_price;
        model.os250k.export.fixed.minFixed = os250_export_minprice;
        model.os250k.export.band1.price = os250_export_band1_price;

        model.os50k.view.price = os50_view_price;

        model.os50k.print.fixed.a4 = os50_print_a4;
        model.os50k.print.fixed.a3 = os50_print_a3;
        model.os50k.print.fixed.a2 = os50_print_a2;
        model.os50k.print.fixed.a1 = os50_print_a1;
        model.os50k.print.fixed.a0 = os50_print_a0;

        model.os50k.print.fixed.minFixed = os50_print_minprice;
        model.os50k.print.band1.price = os50_print_band1_price;
        model.os50k.export.fixed.minFixed = os50_export_minprice;
        model.os50k.export.band1.price = os50_export_band1_price;

        model.os25k.view.price = os25_view_price;
        model.os25k.print.fixed.a4 = os25_print_a4;
        model.os25k.print.fixed.a3 = os25_print_a3;
        model.os25k.print.fixed.a2 = os25_print_a2;
        model.os25k.print.fixed.a1 = os25_print_a1;
        model.os25k.print.fixed.a0 = os25_print_a0;
        model.os25k.print.fixed.minFixed = os25_print_minprice;
        model.os25k.print.band1.price = os25_print_band1_price;
        model.os25k.export.fixed.minFixed = os25_export_minprice;
        model.os25k.export.band1.price = os25_export_band1_price;

        model.vml.view.threshold.price = osvml_view_threshold_price;
        model.vml.view.band1.price = osvml_print_minprice;
        model.vml.print.fixed.minFixed = osvml_print_minprice;
        model.vml.print.fixed.a4_5000 = osvml_print_a4_5k;
        model.vml.print.fixed.a3_5000 = osvml_print_a3_5k;
        model.vml.print.fixed.a2_5000 = osvml_print_a2_5k;
        model.vml.print.fixed.a1_5000 = osvml_print_a1_5k;
        model.vml.print.fixed.a0_5000 = osvml_print_a0_5k;
        model.vml.print.fixed.a4_10000 = osvml_print_a4_10k;
        model.vml.print.fixed.a3_10000 = osvml_print_a3_10k;
        model.vml.print.fixed.a2_10000 = osvml_print_a2_10k;
        model.vml.print.fixed.a1_10000 = osvml_print_a1_10k;
        model.vml.print.fixed.a0_10000 = osvml_print_a0_10k;

        model.vml.print.threshold.price = osvml_print_threshold_price;
        model.vml.print.band1.price = osvml_print_band1_price;
        model.vml.export.fixed.minFixed = osvml_export_minprice;
        model.vml.export.band1.price = osvml_export_band1_price;

        model.osmm.view.threshold.price = osmm_view_threshold_price;
        model.osmm.view.band1.high.price = osmm_view_band1_high_price
        model.osmm.view.band1.med.price = osmm_view_band1_med_price;
        model.osmm.view.band1.low.price = osmm_view_band1_low_price;
        model.osmm.view.band2.high.price = osmm_view_band2_high_price
        model.osmm.view.band2.med.price = osmm_view_band2_med_price;
        model.osmm.view.band2.low.price = osmm_view_band2_low_price;

        model.osmm.print.scale200.a4.high = osmm_print_a4_200_high_price;
        model.osmm.print.scale200.a3.high = osmm_print_a3_200_high_price;
        model.osmm.print.scale200.a2.high = osmm_print_a2_200_high_price;
        model.osmm.print.scale200.a1.high = osmm_print_a1_200_high_price;
        model.osmm.print.scale200.a0.high = osmm_print_a0_200_high_price;
        model.osmm.print.scale200.a4.med = osmm_print_a4_200_med_price;
        model.osmm.print.scale200.a3.med = osmm_print_a3_200_med_price;
        model.osmm.print.scale200.a2.med = osmm_print_a2_200_med_price;
        model.osmm.print.scale200.a1.med = osmm_print_a1_200_med_price;
        model.osmm.print.scale200.a0.med = osmm_print_a0_200_med_price;
        model.osmm.print.scale200.a4.low = osmm_print_a4_200_low_price;
        model.osmm.print.scale200.a3.low = osmm_print_a3_200_low_price;
        model.osmm.print.scale200.a2.low = osmm_print_a2_200_low_price;
        model.osmm.print.scale200.a1.low = osmm_print_a1_200_low_price;
        model.osmm.print.scale200.a0.low = osmm_print_a0_200_low_price;

        model.osmm.print.scale500.a4.high = osmm_print_a4_500_high_price;
        model.osmm.print.scale500.a3.high = osmm_print_a3_500_high_price;
        model.osmm.print.scale500.a2.high = osmm_print_a2_500_high_price;
        model.osmm.print.scale500.a1.high = osmm_print_a1_500_high_price;
        model.osmm.print.scale500.a0.high = osmm_print_a0_500_high_price;
        model.osmm.print.scale500.a4.med = osmm_print_a4_500_med_price;
        model.osmm.print.scale500.a3.med = osmm_print_a3_500_med_price;
        model.osmm.print.scale500.a2.med = osmm_print_a2_500_med_price;
        model.osmm.print.scale500.a1.med = osmm_print_a1_500_med_price;
        model.osmm.print.scale500.a0.med = osmm_print_a0_500_med_price;
        model.osmm.print.scale500.a4.low = osmm_print_a4_500_low_price;
        model.osmm.print.scale500.a3.low = osmm_print_a3_500_low_price;
        model.osmm.print.scale500.a2.low = osmm_print_a2_500_low_price;
        model.osmm.print.scale500.a1.low = osmm_print_a1_500_low_price;
        model.osmm.print.scale500.a0.low = osmm_print_a0_500_low_price;

        model.osmm.print.scale1250.a4.high = osmm_print_a4_1250_high_price;
        model.osmm.print.scale1250.a3.high = osmm_print_a3_1250_high_price;
        model.osmm.print.scale1250.a2.high = osmm_print_a2_1250_high_price;
        model.osmm.print.scale1250.a1.high = osmm_print_a1_1250_high_price;
        model.osmm.print.scale1250.a0.high = osmm_print_a0_1250_high_price;
        model.osmm.print.scale1250.a4.med = osmm_print_a4_1250_med_price;
        model.osmm.print.scale1250.a3.med = osmm_print_a3_1250_med_price;
        model.osmm.print.scale1250.a2.med = osmm_print_a2_1250_med_price;
        model.osmm.print.scale1250.a1.med = osmm_print_a1_1250_med_price;
        model.osmm.print.scale1250.a0.med = osmm_print_a0_1250_med_price;
        model.osmm.print.scale1250.a4.low = osmm_print_a4_1250_low_price;
        model.osmm.print.scale1250.a3.low = osmm_print_a3_1250_low_price;
        model.osmm.print.scale1250.a2.low = osmm_print_a2_1250_low_price;
        model.osmm.print.scale1250.a1.low = osmm_print_a1_1250_low_price;
        model.osmm.print.scale1250.a0.low = osmm_print_a0_1250_low_price;

        model.osmm.print.scale2500.a4.high = osmm_print_a4_2500_high_price;
        model.osmm.print.scale2500.a3.high = osmm_print_a3_2500_high_price;
        model.osmm.print.scale2500.a2.high = osmm_print_a2_2500_high_price;
        model.osmm.print.scale2500.a1.high = osmm_print_a1_2500_high_price;
        model.osmm.print.scale2500.a0.high = osmm_print_a0_2500_high_price;
        model.osmm.print.scale2500.a4.med = osmm_print_a4_2500_med_price;
        model.osmm.print.scale2500.a3.med = osmm_print_a3_2500_med_price;
        model.osmm.print.scale2500.a2.med = osmm_print_a2_2500_med_price;
        model.osmm.print.scale2500.a1.med = osmm_print_a1_2500_med_price;
        model.osmm.print.scale2500.a0.med = osmm_print_a0_2500_med_price;
        model.osmm.print.scale2500.a4.low = osmm_print_a4_2500_low_price;
        model.osmm.print.scale2500.a3.low = osmm_print_a3_2500_low_price;
        model.osmm.print.scale2500.a2.low = osmm_print_a2_2500_low_price;
        model.osmm.print.scale2500.a1.low = osmm_print_a1_2500_low_price;
        model.osmm.print.scale2500.a0.low = osmm_print_a0_2500_low_price;

        model.osmm.export.fixed.minFixed = osmm_export_minprice;
        model.osmm.export.fixed.area1ha = osmm_export_area1ha_price;
        model.osmm.export.fixed.area4ha = osmm_export_area4ha_price;
        model.osmm.export.threshold.price = osmm_export_threshold_price;
        model.osmm.export.band1.high.price = osmm_export_band1_high_price;
        model.osmm.export.band1.med.price = osmm_export_band1_med_price;
        model.osmm.export.band1.low.price = osmm_export_band1_low_price;
        model.osmm.export.band2.high.price = osmm_export_band2_high_price;
        model.osmm.export.band2.med.price = osmm_export_band2_med_price;
        model.osmm.export.band2.low.price = osmm_export_band2_low_price;

        model.aerial.view.threshold.price = aerial_view_threshold_price;
        model.aerial.print.fixed.minFixed = aerial_print_minprice;
        model.aerial.print.threshold.price = aerial_print_threshold_price;
        model.aerial.print.band1.price = aerial_print_band1_price;
        model.aerial.print.band2.price = aerial_print_band2_price;
        model.aerial.print.band3.price = aerial_print_band3_price;

        return model
    };


    const handleSave = () => {

        model.name = name;
        model.description = description;
        model.model = generateModelDetails(model.model);

        createPricingModel(sState, model, aDispatch);
        //refreshPricingList();
        setDisplayid(-1);
    };

    const handleSaveNew = () => {

        //const priceModel = generateModelDetails(model.model);
        model.modelid = -1
        model.name = name;
        model.description = description;
        model.model = generateModelDetails(model.model);
    
        createPricingModel(sState, model, aDispatch);
        //refreshPricingList();
        setDisplayid(-1);
    };

    const handleCancel = () => {
        setDisplayid(-1);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.formLabel} fontWeight="500" fontStyle="italic" fontSize={22} >Pricing Models</Box>
                </Grid>
                <Grid item xs={12}>
                    { displayList ? 
                    <div className={classes.formtable}>
                      <DataGrid rows={pricemodelRows} columns={columns} pageSize={10} onSelectionChange={(newSelection) => {setSelection(newSelection.rows);}}/>
                    </div>
                    : <div className={classes.formdetails}>
                        <Grid container>
                            <Grid item xs={4} md={3}>
                                <TextField className={classes.textField} error={hasError} name="name" label="Name" size="small" margin="dense" variant="outlined" value={name} onChange={e => setName(e.target.value)} required />
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <TextField multiline className={classes.textField} error={hasError} name="description" rows={4} label="Description" size="small" margin="dense" variant="outlined" value={description} onChange={e => setDescription(e.target.value)} required />
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Button className={classes.actionButton} variant="contained" onClick={handleSave} >
                                    <Box fontWeight="fontWeightRegular" fontSize={12} >Save</Box>
                                </Button>
                                <Button className={classes.actionButton} variant="contained" onClick={handleSaveNew} >
                                    <Box fontWeight="fontWeightRegular" fontSize={12} >Save as new</Box>
                                </Button>
                                <Button className={classes.actionButton} variant="contained" onClick={handleCancel} >
                                    <Box fontWeight="fontWeightRegular" fontSize={12} >Cancel / Go back</Box>
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS 1:250,000 Raster</Box>
                                    <Box className={classes.containerLabel} fontSize={12} >Views</Box>
                                    <Grid container>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os250_view_price} onChange={e => setOs250_view_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                                    <Grid container>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Minimum plot price</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os250_print_minprice} onChange={e => setOs250_print_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os250_print_band1_price} onChange={e => setOs250_print_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                    </Grid>
                                    <Box className={classes.containerLabel} fontSize={12} >Data</Box>
                                    <Grid container>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Minimum data price</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os250_export_minprice} onChange={e => setOs250_export_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os250_export_band1_price} onChange={e => setOs250_export_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                </div>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS 1:50,000 Raster</Box>
                                    <Box className={classes.containerLabel} fontSize={12} >Views</Box>
                                    <Grid container>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os50_view_price} onChange={e => setOs50_view_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
 
                                    </Grid>
                                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                                    <Grid container>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Minimum plot price</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os50_print_minprice} onChange={e => setOs50_print_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={2} md={2}>
                                                <Box fontSize={12} >Fixed plot price</Box>
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A4</Box>
                                                <TextField className={classes.textField} value={os50_print_a4} onChange={e => setOs50_print_a4(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A3</Box>
                                                <TextField className={classes.textField} value={os50_print_a3} onChange={e => setOs50_print_a3(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A2</Box>
                                                <TextField className={classes.textField} value={os50_print_a2} onChange={e => setOs50_print_a2(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A1</Box>
                                                <TextField className={classes.textField} value={os50_print_a1} onChange={e => setOs50_print_a1(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A0</Box>
                                                <TextField className={classes.textField} value={os50_print_a0} onChange={e => setOs50_print_a0(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={5}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os50_print_band1_price} onChange={e => setOs50_print_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                    </Grid>
                                    <Box className={classes.containerLabel} fontSize={12} >Data</Box>
                                    <Grid container>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Minimum data price</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os50_export_minprice} onChange={e => setOs50_export_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os50_export_band1_price} onChange={e => setOs50_export_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                </div>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS 1:25,000 Raster</Box>
                                    <Box className={classes.containerLabel} fontSize={12} >Views</Box>
                                    <Grid container>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os25_view_price} onChange={e => setOs25_view_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
 
                                    </Grid>
                                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                                    <Grid container>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Minimum plot price</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os25_print_minprice} onChange={e => setOs25_print_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={2} md={2}>
                                                <Box fontSize={12} >Fixed plot price</Box>
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A4</Box>
                                                <TextField className={classes.textField} value={os25_print_a4} onChange={e => setOs25_print_a4(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A3</Box>
                                                <TextField className={classes.textField} value={os25_print_a3} onChange={e => setOs25_print_a3(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A2</Box>
                                                <TextField className={classes.textField} value={os25_print_a2} onChange={e => setOs25_print_a2(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A1</Box>
                                                <TextField className={classes.textField} value={os25_print_a1} onChange={e => setOs25_print_a1(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A0</Box>
                                                <TextField className={classes.textField} value={os25_print_a0} onChange={e => setOs25_print_a0(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={5}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os25_print_band1_price} onChange={e => setOs25_print_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                    </Grid>
                                    <Box className={classes.containerLabel} fontSize={12} >Data</Box>
                                    <Grid container>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Minimum data price</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os25_export_minprice} onChange={e => setOs25_export_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={os25_export_band1_price} onChange={e => setOs25_export_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                </div>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS Vectormap Local (Vector)</Box>
                                    <Box className={classes.containerLabel} fontSize={12} >Views</Box>
                                    <Grid container>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >1st km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={osvml_view_threshold_price} onChange={e => setOsvml_view_threshold_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={osvml_view_band1_price} onChange={e => setOsvml_view_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                                    <Grid container>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Minimum plot price</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={osvml_print_minprice} onChange={e => setOsvml_print_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={2} md={2}>
                                                <Box fontSize={12} >Fixed plot price @5000</Box>
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A4</Box>
                                                <TextField className={classes.textField} value={osvml_print_a4_5k} onChange={e => setOsvml_print_a4_5k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A3</Box>
                                                <TextField className={classes.textField} value={osvml_print_a3_5k} onChange={e => setOsvml_print_a3_5k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A2</Box>
                                                <TextField className={classes.textField} value={osvml_print_a2_5k} onChange={e => setOsvml_print_a2_5k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A1</Box>
                                                <TextField className={classes.textField} value={osvml_print_a1_5k} onChange={e => setOsvml_print_a1_5k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A0</Box>
                                                <TextField className={classes.textField} value={osvml_print_a0_5k} onChange={e => setOsvml_print_a0_5k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={5}></Grid>

                                            <Grid item xs={2} md={2}>
                                                <Box fontSize={12} >Fixed plot price @10,000</Box>
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A4</Box>
                                                <TextField className={classes.textField} value={osvml_print_a4_10k} onChange={e => setOsvml_print_a4_10k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A3</Box>
                                                <TextField className={classes.textField} value={osvml_print_a3_10k} onChange={e => setOsvml_print_a3_10k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A2</Box>
                                                <TextField className={classes.textField} value={osvml_print_a2_10k} onChange={e => setOsvml_print_a2_10k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A1</Box>
                                                <TextField className={classes.textField} value={osvml_print_a1_10k} onChange={e => setOsvml_print_a1_10k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={2} md={1}>
                                                <Box fontSize={12} >A0</Box>
                                                <TextField className={classes.textField} value={osvml_print_a0_10k} onChange={e => setOsvml_print_a0_10k(e.target.value)} name="price" label="" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={5}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >1st km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={osvml_print_threshold_price} onChange={e => setOsvml_print_threshold_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={osvml_print_band1_price} onChange={e => setOsvml_print_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                    <Box className={classes.containerLabel} fontSize={12} >Data</Box>
                                    <Grid container>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Minimum data price</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={osvml_export_minprice} onChange={e => setOsvml_export_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per km</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={osvml_export_band1_price} onChange={e => setOsvml_export_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                </div>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >OS Mastermap</Box>
                                    <Box className={classes.containerLabel} fontSize={12} >Views</Box>
                                    <Grid container>
                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Band</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Settlement (per hectare)</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Rural (per hectare)</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Moorland (per hectare)</Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >A</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={osmm_view_threshold_price} onChange={e => setOsmm_view_threshold_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                        </Grid>
                                        <Grid item xs={3} md={2}>

                                        </Grid>
                                        <Grid item xs={3} md={2}>

                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >B</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={osmm_view_band1_high_price} onChange={e => setOsmm_view_band1_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={osmm_view_band1_med_price} onChange={e => setOsmm_view_band1_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={osmm_view_band1_low_price} onChange={e => setOsmm_view_band1_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >C</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={osmm_view_band2_high_price} onChange={e => setOsmm_view_band2_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={osmm_view_band2_med_price} onChange={e => setOsmm_view_band2_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <TextField className={classes.textField} value={osmm_view_band2_low_price} onChange={e => setOsmm_view_band2_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>
                                    </Grid>



                                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                                    <Grid container>
                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Band</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Settlement (contains)</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Rural (contains)</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Moorland (contains)</Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>


                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A4 @ 200</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_200_high_price} onChange={e => setOsmm_print_a4_200_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_200_med_price} onChange={e => setOsmm_print_a4_200_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_200_low_price} onChange={e => setOsmm_print_a4_200_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A3 @ 200</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_200_high_price} onChange={e => setOsmm_print_a3_200_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_200_med_price} onChange={e => setOsmm_print_a3_200_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_200_low_price} onChange={e => setOsmm_print_a3_200_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A2 @ 200</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_200_high_price} onChange={e => setOsmm_print_a2_200_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_200_med_price} onChange={e => setOsmm_print_a2_200_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_200_low_price} onChange={e => setOsmm_print_a2_200_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A1 @ 200</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_200_high_price} onChange={e => setOsmm_print_a1_200_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_200_med_price} onChange={e => setOsmm_print_a1_200_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_200_low_price} onChange={e => setOsmm_print_a1_200_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A0 @ 200</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_200_high_price} onChange={e => setOsmm_print_a0_200_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_200_med_price} onChange={e => setOsmm_print_a0_200_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_200_low_price} onChange={e => setOsmm_print_a0_200_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>


                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A4 @ 500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_500_high_price} onChange={e => setOsmm_print_a4_500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_500_med_price} onChange={e => setOsmm_print_a4_500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_500_low_price} onChange={e => setOsmm_print_a4_500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A3 @ 500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_500_high_price} onChange={e => setOsmm_print_a3_500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_500_med_price} onChange={e => setOsmm_print_a3_500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_500_low_price} onChange={e => setOsmm_print_a3_500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A2 @ 500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_500_high_price} onChange={e => setOsmm_print_a2_500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_500_med_price} onChange={e => setOsmm_print_a2_500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_500_low_price} onChange={e => setOsmm_print_a2_500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A1 @ 500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_500_high_price} onChange={e => setOsmm_print_a1_500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_500_med_price} onChange={e => setOsmm_print_a1_500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_500_low_price} onChange={e => setOsmm_print_a1_500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A0 @ 500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_500_high_price} onChange={e => setOsmm_print_a0_500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_500_med_price} onChange={e => setOsmm_print_a0_500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_500_low_price} onChange={e => setOsmm_print_a0_500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>


                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A4 @ 1250</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_1250_high_price} onChange={e => setOsmm_print_a4_1250_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_1250_med_price} onChange={e => setOsmm_print_a4_1250_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_1250_low_price} onChange={e => setOsmm_print_a4_1250_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A3 @ 1250</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_1250_high_price} onChange={e => setOsmm_print_a3_1250_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_1250_med_price} onChange={e => setOsmm_print_a3_1250_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_1250_low_price} onChange={e => setOsmm_print_a3_1250_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A2 @ 1250</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_1250_high_price} onChange={e => setOsmm_print_a2_1250_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_1250_med_price} onChange={e => setOsmm_print_a2_1250_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_1250_low_price} onChange={e => setOsmm_print_a2_1250_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A1 @ 1250</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_1250_high_price} onChange={e => setOsmm_print_a1_1250_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_1250_med_price} onChange={e => setOsmm_print_a1_1250_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_1250_low_price} onChange={e => setOsmm_print_a1_1250_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A0 @ 1250</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_1250_high_price} onChange={e => setOsmm_print_a0_1250_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_1250_med_price} onChange={e => setOsmm_print_a0_1250_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_1250_low_price} onChange={e => setOsmm_print_a0_1250_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>


                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A4 @ 2500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_2500_high_price} onChange={e => setOsmm_print_a4_2500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_2500_med_price} onChange={e => setOsmm_print_a4_2500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a4_2500_low_price} onChange={e => setOsmm_print_a4_2500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A3 @ 2500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_2500_high_price} onChange={e => setOsmm_print_a3_2500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_2500_med_price} onChange={e => setOsmm_print_a3_2500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a3_2500_low_price} onChange={e => setOsmm_print_a3_2500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A2 @ 2500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_2500_high_price} onChange={e => setOsmm_print_a2_2500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_2500_med_price} onChange={e => setOsmm_print_a2_2500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a2_2500_low_price} onChange={e => setOsmm_print_a2_2500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A1 @ 2500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_2500_high_price} onChange={e => setOsmm_print_a1_2500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_2500_med_price} onChange={e => setOsmm_print_a1_2500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a1_2500_low_price} onChange={e => setOsmm_print_a1_2500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Fixed Price A0 @ 2500</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_2500_high_price} onChange={e => setOsmm_print_a0_2500_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_2500_med_price} onChange={e => setOsmm_print_a0_2500_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_print_a0_2500_low_price} onChange={e => setOsmm_print_a0_2500_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>
                                    </Grid>


                                    <Box className={classes.containerLabel} fontSize={12} >Data</Box>
                                    <Grid container>
                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Band</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Minimum data price</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Fixed 1ha</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Fixed 4ha</Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} ></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_minprice} onChange={e => setOsmm_export_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                        <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_area1ha_price} onChange={e => setOsmm_export_area1ha_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                        <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_area4ha_price} onChange={e => setOsmm_export_area4ha_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >Band</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Settlement</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Rural</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} >Moorland</Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >A</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_threshold_price} onChange={e => setOsmm_export_threshold_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >B</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_band1_high_price} onChange={e => setOsmm_export_band1_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_band1_med_price} onChange={e => setOsmm_export_band1_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_band1_low_price} onChange={e => setOsmm_export_band1_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>

                                        <Grid item xs={3} md={2} >
                                            <Box fontSize={12} >C</Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_band2_high_price} onChange={e => setOsmm_export_band2_high_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_band2_med_price} onChange={e => setOsmm_export_band2_med_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={3} md={2}>
                                            <Box fontSize={12} ><TextField className={classes.textField} value={osmm_export_band2_low_price} onChange={e => setOsmm_export_band2_low_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required /></Box>
                                        </Grid>
                                        <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                </div>
                                <div className={classes.productcontainer}>
                                    <Box className={classes.containerLabel} fontWeight="500" fontStyle="italic" fontSize={16} >Aerial Imagery</Box>
                                    <Box className={classes.containerLabel} fontSize={12} >Views</Box>
                                    <Grid container>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Price per session</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={aerial_view_threshold_price} onChange={e => setAerial_view_threshold_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
 
                                    </Grid>

                                    <Box className={classes.containerLabel} fontSize={12} >Plots</Box>
                                    <Grid container>
                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >Minimum plot price</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={aerial_print_minprice} onChange={e => setAerial_print_minprice(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >0 to 3ha</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={aerial_print_threshold_price} onChange={e => setAerial_print_threshold_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >3 to 7ha</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={aerial_print_band1_price} onChange={e => setAerial_print_band1_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >7ha to 100ha</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={aerial_print_band2_price} onChange={e => setAerial_print_band2_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>

                                            <Grid item xs={4} md={2}>
                                                <Box fontSize={12} >100ha +</Box>
                                            </Grid>
                                            <Grid item xs={8} md={6}>
                                                <TextField className={classes.textField} value={aerial_print_band3_price} onChange={e => setAerial_print_band3_price(e.target.value)} name="price" label="price" type="number" error={hasError} size="small" margin="dense" variant="outlined" required />
                                            </Grid>
                                            <Grid item xs={false} md={4}></Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    }
                    </Grid>
            </Grid>
        </div>
    )
  };
