import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";



const useStyles = makeStyles({
    root: {
        width:'100%',
    },
    currentMessage: {
        padding: 20,
    },
    messages: {

    },
    actionDiv: {
        paddingTop: 20,
    },
    textField: {
        width: '80%',
    }
  });

  var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };


export default function SystemStatusInterface(props) {
    const classes = useStyles();
  
    const [sState] = useContext(SessionContext);
    const [currentMessage, setCurrentMessage] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [customDisabled, setCustomDisabled] = useState(true);
    const [messageType, setMessageType] = useState('default');
    

    useEffect(() => {
        getCurrentNotification();
    },[])

    const getCurrentNotification = () => {
        const abortController = new AbortController();
          let mounted = true;
        (async () => {
            const res = await fetch(process.env.REACT_APP_NMC_API+'/session/notification', {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                signal: abortController.signal,
              }).catch(handleError);
              const data = await res.json();
              if (mounted) {
                setCurrentMessage(data.notification);
              } else {
                setCurrentMessage('');
              }
              const cleanup = () => {
                mounted = false;
                abortController.abort();
              };
              return cleanup;
        })();
    };

    const handleChange = (event) => {
        setMessageType(event.target.value);
        if (event.target.value === 'custom'){
            setCustomDisabled(false);
        } else {
            setCustomDisabled(true);
        }
    };

    const handleSave = () => {

        let new_notification = '';
        switch(messageType){
            default:
            case "default":
                new_notification = 'System operating as normal';
                break;
            case "custom":
                new_notification = customMessage;
                break;
        }

        if (new_notification.length > 5){

            const abortController = new AbortController();

            const request = {
                userid: sState.userid,
                ident: sState.ident,
                notification: new_notification
            }
            let mounted = true;
            (async () => {  
                const res = await fetch(process.env.REACT_APP_NMC_API+'/super/notification/set', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(request),
                    signal: abortController.signal,
                }).catch(handleError);
                const data = await res.json();
                if (mounted) {
                    if (data.status === "success"){
                            getCurrentNotification();
                    }
                }
                const cleanup = () => {
                    mounted = false;
                    abortController.abort();
                };
                return cleanup;
            })();
        }
    }



    return (
        <div className={classes.root}>

            <Grid container>
            <Grid item xs={false} sm={2} md={2}></Grid>
            <Grid item xs={12} sm={8} md={8}>
                <Box className={classes.primaryLabel} fontWeight="500" fontSize={22} >System notification panel</Box>
                <Box className={classes.primaryLabel} fontWeight="500" fontSize={16} >This interface allows you to update the system notification message that is displayed on the login box.</Box>
            </Grid>
            <Grid item xs={false} sm={2} md={2}></Grid>
            <Grid item xs={false} sm={2} md={2}></Grid>
            <Grid item xs={12} sm={8} md={8}>
                <div className={classes.currentMessage} >
                    <Box className={classes.primaryLabel} fontWeight="500" fontSize={16} >Current message:</Box>
                    <Box className={classes.primaryLabel} fontSize={16} >{currentMessage}</Box>
                </div>
            </Grid>
            <Grid item xs={false} sm={2} md={2}></Grid>
            <Grid item xs={false} sm={2} md={2}></Grid>
            <Grid item xs={12} sm={8} md={8}>
                <div className={classes.messages} >
                    <Box className={classes.primaryLabel} fontWeight="500" fontSize={16} >New message options:</Box>
                    <RadioGroup className={classes.businessTypeRDO} aria-label="messageType" name="messageType" value={messageType} onChange={handleChange}>
                        <FormControlLabel value="default" control={<Radio />} label="Default message - System operating as normal" />
                        <FormControlLabel value="custom" control={<Radio />} label="Custom message" />
                    </RadioGroup>
                    <TextField className={classes.textField} disabled={customDisabled} value={customMessage} onChange={e => setCustomMessage(e.target.value)} name="message" label="Message to display" size="small" margin="dense" variant="outlined" />
                </div>
            </Grid>
            <Grid item xs={false} sm={2} md={2}></Grid>
            <Grid item xs={false} sm={2} md={2}></Grid>
            <Grid item xs={12} sm={8} md={8}>
                <div className={classes.actionDiv} >
                    <Button className={classes.actionButton} variant="contained" onClick={handleSave} >
                        <Box fontWeight="fontWeightRegular" fontSize={12} >Update message</Box>
                    </Button>
                </div>
            </Grid>
            <Grid item xs={false} sm={2} md={2}></Grid>
            </Grid>
        </div>
    )
  };