/**
 * Returns the boundary box for new view position.
 */
    export default function getMapExtentbyPoint(centreX,centreY,mapObj) {
    
        const ppi = 72;
        const inches = 39.37007874015748031496062992126;
    
        const extent = mapObj.getView().calculateExtent()
        const xmin = extent[0];
        const ymin = extent[1];
        const xmax = extent[2];
        const ymax = extent[3];

        const sw = (xmax - xmin)/2;  
        const sh = (ymax - ymin)/2; 
        
        const objExtent = {
            minX: centreX - sw,
            maxX: centreX + sw,
            minY: centreY - sh,
            maxY: centreY + sh
        }
    
        return objExtent;
      }