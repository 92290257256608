import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import {ReactComponent as NextArrowIcon} from '../../assets/dark_icons/icon_arrow_right_thin.svg';

import ProductPanel from './../rightPanelComponents/ProductPanel';
import PlotPanel from './../rightPanelComponents/PlotPanel';
import DataPanel from './../rightPanelComponents/DataPanel';
import ToolPanel from './../rightPanelComponents/ToolsPanel';
import AnnotationPanel from './../rightPanelComponents/AnnotationPanel';

import getLayerMinZoomInt from "../../functions/getLayerMinZoomInt";

import { ActivityContext } from "../../context/activity-context";
import { SessionContext } from "../../context/session-context";

export default function OrderingPanel(props) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: 270,
      borderLeft: '1px solid #D2D2D8',
      display:props.display
    },
    processStepper: {
      width: 271,
      flexDirection:"row",
      height:50,
      backgroundColor:"#DFE0ED",
      borderBottom: '2px solid #6466A7',
    },
    nextButton:{
      padding:0,
      '&:hover': {
        backgroundColor:"#C1DAE9",
    },
    },
    processContainer:{
      display:"flex",
    },
    arrowIcon:{
      paddingTop:16,
      width:30,
      height:20,
      color:'#fff',
    },
    menuTitleContainer:{
      width: 211,
      textAlign: 'left',
      marginLeft:10,
      //flexGrow:1
    },
    menuButton: {
      width: 50,
      height:50,
      backgroundColor:"#6466A7",
      '& .MuiButton-text': {
        padding: 0,
        minWidth: 48,
      },
    },
  
  }));

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [sState] = useContext(SessionContext);
  const [orderStep, setOrderStep] = useState('productSelect'); 
  const [orderType, setOrderType] = useState('plot'); 
  const [productLayer, setProductLayer] = useState('');
  const [stepLabel, setStepLabel] = useState('Next Step');
  const [doNextDisabled, setDoNextDisabled] = useState(true); 
  const [priceReady, setPriceReady] = useState(false); 
  const [projectreference, setProjectreference] = useState(''); 

  const [hasTools, setHasTools] = useState(false); 
  const [toolsDiv, setToolsDiv] = useState('none'); 
  const [hasAnnotationTools, setHasAnnotationTools] = useState(false); 
  const [annotationToolsDiv, setAnnotationToolsDiv] = useState('none'); 
  const [processDiv, setProcessDiv] = useState({display:'block'});





    //Tracks the status of price loading.
    useEffect(() => {
      setPriceReady(!aState.priceloading);
    },[aState.priceloading])

  
    useEffect(() => {
      if (aState.orderStep === "productSelect"){
        setStepLabel('Next Step');
        setDoNextDisabled(false); //Enable Next button
      }
    },[aState.orderStep])
  
    
    useEffect(() => {
      setProjectreference(aState.projectreference);
    },[aState.projectreference])


    useEffect(() => {
      if (orderStep === 'plot' || orderStep === 'data'){
        //console.log("Called plot data:"+priceReady+", length:"+projectreference.length);
        if (priceReady && projectreference.length > 1){
          setDoNextDisabled(false); //Enable Next button
        } else {
          setDoNextDisabled(true); //Disable Next button
        }
      }      
    },[priceReady, projectreference])



  const doNext = (event) => {
    //console.log("Current orderStep: "+orderStep);
      let newStep;
      let bContinue = true;
      //This is the current step of the process.
      switch (orderStep){
        case "productSelect":
          if (productLayer !== ''){ 
            newStep = orderType;
          }
          setStepLabel('Next Step');
        break;
        case "plot":
        case "data":

            if (hasTools) aDispatch({ type: "isDataCustom", payload: { hasToolbar: false, isDataCustom: false } });
            if (hasAnnotationTools) aDispatch({ type: "hasAnnotationbar", payload: { hasAnnotationbar: false } });
          
            bContinue = false;
            newStep = "orderSummary";
            setStepLabel('Buy now');
            //Also change the UI
            aDispatch({
              type: "updateviewstep",
              payload: { viewStep: newStep }
            });
            if ((orderStep === "plot" && !sState.default.canprint) || (orderStep === "data" && !sState.default.canexport)){
              setDoNextDisabled(true);
              setStepLabel('Buy now. Disabled');
            }

        break;
        case "orderSummary":
          //Either display credit card payment or order complete
          //Need to determine which path to go
          if (!sState.isRegistered || sState.company.companycreditlimit === 0){
            //Payment by credit card
            newStep = "orderPayment";
          } else {
            newStep = "orderComplete";
            setStepLabel('Next Step');
          }
          
          aDispatch({
            type: "updateviewstep",
            payload: { viewStep: newStep }
          });

        break;
        case "orderPayment":
            newStep = "orderComplete";
            setStepLabel('Next Step');
            aDispatch({
              type: "updateviewstep",
              payload: { viewStep: newStep }
            });
        break;
        case "orderComplete":
            //This is empty
        break;
      }

      setOrderStep(newStep); 
      aDispatch({
        type: "setorderstep",
        payload: { orderStep: newStep }
      });
  
  };

  //Tracks and records that a product layer has been selected.
  useEffect(() => {
    if (productLayer !== aState.productLayerSelect){
      //console.log("productLayer: "+aState.productLayerSelect);
      setProductLayer(aState.productLayerSelect);

      const minZoomInt = getLayerMinZoomInt(aState.productLayerSelect);
      if (minZoomInt < aState.minZoomInt){
        aDispatch({ type: "setminzoom", payload: { minZoomInt: parseInt(minZoomInt) }});
      }
      
    }
  },[aState.productLayerSelect])

  //Updates the order type. ie. plot or data. from Product Panel
  useEffect(() => {
    if (aState.productSelect !== orderType){
      //console.log("productSelect: "+aState.productSelect);
      setOrderType(aState.productSelect);
    }
  },[aState.productSelect])

  //Updates the order type. ie. plot or data. from Product Panel
  useEffect(() => {
    //console.log("orderStep useEffect: "+aState.orderStep);
    if (aState.orderStep !== orderStep){
      setOrderStep(aState.orderStep);
    }
  },[aState.orderStep])


  useEffect(() => {
    if (aState.hasToolbar){
      setHasTools(true);
      setToolsDiv('block');
      setProcessDiv({display:'none'});
    } else {
      setHasTools(false);
      setToolsDiv('none');
      setProcessDiv({display:'block'});
    }
  },[aState.hasToolbar])

  
  useEffect(() => {
    if (aState.hasAnnotationbar){
      setHasAnnotationTools(true);
      setAnnotationToolsDiv('block');
      setProcessDiv({display:'none'});
    } else {
      setHasAnnotationTools(false);
      setAnnotationToolsDiv('none');
      setProcessDiv({display:'block'});
    }
  },[aState.hasAnnotationbar])
  
  
  return (
    <div className={classes.root}>
      <div className={classes.processStepper}>
      <Button className={classes.nextButton} disabled={doNextDisabled} onClick={doNext}>
          <Box className={classes.menuTitleContainer}><Box className={classes.menuTitle} fontWeight="fontWeightBold" fontSize={16}>{stepLabel}</Box></Box>
          <Box className={classes.menuButton}><NextArrowIcon className={classes.arrowIcon} /></Box>
      </Button>
      </div>
      <ToolPanel height={props.height} display={toolsDiv} />
      <AnnotationPanel height={props.height} display={annotationToolsDiv} />
      <div style={processDiv}>
        {orderStep === 'productSelect' ? <ProductPanel height={props.height} /> : ''}
        {orderStep === 'plot' ? <PlotPanel height={props.height} /> : ''}
        {orderStep === 'data' ? <DataPanel height={props.height} /> : ''}
      </div>
    </div>
  );
}

