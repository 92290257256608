/**
 * Returns the product name based on value..
 */

function getProductName(value, type) {
    let productName = '';
    if (type === 'plot' || type === 'view'){
        switch (value){
            default:
            case "osmmbw":
                productName = 'OS Plan Black / White'
                break;
            case "osmm":
                productName = 'OS Plan Colour'
                break;
            case "os10k":
                productName = '1:10,000'
                break;
            case "os25k":
                productName = '1:25,000'
                break;
            case "os50k":                
                productName = '1:50,000'
                break;
            case "os250k":
                productName = '1:250,000'
                break;
            case "aerial":
                productName = 'Imagery'
                break;
        }
    }

    if (type === 'data'){
        switch (value){
            case "osmm9":
                productName = 'OS Plan (CAD) Standard 9 Layers'
                break;
            case "osmm55":
                productName = 'OS Plan (CAD) Enhanced 55 Layers'
                break;
            case "os10k":
                productName = '1:10,000 Scale Raster'
                break;
            case "os25k":
                productName = '1:25,000 Scale Raster'
                break;
            case "os50k":
                productName = '1:50,000 Scale Raster'
                break;
            case "os250k":
                productName = '1:250,000 Scale Raster'
                break;
        }
    }
      
    return productName;
  }
  export default getProductName;