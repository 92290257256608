import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ActivityContext } from "../../context/activity-context";

import MapObject from './MapObject';
import MapInfoBox from './MapInfoPaper';
import MapTools from './MapToolsPaper';

var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
};

export default function MappingPanel(props) {

    const useStyles = makeStyles({
        root: {
            borderLeft: '1px solid #D2D2D8',
            width: props.width,
            height: props.height,
            backgroundColor:'#FFF',
            
        },
    });

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  //<div id='watermark' className={classes.watermark} />

  return (
    <div className={classes.root}>
      <MapObject height={props.height} width={props.width} baselayer={'os10k'} left={props.infoPanelWidth}/>
      <MapTools infoPanelWidth={props.infoPanelWidth}/>
      <MapInfoBox />
    </div>
  );
}