/**
 * Returns the boundary box for new view scale.
 */

//export default function setMapScale(newscale,xmin,ymin,xmax,ymax,mapwidth,mapheight) {
export default function setMapScale(newscale,mapObj) {
    
    const ppi = 72;
    const inches = 39.37007874015748031496062992126;

    const extent = mapObj.getView().calculateExtent()
    const xmin = extent[0];
    const ymin = extent[1];
    const xmax = extent[2];
    const ymax = extent[3];
    const mapSize = mapObj.getSize();
    const mapwidth = mapSize[0];
    const mapheight = mapSize[1];

    const sw = (((mapwidth/ppi)/inches)*newscale)/2;  
    const sh = (((mapheight/ppi)/inches)*newscale)/2;

    const centreX = xmin + (xmax - xmin)/2;
    const centreY = ymin + (ymax - ymin)/2;
    
    const objExtent = {
        "scale": newscale,
        "xmin": centreX - sw,
        "ymin": centreY - sh,
        "xmax": centreX + sw,
        "ymax": centreY + sh
    }

    return objExtent;
  }