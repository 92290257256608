/**
 * Creates or updates a user
 * This is a super call.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function createCompany(params, user, dispatch) {

        const abortController = new AbortController();

        const request = {
            userid: params.userid,
            ident: params.ident,
            cuserid:user.userid,
            uname: user.username,
            name: user.username,
            companyid:user.companyid,
            firstname:user.firstname,
            lastname:user.lastname,
            email:user.email,
            telephone:user.telephone,
            status:user.status,
            utype:user.utype,
            password:user.password,
        }
        let mounted = true;
        (async () => {  
            const res = await fetch(process.env.REACT_APP_NMC_API+'/admin/user/save', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(request),
                signal: abortController.signal,
            }).catch(handleError);
            const data = await res.json();
            if (mounted) {
              if (data.status === "success"){
                dispatch({
                  type: "setcompanyusers",
                  payload: { users: data.users}
                });
                  
              }
            }
            const cleanup = () => {
                mounted = false;
                abortController.abort();
            };
            return cleanup;

        })();

    }
