
import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NotificationInterface from './loginComponents/NotificationPanel';
import RegisterBenefits from './loginComponents/RegisterBenefits';
import BusinessLogin from './loginComponents/BusinessLogin';
import UserLogin from './loginComponents/UserLogin';

const nmcLogo = require('../assets/nmc_logo.png');
const osLogo = require('../assets/os_logo.png');

const useStyles = makeStyles({
    root: {
        minWidth:700,
        minHeight: 800
    },
    nmclogo: {
        maxWidth: 200,
    },
    oslogo: {
        marginRight:30,
        marginBottom:10,
        maxWidth: 128,
    },
    containerOSLogo: {
        display:"flex",
        flexDirection:"row",
        justifyContent: "right"
    },
    containerDiv: {
        display:"flex",
        flexDirection:"row",
        justifyContent: "center"
    },
    noticesDiv: {
        height: '70vh',
        backgroundColor:'#3D3E4F',
    },
    businessTypeRDO: {
        paddingTop: 20,
        '& .MuiRadio-colorSecondary.Mui-checked':{
            color: '#447095',
        }
    },
    copyrightText: {
      paddingTop: 20,
      paddingLeft: 40,
    }
    
  });
  

export default function LoginInterface(props) {
    const classes = useStyles();
    const [loginType, setLoginType] = useState('business');
    const [needLogin, setNeedLogin] = useState(true);
 
  
    useEffect(() => {
        //Checks to see if login type already stored from previous.
        if (localStorage.getItem('nmc_login_type') !== null) {setLoginType(localStorage.getItem('nmc_login_type'));}
        localStorage.setItem('nmc_login_typ', loginType);
    }, []);

    useEffect(() => {
      //Stores the login type in local storage.
      if (loginType !== localStorage.getItem('nmc_login_type')){
          localStorage.setItem('nmc_login_type', loginType);
      }        
  }, [loginType]);

    
    const handleChange = (event) => {
        setLoginType(event.target.value);
      };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                <NotificationInterface />
                </Grid>
                <Grid item xs={5}>
                    <Box component="div" className={classes.containerDiv} p={1} m={2} >
                        <Box p={1}> <img src={nmcLogo} alt="National Map Centre logo" className={classes.nmclogo} /></Box>
                    </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                    <RadioGroup className={classes.businessTypeRDO} aria-label="loginType" name="loginType" value={loginType} onChange={handleChange}>
                        <FormControlLabel value="business" control={<Radio />} label="Username and Business ID" />
                        <FormControlLabel value="user" disabled={true} control={<Radio />} label="New customer with email" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={5}>
                    <RegisterBenefits />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                {loginType === 'business' ? ( <BusinessLogin />) : ( <UserLogin /> )}

                </Grid>
                <Grid item xs={8}><Box className={classes.copyrightText} fontSize={12}>{process.env.REACT_APP_LOGIN_COPYRIGHT.replace("dddd", new Date().getFullYear())} | <a href={process.env.REACT_APP_SITE_TERMS} target="_blank" rel="noreferrer">Terms</a></Box></Grid>
                <Grid item xs={4}><Box className={classes.containerOSLogo}> <img src={osLogo} alt="Ordnance Survey partner logo" className={classes.oslogo} /></Box></Grid>
            </Grid>
        </div>
    )
  };
