import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {ReactComponent as CloseIcon} from '../../assets/dark_icons/iconmonstr-x-mark-2 1.svg';
import {ReactComponent as OutlineIcon} from '../../assets/dark_icons/icon-outline.svg';
import {ReactComponent as OutlinesIcon} from '../../assets/dark_icons/icon-outlines.svg';
import {ReactComponent as RectangleIcon} from '../../assets/dark_icons/icon-rectangle-square.svg';
import {ReactComponent as PolygonIcon} from '../../assets/dark_icons/icon-polygon-triangle.svg';
import {ReactComponent as CircleIcon} from '../../assets/dark_icons/iconmonstr-circle-2 1.svg';
import {ReactComponent as LineIcon} from '../../assets/dark_icons/icon-line.svg';
import {ReactComponent as RulerIcon} from '../../assets/dark_icons/iconmonstr-ruler-6 1.svg';
import {ReactComponent as RulerAreaIcon} from '../../assets/dark_icons/iconmonstr-rulerarea.svg';

import {ReactComponent as TrashIcon} from '../../assets/dark_icons/iconmonstr-trash-can-16 1.svg';
import {ReactComponent as CustomAreaIcon} from '../../assets/dark_icons/custom-area.svg';
import {ReactComponent as AreaSizeIcon} from '../../assets/dark_icons/icon-area-size.svg';

import {ReactComponent as LineDashedIcon} from '../../assets/dark_icons/icon-line-dash1.svg';
import {ReactComponent as LineDashedIcon2} from '../../assets/dark_icons/icon-line-dash2.svg';
import {ReactComponent as LineThinIcon} from '../../assets/dark_icons/icon-line-thin.svg';
import {ReactComponent as LineNormalIcon} from '../../assets/dark_icons/icon-line-normal.svg';
import {ReactComponent as LineThickIcon} from '../../assets/dark_icons/icon-line-thick.svg';

import {ReactComponent as CursorIcon} from '../../assets/dark_icons/iconmonstr-cursorselect-1 1.svg';
import {ReactComponent as UndoIcon} from '../../assets/dark_icons/icon-undo.svg';
import {ReactComponent as RedoIcon} from '../../assets/dark_icons/icon-redo.svg';

import getMeasureArea from "../../functions/parseAreaMeasurement";
import getMeasureLength from "../../functions/parseMeasureLength";

import { ActivityContext } from "../../context/activity-context";
import { SessionContext } from "../../context/session-context";



export default function ToolsPanel(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
            width: 270,
            height: props.height - 50,
            overflowY: 'auto',
            backgroundColor: '#3D3E4F',
            display:props.display,
            '& .MuiTypography-colorTextSecondary':{
                color: '#FFF',
                fontSize:12,
            },
            '& .MuiInputAdornment-positionEnd':{
                marginLeft:3
            },
        },
        toolButton: {
            width: 120,
            height:65,
            backgroundColor: '#5C5D6B',
            color:'#FFF',
            border: '1px solid #3D3E4F',
            fill:'#FFF',
            '&:hover': {
                color: '#3D3E4F',
                backgroundColor: '#F9F9FA',
                fill: '#3D3E4F',
            }
        },
        toolButtonHold: {
            width: 120,
            height:65,
            backgroundColor: '#5C5D6B',
            color:'#FFF',
            border: '1px solid #3D3E4F',
            fill:'#FFF',
            '& .MuiToggleButton-root.Mui-disabled':{
                fill: 'rgba(0, 0, 0, 0.12)',
            }
        },
        smToolButton: {
            width: 80,
            height:65,
            backgroundColor: '#5C5D6B',
            color:'#FFF',
            border: '1px solid #3D3E4F',
            fill:'#FFF',
            '&:hover': {
                color: '#3D3E4F',
                backgroundColor: '#F9F9FA',
                fill: '#3D3E4F',
            },
            '& .MuiToggleButton-root.Mui-selected': {
                color: '#3D3E4F',
                fill: '#3D3E4F',
                backgroundColor: '#C1DAE9'
            },
            '& .MuiToggleButton-root.Mui-disabled':{
                fill: 'rgba(0, 0, 0, 0.12)',
            }
        },
        smToolButtonHold: {
            width: 78,
            height:63,
            backgroundColor: '#5C5D6B',
            color:'#FFF',
            border: '1px solid #3D3E4F',
            fill:'#FFF',
        },
        closeContainer:{
            paddingTop:8,
            paddingRight:8,
        },
        LabelContainer:{
            alignItems: 'center',
        },
        iconContainer:{
            width:'100%',
        },
        miniIcon:{
            width:16,
            height:16
        },
        buttonLabel:{
            color:'#FFF',
            width:'100%',
            '&:hover': {
                color: '#3D3E4F',
            },
            '& .MuiToggleButton-root.Mui-selected': {
                color: '#3D3E4F',
            },
        },
        closeLabel:{
            fill:'#FFF',
            paddingRight: '0px',
            minWidth:'0px',            
        },
        iconClose:{
            height:16,
            width:16,
            fill:'inherit',
            '&:hover': {
                fill: '#C1DAE9',
            }
        },
        icon:{
            fill:'inherit',
            '&:hover': {
                fill: '#3D3E4F',
            },
            '& .MuiToggleButton-root.Mui-selected': {
                fill: '#3D3E4F',
            },
        },
        lgButtonContainer:{
            marginLeft:8,
            '& .MuiToggleButton-root.Mui-selected': {
                color: '#3D3E4F',
                fill: '#3D3E4F',
                backgroundColor: '#C1DAE9'
            },
        },
        divider:{
            color: '#FFF',
            marginTop:10,
            marginBottom:10,
            backgroundColor: '#5C5D6B',
        }, 
        sectionContainer:{
            paddingTop:10,
        },
        sectionLabel:{
            marginBottom:10,
            color:'#B2B2BC',
        },
        sectionStyleLabel:{
            marginBottom:0,
            color:'#B2B2BC',
        },
        sectionSubLabel:{
            color:'#B2B2BC',
        },
        sectionItemLabel:{
            color:'#FFF',
            paddingTop:3,
        }, 
        areaIconContainer:{
            width:30,
            paddingTop:6,
            paddingLeft:5
        },
        widthHeightLabel:{
            color:'#FFF',
        }, 
        textItemFont:{
            fontSize:12,
            color: '#FFF',
            marginTop:2
        },
        textAreaInput:{
            '& .MuiOutlinedInput-root':{
                border: '1px solid #5C5D6B',
                color: '#FFF',
            },
        },
        styleItem:{
            height:29,
            width:240,
            backgroundColor: '#5C5D6B',
            color: '#FFF',
            '& .MuiListItem-gutters':{
                paddingLeft:10,
            },
            '&:hover': {
                backgroundColor: '#C1DAE9',
                color: '#000',
            },
            '&.MuiListItem-root.Mui-selected': {
                backgroundColor: '#C1DAE9',
                color: '#000',
                fontWeight:500,
            },
        },
        styleItemText:{
            fontSize:14,
            marginLeft:10
        },
        styleInputContainer:{
            marginLeft:5
        },
        styleInputLabel:{
            color: '#FFF',
            height:18,
            marginBottom:3
        },
        styleInputField:{
            marginTop:0,
            height:35,
            width:65,
            border: '1px solid #5C5D6B',
            '& .MuiOutlinedInput-adornedEnd' : {
				paddingRight: 5,
			},
        },
        areaInputContainer:{
            width:90,
        },
        parameterInputContainerFirst:{
            width:68,
        },
        parameterInputContainer:{
            marginLeft: 10,
            width:68,
        },
        parameterInputLabel:{
            color: '#FFF',
            marginBottom:2,
        },
        parameterInputField:{
            marginTop:0,
            height:35,
            color: '#FFF',
            border: '1px solid #5C5D6B',
            '& .MuiOutlinedInput-adornedEnd' : {
				paddingRight: 5,
			},
        },
        parameterInputFont:{
            fontSize:12,
            color: '#FFF',
            marginTop:2,
            textAlign: 'end'
        },
        createButton:{
            height:22,
            marginTop: 10,
            backgroundColor:'#878897',
            color: '#FFF',
            '&:hover': {
              color: '#5C5D6B',
            },
        },
        areacreateBtnContainer:{
            paddingRight:32,
            paddingBottom:10,
        },
        styleIcon:{
            width:24,
            height:24
        },
        measurementField:{
            margin:0,
            color: '#FFF',
            backgroundColor: '#5C5D6B',
            border: '1px solid #EDEDEF',
            width:70,
            height: 30,
            '& .MuiSelect-outlined.MuiSelect-outlined': {
                fontSize:12,
                padding:6
            },
        },
        measurementOptionLabel:{
            fontSize:12,
        },
        measurementLabel:{
            color: '#FFF',
            paddingTop:15,
            paddingRight:8,
        },
        measurementFieldText:{
            color: '#FFF',
            fontSize:12,
            width: 90,
            border: '1px solid #5C5D6B',
            height: 35
        },
        measurementLengthContainer:{
            marginTop:10,
            marginLeft:20,
        },
        fontContainer:{
            marginLeft:5,
        },
        fontFieldTextLabel:{
            marginTop:10,
            color: '#FFF',
        },
        fontFieldText:{
            marginTop:2,
            color: '#FFF',
            width:240,
            height:35,
            border: '1px solid #5C5D6B'
            //#3D3E4F

        }

          
    }));

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [sState] = useContext(SessionContext);
  const [isAnnotation, setIsAnnotation] = useState(false);
  const [isMastermap, setIsMastermap] = useState(false);
  const [hasParameters, setHasParameters] = useState(true);
  const [hasOutlines, setHasOutlines] = useState(true);
  const [hasFill, setHasFill] = useState(true);
  const [hasLinetype, setHasLinetype] = useState(true);
  const [hasLineweight, setHasLineweight] = useState(true);
  const [hasAreameasurement, setHasAreameasurement] = useState(true);
  const [hasLinemeasurement, setHasLinemeasurement] = useState(true);
  const [hasTextFont, setHasTextFont] = useState(true);
  const [styleSource, setStyleSource] = useState('toolbar');
  const [isDoDelete, setIsDoDelete] = useState(false);
  const [isDoUndo, setIsDoUndo] = useState(false);
  const [isDoRedo, setIsDoRedo] = useState(false);
  const [customRectDisabled, setCustomRectDisabled] = useState(false);
  const [isManual, setIsManual] = useState(false);

  
  const [selectedTool, setSelectedTool] = useState('');
  const [toolSingleAction, setToolSingleAction] = useState('');
  

  const [areaWidth, setAreaWidth] = useState(0);
  const [areaHeight, setAreaHeight] = useState(0);

  const [buffer, setBuffer] = useState(0);
  const [resize, setResize] = useState(0);
  const [rotation, setRotation] = useState(0);

  const [outlineStyle, setOutlineStyle] = useState('#DC3131'); 
  const [fillStyle, setFillStyle] = useState('#DC3131');
  const [linetypeStyle, setLinetypeStyle] = useState('solid');
  const [lineweightStyle, setLineweightStyle] = useState('thin'); 

  const [opacityOutline, setOpacityOutline] = useState(100); 
  const [opacityFill,setOpacityFill] = useState(50); 

  const [fontFont, setFontFont] = useState('Arial'); 
  const [fontSize, setFontSize] = useState(12); 
  const [fontType, setFontType] = useState('normal'); 
  const [fontText, setFontText] = useState(''); 

  const [areaMeasurementUnit,setAreaMeasurementUnit] = useState('km²'); 
  const [lineMeasurementUnit,setLineMeasurementUnit] = useState('m'); 
  const [areaMeasurement,setAreaMeasurement] = useState(''); 
  const [lineMeasurement,setLineMeasurement] = useState(''); 


  //Closes the toolbar.
  const doClose = (event) => {
    setSelectedTool('none');
    aDispatch({ type: "hasAnnotationbar", payload: { hasAnnotationbar: false} });
    aDispatch({ type: "actionchange", payload: { action: 'endAnnotationTools' } });
    
  }

  //Tools displayed are dependent on the basemap.
  useEffect(() => {
      switch (aState.bmap){
        case "osmm":
        case "osmmbw":
            setIsMastermap(true);
        break;
        default:
            setIsMastermap(false);
            break;
      }
    
  },[aState.bmap])



  useEffect(() => {



  },[aState.caction])


    const OrangeRadio = withStyles({
        root: {
          marginTop:22,
          padding:2,
          width:22,
          height:22,
          color: '#F6A036',
          backgroundImage: 'radial-gradient(#3D3E4F,#3D3E4F 20%,transparent 32%)',
          '&$checked': {
            color: '#F6A036',
          },
        },
        checked: {},
      })((props) => <Radio color="default" {...props} />);

      const RedRadio = withStyles({
        root: {
          marginTop:22,
          padding:2,
          width:22,
          height:22,
          color: '#DC3131',
          '&$checked': {
            color: '#DC3131',
          },
        },
        checked: {},
      })((props) => <Radio color="default" {...props} />);

      const BlueRadio = withStyles({
        root: {
            marginTop:22,
            padding:2,
            width:22,
            height:22,
          color: '#2D2F88',
          '&$checked': {
            color: '#2D2F88',
          },
        },
        checked: {},
      })((props) => <Radio color="default" {...props} />);

      const PinkRadio = withStyles({
        root: {
            marginTop:22,
            padding:2,
            width:22,
            height:22,
          color: '#FF0098',
          '&$checked': {
            color: '#FF0098',
          },
        },
        checked: {},
      })((props) => <Radio color="default" {...props} />);

      const LimeRadio = withStyles({
        root: {
            marginTop:22,
            padding:2,
            width:22,
            height:22,
          color: '#43E00C',
          '&$checked': {
            color: '#43E00C',
          },
        },
        checked: {},
      })((props) => <Radio color="default" {...props} />);

      const WhiteRadio = withStyles({
        root: {
            marginTop:22,
            padding:2,
            width:22,
            height:22,
          color: '#FFFFFF',
          '&$checked': {
            color: '#FFFFFF',
          },
        },
        checked: {},
      })((props) => <Radio color="default" {...props} />);

      const handleToolChange = (value) => {
        setSelectedTool(value);
      };
      const handleToolSingleAction = (value) => {
        setToolSingleAction(value);
      };

      /**
       * Listerns for actions on the draw tools
       */
      useEffect(() => {

        let actionSelected = '';
        //setTextToolDisplay('none');
        //sDispatch({ type: "annotationselected", payload: { type: 'none' } });
        switch(selectedTool){
          default:
          case "none":
            actionSelected = '';
          break;
          case "select":
            actionSelected = 'selectAnnotation';
            setHasFill(false);
            setHasLineweight(false);
            setHasParameters(false);
            setHasLinetype(false);
            setHasOutlines(false);
            setHasLinemeasurement(false);
            setHasAreameasurement(false);
          break;
          case "ospolygon":
            actionSelected = 'createAnnotationOSpolygon';
            setHasFill(true);
            setHasLineweight(true);
            setHasParameters(false);
            setHasLinetype(true);
            setHasOutlines(true);
            setHasLinemeasurement(false);
            setHasAreameasurement(true);

          break;
          case "osmultipolygon":
            actionSelected = 'createAnnotationOSmultipolygon';
            setHasFill(true);
            setHasLineweight(true);
            setHasParameters(false);
            setHasLinetype(true);
            setHasOutlines(true);
            setHasLinemeasurement(false);
            setHasAreameasurement(true);

          break;
          case "rectangle":
            actionSelected = 'createAnnotationRectangle';
            setHasFill(true);
            setHasLineweight(true);
            setHasParameters(false);
            setHasLinetype(true);
            setHasOutlines(true);
            setHasLinemeasurement(false);
            setHasAreameasurement(true);
          break;

          case "polygon":
            actionSelected = 'createAnnotationPolygon';
            setHasFill(true);
            setHasLineweight(true);
            setHasParameters(false);
            setHasLinetype(true);
            setHasOutlines(true);
            setHasLinemeasurement(false);
            setHasAreameasurement(true);
          break;
          case "circle":
            actionSelected = 'createAnnotationCircle';
            setHasFill(true);
            setHasLineweight(true);
            setHasParameters(false);
            setHasLinetype(true);
            setHasOutlines(true);
            setHasLinemeasurement(false);
            setHasAreameasurement(true);
          break;
          case "line":
            actionSelected = 'createAnnotationLine';
            setHasFill(false);
            setHasLineweight(true);
            setHasParameters(false);
            setHasLinetype(true);
            setHasOutlines(true);
            setHasLinemeasurement(true);
            setHasAreameasurement(false);
          break;
          case "rulerarea":
            actionSelected = 'createMeasureArea';
            setHasFill(false);
            setHasLineweight(false);
            setHasParameters(false);
            setHasLinetype(false);
            setHasOutlines(false);
            setHasLinemeasurement(false);
            setHasAreameasurement(true);
          break;
          case "ruler":
            actionSelected = 'createMeasureLine';
            setHasFill(false);
            setHasParameters(false);
            setHasLineweight(false);
            setHasLinetype(false);
            setHasOutlines(false);
            setHasLinemeasurement(true);
            setHasAreameasurement(false);
          break;
          case "undo":
            actionSelected = 'undoLast';
          break;
          case "redo":
            actionSelected = 'redoLast';
          break;
          case "deleteObject":
            actionSelected = 'deleteObject';
          break;
        }
        if (actionSelected !== '') {
            //console.log("actionchange called: "+actionSelected); 
            aDispatch({ type: "actionchange", payload: { action: actionSelected } })
        };
        
    },[selectedTool]);


    /**
     * Listerns for single toolbar actions. i.e. undo, redo.
     */
    useEffect(() => {

    let actionSelected = '';
    switch(toolSingleAction){
            default:
            case "none":
            actionSelected = '';
            break;
            case "undo":
            actionSelected = 'undoLast';
            break;
            case "redo":
            actionSelected = 'redoLast';
            break;
            case "deleteObject":
            actionSelected = 'deleteObject';
            break;
        }
        if (actionSelected !== '') {
            //console.log("actionchange called: "+actionSelected); 
            aDispatch({ type: "annotationAction", payload: { annotationAction: actionSelected } })
        };
    },[toolSingleAction]);

    useEffect(() => {
        setToolSingleAction(aState.annotationAction);
    },[aState.annotationAction]);


    /**
     * Listerns and stores changes to annotation styles
     */
    useEffect(() => {

        if (aState.hasAnnotationbar && styleSource === 'toolbar'){
            //{outline:{colour: 'rgb(220,49,49)', width: 3}, fill:{colour: 'rgba(220,49,49,50)'}, source: 'none'}
            const cStyle = aState.currentStyle;

            //{"outline":{"colour":"rgb(220,49,49)","width":3,"linedash":"solid"},"fill":{"colour":"rgba(220,49,49,50)"},"source":"none"}
            
            const l = hexToRgb(outlineStyle);
            cStyle.outline.colour = "rgba("+l.r+","+l.g+","+l.b+","+(opacityOutline/100)+")";
            const f = hexToRgb(fillStyle);
            cStyle.fill.colour = "rgba("+f.r+","+f.g+","+f.b+","+(opacityFill/100)+")";
            //console.log("cStyle: "+cStyle.fill.colour);
            switch(lineweightStyle){
                case "hairline":
                    cStyle.outline.width = 1;
                    break;
                case "thin":
                    cStyle.outline.width = 4;
                    break;
                case "thick":
                    cStyle.outline.width = 8;
                    break;
            }
            cStyle.outline.linedash = linetypeStyle;
            cStyle.source = 'toolbar'
            if (typeof cStyle !== 'undefined'){
                //console.log("Storing cStyle: "+JSON.stringify(cStyle));
                aDispatch({ type: "setCurrentStyle", payload: { currentStyle: cStyle } });
            }
        }
                
    },[outlineStyle, fillStyle, linetypeStyle, lineweightStyle, opacityFill , opacityOutline]);

    /**
     * Event that listerns for the selection of an annotation object.
     */
    useEffect(() => {
        if (aState.caction === 'selectAnnotation'){

            console.log("Storing cStyle: "+JSON.stringify(aState.currentAnnotation.object));
            setStyleSource(aState.currentAnnotation.source);
            let sOutline = '';
            let fOutline = '';
            switch (aState.currentAnnotation.object){
                case 'linestring':
                    
                    sOutline = rgbaStringToHex(aState.currentAnnotation.style.lineColour);
                    setOutlineStyle(sOutline.hex);
                    setOpacityOutline(sOutline.alpha*100);

                    setHasFill(false);
                    setHasLineweight(true);
                    setHasParameters(false);
                    setHasLinetype(true);
                    setHasOutlines(true);
                    setHasLinemeasurement(true);
                    setHasAreameasurement(false);
                break;
                case 'circle':

                    sOutline = rgbaStringToHex(aState.currentAnnotation.style.lineColour);
                    fOutline = rgbaStringToHex(aState.currentAnnotation.style.fillColour);
                    setOutlineStyle(sOutline.hex);
                    setOpacityOutline(sOutline.alpha*100);
                    setFillStyle(fOutline.hex);
                    setOpacityFill(fOutline.alpha*100);

                    setLineweightStyle(widthToType(aState.currentAnnotation.style.lineWidth));
                    setLinetypeStyle(aState.currentAnnotation.style.lineDash);

                    //aState.currentAnnotation.style.area

                    setHasFill(true);
                    setHasLineweight(true);
                    setHasParameters(false);
                    setHasLinetype(true);
                    setHasOutlines(true);
                    setHasLinemeasurement(false);
                    setHasAreameasurement(true);
                break;
                case 'polygon':
                    sOutline = rgbaStringToHex(aState.currentAnnotation.style.lineColour);
                    fOutline = rgbaStringToHex(aState.currentAnnotation.style.fillColour);
                    setOutlineStyle(sOutline.hex);
                    setOpacityOutline(sOutline.alpha*100);
                    setFillStyle(fOutline.hex);
                    setOpacityFill(fOutline.alpha*100);
                    
                    setLineweightStyle(widthToType(aState.currentAnnotation.style.lineWidth));
                    setLinetypeStyle(aState.currentAnnotation.style.lineDash);
                    //setAreaMeasurement(aState.currentAnnotation.area);

                    setHasFill(true);
                    setHasLineweight(true);
                    setHasParameters(false);
                    setHasLinetype(true);
                    setHasOutlines(true);
                    setHasLinemeasurement(false);
                    setHasAreameasurement(true);
                break;
            }
        }
    },[aState.currentAnnotation.geometry]);


    const widthToType = (v) =>{
        let response = "";
        switch(parseInt(v)){
            case 1:
            case 2:
                response = "hairline";
                break;
            case 3:
            case 4:
            case 5:
                response = "thin";
                break;
            case 7:
            case 8:
            case 9:
                response = "thick";
                break;
        }
        return response;
    }


    useEffect(() => {
        const units = {
            'area': areaMeasurementUnit,
            'length': lineMeasurementUnit,
        }
        aDispatch({ type: "setmeasurementunits", payload: { units: units } });

        //If aready have value need to recalculate.
        if (aState.measurements.area !=='-'){
            const vA = getMeasureArea(aState.measurements.raw, areaMeasurementUnit, false)
            setAreaMeasurement(vA.value);
            setFontText('Area: '+vA.value+' '+areaMeasurementUnit);
        }
        if (aState.measurements.length !=='-'){
            const vL = getMeasureArea(aState.measurements.raw, areaMeasurementUnit, false)
            setLineMeasurement(vL.value);
            setFontText('Length: '+vL.value+' '+lineMeasurementUnit);
        }

    },[areaMeasurementUnit, lineMeasurementUnit]);


    useEffect(() => {
        console.log("aState.measurements.area changed "+aState.measurements.area)
        if (aState.measurements.area !=='-'){

            setAreaMeasurement(aState.measurements.area);
            setFontText('Area: '+aState.measurements.area+' '+areaMeasurementUnit);
        }

    },[aState.measurements.area]);

    useEffect(() => {
        if (aState.measurements.length !=='-'){
            setLineMeasurement(aState.measurements.length);
            setFontText('Length: '+aState.measurements.length+' '+lineMeasurementUnit);
        }
    },[aState.measurements.length]);

    const handleOutlineStyleChange = (event) => {
        
        setOutlineStyle(event.target.value);
        setStyleSource('toolbar');
    };

    const handleFillStyleChange = (event) => {
        setFillStyle(event.target.value);
        setStyleSource('toolbar');
    };

    const handleLineTypeStyleChange = (value) => {
        //console.log("handleLineTypeStyleChange "+value);
        setLinetypeStyle(value);
        setStyleSource('toolbar');
    };

    const handleLineWeightStyleChange = (value) => {
        setLineweightStyle(value);
        setStyleSource('toolbar');
    };

    const handleMeasureAreaUnitChange = (value) => {
        setAreaMeasurementUnit(value);
    };

    const handleMeasureLineUnitChange = (value) => {
        setLineMeasurementUnit(value);
    };
    
    const rgbStringToHex = (rgb) => {
        rgb = rgb.replace('rgb(', '');
        rgb = rgb.replace(')', '');
        const rgb_parts = rgb.split(',');
        return rgbToHex(parseInt(rgb_parts[0]),parseInt(rgb_parts[1]),parseInt(rgb_parts[2]));
      };

      const rgbaStringToHex = (rgb) => {
        
        rgb = rgb.replace('rgba(', '');
        rgb = rgb.replace(')', '');
        const rgb_parts = rgb.split(',');
        const response = {};
        response.hex = rgbToHex(parseInt(rgb_parts[0]),parseInt(rgb_parts[1]),parseInt(rgb_parts[2]));
        response.alpha = rgb_parts[3];
        return response;
      };
    
      const rgbToHex = (r, g, b) => {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
      };
    
      const componentToHex = (c) => {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };
    
      const hexToRgb = (hex) => {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b;
        });
      
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }

  return (
    <div className={classes.root}>
        <Box display="flex" justifyContent="flex-end" className={classes.closeContainer}>
            <Button onClick={doClose} className={classes.closeLabel}>
                <Box fontSize={12} ><CloseIcon className={classes.iconClose}/></Box>
            </Button>
        </Box>
        <div className={classes.lgButtonContainer}>
        {!isMastermap ? '' : 
        <Box display="flex" flexDirection="row">
            <ToggleButton className={classes.toolButton} value="ospolygon" selected={selectedTool === 'ospolygon'} onChange={e => handleToolChange('ospolygon') } >
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><OutlineIcon className={classes.icon}/></Box>
                    <Box flexGrow={1} fontSize={8}>OS Polygon</Box>
                </div>
            </ToggleButton>
            <ToggleButton className={classes.toolButton} value="osmultipolygon" selected={selectedTool === 'osmultipolygon'} onChange={e => handleToolChange('osmultipolygon')} >
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><OutlinesIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>OS MultiPolygon</Box>
                </div>
            </ToggleButton>
        </Box>
        }
        <Box display="flex" flexDirection="row">
            <ToggleButton className={classes.toolButton} value="rectangle" selected={selectedTool === 'rectangle'} onChange={e => handleToolChange('rectangle')} >
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><RectangleIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Rectangle</Box>
                </div>
            </ToggleButton>
            <ToggleButton className={classes.toolButton} value="polygon" selected={selectedTool === 'polygon'} onChange={e => handleToolChange('polygon')} >
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><PolygonIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Polygon</Box>
                </div>
            </ToggleButton>
        </Box>

        <Box display="flex" flexDirection="row">
            <ToggleButton className={classes.toolButton} value="circle" selected={selectedTool === 'circle'} onChange={e => handleToolChange('circle')} >
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><CircleIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Circle</Box>
                </div>
            </ToggleButton>
            <ToggleButton className={classes.toolButton} value="line" selected={selectedTool === 'line'} onChange={e => handleToolChange('line')}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><LineIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Line / Multiline</Box>
                </div>
            </ToggleButton>
        </Box>
        

        <Box display="flex" flexDirection="row">
            <ToggleButton className={classes.toolButton} value="rulerarea" selected={selectedTool === 'rulerarea'} onChange={e => handleToolChange('rulerarea')} >
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><RulerAreaIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Measure Area</Box>
                </div>
            </ToggleButton>
            <ToggleButton className={classes.toolButton} value="ruler" selected={selectedTool === 'ruler'} onChange={e => handleToolChange('ruler')} >
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><RulerIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Measure Distance</Box>
                </div>
            </ToggleButton>
        </Box>
        <Box display="flex" flexDirection="row" className={classes.sectionContainer}> 
            <ToggleButton className={classes.smToolButton} value="select" selected={selectedTool === 'select'} onChange={e => handleToolChange('select')} >
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><CursorIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Move</Box>
                </div>
            </ToggleButton>
            <Box value={'redo'} className={classes.smToolButtonHold}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}></Box>
                    <Box flexGrow={1} fontSize={8}></Box>
                </div>
            </Box>
            <Box value={'redo'} className={classes.smToolButtonHold}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}></Box>
                    <Box flexGrow={1} fontSize={8}></Box>
                </div>
            </Box>
        </Box>
        {hasParameters ?
            <Box >
                <Divider className={classes.divider} />
                <Box display="flex" flexDirection="row">
                    <Box className={classes.sectionLabel} flexGrow={1} fontSize={12}>PARAMETERS</Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box className={classes.parameterInputContainerFirst}><Box fontSize={12} className={classes.parameterInputLabel}>Buffer</Box><TextField className={classes.parameterInputField} disabled={true} name="buffer" label="" variant="outlined" size="small" margin="dense" value={buffer} InputProps={{classes: {input: classes.parameterInputFont}, endAdornment:<InputAdornment position="end">m</InputAdornment>}} /></Box>
                    <Box className={classes.parameterInputContainer}><Box fontSize={12} className={classes.parameterInputLabel}>Resize</Box><TextField className={classes.parameterInputField} disabled={true} name="resize" label="" variant="outlined" size="small" margin="dense" value={resize} InputProps={{classes: {input: classes.parameterInputFont}, endAdornment:<InputAdornment position="end">%</InputAdornment>}} /></Box>
                    <Box className={classes.parameterInputContainer}><Box fontSize={12} className={classes.parameterInputLabel}>Rotation</Box><TextField className={classes.parameterInputField} disabled={true} name="rotation" label="" variant="outlined" size="small" margin="dense" value={rotation} InputProps={{classes: {input: classes.parameterInputFont}, endAdornment:<InputAdornment position="end">deg</InputAdornment>}} /></Box>
                </Box>
            </Box>: ''
        }
        {hasOutlines ?
            <Box >
                <Divider className={classes.divider} />
                <Box display="flex" flexDirection="row">
                    <Box className={classes.sectionStyleLabel} flexGrow={1} fontSize={12}>OUTLINES</Box>
                </Box>
                <Box display="flex" flexDirection="row">
                <OrangeRadio
                    checked={outlineStyle === '#F6A036'}
                    onChange={handleOutlineStyleChange}
                    value="#F6A036" //rgb(246,160,54)
                    name="radio-button"
                    inputProps={{ 'aria-label': 'orange' }}
                />
                <RedRadio
                    checked={outlineStyle === '#DC3131'}
                    onChange={handleOutlineStyleChange}
                    value="#DC3131" //rgb(220,49,49)
                    name="radio-button"
                    inputProps={{ 'aria-label': 'red' }}
                />
                <BlueRadio
                    checked={outlineStyle === '#2D2F88'}
                    onChange={handleOutlineStyleChange}
                    value="#2D2F88" //rgb(45,47,136)
                    name="radio-button"
                    inputProps={{ 'aria-label': 'blue' }}
                />
                <PinkRadio
                    checked={outlineStyle === '#FF0098'}
                    onChange={handleOutlineStyleChange}
                    value="#FF0098" //rgb(255,0,152)
                    name="radio-button"
                    inputProps={{ 'aria-label': 'pink' }}
                />
                <LimeRadio
                    checked={outlineStyle === '#43E00C'}
                    onChange={handleOutlineStyleChange}
                    value="#43E00C" //rgb(67,224,12)
                    name="radio-button"
                    inputProps={{ 'aria-label': 'lime' }}
                />
                <WhiteRadio
                    checked={outlineStyle === '#FFFFFF'}
                    onChange={handleOutlineStyleChange}
                    value="#FFFFFF" //rgb(255,255,255)
                    name="radio-button"
                    inputProps={{ 'aria-label': 'white' }}
                />
                <Box className={classes.styleInputContainer}><Box fontSize={12} className={classes.styleInputLabel}>Opacity</Box><TextField className={classes.styleInputField} disabled={false} name="opacity_outline" label="" variant="outlined" size="small" margin="dense" value={opacityOutline} InputProps={{classes: {input: classes.parameterInputFont}, endAdornment:<InputAdornment position="end">%</InputAdornment>}} /></Box>
                </Box>
            </Box>: ''
        }

        {hasFill ?
            <Box >
                <Divider className={classes.divider} />
                <Box display="flex" flexDirection="row">
                    <Box className={classes.sectionStyleLabel} flexGrow={1} fontSize={12}>FILL</Box>
                </Box>
                <Box display="flex" flexDirection="row">
                <OrangeRadio
                    checked={fillStyle === '#F6A036'}
                    onChange={handleFillStyleChange}
                    value="#F6A036"
                    name="radio-button"
                    inputProps={{ 'aria-label': 'red' }}
                />
                <RedRadio
                    checked={fillStyle === '#DC3131'}
                    onChange={handleFillStyleChange}
                    value="#DC3131"
                    name="radio-button"
                    inputProps={{ 'aria-label': 'red' }}
                />
                <BlueRadio
                    checked={fillStyle === '#2D2F88'}
                    onChange={handleFillStyleChange}
                    value="#2D2F88"
                    name="radio-button"
                    inputProps={{ 'aria-label': 'red' }}
                />
                <PinkRadio
                    checked={fillStyle === '#FF0098'}
                    onChange={handleFillStyleChange}
                    value="#FF0098"
                    name="radio-button"
                    inputProps={{ 'aria-label': 'red' }}
                />
                <LimeRadio
                    checked={fillStyle === '#43E00C'}
                    onChange={handleFillStyleChange}
                    value="#43E00C"
                    name="radio-button"
                    inputProps={{ 'aria-label': 'red' }}
                />
                <WhiteRadio
                    checked={fillStyle === '#FFFFFF'}
                    onChange={handleFillStyleChange}
                    value="#FFFFFF"
                    name="radio-button"
                    inputProps={{ 'aria-label': 'red' }}
                />
                <Box className={classes.styleInputContainer}><Box fontSize={12} className={classes.styleInputLabel}>Opacity</Box><TextField className={classes.styleInputField} disabled={false} name="opacity_fill" label="" variant="outlined" size="small" margin="dense" value={opacityFill} InputProps={{classes: {input: classes.parameterInputFont}, endAdornment:<InputAdornment position="end">%</InputAdornment>}} /></Box>
                </Box>
            </Box>: ''
        }
        { hasLinetype ?
            <Box >
                <Divider className={classes.divider} />
                <Box display="flex" flexDirection="row">
                    <Box className={classes.sectionLabel} flexGrow={1} fontSize={12}>LINE TYPE</Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    <List component="nav" aria-label="Linetype List">
                        <ListItem 
                            key={0}
                            button
                            className={classes.styleItem}
                            selected={linetypeStyle === "dashedtight"}
                            onClick={(event) => handleLineTypeStyleChange("dashedtight")}
                            >
                            <LineDashedIcon className={classes.styleIcon}/>
                            <ListItemText primary="Dashed tight" classes={{primary:classes.styleItemText}}/>
                        </ListItem>
                        <Divider />
                        <ListItem 
                            key={1}
                            button
                            className={classes.styleItem}
                            selected={linetypeStyle === "dashedloose"}
                            onClick={(event) => handleLineTypeStyleChange("dashedloose")}
                            >
                            <LineDashedIcon2 className={classes.styleIcon}/>
                            <ListItemText primary="Dashed Loose" classes={{primary:classes.styleItemText}}/>
                        </ListItem>
                        <Divider />
                        <ListItem 
                            key={2}
                            button
                            className={classes.styleItem}
                            selected={linetypeStyle === "solid"}
                            onClick={(event) => handleLineTypeStyleChange("solid")}
                            >
                            <LineThinIcon className={classes.styleIcon}/>
                            <ListItemText primary="Solid" classes={{primary:classes.styleItemText}}/>
                        </ListItem>
                    </List>
                </Box>
            </Box>: ''
        }
        { hasLineweight ?
            <Box >
                <Divider className={classes.divider} />
                <Box display="flex" flexDirection="row">
                    <Box className={classes.sectionLabel} flexGrow={1} fontSize={12}>LINE WEIGHT</Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    <List component="nav" aria-label="Linetype List">
                        <ListItem 
                            key={0}
                            button
                            className={classes.styleItem}
                            selected={lineweightStyle === "hairline"}
                            onClick={(event) => handleLineWeightStyleChange("hairline")}
                            >
                            <LineThinIcon className={classes.styleIcon}/>
                            <ListItemText primary="Hairline" classes={{primary:classes.styleItemText}}/>
                        </ListItem>
                        <Divider />
                        <ListItem 
                            key={1}
                            button
                            className={classes.styleItem}
                            selected={lineweightStyle === "thin"}
                            onClick={(event) => handleLineWeightStyleChange("thin")}
                            >
                            <LineNormalIcon className={classes.styleIcon}/>
                            <ListItemText primary="Thin" classes={{primary:classes.styleItemText}}/>
                        </ListItem>
                        <Divider />
                        <ListItem 
                            key={2}
                            button
                            className={classes.styleItem}
                            selected={lineweightStyle === "thick"}
                            onClick={(event) => handleLineWeightStyleChange("thick")}
                            >
                            <LineThickIcon className={classes.styleIcon}/>
                            <ListItemText primary="Thick" classes={{primary:classes.styleItemText}}/>
                        </ListItem>
                    </List>
                </Box>
            </Box>: ''
        }

        { hasAreameasurement ?
        <Box >
            <Divider className={classes.divider} />
            <Box display="flex" flexDirection="row">
                <Box className={classes.sectionLabel} flexGrow={1} fontSize={12}>AREA MEASUREMENT</Box>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box className={classes.areaIconContainer}><AreaSizeIcon className={classes.miniIcon} /></Box>
                <TextField className={classes.measurementField} name="area_measurement_unit" label="" variant="outlined" size="small" margin="dense" value={areaMeasurementUnit} onChange={e => handleMeasureAreaUnitChange(e.target.value)} InputProps={{classes: {input: classes.textItemFont}}} select >
                    <MenuItem className={classes.measurementOptionLabel} value={'ha'}>Hectares</MenuItem>
                    <MenuItem className={classes.measurementOptionLabel} value={'acre'}>Acre</MenuItem>
                    <MenuItem className={classes.measurementOptionLabel} value={'sqft'}>Sq feet</MenuItem>
                    <MenuItem className={classes.measurementOptionLabel} value={'m²'}>m²</MenuItem>
                    <MenuItem className={classes.measurementOptionLabel} value={'km²'}>km²</MenuItem>
                </TextField>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box fontSize={12} className={classes.measurementLabel}>Area:</Box>
                <TextField className={classes.measurementFieldText} name="areaMeasurement" editable={false} label="" variant="outlined" size="small" margin="dense" value={areaMeasurement} InputProps={{classes: {input: classes.textItemFont}, endAdornment:<InputAdornment position="end">{areaMeasurementUnit}</InputAdornment>}} />
            </Box>
        </Box>: ''
        }
        { hasLinemeasurement ?
        <Box >
            <Divider className={classes.divider} />
            <Box display="flex" flexDirection="row">
                <Box className={classes.sectionLabel} flexGrow={1} fontSize={12}>AREA MEASUREMENT</Box>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box fontSize={12} className={classes.measurementLabel}>Length:</Box>
                <TextField className={classes.measurementFieldText} name="lineMeasurement" editable={false} label="" variant="outlined" size="small" margin="dense" value={lineMeasurement} InputProps={{classes: {input: classes.textItemFont}}} />
                <Box className={classes.measurementLengthContainer}>
                    <TextField className={classes.measurementField} name="line_measurement_unit" label="" variant="outlined" size="small" margin="dense" value={lineMeasurementUnit} onChange={e => handleMeasureLineUnitChange(e.target.value)} InputProps={{classes: {input: classes.textItemFont}}} select >
                        <MenuItem className={classes.measurementOptionLabel} value={'mile'}>Mile</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={'ft'}>Feet</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={'m'}>Metres</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={'km'}>Kilometers</MenuItem>
                    </TextField>
                </Box>
            </Box>
        </Box>: ''
        }
        
        { hasTextFont ?
        <Box >
            <Divider className={classes.divider} />
            <Box display="flex" flexDirection="row">
                <Box className={classes.sectionLabel} flexGrow={1} fontSize={12}>FONT</Box>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box className={classes.fontContainerFirst}>
                    <TextField className={classes.measurementField} name="font_font" label="" variant="outlined" size="small" margin="dense" value={fontFont} onChange={e => setFontFont(e.target.value)} InputProps={{classes: {input: classes.textItemFont}}} select >
                        <MenuItem className={classes.measurementOptionLabel} value={"Arial"}>Arial</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={"'Courier New'"}>Courier New</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={"'Open Sans'"}>Open Sans</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={"Verdana"}>Verdana</MenuItem>
                    </TextField>
                </Box>
                <Box className={classes.fontContainer}>
                    <TextField className={classes.measurementField} name="font_size" label="" variant="outlined" size="small" margin="dense" value={fontSize} onChange={e => setFontSize(e.target.value)} InputProps={{classes: {input: classes.textItemFont}}} select >
                        <MenuItem className={classes.measurementOptionLabel} value={6}>6px</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={8}>8px</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={11}>11px</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={12}>12px</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={16}>16px</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={18}>18px</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={20}>20px</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={24}>24px</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={28}>28px</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={32}>32px</MenuItem>
                    </TextField>
                </Box>
                <Box className={classes.fontContainer}>
                    <TextField className={classes.measurementField} name="font_type" label="" variant="outlined" size="small" margin="dense" value={fontType} onChange={e => setFontType(e.target.value)} InputProps={{classes: {input: classes.textItemFont}}} select >
                        <MenuItem className={classes.measurementOptionLabel} value={'normal'}>Normal</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={'bold'}>Bold</MenuItem>
                        <MenuItem className={classes.measurementOptionLabel} value={'italic'}>italic</MenuItem>
                    </TextField>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box flexGrow={1} className={classes.fontFieldTextLabel} fontSize={12}>Label text</Box>
            </Box>
            <Box display="flex" flexDirection="row">
                <TextField className={classes.fontFieldText} name="font_text" label="" variant="outlined" size="small" margin="dense" value={fontText} InputProps={{classes: {input: classes.textItemFont} }} onChange={e => setFontText(e.target.value)} InputProps={{classes: {input: classes.textItemFont}}} />
            </Box>
        </Box>: ''
        }
        <Box display="flex" flexDirection="row" className={classes.sectionContainer}>
            <Button value={'undo'} className={classes.smToolButton} onClick={e => handleToolSingleAction('undo')}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><UndoIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Undo</Box>
                </div>
            </Button>
            <Button value={'redo'} className={classes.smToolButton} onClick={e => handleToolSingleAction('redo')}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><RedoIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Redo</Box>
                </div>
            </Button>
            <Button value={'trash'} className={classes.smToolButton} onClick={e => handleToolSingleAction('deleteObject')}>
                <div className={classes.LabelContainer}>
                    <Box className={classes.iconContainer} flexGrow={1}><TrashIcon className={classes.icon} /></Box>
                    <Box flexGrow={1} fontSize={8}>Delete</Box>
                </div>
            </Button>
        </Box>
        </div>
    </div>
  );
}