/**
 * Returns the minX, minY, maxX, maxY from polygon.
 * 
 */
export default function getScaleFromExtent(mapObj, extent) {

    const ppi = 72;
    const inches = 39.37007874015748031496062992126;
    let scale;

    const xmin = extent[0];
    const ymin = extent[1];
    const xmax = extent[2];
    const ymax = extent[3];
    const mapSize = mapObj.getSize();
    const mapwidth = mapSize[0];
    const mapheight = mapSize[1];

    const xRatio = ((parseFloat(xmax) - parseFloat(xmin)) / parseFloat(mapwidth));
    const yRatio = ((parseFloat(ymax) - parseFloat(ymin)) / parseFloat(mapheight));
    //console.log("xRatio: "+xRatio+", yRation: "+yRatio+", mw: "+mapwidth+", mh"+mapheight);
    if (xRatio > yRatio){
        scale = Math.round(((xmax - xmin) * inches) / (mapwidth/ppi));
    } else {
        scale = Math.round(((ymax - ymin) * inches) / (mapheight/ppi));
    }
    //console.log("xRatio: "+xRatio+", yRation"+yRatio);
    
    return scale;
  }
