import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';



import { SessionContext } from "../../context/session-context";
import { ActivityContext } from "../../context/activity-context";

/**
 * This script displays the list of addresses that the user can select from.
 * The user actions the display of the mapping window through the selection of an address.
 */

const useStyles = makeStyles({
    root:{
      marginRight: 50,
      marginLeft: 10,
      maxWidth: 380,
      minHeight: 350
    },
    addressContainer:{
        maxWidth: 380,
        maxHeight: 350,
        height: 350,
        overflowY: 'auto',
        backgroundColor: '#ECF4F9',
        border: '1px solid #3D3E4F',

    },
    addressItem:{
        color: '#000',
        backgroundColor: '#FFF',
        '&.MuiListItem-root.Mui-selected': {
          backgroundColor: '#C1DAE9',
        }

    },
    listItemText:{
      fontSize:12,//Insert your required size
    },
    selectButton:{
      marginTop: 20,
      backgroundColor:'#2D2F88',
      color: '#FFF',
      '&:hover': {
        color: '#F6A036',
      },
    }
});

var handleError = function (err) {
  console.log(err);
  return new Response(JSON.stringify({
    code: 400,
    message: 'Stupid network Error'
  }));
};

export default function AddressPanel(props) {
  const classes = useStyles();
  const [sState] = useContext(SessionContext);
  const [aState, aDispatch] = useContext(ActivityContext);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [disabled, setDisabled] = useState(true);
  const [addressList, setAddressList] = useState([]);
  
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index !== -1){
      setDisabled(false);
    }
  };

  /**
   * Display the selected address.
   * This loads the main mapping interface.
   */
  const handleSelect = (event, index) => {
    const a = props.address[selectedIndex];
    //console.log("Address selected:"+a.address+", x="+a.x+", y="+a.y);
    const v = {'type': a.type,'x':a.x,'y': a.y };
    const address = {
      name: a.address,
      value: v
    };

    aDispatch({
      type: "firstLoad",
      payload: { isloading: true, caddress: address, caction:'addresssearch', viewstep:'mapping', orderstep:'productSelect', addressSource: props.searchType}
    });    
    recordSelection(address);
  };

  /**
   * Record the selection of the address into the database.
   * Activates on aState.address change.
   */
  const recordSelection = (address) => {

      const abortController = new AbortController();
      const request = {   
        userid:sState.userid,
        type: address.value.type,
        search: address.name
      };
  
      let mounted = true;
      (async () => {
  
        const res = await fetch(process.env.REACT_APP_NMC_API+'/search/record', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(request),
          signal: abortController.signal,
        }).catch(handleError);
        const data = await res.json();
        if (mounted) {
          //Do nothing.
        }
        const cleanup = () => {
          mounted = false;
          abortController.abort();
        };
        return cleanup;
    })();
  };

  return (
    <div className={classes.root}>
      <div className={classes.addressContainer}>
      <List component="nav" aria-label="Addresses returned">
            {props.address.map((item, i) => (
              <React.Fragment key={i}> 
                <ListItem 
                    key={i}
                    button
                    className={classes.addressItem}
                    selected={selectedIndex === item.id}
                    onClick={(event) => handleListItemClick(event, item.id)}
                    >
                    <ListItemText primary={item.address} classes={{primary:classes.listItemText}}/>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
      </List>
      </div>
      <Button className={classes.selectButton} variant="contained" onClick={handleSelect} disabled={disabled}>
            <Box fontWeight="fontWeightRegular" fontSize={16} >Select</Box>
      </Button>
    </div> 
  );
}