import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ActivityContext } from "../../context/activity-context";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ProjectPaper from "../orderComponents/projectPaper";
import OrderPlotPaper from "../orderComponents/orderPlotPaper";
import OrderData from "../orderComponents/orderData";

export default function OrderSummaryPanel(props) {

    const useStyles = makeStyles({
        root: {
            borderLeft: '1px solid #D2D2D8',
            width: props.width,
            height: props.height,
            backgroundColor: '#EDEDEF',
        },
        summaryContainer:{
            maxHeight:props.height,
            
            width: 600,
            paddingLeft: 90
        },
        summaryTitle: {
            marginTop: 40,
            color: '#447095',
        },
        summaryDescription: {
            color:'#3D3E4F',
            width: 600,
        },

    });

  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [orderType, setOrderType] = useState('');

  
    //Prepare and order the requested item.
    useEffect(() => {
        setOrderType(aState.productSelect.toLowerCase());
    },[aState.productSelect])

  return (
    <div className={classes.root}>
        <Box display="block" className={classes.summaryContainer} justifyContent="center">
            <Box className={classes.summaryTitle} fontWeight="500" fontStyle="italic" fontSize={22} >Order Summary</Box><br />
            <Box className={classes.summaryDescription} fontSize={16}>{process.env.REACT_APP_ORDER_SUMMARY_BODY}</Box><br />
            <ProjectPaper disabled={false}/><br />
            {orderType == 'plot' ? <OrderPlotPaper/> : ''}
            {orderType == 'data' ? <OrderData/> : ''}
            
        </Box>      
    </div>
  );
}