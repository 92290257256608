import React, { useState, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import SaveForm from '../createComponents/SaveProjectsForm';

import { ActivityContext } from "../../context/activity-context";

const useStyles = makeStyles({
    menuButton: {
      color: '#000',
        '&:hover': {
          color: '#F9AF44'
        },
      textDecoration: 'underline',
    }
  });

export default function SaveButton() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [aState, aDispatch] = useContext(ActivityContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  
  return (
    <div>
      <Button className={classes.menuButton} onClick={handleClickOpen}><Box fontSize={12}>Save</Box></Button>
      <SaveForm open={open} onClose={handleClose} />
    </div>
  );
}