import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { ActivityContext } from "../../context/activity-context";

export default function ProjectSummaryPaper(props) {

  const useStyles = makeStyles({
    paper: {
      border: '1px solid #D2D2D8',
      padding: '2px 2px',
      display: 'flex',
      width: 520,
      height: 220,
      backgroundColor:'#FFF',
    },
    projectTitle:{
        paddingTop: 15,
        paddingLeft: 10
    },
    projectText:{
        paddingTop: 15,
        width:250,
        color: '#000',
    },
  });


  const classes = useStyles();
  const [aState, aDispatch] = useContext(ActivityContext);
  const [projectReference, setProjectReference] = useState(aState.projectreference);
  const [projectLocation, setProjectLocation] = useState('');
  const [projectEmail, setProjectEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);


  useEffect(() => {

    let add = aState.caddress.name;
    add = add.replace(/, /g, '\n');
    setProjectLocation(add);

    setIsDisabled(props.disabled);

  },[]);


  useEffect(() => {
    if (projectReference !== aState.projectreference){
        aDispatch({ type: "setreference", payload: { projectreference: projectReference} });
    }
  },[projectReference]);

  useEffect(() => {

    const nValue = {
        name: projectLocation,
        value: aState.caddress.value,
    }
    aDispatch({ type: "setlocation", payload: { caddress: nValue } });
    
  },[projectLocation]);


  return (
    <div className={classes.paper}>
        <Grid container spacing={0}>
            <Grid item xs={5}>
                <Box className={classes.projectTitle} fontWeight={500} fontSize={16}>Project Name</Box>
            </Grid>
            <Grid item xs={7} >
                <TextField className={classes.projectText} disabled={isDisabled} InputProps={{ style: { fontSize: 12, color: '#000' } }} onChange={e => setProjectReference(e.target.value)} name="projectReference" variant="outlined" size="small" value={projectReference} />
            </Grid>
            <Grid item xs={5}>
                <Box className={classes.projectTitle} fontWeight={500} fontSize={16}>Location and text area</Box>
            </Grid>
            <Grid item xs={7}>
                <TextField className={classes.projectText} disabled={isDisabled} InputProps={{ style: { fontSize: 12, color: '#000'} }} onChange={e => setProjectLocation(e.target.value)} name="projectLocation" multiline rows={4} variant="outlined" size="small" value={projectLocation} />
            </Grid>
            <Grid item xs={5}>
                <Box className={classes.projectTitle} fontWeight={500} fontSize={16}>Email address</Box>
            </Grid>
            <Grid item xs={7}>
                <TextField className={classes.projectText} disabled={true} InputProps={{ style: { fontSize: 12, color: '#000' } }} onChange={e => setProjectEmail(e.target.value)} name="email" variant="outlined" size="small" value={projectEmail} />
            </Grid>
        </Grid>
    </div>
  );
}