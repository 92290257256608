import React, { useState, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  menuButton: {
    backgroundColor: '#FFFFFF',
    color: '#3D3E4F',
    '&:hover': {
      backgroundColor: '#3D3E4F',
      color: '#FFF',
    },
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: '#3D3E4F',
      color: '#FFF',
    },
    border: 0,
    marginRight: 4,
    height: 28,
}
  });

export default function MenuButton(props) {
  const classes = useStyles();

  const handleClick = () => {
    props.handleClick();
  };

  return <Button value={props.label} className={classes.menuButton} onClick={handleClick}>
            <Box p={1} fontWeight="fontWeightBold" fontSize={14}>{props.label}</Box>
        </Button>;
}