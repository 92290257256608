/**
 * Gets the data order price.

 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function getDataPrice(params, state, dispatch) {

  dispatch({ type: "isloading", payload: { isloading: true} }); 
  const abortController = new AbortController();

  const request = {
      pricingid: params.pricingid,
      datalayer: params.layer,
      fixedsize: params.fixedsize,
      fixedprice: params.fixedprice,
      geometry: params.geom.geometry,
      area: params.geom.area,
  }

  let mounted = true;
  (async () => {  

      const res = await fetch(process.env.REACT_APP_NMC_API+'/data/price', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(request),
          signal: abortController.signal,
      }).catch(handleError);
      const data = await res.json();
      if (mounted) {
        if (data.status === "success"){
          if (data.price !== state.cprice){
            dispatch({ type: "setprice", payload: { price: data.price} });
          } else {
            dispatch({ type: "setpriceloading", payload: { priceloading: false} });
          }
        }
        dispatch({ type: "isloading", payload: { isloading: false} }); 
      }
      const cleanup = () => {
          mounted = false;
          abortController.abort();
      };
      return cleanup;

  })();

    }
