var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

/**
 * Gets and returns a geom from OS Mastermap data based on an xy.
 */
export default function getGeomFromCoords(x,y, union, currentGeom, aDispatch) {

    const abortController = new AbortController();

      let mounted = true;
      (async () => {  
        const res = await fetch(process.env.REACT_APP_NMC_API+'/mapping/capture?x='+x+'&y='+y+'&union='+union+'&geom='+currentGeom, {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          signal: abortController.signal,
        }).catch(handleError);
        const data = await res.json();
        if (mounted) {
          //console.log("getGeomFromCoords: "+JSON.stringify(data));
          if (data.status === "success"){
            
            const response = {};
            response.geometry = data.geom;
            aDispatch({
              type: "setdbannotation",
              payload: { annotations: response }
            });
          } else {
          }
        }
        const cleanup = () => {
          mounted = false;
          abortController.abort();
        };
        return cleanup;
    
    })();
}