/**
 * Creates or updates a user
 * This is a super call.
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function setUserpassword(params, user) {

        const abortController = new AbortController();

        const request = {
            userid: params.userid,
            ident: params.ident,
            cuserid: user.userid,
            password:user.password,
        }
        let mounted = true;
        (async () => {  
            const res = await fetch(process.env.REACT_APP_NMC_API+'/users/password/set', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(request),
                signal: abortController.signal,
            }).catch(handleError);
            const data = await res.json();
            if (mounted) {
              if (data.status === "success"){

                
              }
            }
            const cleanup = () => {
                mounted = false;
                abortController.abort();
            };
            return cleanup;

        })();

    }
