/**
 * Gets and store print templates that are avilable to the user.
 * Called from UserInterface.js
 */
var handleError = function (err) {
    console.log(err);
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

export default function getPrintTemplate(companyid, dispatch) {

        const abortController = new AbortController();
        const request = {   
            addressid:companyid
          };
    
        let mounted = true;
        (async () => {  
          const res = await fetch(process.env.REACT_APP_NMC_API+'/print/get', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request),
            signal: abortController.signal,
          }).catch(handleError);
          const data = await res.json();
          if (mounted) {
            if (data.status === "success"){
                dispatch({
                    type: "settemplates",
                    payload: { 
                    templates: data.template,
                    }
                });
    
            } else {
            }
          }
          const cleanup = () => {
            mounted = false;
            abortController.abort();
          };
          return cleanup;
      
      })();

    }
